import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<{ breakdown: unknown[] } & Pick<ChildrenProps, 'translationOptions'> } */
const BreakdownTooltip = React.memo(function BreakdownTooltip(props) {
	const { breakdown, translationOptions } = props;

	if (!breakdown?.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-2">
			{breakdown.map((value, index) => (
				<div key={value?.id}>
					<div className="flex items-center gap-1">
						<Prefix
							custom
							className={cn({
								'bg-taxes-1': index + 1 === 1,
								'bg-taxes-2': index + 1 === 2,
								'bg-taxes-3': index + 1 === 3,
								'bg-taxes-4': index + 1 === 4,
								'bg-taxes-5': index + 1 === 5,
								'bg-taxes-6': index + 1 === 6,
								'bg-taxes-7': index + 1 === 7,
								'bg-taxes-8': index + 1 === 8,
								'bg-taxes-9': index + 1 === 9,
								'bg-taxes-10': index + 1 === 10,
							})}
						/>
						<Translation
							translationKey="card.content.table.row.label"
							translationOptions={{
								...translationOptions,
								postfix: {
									key: 'name',
									...translationOptions?.postfix,
								},
								data: {
									...translationOptions?.data,
									current: value,
								},
							}}
							Component={Text}
							size="sm"
						/>
					</div>
				</div>
			))}
		</div>
	);
});

BreakdownTooltip.propTypes = {
	breakdown: PropTypes.array,
	translationOptions: PropTypes.object,
};

export default BreakdownTooltip;
