import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useCardState } from './hooks';
import OnboardingV1Title from './v1/title';
import OnboardingV2Content from './v2/content';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { optional: true },
	card: {
		content: {
			promotion: {
				view: {
					v2: (props) => <OnboardingV2Content {...props} />,
				},
			},
			default: {
				view: {
					v2: (props) => <OnboardingV2Content {...props} />,
				},
			},
			title: {
				v1: (props) => <OnboardingV1Title {...props} />,
			},
		},
	},
};

/** @type { React.FC<Props> } */
const OnboardingCard = React.memo(function OnboardingCard(props) {
	return (
		<BasicCard
			{...props}
			type="onboarding"
			config={CONFIG}
			useCardState={useCardState}
		/>
	);
});

OnboardingCard.displayName = 'OnboardingCard';

OnboardingCard.propTypes = { className: PropTypes.string };

export default OnboardingCard;
