import { CompanyService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';
import { setCompany } from '@asteria/datalayer/stores/app';

import Analytics from '@asteria/utils-analytics';

export const FIELDS = `
_id
id
name

settings {
	currency
	flags
}

config
questions

name
vat
orgnumber
contact {
	phone
	zipCode
	email
	url
	address
	contactFirstName
	contactLastName
}
`;

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export async function fetch(options = {}) {
	const { accessToken, dispatch, dataloader } = options;

	const response = await CompanyService.company
		.fetchOne(
			{ isBulk: true, fields: FIELDS, dataloader },
			{ token: accessToken },
		)
		.catch(() => {});

	dispatch?.(setCompany(response));

	return response;
}

export async function remove({ accessToken, id }) {
	await CompanyService.company.remove({ id: id }, { token: accessToken });

	localStorage.removeItem('wingsRefreshToken');
	localStorage.removeItem('wingsToken');

	const url = new URL('', window.location.origin);
	url.search = window.location.search;

	window.location.replace(url.toString());
}

export async function update({ accessToken, id, settings, dispatch }) {
	try {
		const { company } = await CompanyService.company.update(
			{
				fields: `ok error company { ${FIELDS} }`,
				id: id,
				input: settings,
			},
			{ token: accessToken },
		);

		dispatch?.(setCompany(company));

		return company;
	} catch (e) {
		// Do nothing
	}
}

export async function updateSelf({ accessToken, input, dispatch }) {
	try {
		const { company } = await CompanyService.company.updateMyCompany(
			{ fields: `ok error company { ${FIELDS} }`, input: input },
			{ token: accessToken },
		);

		dispatch?.(setCompany(company));

		return company;
	} catch (e) {
		// Do nothing
	}
}

export async function refreshSettingsFlags({ accessToken, dispatch }) {
	const company = store.getState()?.app?.company;
	const flags = company?.settings?.flags ?? {};

	Analytics.event('company.settings.flags.refresh', {
		company: company,
		data: flags,
	});

	return updateSelf({
		accessToken: accessToken,
		dispatch: dispatch,
		input: { settings: { flags: flags } },
	});
}

export async function clean({ accessToken, id }) {
	return CompanyService.company.clean(
		{ filters: { _id: id } },
		{ token: accessToken },
	);
}

export async function health({ accessToken, filters, dataloader }) {
	return CompanyService.company
		.sendRequest(
			{
				query: `
					query Health(
						$filters: [HealthFilters!]!
						$companyId: ObjectId
					) {
						health(filters: $filters, companyId: $companyId) {
							score
							trend
							actions {
								type
								postfix
								data
							}
						}
					}
				`,
				variables: { filters: filters },
				dataloader: dataloader,
			},
			{ token: accessToken },
		)
		.then((data) => {
			if (data?.response) {
				return data?.response;
			}

			if (data?.health) {
				return data?.health;
			}

			return data;
		});
}

export async function employees({
	accessToken,
	filters,
	paymentsStart,
	paymentsEnd,
	dataloader,
}) {
	const response = await CompanyService.company
		.sendRequest(
			{
				query: `
					query Employees($paymentsStart: Date, $paymentsEnd: Date) {
						employees {
							edges {
								node {
									payments(startDate: $paymentsStart, endDate: $paymentsEnd) {
										paymentDate
										status
										type
										sums {
											display {
												total
											}
										}
									}
									name
								}
							}
						}
					}
				`,
				variables: { filters: filters, paymentsStart, paymentsEnd },
				dataloader: dataloader,
			},
			{ token: accessToken },
		)
		.then((data) => {
			if (data?.response) {
				return data?.response;
			}

			if (data?.employees) {
				return data?.employees;
			}

			return data;
		})
		.then(({ edges }) => edges.map(({ node }) => node))
		.catch(() => []);

	return response;
}
