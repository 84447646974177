import React from 'react';

import PropTypes from 'prop-types';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

import Skeleton from '@asteria/component-core/skeleton';
import { TextGroup } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const PromotionContent = React.memo(function PromotionContent(props) {
	const { importing, loading } = props;

	return (
		<div className="flex flex-col gap-4 flex-1">
			{!(importing || loading) ? (
				<TextGroup>
					<Content.Title {...props} />
					<Content.Text {...props} />
				</TextGroup>
			) : null}
			<div className="grid lg:grid-cols-2 gap-4 items-center">
				<div className="flex flex-col gap-4">
					<div className="flex gap-2">
						<Skeleton animated={importing || loading} />
						<Skeleton
							animated={importing || loading}
							className="w-10"
						/>
					</div>
					{Array.from({ length: 5 }).map((_, index) => (
						<div className="flex gap-2" key={index}>
							<Skeleton
								animated={importing || loading}
								className="w-10"
							/>
							<Skeleton animated={importing || loading} />
						</div>
					))}
				</div>
				<div className={cn('order-first lg:order-last')}>
					<ResponsiveContainer
						height={200}
						className={cn({
							'animate-pulse': importing || loading,
						})}
					>
						<PieChart>
							<Pie
								data={[
									{ value: 100 / 3 },
									{ value: 100 / 3 },
									{ value: 100 / 3 },
								]}
								cx="50%"
								cy="50%"
								strokeWidth={5}
								innerRadius="35%"
								// outerRadius={80}
								fill="var(--color-skeleton-normal-background, var(--color-progress-background, rgba(235, 231, 226, 1)))"
								isAnimationActive={false}
							/>
						</PieChart>
					</ResponsiveContainer>
					<Content.Loading {...props} />
				</div>
			</div>
		</div>
	);
});

PromotionContent.propTypes = {
	importing: PropTypes.bool,
	loading: PropTypes.bool,
};

export default PromotionContent;
