import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';
import { useBlocker, useParams } from 'react-router-dom';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import { useModalActions } from '../../../components/modal-collection';
import { LoanLeaveModal } from '../../../modals';

const LeaveWrapper = React.memo((props) => {
	const { children, onAction } = props;

	const { id } = useParams();
	const { getValues } = useFormContext();
	const { isDirty } = useFormState();
	const { open } = useModalActions();

	const shouldBlock = React.useCallback(
		({ currentLocation, nextLocation }) => {
			return (
				!nextLocation?.state?.backgroundLocation &&
				currentLocation.pathname.includes('financial/loan') &&
				!nextLocation.pathname.includes('financial/loan') &&
				isDirty
			);
		},
		[isDirty],
	);

	const blocker = useBlocker(shouldBlock);

	React.useEffect(() => {
		if (blocker.state === 'blocked') {
			blocker.reset();

			open(<LoanLeaveModal />, {
				simulationId: id,
				next: blocker.location.pathname,
				form: cloneDeep(getValues()),
				callback: onAction,
			});
		}
	}, [blocker, blocker.state, getValues, id, onAction, open]);

	return <>{children}</>;
});

LeaveWrapper.displayName = 'LeaveWrapper';
LeaveWrapper.propTypes = { children: PropTypes.node, onAction: PropTypes.func };

export default LeaveWrapper;
