import React from 'react';
import { createPortal } from 'react-dom';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import animationListener from '@asteria/utils-funcs/animationListener';
import useWrapper from '@asteria/utils-hooks/useWrapper';

import Content from './content';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Props> } */
const Modal = React.memo(function Modal(props) {
	const { open } = props;

	const wrapper = useWrapper('asteria-modals-container');

	return (
		<>
			{createPortal(
				<CSSTransition
					in={open}
					appear
					unmountOnExit
					classNames="asteria-modal"
					addEndListener={animationListener}
				>
					<Content {...props} />
				</CSSTransition>,
				wrapper,
			)}
		</>
	);
});

Modal.displayName = 'Modal';

Modal.propTypes = {
	className: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	size: PropTypes.string,
	closeOnClickOutside: PropTypes.bool,
	closeOnEscape: PropTypes.bool,
	children: PropTypes.node,
};

Modal.defaultProps = {
	open: true,
	size: 'md',
	closeOnClickOutside: true,
	closeOnEscape: true,
};

export default Modal;
