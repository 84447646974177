import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
	addMonths,
	endOfMonth,
	format,
	formatISO,
	parseISO,
	startOfISOWeek,
	startOfMonth,
	startOfYear,
} from 'date-fns';
import { isEqual } from 'lodash-es';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import formatNumber from '@asteria/utils-funcs/formatNumber';

import { ACTIONS } from '../constants';

import {
	setCurrentDate,
	setFilters,
	setSelectedDate,
	setTimesliceSize,
	toggleFilter,
} from './app';

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}

	if (size === 'month') {
		return startOfMonth(date);
	}

	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const roundedToNextSignficant = (val, space = 1) => {
	if (val === 0) {
		return 0;
	}

	const digits = Math.floor(Math.log10(Math.abs(val))) - 1;
	const base =
		(Math.ceil(Math.abs(val) / 10 ** digits) + space) * 10 ** digits;
	return val < 0 ? -base : base;
};

const roundToPowOfTen = ({ value, parts = 2 }) => {
	if (value === 0) {
		return 0;
	}

	const digits = Math.floor(Math.log10(Math.abs(value))) + 1;
	const roundedValue = Math.round(10 ** digits);
	const partialValue = roundedValue / parts;

	const base = Math.round(
		Math.ceil(Math.abs(value) / partialValue) * partialValue,
	);

	return value < 0 ? -base : base;
};

// eslint-disable-next-line no-unused-vars
const roundedToPrevSignficant = (val) => {
	if (val === 0) {
		return 0;
	}
	/*
	const d = Math.floor(Math.log10(val < 0 ? -val : val));
	const pw = 1 - d;
	const mag = 10 ** pw;
	const shifted = Math.ceil(val * mag);

	return Math.round(shifted / mag);
	*/
	const digits = Math.floor(Math.log10(Math.abs(val))) - 1;
	const base = Math.floor(val / 10 ** digits) * 10 ** digits;
	return base;
};

const getMinimalStep = (value) => (value === 0 ? 1 : value);

const roundGraphValue = (value) => roundedToNextSignficant(value);

const getLabels = (options) => {
	const {
		valMax = 2500,
		valMin = 0,
		steps = 3,
		prefix = '±',
		multiplier = 1.1,
	} = options;

	// adding padding to min and max
	const expandedMin = Math.round(valMin * multiplier);
	const expandedMax = Math.round(valMax * multiplier);

	// find approximate steps
	const valueSums = Math.abs(expandedMin) + Math.abs(expandedMax);
	const interval = Math.round(valueSums / steps + 1);

	let topValue;
	let graphInterval;

	// both values less than 0
	if (valMax <= 0 && valMin <= 0) {
		topValue = 0;
		graphInterval = roundGraphValue(interval);
	}

	// both values more than 0
	if (valMax >= 0 && valMin >= 0) {
		topValue = roundGraphValue(interval) * steps;
		graphInterval = roundGraphValue(interval);
	}

	// graph crosses zero
	if (valMax * valMin < 0) {
		const positiveSteps = getMinimalStep(
			Math.round(Math.abs(expandedMax) / interval),
		); // count of lines above zero

		const negativeSteps = getMinimalStep(
			Math.round(Math.abs(expandedMin) / interval),
		); // count of lines below zero

		const aboveZeroStep = Math.round(Math.abs(expandedMax) / positiveSteps); // step for line above zero
		const belowZeroStep = Math.round(Math.abs(expandedMin) / negativeSteps); // step for line below zero

		if (Math.abs(aboveZeroStep) > Math.abs(belowZeroStep)) {
			topValue = roundGraphValue(aboveZeroStep) * positiveSteps;
			graphInterval = roundGraphValue(aboveZeroStep);

			if (positiveSteps === steps) {
				graphInterval = roundGraphValue(
					expandedMax / (positiveSteps - 1),
				);

				topValue = graphInterval * (positiveSteps - 1);
			}
		} else {
			topValue =
				roundGraphValue(belowZeroStep) * negativeSteps * -1 +
				roundGraphValue(belowZeroStep) * steps;
			graphInterval = roundGraphValue(belowZeroStep);

			if (negativeSteps === steps) {
				graphInterval = roundGraphValue(
					(expandedMin / (negativeSteps - 1)) * -1,
				);

				topValue = graphInterval * positiveSteps;
			}
		}
	}

	// graph data is empty

	if (
		valMax === Number.MIN_VALUE ||
		valMax === Number.MAX_VALUE ||
		valMin === Number.MAX_VALUE ||
		(valMin === 0 && valMax === 0)
	) {
		topValue = 750;
		graphInterval = 250;
	}

	const labels = new Array(steps + 1)
		.fill()
		.map((_, index) => ({
			value: topValue - graphInterval * index,
			label: `${prefix || ''}${formatNumber(
				topValue - graphInterval * index,
				false,
				graphInterval > 1_000,
				!!prefix,
				{
					thousand: '',
				},
			)}`,
		}))
		.reverse();

	return labels;
};

const updateGraph = (state, data) => {
	const {
		dates: { currentDate } = {},
		data: { range: $range = [], barGroups = {}, centerZero = true } = {},
		statistics,
	} = state;

	const {
		barLayout: $barLayout = 'grouped',
		scale = 'dynamic',
		lines = false,
		mode = 'default',
		dynamicCredit = 0,
	} = data;

	const isCreditMode = mode === 'credit';

	let range = Array.from($range);

	if (FeatureService.isActive('graph-large-bars')) {
		const selectedMonth = format(
			addMonths(parseISO(currentDate), 1),
			'yyyy-MM',
		);

		const index = range.findIndex(
			(object) =>
				object?.id &&
				format(parseISO(object?.id), 'yyyy-MM') === selectedMonth,
		);

		if (index !== -1) {
			const min = Math.max(index - 3, 0);
			const max = min + 7;

			range = range.slice(min, max);
		}
	}

	let barLayout = $barLayout;

	if (isCreditMode) {
		barLayout = 'grouped';
	}

	let barsMaxVal, barsMinVal;

	if (scale === 'static') {
		barsMaxVal = statistics?.transactions?.max;
		barsMinVal = statistics?.transactions?.min;

		if (barLayout === 'stacked') {
			barsMaxVal = statistics?.transactions?.deposit?.max;
			barsMinVal = -statistics?.transactions?.withdraw?.max;
		}
	}

	if (barsMaxVal === undefined) {
		barsMaxVal = range.slice(1, -1).reduce((max, { id }) => {
			const { bars = [{ value: 0 }] } = barGroups[id] || {};
			return bars.reduce(
				(localMax, bar) => Math.max(localMax, bar.value || 0),
				max,
			);
		}, Number.MIN_VALUE);
	}

	if (barsMinVal === undefined) {
		barsMinVal = range.slice(1, -1).reduce((min, { id }) => {
			const { bars = [{ value: 0 }] } = barGroups[id] || {};
			return bars.reduce(
				(localMin, bar) => Math.min(localMin, bar.value || 0),
				min,
			);
		}, Number.MAX_VALUE);
	}

	if (barsMaxVal === Number.MIN_VALUE) {
		barsMaxVal = 0;
	}

	if (barsMinVal === Number.MAX_VALUE) {
		barsMinVal = 0;
	}

	let linesMaxVal, linesMinVal;

	if (scale === 'static') {
		linesMaxVal = statistics?.account?.max;
		linesMinVal = statistics?.account?.min;
	}

	if (linesMaxVal === undefined) {
		linesMaxVal = range.slice(0, -1).reduce((max, { id }) => {
			const { lines = [{ value: 0 }] } = barGroups[id] || {};
			return lines.reduce(
				(localMax, line) =>
					Math.max(
						localMax,
						line.min || line.value,
						line.max || line.value,
						line.value,
					),
				max,
			);
		}, -Number.MAX_VALUE);
	}

	if (linesMinVal === undefined) {
		linesMinVal = range.slice(0, -1).reduce(
			(min, { id }) => {
				const { lines = [{ value: 0 }] } = barGroups[id] || {};
				return lines.reduce(
					(localMin, line) =>
						Math.min(
							localMin,
							line.min || line.value,
							line.max || line.value,
							line.value,
						),
					min,
				);
			},
			isCreditMode ? -1000 : Number.MAX_VALUE,
		);
	}

	if (centerZero) {
		// barsMaxVal = Math.max(Math.abs(barsMaxVal), Math.abs(barsMinVal));

		if (barLayout === 'grouped') {
			barsMaxVal = Math.max(Math.abs(barsMinVal), barsMaxVal);
		}

		barsMinVal = barLayout === 'grouped' ? 0 : barsMinVal;
	}

	const layoutSteps = lines ? lines : barLayout === 'grouped' ? 3 : 4;

	const newBarSteps = getLabels({
		valMax: barsMaxVal,
		valMin: barsMinVal,
		steps: layoutSteps,
	});

	const { value: barsMaxValue } = newBarSteps[newBarSteps.length - 1] || 0;
	const { value: barsMinValue } = newBarSteps[0] || 0;

	const lineMax = linesMaxVal;

	const lineMin = isCreditMode
		? roundToPowOfTen({
				value: Math.min(linesMinVal, dynamicCredit * -1),
		  })
		: linesMinVal;

	const newLineSteps = getLabels({
		valMax: lineMax,
		valMin: lineMin,
		steps: layoutSteps,
		prefix: false,
		multiplier: isCreditMode ? 1 : 1.1,
	});

	const { value: linesMaxValue } = newLineSteps[newLineSteps.length - 1] || 0;
	const { value: linesMinValue } = newLineSteps[0] || 0;

	const graphMax = Math.max(barsMaxVal, linesMaxVal, 0);
	const graphMin = Math.min(barsMinVal, linesMinVal, 0);

	const graphSteps = getLabels({
		valMax: isCreditMode ? roundToPowOfTen({ value: graphMax }) : graphMax,
		valMin: isCreditMode ? roundToPowOfTen({ value: graphMin }) : graphMin,
		steps: layoutSteps,
		prefix: false,
	});

	const { value: graphMaxValue } = graphSteps[graphSteps.length - 1] || 0;
	const { value: graphMinValue } = graphSteps[0] || 0;

	state.data.source = {
		bars: { min: barsMinValue, max: barsMaxValue },
		lines: { min: linesMinValue, max: linesMaxValue },
		graph: { min: graphMinValue, max: graphMaxValue },
	};

	state.data.barsMaxValue = barsMaxValue;
	state.data.barsMinValue = barsMinValue;
	state.data.barsSteps = newBarSteps.reverse();

	state.data.linesMaxValue = linesMaxValue;
	state.data.linesMinValue = linesMinValue;
	state.data.linesSteps = newLineSteps.reverse();

	state.data.graphMaxValue = graphMaxValue;
	state.data.graphMinValue = graphMinValue;
	state.data.steps = graphSteps.reverse();
};

const graphData = {
	visibleTags: [],
	activeBars: [],
	barsMaxValue: 27300,
	barsMinValue: 0,
	linesMaxValue: 500000,
	linesMinValue: -250000,
	graphMaxValue: 500000,
	graphMinValue: -250000,
	steps: [
		{
			value: 500000,
			label: '+500',
		},
		{
			value: 250000,
			label: '+250',
		},
		{
			value: 0,
			label: '0',
		},
		{
			value: -250000,
			label: '-250',
		},
	],
	barsSteps: [
		{
			value: 27300,
			label: '±27',
		},
		{
			value: 18200,
			label: '±18',
		},
		{
			value: 9100,
			label: '±9',
		},
		{
			value: 0,
			label: '±0',
		},
	],
	linesSteps: [
		{
			value: 500000,
			label: '+500',
		},
		{
			value: 250000,
			label: '+250',
		},
		{
			value: 0,
			label: '0',
		},
		{
			value: -250000,
			label: '-250',
		},
	],
	range: [
		{
			id: '2021-04-01',
			types: ['history'],
			prefix: '',
			label: 'April',
		},
		{
			id: '2021-05-01',
			types: ['history'],
			prefix: '',
			label: 'Maj',
		},
		{
			id: '2021-06-01',
			types: ['history'],
			prefix: '',
			// eslint-disable-next-line spellcheck/spell-checker
			label: 'Juni',
		},
		{
			id: '2021-07-01',
			types: ['history'],
			prefix: '',
			// eslint-disable-next-line spellcheck/spell-checker
			label: 'Juli',
		},
		{
			id: '2021-08-01',
			types: ['history'],
			prefix: '',
			// eslint-disable-next-line spellcheck/spell-checker
			label: 'Augusti',
		},
		{
			id: '2021-09-01',
			types: ['history'],
			prefix: '',
			label: 'September',
		},
		{
			id: '2021-10-01',
			types: ['history'],
			prefix: '',
			// eslint-disable-next-line spellcheck/spell-checker
			label: 'Oktober',
		},
		{
			id: '2021-11-01',
			types: ['today'],
			// eslint-disable-next-line spellcheck/spell-checker
			prefix: 'Idag',
			label: 'November',
		},
		{
			id: '2021-12-01',
			types: ['forecast'],
			prefix: '',
			label: 'December',
		},
		{
			id: '2022-01-01',
			types: ['forecast'],
			prefix: '2022',
			// eslint-disable-next-line spellcheck/spell-checker
			label: 'Januari',
		},
	],
	barGroups: {},
	originalGroups: {},
	fetching: false,
	graphGroupWidth: 150,
	hoverGroups: [],
	hoverBars: [],
	layout: {
		barLayout: 'grouped',
		layout: 'flat',
		showAccountOnSearch: true,
	},
	activeGroups: [
		formatISO(startOfMonth(new Date()), { representation: 'date' }),
	],
};

const initialState = {
	data: graphData,
	dates: {
		selectedDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
		currentDate: format(addMonths(new Date(), -3), 'yyyy-MM-dd'),
	},

	predictions: [],

	hasUnpaid: false,
	hasOverdue: false,
	hasForecast: false,
	hasCredit: false,
	hasRisk: false,
	options: { lineGraph: true, barGraph: true },

	legends: {
		selected: [],
		highlight: null,
	},

	statistics: {
		timeslice: 'MONTH',
		account: {
			min: 0,
			max: 0,
		},
		transactions: {
			min: 0,
			max: 0,
			deposit: {
				min: 0,
				max: 0,
			},
			withdraw: {
				min: 0,
				max: 0,
			},
		},
	},

	active: null,

	loading: true,
};

function setGraphGroup(state, { id, data, deletePrevious }) {
	if (deletePrevious) {
		state.data.barGroups = {};
	}
	state.data.barGroups[id] = data;
	state.data.originalGroups[id] = data;

	let hasUnpaid = false;
	let hasOverdue = false;
	let hasForecast = false;
	let hasCredit = false;
	let hasRisk = false;

	const allBars = state.data.range
		.slice(1, -3)
		.map(({ id }) => state.data.barGroups[id] || {})
		.reduce(
			(a, { bars = [] }) => [
				...a,
				...bars.reduce((b, { parts = [] }) => [...b, ...parts], []),
			],
			[],
		);

	const allLines = state.data.range
		.slice(1, -3)
		.map(({ id }) => state.data.barGroups[id] || {})
		.reduce((a, { lines = [] }) => [...a, ...lines], []);

	for (let i = 0; i < allBars.length; i += 1) {
		hasUnpaid =
			hasUnpaid ||
			allBars[i].data.status === 'UNPAID' ||
			allBars[i].data.status === 'SIGNED';
		hasOverdue = hasOverdue || allBars[i].data.status === 'OVERDUE';
		hasForecast = hasForecast || allBars[i].data.status === 'FORECAST';
	}

	for (let i = 0; i < allLines.length; i += 1) {
		hasRisk =
			hasRisk || allLines[i].info.some(({ type }) => type === 'risk');

		hasForecast =
			hasForecast ||
			(allLines[i].types.includes('forecast') && !!allLines[i].value);

		hasCredit = hasCredit || allLines[i].types.includes('credit');
	}

	if (hasUnpaid !== state.hasUnpaid) {
		state.hasUnpaid = hasUnpaid;
	}

	if (hasOverdue !== state.hasOverdue) {
		state.hasOverdue = hasOverdue;
	}

	if (hasOverdue !== state.hasOverdue) {
		state.hasOverdue = hasOverdue;
	}

	if (hasForecast !== state.hasForecast) {
		state.hasForecast = hasForecast;
	}

	if (hasCredit !== state.hasCredit) {
		state.hasCredit = hasCredit;
	}
}

export const graphSlice = createSlice({
	name: 'graph',
	initialState: initialState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},

		setActive: (state, { payload }) => {
			state.active = payload;
		},

		setStatistics: (state, { payload }) => {
			state.statistics = payload;
		},

		setData: (state, actions) => {
			state.data = actions.payload;
			state.data.originalGroups = {
				...(state?.data?.barGroups || {}),
			};
		},
		updateAxis: (state, action) => {
			const layout =
				action?.payload?.barLayout === 'tabs'
					? { ...action.payload, barLayout: 'grouped' }
					: action.payload;

			updateGraph(state, layout);
		},
		setGroup: (state, action) => {
			const { id, data, deletePrevious } = action.payload;
			setGraphGroup(state, { id, data, deletePrevious });
		},
		setMultipleGroups: (state, action) => {
			for (const { id, data, deletePrevious } of action.payload) {
				setGraphGroup(state, { id, data, deletePrevious });
			}
		},
		setGroups: (state, action) => {
			const { items = {}, deletePrevious } = action.payload;
			if (deletePrevious) {
				state.data.barGroups = {};
			}

			state.data.barGroups = {
				...state.data.barGroups,
				...items,
			};

			let hasUnpaid = false;
			let hasOverdue = false;
			let hasForecast = false;
			let hasCredit = false;
			let hasRisk = false;

			const allBars = state.data.range
				.slice(1, -3)
				.map(({ id }) => state.data.barGroups[id] || {})
				.reduce(
					(a, { bars = [] }) => [
						...a,
						...bars.reduce(
							(b, { parts = [] }) => [...b, ...parts],
							[],
						),
					],
					[],
				);

			const allLines = state.data.range
				.slice(1, -3)
				.map(({ id }) => state.data.barGroups[id] || {})
				.reduce((a, { lines = [] }) => [...a, ...lines], []);

			for (let i = 0; i < allBars.length; i += 1) {
				hasUnpaid =
					hasUnpaid ||
					allBars[i].data.status === 'UNPAID' ||
					allBars[i].data.status === 'SIGNED';
				hasOverdue = hasOverdue || allBars[i].data.status === 'OVERDUE';
				hasForecast =
					hasForecast || allBars[i].data.status === 'FORECAST';
			}

			for (let i = 0; i < allLines.length; i += 1) {
				hasRisk =
					hasRisk ||
					allLines[i].info.some(({ type }) => type === 'risk');

				hasForecast =
					hasForecast ||
					(allLines[i].types.includes('forecast') &&
						!!allLines[i].value);

				hasCredit = hasCredit || allLines[i].types.includes('credit');
			}

			if (hasUnpaid !== state.hasUnpaid) {
				state.hasUnpaid = hasUnpaid;
			}

			if (hasOverdue !== state.hasOverdue) {
				state.hasOverdue = hasOverdue;
			}

			if (hasOverdue !== state.hasOverdue) {
				state.hasOverdue = hasOverdue;
			}

			if (hasForecast !== state.hasForecast) {
				state.hasForecast = hasForecast;
			}

			if (hasCredit !== state.hasCredit) {
				state.hasCredit = hasCredit;
			}
		},
		setGraphGroupSize: () => {},
		requestNewRange: () => {},
		setRange: (state, action) => {
			state.data.range = action.payload;

			let hasUnpaid = false;
			let hasOverdue = false;
			let hasForecast = false;
			let hasCredit = false;
			let hasRisk = false;

			if (state.data.barGroups) {
				const allBars = state.data.range
					.slice(1, -3)
					.map(({ id }) => state.data.barGroups[id] || {})
					.reduce(
						(a, { bars = [] }) => [
							...a,
							...bars.reduce(
								(b, { parts = [] }) => [...b, ...parts],
								[],
							),
						],
						[],
					);

				const allLines = state.data.range
					.slice(1, -3)
					.map(({ id }) => state.data.barGroups[id] || {})
					.reduce((a, { lines = [] }) => [...a, ...lines], []);

				for (let i = 0; i < allBars.length; i += 1) {
					hasUnpaid =
						hasUnpaid ||
						allBars[i].data.status === 'UNPAID' ||
						allBars[i].data.status === 'SIGNED';
					hasOverdue =
						hasOverdue || allBars[i].data.status === 'OVERDUE';
					hasForecast =
						hasForecast || allBars[i].data.status === 'FORECAST';
					hasCredit =
						hasCredit || allBars[i].data.status === 'CREDIT';
				}

				for (let i = 0; i < allLines.length; i += 1) {
					hasRisk =
						hasRisk ||
						allLines[i].info.some(({ type }) => type === 'risk');

					hasCredit =
						hasCredit || allLines[i].types.includes('credit');

					hasForecast =
						hasForecast ||
						(allLines[i].types.includes('forecast') &&
							!!allLines[i].value);
				}

				if (hasUnpaid !== state.hasUnpaid) {
					state.hasUnpaid = hasUnpaid;
				}

				if (hasOverdue !== state.hasOverdue) {
					state.hasOverdue = hasOverdue;
				}

				if (hasOverdue !== state.hasOverdue) {
					state.hasOverdue = hasOverdue;
				}

				if (hasForecast !== state.hasForecast) {
					state.hasForecast = hasForecast;
				}

				if (hasCredit !== state.hasCredit) {
					state.hasCredit = hasCredit;
				}
			}
		},
		setActiveGroups: (state, action) => {
			state.data.activeGroups = action.payload;
		},
		setActiveBars: (state, action) => {
			state.data.activeBars = action.payload;
		},
		setHoverBars: (state, action) => {
			state.data.hoverBars = action.payload;
		},
		setHoverGroups: (state, action) => {
			state.data.hoverGroups = action.payload;
		},

		setGraphOptions: (state, { payload }) => {
			for (const key in payload) {
				state.options[key] = payload[key] ?? true;
			}
		},

		setLegendSelected: (state, { payload }) => {
			if (!payload) {
				state.legends.selected = [];
				return state;
			}

			const index = state.legends.selected.findIndex(
				(object) => object?.id === payload?.id,
			);

			if (index !== -1) {
				state.legends.selected.splice(index, 1);
			} else {
				state.legends.selected = [payload];
				// state.legends.selected.push(payload);
			}

			return state;
		},

		setLegendHighlight: (state, { payload }) => {
			state.legends.highlight = payload;
		},

		setPredictions: (state, { payload }) => {
			state.predictions = payload;
		},
	},
	extraReducers: {
		extraReducers: {
			[ACTIONS.RESET]: () => initialState,
		},
		[toggleFilter]: (state) => {
			state.data.barGroups = {};
		},
		[setFilters]: (state) => {
			state.data.barGroups = {};
		},

		[setTimesliceSize]: (state, action) => {
			const date = format(
				startOfTime(new Date(), action.payload),
				'yyyy-MM-dd',
			);

			state.data.activeGroups = [date];
		},
		[setSelectedDate]: (state, action) => {
			const isoDate =
				action.payload instanceof Date
					? format(action.payload, 'yyyy-MM-dd')
					: action.payload;

			state.dates.selectedDate = isoDate;
		},
		[setCurrentDate]: (state, action) => {
			const isoDate =
				action.payload instanceof Date
					? format(action.payload, 'yyyy-MM-dd')
					: action.payload;

			state.dates.currentDate = isoDate;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	startLoading,
	stopLoading,

	setActive,
	setStatistics,

	setGraphGroupSize,
	requestNewRange,
	setRange,
	setActiveGroups,
	setActiveBars,
	setHoverBars,
	setHoverGroups,
	setGroup,
	setMultipleGroups,

	setLegendSelected,
	setLegendHighlight,

	updateAxis,
	setData,
	setGroups,
	setGraphOptions,

	setPredictions,
} = graphSlice.actions;

export const selectors = {
	predictions: createSelector(
		(store) => store?.graph?.predictions ?? [],
		(value) => value,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	legends: {
		selected: createSelector(
			(store) => store?.graph?.legends?.selected,
			(_, options) => options,
			(value, options) => {
				if (!options) {
					return value;
				}

				return value.filter((object) => {
					let valid = true;

					if (options?.part) {
						valid = isEqual(object?.part, options?.part);
					}

					return valid;
				});
			},
			{
				memoizeOptions: {
					resultEqualityCheck: (a, b) => isEqual(a, b),
				},
			},
		),
		highlight: createSelector(
			(store) => store?.graph?.legends?.highlight,
			(value) => value ?? null,
			{
				memoizeOptions: {
					resultEqualityCheck: (a, b) => isEqual(a, b),
				},
			},
		),
	},
};

export default graphSlice.reducer;
