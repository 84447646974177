import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation } from '@asteria/language';

import { useIntegrationName } from '../../../../hooks';

/** @type { React.FC<{ id: string }> } */
const LoadingText = React.memo(function LoadingText(props) {
	const { id } = props;

	const { state, object: integration } = useSelector(
		(store) => {
			const object = IntegrationStore.selectors.integration(store, id);

			let state = null;

			if (object?.status?.state === 'IDLE') {
				if (object?.config?.connected) {
					state = 'connected';
				} else {
					state = 'created';
				}
			} else {
				state = 'loading';
			}

			return { state, object };
		},
		(a, b) => isEqual(a, b),
	);

	const type = integration?.type;
	const key = integration?.key;

	const integrationName = useIntegrationName(type, key);

	return (
		<Translation
			translationKey={[
				'card.content.title',
				'card.content.title.onboarding-loading',
			]}
			translationOptions={{
				postfix: {
					status: state,
					'integration-key': key,
					'integration-type': type,
				},
				data: {
					integration: integration,
					integrationName: integrationName,
				},
			}}
			showWhenEmpty={false}
			Component={Text}
			size="sm"
		/>
	);
});

LoadingText.propTypes = { id: PropTypes.string };

const StatusText = React.memo(function StatusText(props) {
	const { id, size, status, translationOptions } = props;

	if (size === 'xs' && ['initiating', 'importing'].includes(status)) {
		return <LoadingText id={id} />;
	}

	return (
		<Translation
			translationKey="onboarding.box.status"
			translationOptions={translationOptions}
			Component={Text}
			size="sm"
		/>
	);
});

StatusText.propTypes = {
	id: PropTypes.string,
	size: PropTypes.string,
	status: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default StatusText;
