import React, { useEffect, useState } from 'react';

import { Provider, useDispatch } from 'react-redux';

import Logo from '!!raw-loader!./logo.svg';
import PropTypes from 'prop-types';

import { PartnerService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';

import { formatFavicon } from '@asteria/utils-funcs/app';
import { cn } from '@asteria/utils-funcs/classes';
import { AsteriaWidget } from '@asteria/widget-thor';

import Features from './FeatureWrapper';
import fetchPartnerFeatures from './funcs/fetchPartnerFeatures';

const getLanguage = () => {
	const { search } = window.location;

	const hashes = search.slice(search.indexOf('?') + 1).split('&');
	const searchParams = hashes.reduce((params, hash) => {
		const [key, val] = hash.split('=');
		return Object.assign(params, { [key]: decodeURIComponent(val) });
	}, {});

	if (searchParams.lang) {
		return searchParams.lang;
	}

	return localStorage.getItem('asteriaLanguage') || 'sv';
};

const getSitePartner = async (site) => {
	const response = await PartnerService.partner
		.fetchByDomain({
			useTokenFromContext: false,
			domain: site,
			fields: `
			id
			name
			settings {
				themeId
				hosting {
					language
					domain
				}
			}
		`,
		})
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.error(err);

			return {
				id: site,
				name:
					site === 'wings'
						? 'Asteria AB'
						: site[0].toUpperCase() + site.slice(1),
				settings: {
					themeId: null,
					hosting: { language: 'sv', domain: site },
				},
			};
		});

	return response;
};

function Content(props) {
	const { language, partner } = props;

	const auth = React.useMemo(
		() => ({
			logo: <div dangerouslySetInnerHTML={{ __html: Logo }} />,
			homepage: false,
		}),
		[],
	);

	const router = React.useMemo(() => ({ type: 'browser' }), []);

	return (
		<AsteriaWidget
			partnerId={partner?.id}
			domain={partner?.settings?.hosting?.domain}
			languageCode={language}
			analytics={false}
			demo={false}
			theme={null}
			router={router}
			debug={!!localStorage.getItem('AsteriaDebug')}
			auth={auth}
			// callback={callback}
		/>
	);
}

Content.displayName = 'Content';

Content.propTypes = {
	token: PropTypes.string,
	setToken: PropTypes.func,
	setFetchLanguage: PropTypes.func,
	language: PropTypes.string,
	partner: PropTypes.object,
	user: PropTypes.object,
	hasIntegrations: PropTypes.bool,
};

const Updater = React.memo((props) => {
	const { partnerId } = props;

	const dispatch = useDispatch();

	React.useEffect(() => {
		fetchPartnerFeatures({
			partnerId: partnerId,
			dispatch: dispatch,
		});
	}, [dispatch, partnerId]);

	return null;
});

Updater.displayName = 'Updater';
Updater.propTypes = { partnerId: PropTypes.string };

function App() {
	const [partner, setPartner] = useState(null);

	useEffect(() => {
		const fetch = async () => {
			const partner = await getSitePartner('wings');

			localStorage.asteriaSite =
				partner?.settings?.hosting?.domain || 'wings';

			document.title = 'Asteria Smart Cash Flow (Demo)';

			const nodes = document.querySelectorAll(
				`link[rel~='icon'],link[rel~='apple-touch-icon']`,
			);

			if (nodes.length) {
				nodes.forEach((node) => {
					if (node.href.includes('/images/favicon/')) {
						node.href = formatFavicon(
							node,
							partner?.settings?.theme?.name,
						);
					}
				});
			} else {
				const link = document.createElement('link');
				link.rel = 'icon';
				link.href = formatFavicon(null, partner?.name);

				document.head.appendChild(link);
			}

			setPartner(partner);
		};
		fetch();
	}, []);

	if (!partner) {
		return null;
	}

	return (
		<Provider store={store}>
			<Updater partnerId={partner?.id} />
			<Features>
				<div className={cn(`webapp`, `partner-${partner.name}`)}>
					<div className="wings-main">
						<Content language={getLanguage()} partner={partner} />
					</div>
				</div>
			</Features>
		</Provider>
	);
}

export default App;
