import React from 'react';

import PropTypes from 'prop-types';

import Prefix, { ForecastPrefix, MultiPrefix } from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';

import { getSalaryColor } from '../../utils';

import '../../styles.scss';

const CustomLegend = (props) => {
	const { graphData, compact } = props;

	if (compact) {
		return (
			<div className="flex flex-wrap justify-center gap-4 mt-4">
				<div className="flex items-center gap-2">
					<MultiPrefix className="asteria-component__taxes-breakdown">
						{graphData.map((value, index) => (
							<Prefix
								key={value?._id ?? value?.id}
								custom
								className={getSalaryColor?.({
									index: index,
									variant: 'bg',
								})}
							/>
						))}
					</MultiPrefix>
					<span className="text-content-text-muted">
						{TranslationService.getV2('tags.salaries.salary')}
					</span>
				</div>
				<div className="flex items-center gap-2">
					<ForecastPrefix className="asteria-component__graph-legend-prefix" />
					<span className="text-content-text-muted">
						{TranslationService.getV2(
							'graph.account.legend.forecast',
						)}
					</span>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-wrap justify-center gap-4 mt-4">
			{graphData.map((object, index) => {
				const hasValue = graphData?.some(
					(item) =>
						item?.[object?.dataKey] !== 0 &&
						object?.dataKey !== 'loading',
				);

				if (!hasValue) {
					return <div className="h-auto" />;
				}

				return (
					<div
						key={`item-${index}`}
						className="flex items-center gap-2"
					>
						<svg
							width="12"
							height="12"
							className="asteria-component__graph-legend-prefix"
						>
							<rect
								width="12"
								height="12"
								className={getSalaryColor?.({ index: index })}
							/>
						</svg>
						<span className="text-content-text-muted">
							{object?.name}
						</span>
					</div>
				);
			})}
			<div className="flex items-center gap-2">
				<ForecastPrefix className="asteria-component__graph-legend-prefix" />
				<span className="text-content-text-muted">
					{TranslationService.getV2('graph.account.legend.forecast')}
				</span>
			</div>
		</div>
	);
};

CustomLegend.propTypes = {
	compact: PropTypes.bool,
	graphData: PropTypes.array,
	translationOptions: PropTypes.object,
};

export default CustomLegend;
