import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TextGroup } from '@asteria/component-core/typography';

import { StatusIntegrationBox } from '@asteria/component-integrations-v2/components/box';
import { useFlow } from '@asteria/component-integrations-v2/hooks';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<{ type: 'bank' | 'erp', name: string }> } */
const OnboardingCardLogo = React.memo(function OnboardingCardLogo(props) {
	const { type, name: key } = props;

	const url = useFlow(
		type,
		key,
		React.useCallback((flow) => flow?.info?.logo?.small?.path, []),
	);

	return (
		<div
			className="w-8 h-8 bg-center bg-contain rounded-full border border-solid border-border-normal"
			style={{ backgroundImage: `url('${url}')` }}
		/>
	);
});

OnboardingCardLogo.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
};

/** @type { React.FC<ChildrenProps> } */
const OnboardingCardLogos = React.memo(function OnboardingCardLogos(props) {
	const { onboarding } = props;

	const hasBankFeature = useFeature('bank-integrations');

	const available = useConfig('integrations', {
		callback: React.useCallback(
			(config) => {
				const { erp = [], bank = [] } = (config ?? []).reduce(
					(acc, { type, key }) => {
						if (!acc[type]) {
							acc[type] = [];
						}

						acc[type].push(key);

						return acc;
					},
					{},
				);

				let response = [];

				let left = 0,
					right = 0;

				const shouldShowERP = ['none', 'bank', 'both'].includes(
					onboarding,
				);

				const shouldShowBank =
					hasBankFeature &&
					['none', 'erp', 'both'].includes(onboarding);

				while (left < erp.length && right < bank.length) {
					if (shouldShowERP) {
						response.push({ type: 'erp', key: erp[left] });
						left += 1;
					}

					if (shouldShowBank) {
						response.push({ type: 'bank', key: bank[right] });
						right += 1;
					}
				}

				return response;
			},
			[hasBankFeature, onboarding],
		),
	});

	return (
		<div className="flex gap-1">
			{available.slice(0, 4).map(({ type, key }) => (
				<OnboardingCardLogo
					key={[type, key].join('-')}
					type={type}
					name={key}
				/>
			))}
			<div className="h-8 w-8 border border-solid border-border-normal rounded-full flex items-center justify-center">
				<Icon icon="integrations" />
			</div>
		</div>
	);
});

OnboardingCardLogos.propTypes = {
	onboarding: PropTypes.string,
};

/** @type { React.FC<ChildrenProps> } */
const OnboardingCardContent = React.memo(function OnboardingCardContent(props) {
	const { onAction, onSubmit } = props;

	const hasBankFeature = useFeature('bank-integrations');

	const integrations = useSelector((store) => {
		return IntegrationStore.utils
			.sortByStatus(
				IntegrationStore.selectors.integrations(store, {
					type: hasBankFeature ? null : 'erp',
				}),
			)
			.map((object) => object?._id ?? object?.id);
	}, isEqual);

	const handleOpen = React.useCallback(
		(flow) => {
			const id = flow?.integration?._id ?? flow?.integration?.id;

			return props?.onAction?.('go', `/onboarding/${id}`);
		},
		[props],
	);

	return (
		<div className="flex flex-col gap-4">
			<FeatureFlag feature="card-onboarding-v2-logos">
				<OnboardingCardLogos {...props} />
			</FeatureFlag>
			<TextGroup className="flex flex-col gap-2">
				<Content.Title {...props} />
				<Content.Text {...props} />
			</TextGroup>
			<div
				className={cn(
					'flex flex-col gap-2 max-h-64',
					'asteria-component__card-onboarding-integrations-list',
				)}
			>
				{integrations.map((id) => (
					<StatusIntegrationBox
						key={id}
						id={id}
						size="xs"
						onAction={onAction}
						onSubmit={onSubmit}
						onOpen={handleOpen}
					/>
				))}
			</div>
		</div>
	);
});

OnboardingCardContent.displayName = 'OnboardingCardContent';

OnboardingCardContent.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default OnboardingCardContent;
