import React from 'react';

import PropTypes from 'prop-types';

import { Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } path
 * @property { unknown } actions
 * @property { boolean } deep
 */

/**
 * @type { React.FC<Props> }
 */
const Splash = React.memo((props) => {
	const { className, path, actions, deep = true } = props;

	const config = useConfig(path, { deep: deep });

	return (
		<Wrapper className={cn('asteria-component__splash', className)}>
			{config?.title ? (
				<Header className="asteria-component__splash-header">
					<Title size={config?.title?.size ?? 'sm'}>
						{TranslationService.get(
							[].concat(config?.title?.value ?? config?.title),
							config?.title?.value ?? config?.title,
						)}
					</Title>
				</Header>
			) : null}

			<Contenter
				className="asteria-component__splash-content"
				content={config?.content}
				actions={actions}
				as={Content}
			/>
		</Wrapper>
	);
});

Splash.displayName = 'Splash';

Splash.propTypes = {
	className: PropTypes.string,
	path: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	actions: PropTypes.any,
	deep: PropTypes.bool,
};

export default Splash;
