import Loading from './components/loading';
import Onboarding from './components/onboarding';
import Subtitle from './components/subtitle';
import Text from './components/text';
import Title from './components/title';
import View, {
	DefaultViewContent,
	FeedbackViewContent,
	ImportingViewContent,
	PromotionViewContent,
} from './view';

/**
 * @typedef { import('../types').ChildrenProps } ChildrenProps
 *
 * @typedef Extension
 * @property { typeof Subtitle } Subtitle
 * @property { typeof Title } Title
 * @property { typeof Loading } Loading
 * @property { typeof Text } Text
 * @property { typeof Onboarding } Onboarding
 * @property { typeof DefaultViewContent } DefaultViewContent
 * @property { typeof FeedbackViewContent } FeedbackViewContent
 * @property { typeof ImportingViewContent } ImportingViewContent
 * @property { typeof PromotionViewContent } PromotionViewContent
 */

/** @type { React.FC<ChildrenProps> & Extension } */
export default Object.assign(View, {
	Subtitle,
	Title,
	Loading,
	Text,
	Onboarding,

	DefaultViewContent,
	FeedbackViewContent,
	ImportingViewContent,
	PromotionViewContent,
});
