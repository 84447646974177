import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const FeedbackSuccessModalContent = (props) => {
	const { onClose, feedbackKey, type } = props;

	return (
		<Wrapper
			className={cn(
				'asteria-component__feedback__modal',
				'asteria-component__feedback__modal--success',
			)}
		>
			<Header onClose={onClose} verticalAlign="center">
				{TranslationService.get([
					'feedback.success.header',
					`feedback.success.${feedbackKey}.header`,
					`feedback.success.${type}.header`,
					`feedback.success.${feedbackKey}.${type}.header`,
				])}
			</Header>
			<Content>
				<Alert
					level="success"
					type="feedback"
					icon="check"
					iconPosition="first"
					title={TranslationService.get([
						'feedback.success.title',
						`feedback.success.${feedbackKey}.title`,
						`feedback.success.${type}.title`,
						`feedback.success.${feedbackKey}.${type}.title`,
					])}
				>
					{TranslationService.get([
						'feedback.success.description',
						`feedback.success.${feedbackKey}.description`,
						`feedback.success.${type}.description`,
						`feedback.success.${feedbackKey}.${type}.description`,
					])}
				</Alert>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'feedback.success.close',
							`feedback.success.${feedbackKey}.close`,
							`feedback.success.${type}.close`,
							`feedback.success.${feedbackKey}.${type}.close`,
						])}
						onClick={onClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

FeedbackSuccessModalContent.displayName = 'FeedbackSuccessModalContent';

FeedbackSuccessModalContent.propTypes = {
	onClose: PropTypes.func,
	feedbackKey: PropTypes.string,
	type: PropTypes.string,
};

const FeedbackSuccessModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal open={open} size="sm" onClose={onClose}>
			<FeedbackSuccessModalContent {...props} />
		</Modal>
	);
};

FeedbackSuccessModal.displayName = 'FeedbackSuccessModal';

FeedbackSuccessModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	feedbackKey: PropTypes.string,
	type: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default FeedbackSuccessModal;

export { FeedbackSuccessModalContent };
