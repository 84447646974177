import React from 'react';

/**
 * @typedef TranslationOptions
 * @property { Record<string, unknown> } [postfix]
 * @property { Record<string, unknown> } [prefix]
 * @property { Record<string, unknown> } [features]
 * @property { unknown } [data]
 * @property { unknown } [actions]
 * @property { string } [default]
 * @property { boolean } [debug]
 *
 * @typedef { (key: string | string[], translationOptions?: TranslationOptions) => string } TranslationFn
 *
 * @type { React.Context<{ language: string, onLanguageChange: (language: string) => void, t: TranslationFn, data: unknown }> }
 */
const TranslationContext = React.createContext({
	language: 'sv',
	onLanguageChange: null,
	t: null,
	data: null,
});

export default TranslationContext;
