import React from 'react';

import PropTypes from 'prop-types';
import { Bar, Cell, Tooltip } from 'recharts';

import { cn } from '@asteria/utils-funcs/classes';

import BarGraph from '../../../../../chart/bar';
import { getName, getSalaryColor } from '../../utils';

import CustomLegend from './employee-legend';
import GraphTooltip from './tooltip';

const EmployeeBarGraph = React.memo(function EmployeeBarGraph(props) {
	const {
		data,
		translationOptions,
		importing,
		loading,
		state,
		compactLegend,
	} = props;

	const employeeData = React.useMemo(() => {
		return data?.map((object, index) => ({
			name: object?.name,
			current: object?.salary?.current,
			forecast: object?.salary?.forecast,
			index: index,
		}));
	}, [data]);

	const hasPromotion = state?.promotion?.visible;

	return (
		<div
			className={cn(
				'w-full flex flex-col',
				'asteria-component__card-chart',
			)}
		>
			<BarGraph
				data={hasPromotion ? [{}] : employeeData}
				loading={importing || loading}
				placeholder={{ initial: -900, toggle: -300 }}
				translationOptions={translationOptions}
				legend={
					<CustomLegend
						graphData={hasPromotion ? [{}] : employeeData}
						translationOptions={translationOptions}
						loading={importing || loading}
						compact={compactLegend}
					/>
				}
			>
				<defs>
					{new Array(10).fill()?.map((color, index) => (
						<pattern
							key={`salaries-${index + 1}`}
							id={`salaries-${index + 1}`}
							patternUnits="userSpaceOnUse"
							width="6"
							height="6"
							patternTransform="rotate(45)"
						>
							<rect
								width="6"
								height="6"
								className={getSalaryColor?.({ index: index })}
							/>
							<rect
								width="3"
								height="6"
								fill="rgba(255, 255, 255, 0.2)"
							/>
						</pattern>
					))}
				</defs>

				<Tooltip
					cursor={{
						fill:
							hasPromotion || importing || loading
								? 'transparent'
								: 'var(--color-graph-group-hover-background, var(--color-graph-group-normal-background, var(--color-graph-background, rgba(255, 255, 255, 0))))',
					}}
					content={(props) => (
						<GraphTooltip
							translationOptions={{
								...translationOptions,
								postfix: {
									...translationOptions?.postfix,
									type: 'employee',
								},
							}}
							{...props}
						/>
					)}
				/>
				{hasPromotion ? (
					<Bar
						name={getName('loading', translationOptions)}
						dataKey="loading"
						fill="var(--color-skeleton-normal-background, var(--color-progress-background, rgba(235, 231, 226, 1)))"
						stackId="salaries"
					/>
				) : (
					<>
						<Bar dataKey="current" stackId={'salary'}>
							{employeeData.map((entry, index) => (
								<Cell
									key={`current-${index}`}
									className={getSalaryColor?.({
										index: index,
									})}
								/>
							))}
						</Bar>
						<Bar dataKey="forecast" stackId={'salary'}>
							{employeeData.map((entry, index) => (
								<Cell
									key={`forecast-${index}`}
									fill={cn({
										'url(#salaries-1)': index + 1 === 1,
										'url(#salaries-2)': index + 1 === 2,
										'url(#salaries-3)': index + 1 === 3,
										'url(#salaries-4)': index + 1 === 4,
										'url(#salaries-5)': index + 1 === 5,
										'url(#salaries-6)': index + 1 === 6,
										'url(#salaries-7)': index + 1 === 7,
										'url(#salaries-8)': index + 1 === 8,
										'url(#salaries-9)': index + 1 === 9,
										'url(#salaries-10)': index + 1 >= 10,
									})}
								/>
							))}
						</Bar>
					</>
				)}
			</BarGraph>
		</div>
	);
});

EmployeeBarGraph.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	compactLegend: PropTypes.bool,
	data: PropTypes.object,
	translationOptions: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	state: PropTypes.object,
};

export default EmployeeBarGraph;
