import React from 'react';

import { format, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import Chip from '@asteria/component-chip/chip';
import { useFormValues, useManualFormValues } from '@asteria/component-form';
import Datepicker from '@asteria/component-form/datepicker';
import Input from '@asteria/component-form/input';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';

import getFormPaths from '../../../../../../utils/getFormPaths';
import ClientSelector from '../../../../../selector/client';
import CurrencySelector from '../../../../../selector/currency';
import TagSelector from '../../../../../selector/tag';

import '../../../../styles.scss';

function useVisibleValue({ name }) {
	const { type } = getFormPaths(name);
	let value = useManualFormValues({ name: `${name}.total` });

	if (typeof value === 'object') {
		value = value?.total;
	}

	return React.useMemo(() => {
		if (value === 0) {
			return value;
		}

		return formatNumber(value, type === 'withdraw');
	}, [type, value]);
}

const TransactionItemView = React.memo((props) => {
	const {
		className,
		name,
		style,
		types,
		type,
		inputConfig,
		placeholder,
		showCategory,
		handleChange,
		InputFormat,
		handleRemove,
		handleCopy,
		currencySelectorRef,
		analytics,
	} = props;

	const visibleValue = useVisibleValue({ name });

	const categoryValue = useFormValues({ name: `${name}.$category` });

	const [category, ...$tag] = (categoryValue ?? '').split('-');
	const tag = $tag.join('-');

	const selected = React.useMemo(
		() => [{ type: type, category: category, tag: tag }],
		[category, tag, type],
	);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-transactions-list-item',
				className,
			)}
			style={style}
		>
			{types?.length > 1 ? (
				<Chip
					variant="simple"
					colors={type}
					label={TranslationService.get(
						`forecaster.transactions.item.type.${type}`,
					)}
				/>
			) : null}
			{showCategory ? (
				<div
					className={cn(
						'asteria-component__forecaster-transactions-list-cell',
						'asteria--type-categories',
					)}
				>
					<TagSelector
						variant="select"
						name={`${name}.$category`}
						onChange={handleChange}
						type={type}
						showProbability
						selected={selected}
					/>
				</div>
			) : null}
			<div
				className={cn(
					'asteria-component__forecaster-transactions-list-cell',
					'asteria--type-description',
				)}
			>
				{!inputConfig ? (
					<Input
						name={`${name}.description`}
						placeholder={placeholder}
						debounce={500}
					/>
				) : (
					<ClientSelector
						dataSource={inputConfig.dataSource}
						filters={inputConfig.filters}
						name={`${name}.${inputConfig.name}`}
						showSelectedIcon
						showEdit
						showAdd
					/>
				)}
			</div>
			<div
				className={cn(
					'asteria-component__forecaster-transactions-list-cell',
					'asteria--type-date',
				)}
			>
				<Datepicker
					isPastDisabled
					iconPosition="first"
					name={`${name}.paymentDate`}
					startDate={format(startOfMonth(new Date()), 'yyyy-MM-dd')}
					// endDate={format(endOfMonth(new Date(date)), 'yyyy-MM-dd')}
					onChange={handleChange}
					tooltip={TranslationService.get(
						['forecaster.transactions.datepicker.tooltip'],
						// eslint-disable-next-line spellcheck/spell-checker
						'Klicka för att välja datum',
					)}
				/>
			</div>
			<div
				className={cn(
					'asteria-component__forecaster-transactions-list-cell',
					'asteria--type-currency',
				)}
			>
				<CurrencySelector
					name={`${name}.currency`}
					onChange={handleChange}
					ref={currencySelectorRef}
				/>
			</div>
			<div
				className={cn(
					'asteria-component__forecaster-transactions-list-cell',
					'asteria--type-total',
				)}
			>
				<Input
					name={`${name}.total`}
					value={0}
					format={InputFormat}
					onChange={handleChange}
					type="number"
					debounce={500}
					visibleValue={visibleValue}
					hideVisibleValueOnFocus
				/>
			</div>
			<div
				className={cn(
					'asteria-component__forecaster-transactions-list-cell',
					'asteria--type-remove',
				)}
			>
				<Button
					icon="chevron-down"
					onClick={handleCopy}
					tooltip={TranslationService.get(
						['forecaster.transactions.copy.tooltip'],
						// eslint-disable-next-line spellcheck/spell-checker
						'Klicka för att kopiera till nästa månad',
					)}
					analyticsKey="forecaster.transactions.copy"
					analytics={analytics}
				/>
				<Button
					icon="remove"
					onClick={handleRemove}
					tooltip={TranslationService.get(
						['forecaster.transactions.remove.tooltip'],
						// eslint-disable-next-line spellcheck/spell-checker
						'Klicka för att ta bort transaktion',
					)}
					analyticsKey="forecaster.transactions.remove"
					analytics={analytics}
				/>
			</div>
		</div>
	);
});

TransactionItemView.displayName = 'TransactionItemView';

TransactionItemView.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	style: PropTypes.object,
	types: PropTypes.arrayOf(PropTypes.string),
	type: PropTypes.string,
	inputConfig: PropTypes.object,
	placeholder: PropTypes.string,
	showCategory: PropTypes.bool,
	handleChange: PropTypes.func,
	handleRemove: PropTypes.func,
	InputFormat: PropTypes.object,
	currencySelectorRef: PropTypes.node,
	analytics: PropTypes.object,
	handleCopy: PropTypes.func,
};

export default TransactionItemView;
