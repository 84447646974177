import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useRequest } from './hooks';
import CardContentV1 from './v1/content';
import CardContentV2 from './v2/content';
import Loading from './v2/loading';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { required: ['erp', 'bank'], optional: 'both' },
	card: {
		content: {
			title: { trends: true },
			default: {
				view: {
					v1: (props) => <CardContentV1 {...props} />,
					v2: (props) => <CardContentV2 {...props} />,
				},
			},
			promotion: {
				view: {
					v2: (props) => <CardContentV2 {...props} />,
				},
			},
			importing: {
				view: {
					v2: (props) => <CardContentV2 {...props} />,
				},
			},
			fetching: {
				view: {
					v2: (props) => <CardContentV2 {...props} />,
				},
			},
			loading: { v2: (props) => <Loading {...props} /> },
		},
		footer: {
			connect: {
				button: {
					connect: { required: 'erp' },
					postfix: { required: { onboarding: 'bank' } },
				},
			},
		},
	},
};

/** @type { React.FC<Props> } */
const TaxesCard = React.memo(function TaxesCard(props) {
	const query = useRequest(props);

	return <BasicCard {...props} type="taxes" query={query} config={CONFIG} />;
});

TaxesCard.displayName = 'TaxesCard';

TaxesCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default TaxesCard;
