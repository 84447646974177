export const CARDS = {
	HEALTH: 'health',
	FORECAST: 'forecast',
	FORECAST_ACTIONS: 'forecast-actions',
	FORECAST_STATUS: 'forecast-status',
	CUSTOMER_PERFORMANCE: 'customer-performance',
	ACCOUNT: 'account',
	INCOMING: 'incoming',
	INVOICES: 'invoices',
	SUPPLIER_PERFORMANCE: 'supplier-performance',
	PROFIT: 'profit',
	UNDER_CONSTRUCTION: 'under-construction',
	OUTGOING: 'outgoing',
	ONBOARDING: 'onboarding',
	CLIENT_PERFORMANCE: 'client-performance',
	SALARIES: 'salaries',
	TAXES: 'taxes',
	CATEGORIES: 'categories',

	MIGRATION_SPLASH: 'migration:splash',
};

const GRID_PARTS = {
	INCOMING_OUTGOING: {
		type: 'flex',
		direction: 'horizontal',
		size: 1,
		breakpointSize: 2,
		breakpointWidth: 1100,
		children: [
			{ type: 'card', id: CARDS['INCOMING'] },
			{ type: 'card', id: CARDS['OUTGOING'] },
		],
	},
	OTHER: [
		{
			type: 'flex',
			direction: 'vertical',
			children: [
				{ type: 'card', id: CARDS['SALARIES'], size: 0 },
				{ type: 'card', id: CARDS['CLIENT_PERFORMANCE'], size: 0 },
				{ type: 'card', id: CARDS['PROFIT'], size: 0 },
				{ type: 'card', id: CARDS.CATEGORIES, size: 0 },
			],
		},
		{
			type: 'flex',
			direction: 'vertical',
			children: [
				{ type: 'card', id: CARDS['TAXES'], size: 0 },
				{ type: 'card', id: CARDS['FORECAST_ACTIONS'], size: 0 },
				{ type: 'card', id: CARDS['INVOICES'], size: 0 },
				{ type: 'card', id: CARDS['UNDER_CONSTRUCTION'], size: 0 },
			],
		},
	],
};

export const GRID = {
	DEFAULT: [
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1200,
			children: [
				{
					type: 'flex',
					size: 1,
					breakpointSize: 2,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['ACCOUNT'] }],
				},
				{
					type: 'flex',
					size: 1,
					breakpointSize: 1,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['HEALTH'] }],
				},
			],
		},
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1500,
			children: [
				{ type: 'card', id: CARDS['ONBOARDING'] },
				GRID_PARTS.INCOMING_OUTGOING,
			],
		},
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1250,
			children: GRID_PARTS.OTHER,
		},
	],
	ONBOARDING: [
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1200,
			children: [
				{
					type: 'flex',
					size: 1,
					breakpointSize: 1,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['ONBOARDING'] }],
				},
				{
					type: 'flex',
					size: 1,
					breakpointSize: 2,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['ACCOUNT'] }],
				},
			],
		},
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1500,
			children: [
				{ type: 'card', id: CARDS['HEALTH'] },
				GRID_PARTS.INCOMING_OUTGOING,
			],
		},
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1250,
			children: GRID_PARTS.OTHER,
		},
	],
	MIGRATION: [
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1200,
			children: [
				{
					type: 'flex',
					size: 1,
					breakpointSize: 2,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['ACCOUNT'] }],
				},
				{
					type: 'flex',
					size: 1,
					breakpointSize: 1,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['MIGRATION_SPLASH'] }],
				},
			],
		},
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1500,
			children: [
				{ type: 'card', id: CARDS['ONBOARDING'] },
				{ type: 'card', id: CARDS['HEALTH'] },
			],
		},
		GRID_PARTS.INCOMING_OUTGOING,
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1250,
			children: GRID_PARTS.OTHER,
		},
	],
	MIGRATION_ONBOARDING: [
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1200,
			children: [
				{ type: 'card', id: CARDS['ONBOARDING'] },
				{ type: 'card', id: CARDS['MIGRATION_SPLASH'] },
			],
		},
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1200,
			children: [
				{
					type: 'flex',
					size: 1,
					breakpointSize: 2,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['ACCOUNT'] }],
				},
				{
					type: 'flex',
					size: 1,
					breakpointSize: 1,
					breakpointWidth: 1500,
					children: [{ type: 'card', id: CARDS['HEALTH'] }],
				},
			],
		},
		GRID_PARTS.INCOMING_OUTGOING,
		{
			type: 'flex',
			direction: 'horizontal',
			breakpointWidth: 1250,
			children: GRID_PARTS.OTHER,
		},
	],
};
