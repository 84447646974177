import React from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper from '@asteria/component-core/wrapper';
import Content from '@asteria/component-core/wrapper/content';
import Header from '@asteria/component-core/wrapper/header';

import { Input } from '@asteria/component-form';
import Form from '@asteria/component-form/form';
import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import MessageGroup from './components/group';
import { useAutoScroll } from './hooks';

import './index.scss';

const ConversationContent = (props) => {
	const { onAction, onSubmit, onClose, info, id, type } = props;

	const ref = React.useRef(null);

	const [message, setMessage] = React.useState('');

	const handleChange = React.useCallback(({ value }) => {
		setMessage(value);
	}, []);

	const { data: messages, refetch } = useQuery({
		queryKey: ['chat', id],
		queryFn: () => onSubmit?.('conversation:chat', { id: id }),

		select: (response) => response,

		placeholderData: [],
		refetchInterval: 30_000,
		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		enabled: !!id,
	});

	const { mutate: sendMessage } = useMutation({
		mutationFn: async () => {
			return onSubmit?.('message:send', {
				id: id,
				message: message,
				read: false,
			});
		},

		onSuccess: () => {
			refetch();
			setMessage('');
		},
	});

	const handleSubmit = React.useCallback(() => {
		if (message.length) {
			return sendMessage?.();
		}

		return;
	}, [message.length, sendMessage]);

	useAutoScroll(ref, messages);

	React.useLayoutEffect(() => {
		async function read() {
			const input = messages
				.filter(({ createdBy }) => createdBy?.type !== 'UserToken')
				.map((message) => ({
					messageId: message?.id,
					read: true,
				}));

			return onSubmit?.('message:update', {
				layoutId: id,
				input: input,
			});
		}

		if (messages?.length) {
			read();
		}
	}, [id, messages, onSubmit]);

	return (
		<Form onSubmit={handleSubmit}>
			<div className="asteria-component__conversation-content">
				{typeof info === 'string' ? (
					<TextGroup>
						<Text>{info}</Text>
					</TextGroup>
				) : (
					info
				)}
				<Group ref={ref} className="asteria-component__message-content">
					{messages?.length ? (
						<MessageGroup
							messages={messages}
							type={type}
							onAction={onAction}
							onSubmit={onSubmit}
							onClose={onClose}
						/>
					) : (
						<Text>
							{TranslationService.get([
								'conversation.modal.empty.text',
								`conversation.modal.${type}.empty.text`,
							])}
						</Text>
					)}
				</Group>
				<Group
					className="asteria-component__message-input"
					direction="horizontal"
					flex
				>
					<Input
						value={message}
						size="lg"
						placeholder={TranslationService.get([
							'conversation.modal.input.placeholder',
							`conversation.modal.${type}.input.placeholder`,
						])}
						onChange={handleChange}
						uncontrolled
						autoFocus
						postfix={
							<Button
								size="sm"
								variant="primary"
								icon="arrow-right"
								type="submit"
								disabled={!message?.length}
							/>
						}
					/>
				</Group>
			</div>
		</Form>
	);
};

ConversationContent.displayName = 'ConversationContent';

ConversationContent.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	open: PropTypes.bool,
	info: PropTypes.node,
	type: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ConversationModal = (props) => {
	const { className, open, type, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn('asteria-component__conversation-modal', className)}
			size="md"
		>
			<Wrapper>
				<Header onBack={onClose} onClose={onClose}>
					{TranslationService.get([
						'conversation.modal.header',
						`conversation.modal.${type}.header`,
					])}
				</Header>
				<Content>
					<ConversationContent {...props} />
				</Content>
			</Wrapper>
		</Modal>
	);
};

ConversationModal.displayName = 'ConversationModal';

ConversationModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	type: PropTypes.string,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ConversationModal;

export { ConversationContent };
