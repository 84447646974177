import React from 'react';
import ReactDOM from 'react-dom';

import { Route } from 'react-router-dom';

import { QueryClientProvider, useQuery } from '@tanstack/react-query';
import cssVars from 'css-vars-ponyfill';
import { enableES5 } from 'immer';
import PropTypes from 'prop-types';

import { AuthService } from '@asteria/backend-utils-services';

import store, { reset } from '@asteria/datalayer';

import '@asteria/utils-cache';
import * as Configuration from '@asteria/utils-configuration';
import { decode } from '@asteria/utils-funcs/jwt';
import '@asteria/utils-graphql';
import BaseWidget, {
	AuthValidate,
	ErrorBoundary,
	queryClient,
} from '@asteria/widget-base';

import * as DATALAYER from './datalayer';
import Layout from './layout';
import Routing from './routes';

import '@asteria/themes/base/main.scss';

Configuration.apply();
cssVars({});
enableES5();

const getPartnerIdFromToken = (token) => decode(token)?.partnerId;

function useDemo({ demo, accessToken, environment, partnerId }) {
	return useQuery({
		queryKey: ['widget', 'full', 'demo'],
		queryFn: async () => {
			if (!demo) {
				return accessToken ?? null;
			}

			if (environment === 'stage') {
				Configuration.set('https://stage-api.asteria.ai/');
			} else if (environment === 'development') {
				Configuration.set('https://dev-api.asteria.ai/');
			}

			if (location && location?.hostname?.includes('swedbank.net')) {
				Configuration.set('https://stage-api.asteria.ai/');
			}

			return AuthService.auth
				.login({
					useTokenFromContext: false,
					username: 'demo.swedbank@asteria.ai',
					password: 'testar',
					partnerId,
				})
				.then((response) => response?.data?.accessToken ?? null)
				.catch(() => null);
		},
		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
}

const AsteriaWidgetContent = React.memo(function AsteriaWidgetContent(props) {
	const {
		accessToken: externalAccessToken,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		partnerId,
		domain,
		theme = 'swedbank',
		environment = 'production',
		callback,
		router,
		debug,
		auth,
	} = props;

	const { data: accessToken, isFetching } = useDemo({
		demo,
		accessToken: externalAccessToken,
		partnerId,
		environment,
	});

	const routing = React.useMemo(
		() => ({
			type: router?.type,
			routes: (
				<Route
					path="/"
					element={
						<AuthValidate
							otherwise={
								<Layout
									callback={callback}
									debug={debug}
									auth
								/>
							}
						>
							<Layout callback={callback} debug={debug} />
						</AuthValidate>
					}
					errorElement={<ErrorBoundary />}
				>
					<Route
						index
						element={<Routing auth={auth} partnerId={partnerId} />}
					/>
					<Route
						path="*"
						element={<Routing auth={auth} partnerId={partnerId} />}
					/>
				</Route>
			),
		}),
		[auth, callback, debug, partnerId, router?.type],
	);

	if (isFetching) {
		return null;
	}

	Configuration.setURLByToken(accessToken);

	return (
		<BaseWidget
			accessToken={accessToken}
			partnerId={partnerId ?? getPartnerIdFromToken(accessToken)}
			domain={domain}
			languageCode={languageCode}
			analytics={analytics}
			theme={theme}
			callback={callback}
			datalayer={DATALAYER}
			routing={routing}
		/>
	);
});

AsteriaWidgetContent.propTypes = {
	accessToken: PropTypes.string,
	languageCode: PropTypes.string,
	demo: PropTypes.bool,
	analytics: PropTypes.bool,
	partnerId: PropTypes.string,
	domain: PropTypes.string,
	theme: PropTypes.string,
	environment: PropTypes.string,

	callback: PropTypes.func,
	router: PropTypes.shape({
		type: PropTypes.oneOf(['memory', 'browser', 'hash']),
	}),
	debug: PropTypes.bool,
	auth: PropTypes.shape({ logo: PropTypes.node, homepage: PropTypes.bool }),
};

const AsteriaWidget = React.memo(function AsteriaWidget(props) {
	return (
		<QueryClientProvider client={queryClient}>
			<AsteriaWidgetContent {...props} />
		</QueryClientProvider>
	);
});

AsteriaWidget.propTypes = {
	accessToken: PropTypes.string,
	languageCode: PropTypes.string,
	demo: PropTypes.bool,
	analytics: PropTypes.bool,
	partnerId: PropTypes.string,
	domain: PropTypes.string,
	theme: PropTypes.string,
	environment: PropTypes.string,

	callback: PropTypes.func,
	router: PropTypes.shape({
		type: PropTypes.oneOf(['memory', 'browser', 'hash']),
	}),
	debug: PropTypes.bool,
	auth: PropTypes.shape({ logo: PropTypes.node, homepage: PropTypes.bool }),
};

const create = async (
	element,
	{
		accessToken,
		partnerId,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		callback,
		theme,
		environment = 'production',
	} = {},
) => {
	ReactDOM.render(
		<AsteriaWidget
			accessToken={accessToken}
			languageCode={languageCode}
			demo={demo}
			analytics={analytics}
			partnerId={partnerId}
			callback={callback}
			theme={theme}
			environment={environment}
		/>,
		element,
	);

	return true;
};

const createErp = async (element, config) =>
	create(element, { ...config, route: '/integrations/status' });

const cleanup = (element) => {
	reset(store.dispatch);
	try {
		queryClient.clear();
	} catch (e) {
		// DO NOTHING!!!!
	}

	ReactDOM.unmountComponentAtNode(element);
};

export default create;
export { AsteriaWidget, cleanup, create, createErp };
