import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<{ value: unknown, color: string } & Pick<ChildrenProps, 'translationOptions'>> } */
const BreakdownDetailsTooltip = function BreakdownDetailsTooltip(props) {
	const { value, color, translationOptions } = props;

	const paid = value?.paid?.total ?? 0;
	const forecast = value?.forecast?.total ?? 0;

	const amount = {
		paid: value?.paid,
		forecast: value?.forecast,
		summary: {
			total: paid + forecast,
			currency: value?.paid?.currency ?? value?.forecast?.currency,
		},
	};

	return (
		<div className="flex flex-col gap-4">
			<Translation
				translationKey="card.content.table.row.label"
				translationOptions={{
					...translationOptions,
					postfix: {
						key: 'name',
						...translationOptions?.postfix,
					},
					data: {
						...translationOptions?.data,
						current: value,
					},
				}}
				Component={Title}
				size="xxs"
				align="center"
			/>
			<div className="flex flex-col gap-2">
				{forecast ? (
					<div className="flex items-center justify-between gap-4">
						<div className="flex gap-1 items-center">
							<Prefix
								custom
								style={{ backgroundImage: `var(--image)` }}
								className={cn(color, 'asteria-color__forecast')}
							/>
							<Translation
								translationKey="card.chart.tooltip"
								translationOptions={{
									...translationOptions,
									postfix: {
										'label-variant': 'label',
										'label-type': 'forecast',
										...translationOptions?.postfix,
									},
									data: {
										...translationOptions?.data,
										value: value,
										amount: amount,
									},
								}}
								Component={Text}
							/>
						</div>
						<Translation
							translationKey="card.chart.tooltip"
							translationOptions={{
								...translationOptions,
								postfix: {
									'label-variant': 'amount',
									'label-type': 'forecast',
									...translationOptions?.postfix,
								},
								data: {
									...translationOptions?.data,
									value: value,
									amount: amount,
								},
							}}
							Component={Text}
							align="right"
						/>
					</div>
				) : null}
				{paid ? (
					<div className="flex items-center justify-between gap-4">
						<div className="flex gap-1 items-center">
							<Prefix custom className={cn(color)} />
							<Translation
								translationKey="card.chart.tooltip"
								translationOptions={{
									...translationOptions,
									postfix: {
										'label-variant': 'label',
										'label-type': 'paid',
										...translationOptions?.postfix,
									},
									data: {
										...translationOptions?.data,
										value: value,
										amount: amount,
									},
								}}
								Component={Text}
							/>
						</div>
						<Translation
							translationKey="card.chart.tooltip"
							translationOptions={{
								...translationOptions,
								postfix: {
									'label-variant': 'amount',
									'label-type': 'paid',
									...translationOptions?.postfix,
								},
								data: {
									...translationOptions?.data,
									value: value,
									amount: amount,
								},
							}}
							Component={Text}
							align="right"
						/>
					</div>
				) : null}
			</div>
			<div className="flex items-center justify-between gap-4">
				<div className="flex gap-1 items-center">
					<Prefix custom />
					<Translation
						translationKey="card.chart.tooltip"
						translationOptions={{
							...translationOptions,
							postfix: {
								'label-variant': 'label',
								'label-type': 'summary',
								...translationOptions?.postfix,
							},
							data: {
								...translationOptions?.data,
								value: value,
								amount: amount,
							},
						}}
						Component={Text}
						weight="medium"
					/>
				</div>
				<Translation
					translationKey="card.chart.tooltip"
					translationOptions={{
						...translationOptions,
						postfix: {
							'label-variant': 'amount',
							'label-type': 'summary',
							...translationOptions?.postfix,
						},
						data: {
							...translationOptions?.data,
							value: value,
							amount: amount,
						},
					}}
					Component={Text}
					weight="medium"
					align="right"
				/>
			</div>
		</div>
	);
};

BreakdownDetailsTooltip.propTypes = {
	value: PropTypes.object,
	color: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default BreakdownDetailsTooltip;
