import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { formatISO, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import BetaSign from '@asteria/component-beta-sign';

import { parseDate } from '@asteria/utils-funcs/normalize';

import BasicCard from '../basic';

import InvoicesContent from './v2/content';
import Loading from './v2/loading';
import PromotionContentV2 from './v2/promotion';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	return useQuery({
		queryKey: ['card', 'categories', { startDate, endDate }],
		queryFn: async ({ meta }) => {
			return onSubmit?.('card:fetch', {
				type: 'categories',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,

		meta: { dataloader },
	});
}

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: {
		required: 'bank',
		optional: 'erp',
	},
	card: {
		header: {
			postfix: {
				v2: (props) => (
					<BetaSign
						action="contact"
						variant="categories"
						tooltip
						translationOptions={{
							data: {
								score: props?.query?.data?.source?.score,
								trend: props?.query?.data?.source?.trend,
							},
						}}
						onAction={props.onAction}
						size="xs"
						feature="categories-card-beta"
					/>
				),
			},
			default: {
				chevron: false,
			},
		},
		content: {
			default: {
				view: {
					v2: (props) => <InvoicesContent {...props} />,
				},
			},
			importing: {
				view: {
					v2: (props) => <PromotionContentV2 {...props} />,
				},
			},
			fetching: {
				view: {
					v2: (props) => <PromotionContentV2 {...props} />,
				},
			},
			promotion: {
				view: {
					v2: (props) => <PromotionContentV2 {...props} />,
				},
			},
			loading: {
				v2: (props) => <Loading {...props} />,
			},
		},
	},
};

/** @type { React.FC<Props> } */
const CategoriesCard = React.memo(function CategoriesCard(props) {
	const { startDate, endDate, onSubmit, dataloader } = props;

	const query = useRequest({ onSubmit, startDate, endDate, dataloader });

	if (
		!query?.data?.source?.deposit?.length &&
		!query?.data?.source?.withdraw?.length
	) {
		return null;
	}

	return (
		<BasicCard
			{...props}
			type="categories"
			config={CONFIG}
			query={query}
			version={2}
		/>
	);
});

CategoriesCard.displayName = 'CategoriesCard';

CategoriesCard.propTypes = {
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	dataloader: PropTypes.object,
};

export default CategoriesCard;
