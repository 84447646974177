import React from 'react';

import PropTypes from 'prop-types';

import BaseCard from '../../../../base';
import { useChildren } from '../../hooks';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

import { BaseViewContent } from './default';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

const ViewContentConfig = withConfig(BaseViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.fetching?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const FetchingView = React.memo(function FetchingView({ children, ...props }) {
	const content = useChildren({ children, ...props }, (props) => (
		<ViewContentConfig {...props} />
	));

	return <BaseCard.Content>{content}</BaseCard.Content>;
});

FetchingView.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default withConfig(FetchingView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.fetching, version);
});

export { ViewContentConfig as ViewContent };
