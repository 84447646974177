import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import * as AppStore from '@asteria/datalayer/stores/app';

import { Translation, TranslationService } from '@asteria/language';

import { Content } from '../../basic';

import EmployeeBarGraph from './graph/employee-graph';
import SalaryGraph from './graph/salary-graph';
import SalariesLoading from './loading';
import UnderConstructionModal from './modal';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const SalariesPromotion = React.memo(function SalariesPromotion(props) {
	const {
		query,
		translationOptions,
		variant,
		startDate,
		importing,
		loading,
		onboarding,
		onAction,
	} = props;

	const [show, setShow] = React.useState(false);

	const openEmployeeList = React.useCallback(() => setShow(true), []);
	const closeEmployeeList = React.useCallback(() => setShow(false), []);

	const hasSalariesTag = useSelector((store) => {
		const tags = AppStore.selectors.tags(store);
		return tags?.some((tag) => tag?.name === '$salary');
	});

	const openForecaster = React.useCallback(
		() => onAction?.('card:action', { type: 'salaries', date: startDate }),
		[onAction, startDate],
	);

	return (
		<>
			<div className="flex flex-col gap-4">
				<Content.Title
					{...props}
					loading={importing || loading}
					showLoading
				/>
				<Content.Text
					{...props}
					showLoading
					loading={importing || loading}
				/>
				{onboarding === 'none' ? (
					<Translation
						translationKey="card.content.text.promotion.sub"
						translationOptions={translationOptions}
						Component={Text}
						size="sm"
					/>
				) : null}

				{query?.data?.employee?.length ? (
					<EmployeeBarGraph
						{...props}
						data={query?.data?.employee}
						compactLegend
					/>
				) : (
					<SalaryGraph {...props} />
				)}

				<SalariesLoading {...props} />

				{query?.data?.employee?.length && onboarding !== 'none' ? (
					<Button
						variant="link"
						size="sm"
						label={TranslationService.getV2(
							'card.button.modal',
							translationOptions,
						)}
						onClick={openEmployeeList}
					/>
				) : null}

				{(variant === 'today' || variant === 'future') &&
				!query?.data?.employee?.length &&
				hasSalariesTag ? (
					<Button
						variant="link"
						size="sm"
						label={TranslationService.getV2(
							'card.button',
							translationOptions,
						)}
						onClick={openForecaster}
					/>
				) : null}
				<Content.Onboarding {...props} />
			</div>
			<UnderConstructionModal
				open={show}
				{...props}
				onClose={closeEmployeeList}
			/>
		</>
	);
});

SalariesPromotion.propTypes = {
	query: PropTypes.object,
	variant: PropTypes.object,
	onboarding: PropTypes.string,
	startDate: PropTypes.string,
	importing: PropTypes.bool,
	loading: PropTypes.bool,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
};

export default SalariesPromotion;
