import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { formatISO, subYears } from 'date-fns';

import * as AppStore from '@asteria/datalayer/stores/app';

import { parseDate } from '@asteria/utils-funcs/normalize';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @param { Props } props */
export function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	const tagId = useSelector((store) => {
		const tags = AppStore.selectors.tags(store);
		const tag = tags.find((tag) => tag?.name === '$tax');

		return tag?._id ?? tag?.id;
	});

	return useQuery({
		queryKey: ['card', 'taxes', { startDate, endDate }, tagId],
		queryFn: async ({ meta }) => {
			const response = await onSubmit?.('card:fetch', {
				type: 'taxes',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subYears(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subYears(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				dataloader: meta?.dataloader,
			});

			return response;
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate && !!tagId,

		meta: { dataloader },
	});
}
