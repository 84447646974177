import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { 'none' | 'modal' | 'contact' } action
 * @property { string } className
 * @property { string } variant
 * @property { string | React.ReactNode } tooltip
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { 'xs' | 'sm' | 'md' | 'lg' } size
 * @property { string } feature
 */

/** @type { React.FC<Props> } */
const BetaSign = React.memo(function BetaSign(props) {
	const {
		action = 'none',
		className,
		variant,
		tooltip,
		translationOptions,
		onAction,
		size = 'sm',
		feature = 'beta-sign',
		...restProps
	} = props;

	const dispatch = useDispatch();

	const handleClick = React.useCallback(() => {
		if (action === 'modal') {
			return dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.BETA_INFO,
					props: { variant },
				}),
			);
		}

		if (action === 'contact') {
			return onAction?.('go', '/support');
		}

		return;
	}, [dispatch, action, variant, onAction]);

	const tooltipContent = React.useMemo(() => {
		if (!tooltip) {
			return null;
		}

		if (typeof tooltip === 'string' || React.isValidElement(tooltip)) {
			return tooltip;
		}

		return (
			<Text>
				{TranslationService.getV2(`beta.tooltip`, {
					postfix: {
						variant: variant,
						...translationOptions?.postfix,
					},
					default: 'Feature in beta - Click for Support',
					data: translationOptions?.data,
				})}
			</Text>
		);
	}, [
		tooltip,
		translationOptions?.data,
		translationOptions?.postfix,
		variant,
	]);

	return (
		<FeatureFlag feature={feature}>
			<TooltipWrapper tooltip={tooltipContent}>
				<Chip
					className={cn('uppercase', className)}
					variant="beta"
					size={size}
					icon={action === 'modal' ? 'info-circle' : null}
					label={TranslationService.getV2('beta.text.variant', {
						postfix: { variant: variant },
						default: 'BETA',
						data: translationOptions?.data,
					})}
					onClick={handleClick}
					{...restProps}
				/>
			</TooltipWrapper>
		</FeatureFlag>
	);
});

BetaSign.propTypes = {
	action: PropTypes.oneOf(['none', 'modal', 'contact']),
	className: PropTypes.string,
	variant: PropTypes.string.isRequired,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
		data: PropTypes.object,
	}),
	onAction: PropTypes.func,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
	feature: PropTypes.string,
};

export default BetaSign;
