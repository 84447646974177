import { isBefore, parseISO, subMonths } from 'date-fns';

import { INTEGRATION_STATUS, INTEGRATION_STATUS_PRIORITY } from './constants';

export function getIntegrationStatus(object) {
	if (!object) {
		return null;
	}

	const status = object?.status?.state;
	const errors = object?.config?.errors;
	const connected = object?.config?.connected;
	const disabled = object?.disabled;
	const lastSync = object?.lastSync;

	if (errors?.length || status === 'ERROR') {
		return INTEGRATION_STATUS.ERROR;
	}

	if (disabled) {
		return INTEGRATION_STATUS.DISABLED;
	}

	if (!connected) {
		if (lastSync) {
			return INTEGRATION_STATUS.IMPORTING;
		}

		return INTEGRATION_STATUS.INITIATING;
	}

	if (status === 'INITIATING') {
		return INTEGRATION_STATUS.INITIATING;
	}

	if (status === 'IMPORTING') {
		return INTEGRATION_STATUS.IMPORTING;
	}

	if (lastSync) {
		if (isBefore(parseISO(object.lastSync), subMonths(new Date(), 1))) {
			return INTEGRATION_STATUS.OUTDATED;
		}
	}

	return INTEGRATION_STATUS.CONNECTED;
}

export function formatIntegration(object) {
	if (!object) {
		return object;
	}

	return {
		...object,
		status: {
			...object.status,
			$state: getIntegrationStatus(object),
		},
	};
}

export function sortByStatus(integrations) {
	return integrations.sort((a, b) => {
		const source = INTEGRATION_STATUS_PRIORITY?.[a?.status?.$state] ?? 0;
		const target = INTEGRATION_STATUS_PRIORITY?.[b?.status?.$state] ?? 0;

		return source - target;
	});
}
