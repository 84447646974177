import { addDays, addMonths, addWeeks, addYears, format } from 'date-fns';
import { merge } from 'lodash-es';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

export function setAccessToken(state, { payload }) {
	state.auth.accessToken = payload;
}

export function setLoginStatus(state, { payload }) {
	state.auth.loggedIn = payload;
}

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

export const setStandalone = (state, action) => {
	state.standalone = action.payload;
};

export const setStatement = (state, action) => {
	state.statement = action.payload;
};

export const setMode = (state, action) => {
	state.mode = action.payload;
};

export const setFilters = (state, action) => {
	state.filters = action.payload;
};

export const resetFilterType = (state, action) => {
	if (action?.payload?.type) {
		state.filters = state.filters.filter(
			({ type }) => type !== action.payload.type,
		);
	}
};

export const toggleFilter = (state, action) => {
	if (
		action.payload.type === 'status' &&
		action.payload.id === 'SIGNED' &&
		!FeatureService.isActive('cashflow-filter-signed')
	) {
		action.payload.id = 'UNPAID';
		action.payload.item.code = 'UNPAID';
	}

	if (state.filters.some(({ id }) => id === action.payload.id)) {
		state.filters = state.filters.filter(
			({ id }) => id !== action.payload.id,
		);
	} else {
		state.filters.push(action.payload);
	}
};

export const addFilter = (state, action) => {
	if (!state.filters.some(({ id }) => id === action.payload.id)) {
		state.filters.push(action.payload);
	}
};

export const setConfig = (state, action) => {
	state.config = action.payload;
};

export const setUser = (state, action) => {
	const companyId = state.user?.companyId;

	state.user = action.payload ?? {};

	if (companyId) {
		state.user.companyId = companyId;
	}

	TranslationService.updateGlobals({
		user: JSON.parse(JSON.stringify(state.user)),
	});
};

export const setFeedback = (state, action) => {
	state.user.feedback = action.payload;
};

export const setCompany = (state, action) => {
	state.company = action.payload;
};

export const setCompanySettings = (state, action) => {
	if (!state.company) {
		state.company = {};
	}

	state.company.settings = action.payload;
};

export const setCompanySettingsFlags = (state, action) => {
	if (!state.company) {
		state.company = {};
	}

	if (!state.company.settings) {
		state.company.settings = {};
	}

	if (!state.company.settings.flags) {
		state.company.settings.flags = {};
	}

	for (const key in action.payload) {
		state.company.settings.flags[key] = action.payload[key];
	}
};

export const setPartner = (state, action) => {
	state.partner = action.payload;
};

export const setCurrencies = (state, action) => {
	state.currencies = action.payload;
};

export const setTags = (state, action) => {
	state.tags = action.payload;
};

export const updateTags = (state, action) => {
	const payload = [].concat(action?.payload);

	for (const object of payload) {
		const _id = object?._id;

		const index = state.tags.findIndex((value) => value?._id === _id);

		if (index !== -1) {
			state.tags[index] = merge({}, state.tags[index], object);
		}
	}
};

export const pushTags = (state, action) => {
	if (!state.tags) {
		state.tags = [];
	}

	state.tags.push(action.payload);
};

export const setClients = (state, action) => {
	state.clients = action.payload;
};

export const updateClients = (state, action) => {
	const objects = [].concat(action.payload).filter(Boolean);

	for (const object of objects) {
		const id = object?._id ?? object?.id;

		const index = state.clients.findIndex(
			(object) => (object?._id ?? object?.id) === id,
		);

		if (index !== -1) {
			state.clients[index] = object;
		} else {
			state.clients.push(object);
		}
	}
};

export const addClient = (state, action) => {
	state.clients = [...state.clients, action.payload];
};

export const setStatuses = (state, action) => {
	state.statuses = action.payload;
};

export const setFullScreenState = (state, action) => {
	if (!state.user) {
		state.user = {};
	}

	if (!state.user.settings) {
		state.user.settings = {};
	}

	if (!state.user.settings.layout) {
		state.user.settings.layout = {};
	}

	state.user.settings.layout.fullscreen = action.payload;
};

export const setGraphLayout = (state, action) => {
	if (!state.user) {
		state.user = {};
	}

	if (!state.user.settings) {
		state.user.settings = {};
	}

	if (!state.user.settings.layout) {
		state.user.settings.layout = {};
	}

	if (!state.user.settings.layout.graph) {
		state.user.settings.layout.graph = {};
	}

	state.user.settings.layout.graph.layout = action.payload;
};

export const setUserSettings = (state, action) => {
	if (!state.user) {
		state.user = {};
	}

	state.user.settings = action.payload;
};

export const setUserSettingsPartial = (state, action) => {
	if (!state.user) {
		state.user = {};
	}

	if (!state.user.settings) {
		state.user.settings = {};
	}

	for (const key in action.payload) {
		state.user.settings[key] = action.payload[key];
	}
};

export const setUserSettingsFlags = (state, action) => {
	if (!state.user) {
		state.user = {};
	}

	if (!state.user.settings) {
		state.user.settings = {};
	}

	if (!state.user.settings.flags) {
		state.user.settings.flags = {};
	}

	for (const key in action.payload) {
		state.user.settings.flags[key] = action.payload[key];
	}
};

export const setUserFeedback = (state, action) => {
	if (!state.user) {
		state.user = {};
	}

	if (!state.user.settings) {
		state.user.settings = {};
	}

	if (!state.user.feedback) {
		state.user.feedback = {};
	}

	for (const key in action.payload) {
		state.user.feedback[key] = action.payload[key];
	}
};

export const setListOpen = () => {};

export const setTimesliceSize = (state, action) => {
	state.timesize = action.payload;

	const date = format(
		addTimeslice(new Date(), action.payload, -3),
		// addTimeslice(new Date(), action.payload, -4),
		'yyyy-MM-dd',
	);

	state.currentDate = date;
};

export const setCurrentDate = (state, action) => {
	const isoDate =
		action.payload instanceof Date
			? format(action.payload, 'yyyy-MM-dd')
			: action.payload;
	state.currentDate = isoDate;
};

export const setAdjustOpen = () => {};

export const setSelectedDate = (state, action) => {
	const isoDate =
		action.payload instanceof Date
			? format(action.payload, 'yyyy-MM-dd')
			: action.payload;
	state.selectedDate = isoDate;
};

export const setSelectedType = (state, actions) => {
	state.selectedTypes = actions.payload;
};

export const setSettings = (state, action) => {
	for (const key in action.payload) {
		state.settings[key] = action.payload[key];
	}
};

export function register2FA(state, { payload }) {
	const value = payload ?? true;

	window.localStorage.setItem('Asteria2FA', JSON.stringify(value));
	state.register2FA = value;
}

export function setUserCompanyId(state, { payload }) {
	if (!state.user) {
		state.user = {};
	}

	state.user.companyId = payload;
}
