import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';

import Item from './item';

import './styles.scss';

const Slider = React.memo(function Slider(props) {
	const {
		className,
		items,
		translationOptions,
		children,
		onAction,
		onSubmit,
	} = props;

	const [activeSlide, setActiveSlide] = React.useState(0);

	const nextSlide = React.useCallback(() => {
		setActiveSlide((activeSlide) => activeSlide + 1);
	}, []);

	const prevSlide = React.useCallback(() => {
		setActiveSlide((activeSlide) => activeSlide - 1);
	}, []);

	const setSlide = React.useCallback((index) => {
		setActiveSlide(index);
	}, []);

	useEffect(() => {
		setActiveSlide(0);
	}, [items?.length]);

	if (!items || !items?.length) {
		return null;
	}

	return (
		<div
			className={cn(
				'flex flex-col gap-2 w-full',
				'asteria-component__carousel',
				'asteria-component__card-slider',
				className,
			)}
		>
			<div
				className={cn(
					'w-full gap-2 relative',
					'asteria-component__carousel-content',
				)}
			>
				{children ? (
					children({ action: items[activeSlide], translationOptions })
				) : (
					<Item
						action={items[activeSlide]}
						translationOptions={translationOptions}
						onAction={onAction}
						onSubmit={onSubmit}
					/>
				)}
			</div>
			{items?.length > 1 ? (
				<div
					className={cn(
						'flex flex-row justify-center items-center gap-2',
						'asteria-component__carousel-buttons',
					)}
				>
					<Button
						size="sm"
						icon="chevron-left"
						onClick={prevSlide}
						disabled={activeSlide === 0}
					/>
					{items.map((_, index) => (
						<span
							key={`slider-${index}`}
							className={cn(
								'asteria-component__carousel-button',
								'asteria-component__card-slider-switch',
								{
									'asteria--state-active':
										index === activeSlide,
								},
							)}
							onClick={() => setSlide(index)}
						/>
					))}
					<Button
						size="sm"
						icon="chevron-right"
						onClick={nextSlide}
						disabled={activeSlide >= items?.length - 1}
					/>
				</div>
			) : null}
		</div>
	);
});

Slider.displayName = 'Slider';

Slider.propTypes = {
	className: PropTypes.string,
	translationOptions: PropTypes.object,
	items: PropTypes.arrayOf(PropTypes.object),
	children: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Slider;
