/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import { useLanguages } from '@asteria/utils-hooks/useLanguages';

import './styles.scss';

const Languages = React.memo(({ onChange }) => {
	const [language, setLanguage] = React.useState(
		localStorage.getItem('asteriaLanguage') ?? 'sv',
	);

	const languages = useLanguages();

	const onLanguageChange = React.useMemo(
		() => (code) => () => {
			localStorage.setItem('asteriaLanguage', code);

			setLanguage(code);

			return onChange?.(code);
		},
		[onChange],
	);

	return (
		<Group
			className="asteria-view__auth-languages"
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="center"
		>
			{languages.map((item) => (
				<Button
					key={item.code}
					label={item.name}
					onClick={onLanguageChange(item.code)}
					active={item.code === language}
					size="sm"
				/>
			))}
		</Group>
	);
});

Languages.displayName = 'Languages';

Languages.propTypes = { onChange: PropTypes.func };

export default Languages;
