import React from 'react';

import PropTypes from 'prop-types';

import Tooltip, { TooltipWrapper } from '@asteria/component-core/tooltip';
import Text from '@asteria/component-core/typography';
import { sizeClasses, stateClasses } from '@asteria/component-core/utils';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import ControlledWrapper from '../base/controlled';
import Error from '../base/error';

import './styles.scss';

const Switch = React.memo(
	React.forwardRef((props, ref) => {
		const {
			className,
			onChange,
			valueChecked,
			valueUnchecked,
			analyticsKey,

			label,
			text,
			tooltip,
			icon,
			iconChecked,
			disabled,
			name,
			children,

			fieldProps = {},
			error,
			value,
			direction,
			floatingError,

			stopPropagation,

			formNamePrefix,
		} = props;

		const [, setInternalValue] = React.useState();

		const onInternalChange = React.useCallback(
			(event) => {
				Analytics.event('form.switch.change', {
					checked: event?.value,
					val: event?.value ? valueChecked : valueUnchecked,
					className: className,
					analyticsKey:
						analyticsKey || event.target.name || className,
				});

				setInternalValue(event && event.value);
				if (onChange) {
					if (event?.value) {
						onChange({ ...event, value: valueChecked });
					} else {
						onChange({ ...event, value: valueUnchecked });
					}
				}
			},
			[valueChecked, valueUnchecked, className, analyticsKey, onChange],
		);

		const innerRef = React.useRef(null);

		const onClick = React.useCallback(
			(event) => {
				if (stopPropagation) {
					event.stopPropagation();
				}
			},
			[stopPropagation],
		);

		const style = React.useMemo(() => {
			if (icon) {
				return {
					'--thumb-icon': `url(null)`,
					'--thumb-icon-active': iconChecked
						? `url(null)`
						: 'var(--thumb-icon)',
				};
			}
			return {};
		}, [icon, iconChecked]);

		return (
			<div
				className={cn(
					className,
					'asteria-component__switch',
					{
						[`asteria-component__switch--icon`]: props?.icon,
					},
					'asteria-component__base-switch',
					sizeClasses(props),
					stateClasses({ ...props, checked: value, error: error }),
				)}
				style={style}
				onClick={onClick}
			>
				<label
					className={cn(
						className,
						'asteria-component__label__wrapper',
						{
							[`asteria-component__label__wrapper--direction-${direction}`]:
								direction,
						},
					)}
				>
					{label ? <Text type="label">{label}</Text> : null}

					<TooltipWrapper tooltip={tooltip}>
						<div
							className="asteria-component__base-switch__inner"
							ref={innerRef}
						>
							<input
								type="checkbox"
								disabled={disabled}
								checked={value}
								value={value}
								name={name}
								onChange={onInternalChange}
								{...fieldProps}
								ref={ref}
							/>

							{text && (
								<div className="asteria-component__base-switch__value">
									{text}
								</div>
							)}
							{children}
						</div>
					</TooltipWrapper>
				</label>
				{error && floatingError ? (
					<Tooltip target={innerRef} open>
						<div className="asteria-component__switch-error">
							{error && (
								<Error
									error={error}
									name={name}
									formNamePrefix={formNamePrefix}
								/>
							)}
						</div>
					</Tooltip>
				) : null}
				{!floatingError && (
					<div className="asteria-component__switch-error">
						{error && (
							<Error
								error={error}
								name={name}
								formNamePrefix={formNamePrefix}
							/>
						)}
					</div>
				)}
			</div>
		);
	}),
);

Switch.displayName = 'Switch';

Switch.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	valueChecked: PropTypes.any,
	valueUnchecked: PropTypes.any,
	analyticsKey: PropTypes.string,

	label: PropTypes.string,
	text: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	icon: PropTypes.string,
	iconChecked: PropTypes.string,
	disabled: PropTypes.bool,
	name: PropTypes.string,
	children: PropTypes.node,

	fieldProps: PropTypes.object,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	value: PropTypes.any,
	direction: PropTypes.oneOf(['horizontal', 'vertical']),
	floatingError: PropTypes.bool,

	stopPropagation: PropTypes.bool,

	formNamePrefix: PropTypes.string,
};

Switch.defaultProps = {
	size: 'md',
	valueChecked: true,
	valueUnchecked: false,
};

const Component = ControlledWrapper(Switch);

ComponentService.register('Switch', Component, {});

export default Component;
export { Switch };
