import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { formatISO, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import { parseDate } from '@asteria/utils-funcs/normalize';

import BasicCard from '../basic';

import InvoicesContentV1 from './v1/content';
import InvoicesContentV2 from './v2/content';
import Loading from './v2/loading';
import PromotionContentV2 from './v2/promotion';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	return useQuery({
		queryKey: ['card', 'invoices', { startDate, endDate }],
		queryFn: async ({ meta }) => {
			return onSubmit?.('card:fetch', {
				type: 'invoices',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,

		meta: { dataloader },
	});
}

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: {
		required: ['erp'],
		loading: 'erp',
	},
	card: {
		content: {
			default: {
				view: {
					v1: (props) => <InvoicesContentV1 {...props} />,
					v2: (props) => <InvoicesContentV2 {...props} />,
				},
			},
			importing: {
				view: {
					v2: (props) => <PromotionContentV2 {...props} />,
				},
			},
			fetching: {
				view: {
					v2: (props) => <PromotionContentV2 {...props} />,
				},
			},
			promotion: {
				view: {
					v2: (props) => <PromotionContentV2 {...props} />,
				},
			},
			loading: {
				v2: (props) => <Loading {...props} />,
			},
		},
	},
};

/** @type { React.FC<Props> } */
const InvoicesCard = React.memo(function InvoicesCard(props) {
	const { startDate, endDate, onSubmit, dataloader } = props;

	const query = useRequest({ onSubmit, startDate, endDate, dataloader });

	return (
		<BasicCard {...props} type="invoices" config={CONFIG} query={query} />
	);
});

InvoicesCard.displayName = 'InvoicesCard';

InvoicesCard.propTypes = {
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	dataloader: PropTypes.object,
};

export default InvoicesCard;
