import React from 'react';

import { useStore } from 'react-redux';
import { createSelector } from 'reselect';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	CloseButton,
	Content,
	Header,
} from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import FloatingFeedbackButton, { useGenericProperties } from './FloatingButton';
import FeedbackModal from './FormModal';
import FeedbackRating from './Rating';
import FeedbackSuccessModal from './SuccessModal';
import { useFeedback } from './hooks';

import './styles.scss';

const selectors = {
	isValid: createSelector(
		(state) => state?.app?.user?.feedback ?? {},
		(_, { key, validate }) => ({ key, validate }),
		(feedback, { key, validate, open }) => {
			if (!validate || open) {
				return true;
			}

			return (feedback?.[key] ?? null) === null;
		},
	),
};

const Feedback = (props) => {
	const {
		className,
		title,
		description,
		minText,
		maxText,
		values,
		icon,
		iconActive,
		size,
		open,
		feedbackKey,
		validate = false,

		nodismiss,
		onAction,
		onSubmit,
		onSupportRequest,
		translationOptions,

		onEnd,
		rating,
		type,
	} = props;

	const store = useStore();

	const { data: feedbackData } = useFeedback({
		onSubmit: onSubmit,
		type: type,
	});

	const [value, setValue] = React.useState(null);
	const [isVisible, setVisible] = React.useState(open || !validate);

	React.useEffect(() => {
		setVisible(
			selectors.isValid(store.getState(), {
				key: feedbackKey,
				validate: validate,
				open: open,
			}),
		);
	}, []);

	React.useEffect(() => {
		if (value !== null) {
			Analytics.event('feedback.rating', {
				feedbackKey: feedbackKey,
				value: value,
			});
		}
	}, [value, feedbackKey]);

	const [{ loading, done }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'FETCHING':
					return { loading: true, done: false };

				case 'DONE':
					return { loading: false, done: true };

				case 'RESET':
					return { loading: false, done: false };

				default:
					return state;
			}
		},
		{ loading: false, done: false },
	);

	const handleResetValue = React.useCallback(() => setValue(null), []);

	const handleSubmit = React.useCallback(
		async (form) => {
			if (form.dismissed) {
				Analytics.event('feedback.dismiss', {
					feedbackKey: feedbackKey,
				});

				onAction?.('updateFeedback', {
					feedbackKey: feedbackKey,
					dismissed: true,
				});

				if (!open) {
					setVisible(false);
				}
			} else {
				Analytics.event('feedback.submit', {
					feedbackKey: feedbackKey,
					...form,
				});

				dispatch({ type: 'FETCHING' });
				await onAction?.('updateFeedback', { feedbackKey, ...form });
				dispatch({ type: 'DONE' });
			}

			handleResetValue();
		},
		[feedbackKey, handleResetValue, onAction, open],
	);

	const handleClose = React.useCallback(
		() => handleSubmit({ dismissed: true }),
		[handleSubmit],
	);

	const handleCloseSuccess = React.useCallback(
		(event) => {
			dispatch({ type: 'RESET' });

			setVisible(
				selectors.isValid(store.getState(), {
					key: feedbackKey,
					validate: validate,
					open: open,
				}),
			);

			onEnd?.(event);
		},
		[feedbackKey, onEnd, open, store, validate],
	);

	const PostfixProps = React.useMemo(
		() => ({ size: 'sm', position: 'absolute' }),
		[],
	);

	const RatingProps = React.useMemo(
		() => ({
			onChange: setValue,
			value: value,
			minText: minText,
			maxText: maxText,
			values: values,
			icon: icon,
			iconActive: iconActive,
			size: size,
		}),
		[icon, iconActive, maxText, minText, size, value, values],
	);

	if (!isVisible) {
		return null;
	}

	if (rating) {
		return (
			<>
				<FeedbackRating
					{...RatingProps}
					value={feedbackData?.[feedbackKey]?.value}
					type={type}
				/>
				<FeedbackModal
					open={value !== null}
					rating={RatingProps}
					onClose={handleResetValue}
					onSubmit={handleSubmit}
					loading={loading}
					onSupportRequest={onSupportRequest}
					feedbackKey={feedbackKey}
					type={type}
					translationOptions={translationOptions}
				/>
				<FeedbackSuccessModal
					open={done}
					onClose={handleCloseSuccess}
					type={type}
					feedbackKey={feedbackKey}
					translationOptions={translationOptions}
				/>
			</>
		);
	}

	return (
		<>
			<Wrapper className={cn('asteria-component__feedback', className)}>
				{title ? (
					<Header
						onClose={!nodismiss ? handleClose : null}
						verticalAlign="center"
						postfix={PostfixProps}
					>
						{typeof title === 'object' ? (
							title
						) : (
							<Title size="xs">
								{TranslationService.get(title, title)}
							</Title>
						)}
					</Header>
				) : null}

				<Content>
					{!title && !nodismiss ? (
						<CloseButton onClose={handleClose} size="sm" />
					) : null}
					{description ? (
						<Text size="md">
							{TranslationService.get(description, description)}
						</Text>
					) : null}
					<FeedbackRating {...RatingProps} type={type} />
				</Content>
			</Wrapper>
			<FeedbackModal
				open={value !== null}
				rating={RatingProps}
				onClose={handleResetValue}
				onSubmit={handleSubmit}
				loading={loading}
				onSupportRequest={onSupportRequest}
				feedbackKey={feedbackKey}
				type={type}
			/>
			<FeedbackSuccessModal
				open={done}
				onClose={handleCloseSuccess}
				type={type}
				feedbackKey={feedbackKey}
			/>
		</>
	);
};

Feedback.displayName = 'Feedback';

Feedback.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	nodismiss: PropTypes.bool,
	rating: PropTypes.bool,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onSupportRequest: PropTypes.func,
	onEnd: PropTypes.func,
	open: PropTypes.bool,

	minText: PropTypes.string,
	maxText: PropTypes.string,
	values: PropTypes.arrayOf(PropTypes.number),
	icon: PropTypes.string,
	iconActive: PropTypes.string,
	size: SizeProp(),

	feedbackKey: PropTypes.string,
	validate: PropTypes.bool,

	type: PropTypes.string,
	translationOptions: PropTypes.object,
};

Feedback.defaultProps = {
	icon: 'star',
	iconActive: 'star-filled',
	values: [1, 2, 3, 4, 5],
	size: 'md',

	validate: false,
};

export default Feedback;
export { FloatingFeedbackButton, useGenericProperties, FeedbackRating };
