import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Footer,
	FooterSection,
	Header,
	Content as WrapperContent,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useRedirectLink } from '../hooks';
import MigrationWrapper from '../wrapper';

import Stepper from './stepper';

import '../styles.scss';

/**
 * @typedef  { <TResponse = Promise<unknown>>(action: string, data?: unknown) => TResponse } onAction
 *
 * @typedef Props
 * @property { boolean } open
 * @property { string } className
 * @property { onAction } onAction
 * @property { onAction } onSubmit
 * @property { (event: MouseEvent) => void } onClose
 */

/** @type { React.FC<Props> } */
const MigrationModalContent = React.memo(function MigrationModalContent(props) {
	const { className, onClose, onAction, onSubmit } = props;

	const steps = useConfig('migration.modal.stepper', { deep: true });

	const redirectUri = useRedirectLink('modal');

	const openChat = React.useCallback(() => {
		return onAction?.('support:chat:open');
	}, [onAction]);

	return (
		<Wrapper
			className={cn('asteria-component__migration-modal', className)}
			scroll
		>
			<Header onClose={onClose}>
				{TranslationService.getV2(['modal.migration.title'])}
			</Header>
			<WrapperContent className="gap-4" scroll>
				<Translation
					translationKey="modal.migration.text"
					Component={Text}
				/>
				<div className="flex flex-col md:flex-row gap-8 justify-between items-start">
					<div className="flex flex-col w-full gap-2">
						<Translation
							translationKey="modal.migration.stepper.title"
							weight="bold"
							size="sm"
							Component={Text}
						/>
						<div className="border border-solid rounded w-full h-full p-4">
							<Stepper
								steps={steps}
								onAction={onAction}
								onSubmit={onSubmit}
							/>
						</div>
					</div>
					<div className="bg-support-normal-background flex flex-col p-4 gap-2 items-start rounded w-96">
						<Translation
							translationKey="modal.migration.support.title"
							weight="bold"
							size="sm"
							Component={Text}
						/>
						<Translation
							translationKey="modal.migration.support.text"
							size="sm"
							Component={Text}
						/>
						<Button
							variant="href"
							size="sm"
							icon="triangle-right"
							href={TranslationService.getV2(
								'faq.contact.button.phone.href',
							)}
							label={TranslationService.getV2(
								'faq.contact.button.phone.label',
							)}
						/>
						<Button
							variant="secondary"
							onClick={openChat}
							label={TranslationService.getV2(
								'modal.migration.support.button.chat',
							)}
						/>
					</div>
				</div>
			</WrapperContent>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						label={TranslationService.getV2(['action.close'], {
							postfix: { modal: true, variant: 'migration' },
						})}
						onClick={onClose}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.getV2(['action.apply'], {
							postfix: { modal: true, variant: 'migration' },
						})}
						href={redirectUri}
						target="_blank"
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

MigrationModalContent.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const MigrationModal = React.memo(function MigrationModal(props) {
	const { open, onClose } = props;

	return (
		<FeatureFlag feature="migration-modal">
			<MigrationWrapper>
				<Modal open={open} size="lg" onClose={onClose}>
					<MigrationModalContent {...props} />
				</Modal>
			</MigrationWrapper>
		</FeatureFlag>
	);
});

MigrationModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	translationData: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MigrationModal;
