import React from 'react';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { animationListener, sizeClasses } from '@asteria/component-core/utils';
import ElementAnchor from '@asteria/component-core/utils/elementAnchor';

import ComponentService from '@asteria/component-tools/contenter/service';
import { useMediaQuery } from '@asteria/component-tools/mediaquery';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useClickOutside from '@asteria/utils-hooks/useClickOutside';

import ControlledWrapper from '../base/controlled';
import Input from '../input';

import * as Constants from './constants';
import DatePickerContent from './content';
import {
	useChange,
	useChipDismiss,
	useDatepickerType,
	useDatepickerValue,
	useDefaultChange,
	useOpenState,
} from './hooks';
import DatepickerModal from './modal';

const MOBILE_MEDIA_QUERY = `(max-width: 640px)`;

const Datepicker = React.memo(
	React.forwardRef((props, ref) => {
		const {
			className,
			type: propType = 'day',
			types,
			variant = 'default',
			layout = 'default',
			behavior = 'default',
			size = 'medium',
			placement = 'bottom',
			locale = TranslationService.code || 'en',
			label,
			placeholder,

			startDate,
			endDate,
			disabledDates,

			name,

			disabled,

			isPastDisabled,
			isFutureDisabled,

			icon,
			iconActive = icon,
			iconPosition = 'last',

			fieldProps = {},
			error,
			floatingError,
			tooltip,
			repeatable,
			parent,
			direction,
			skipVisibleValue,
			iconOnly,

			children,
		} = props;

		const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

		const value = useDatepickerValue(props);

		const toggleRef = React.useRef(null);
		const contentRef = React.useRef(null);
		const datepickerRef = React.useRef(null);

		const [isOpen, { open: handleOpen, close: handleClose }] =
			useOpenState(props);

		const [type, onTypeSelect] = useDatepickerType(props);

		const [repeatableValue, setRepeatableValue] = React.useState(null);

		const handleRepeatableChange = React.useCallback((form) => {
			setRepeatableValue(form);
		}, []);

		const onDefaultChange = useDefaultChange(props);

		const onChange = useChange(props, {
			value,
			onClose: handleClose,
			repeatable: repeatableValue,
			onDefaultChange: onDefaultChange,
		});

		const onChipDismiss = useChipDismiss(props, { value, onDefaultChange });

		// const onBlur = React.useCallback(
		// 	(event) => {
		// 		const target = event?.relatedTarget ?? event?.target;

		// 		if (contentRef?.current) {
		// 			if (!contentRef?.current?.contains(target)) {
		// 				return handleClose(event);
		// 			}
		// 		}
		// 	},
		// 	[contentRef, handleClose],
		// );

		const variantRef = React.useRef(null);

		React.useEffect(() => {
			if (variantRef.current && variantRef.current !== variant) {
				onChange(null);
			}

			variantRef.current = variant;
		}, [variant]);

		const inputValue = React.useMemo(() => {
			if (!value) {
				return null;
			}

			if (variant === 'range') {
				return [
					value?.startDate?.split?.('T')?.[0],
					value?.endDate?.split?.('T')?.[0],
				].filter(Boolean);
			}

			if (typeof value === 'object' && !Array.isArray(value)) {
				return value?.startDate?.split('T')?.[0];
			}

			return value;
		}, [value, variant]);

		const visibleValue = React.useMemo(() => {
			if (!value) {
				return;
			}

			if (variant === 'range') {
				return null;
			}

			return TranslationService.get(
				['datepicker.value.format', `datepicker.${type}.value.format`],
				value,
				{ date: value },
			);
		}, [type, value, variant]);

		useClickOutside([contentRef, datepickerRef], handleClose);

		return (
			<TooltipWrapper tooltip={tooltip}>
				<div
					className={cn(
						className,
						'asteria-component__datepicker',
						sizeClasses(props),
						`asteria-component__datepicker--type-${propType}`,
						`asteria-component__datepicker--variant-${variant}`,
					)}
					ref={datepickerRef}
					// onBlur={onBlur}
				>
					<Input
						uncontrolled
						ref={ref}
						name={name}
						value={inputValue}
						placeholder={placeholder}
						icon={isOpen ? iconActive : icon}
						size={size}
						iconPosition={iconPosition}
						onIconClick={isOpen ? handleClose : handleOpen}
						error={error}
						floatingError={floatingError}
						label={label}
						readonly
						buttonRef={toggleRef}
						fieldProps={fieldProps}
						disabled={disabled}
						type={variant === 'default' ? 'text' : 'chip'}
						onChipDismiss={onChipDismiss}
						visibleValue={!skipVisibleValue ? visibleValue : null}
						active={isOpen}
						direction={direction}
						isRawEvent
						iconOnly={iconOnly}
						onFocus={handleOpen}
					/>

					{children
						? typeof children === 'function'
							? children({
									open: isOpen,
									onOpen: handleOpen,
									onClose: handleClose,
									value: value,
									toggleRef: toggleRef,
							  })
							: children
						: null}

					{isMobile ? (
						<DatepickerModal
							{...props}
							open={isOpen}
							onClose={handleClose}
							repeatableValue={repeatableValue}
							onRepeatableChange={handleRepeatableChange}
							footer={variant !== 'default'}
						/>
					) : (
						<CSSTransition
							in={isOpen}
							appear
							unmountOnExit
							classNames="my-node"
							addEndListener={animationListener}
						>
							<ElementAnchor
								element={toggleRef}
								placement={placement}
								minWidth
								className={cn(
									'asteria-component__datepicker-level',
									{ [`asteria--parent-${parent}`]: parent },
								)}
							>
								<DatePickerContent
									locale={locale}
									type={type}
									variant={variant}
									value={value}
									behavior={behavior}
									layout={layout}
									isPastDisabled={isPastDisabled}
									isFutureDisabled={isFutureDisabled}
									startDate={startDate}
									endDate={endDate}
									disabledDates={disabledDates}
									onTypeSelect={onTypeSelect}
									onChange={onChange}
									ref={contentRef}
									size={size}
									repeatable={repeatable}
									types={types}
									onRepeatableChange={handleRepeatableChange}
								/>
							</ElementAnchor>
						</CSSTransition>
					)}
				</div>
			</TooltipWrapper>
		);
	}),
);

Datepicker.displayName = 'Datepicker';

Datepicker.propTypes = {
	name: PropTypes.string,

	className: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({ value: PropTypes.string, tooltip: PropTypes.string }),
	]),
	placeholder: PropTypes.string,

	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	type: Constants.DatePickerTypesPropType,
	types: PropTypes.arrayOf(Constants.DatePickerTypesPropType),
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	repeatable: PropTypes.bool,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	icon: PropTypes.string,
	iconActive: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),

	size: SizeProp(),
	placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),

	onChange: PropTypes.func,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,

	fieldProps: PropTypes.object,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	floatingError: PropTypes.bool,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

	analyticsKey: PropTypes.string,
	parent: PropTypes.string,

	skipVisibleValue: PropTypes.bool,

	iconOnly: PropTypes.bool,
	direction: PropTypes.string,
};

Datepicker.defaultProps = {
	type: 'day',
	variant: 'default',
	behavior: 'default',
	layout: 'default',

	icon: 'date',
	iconPosition: 'last',

	size: 'md',
	placement: 'bottom',
};

const Component = ControlledWrapper(Datepicker);

ComponentService.register('Datepicker', Component, {
	name: { type: 'string' },

	className: { type: 'string' },
	label: { type: 'string' },

	value: { type: 'string' },
	variant: { type: 'enum', options: ['default', 'multiple', 'range'] },
	type: { type: 'enum', options: ['day', 'week', 'month', 'year'] },
	types: {
		type: 'array',
		of: { type: 'enum', options: ['day', 'week', 'month', 'year'] },
	},
	behavior: { type: 'enum', options: ['default', 'scroll'] },
	layout: { type: 'enum', options: ['default', 'double', 'triple'] },
	locale: {
		type: 'enum',
		options: ['sv', 'en', 'enGB', 'enUS', 'fr', 'uk', 'ru', 'es', 'pt'],
	},

	repeatable: { type: 'boolean' },
	active: { type: 'boolean' },
	disabled: { type: 'boolean' },
	isPastDisabled: { type: 'boolean' },
	isFutureDisabled: { type: 'boolean' },

	startDate: { type: 'string' },
	endDate: { type: 'string' },
	disabledDates: { type: 'string' },

	icon: { type: 'string' },
	iconActive: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },

	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	placement: { type: 'enum', options: ['top', 'bottom', 'right', 'left'] },

	onChange: { type: 'function' },
	onOpen: { type: 'function' },
	onClose: { type: 'function' },

	fieldProps: { type: 'object' },
	error: {
		type: 'object',
		options: {
			type: { type: 'string' },
			message: { type: 'string' },
		},
	},
	floatingError: { type: 'boolean' },
	tooltip: { type: 'string' },

	analyticsKey: { type: 'string' },
	parent: { type: 'string' },

	skipVisibleValue: { type: 'boolean' },

	iconOnly: { type: 'boolean' },
	direction: { type: 'string' },
});

export default Component;
export { Datepicker };
