import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Progress from '@asteria/component-core/progress';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import Splash from '@asteria/component-splash';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';
import useStateToggle from '@asteria/utils-hooks/useStateToggle';

import PieGraph from '../../../../chart/pie';
import ResponsiveContainer from '../../../components/responsive-container';

import InvoicePieTooltip from './pieTooltip';

import './styles.scss';

const InvoicesContent = (props) => {
	const { query, translationOptions, onAction, onSubmit } = props;

	const [type, setTypeType] = useStateToggle('withdraw');
	const categoriesTotal = (query?.data?.source?.[type] ?? []).reduce(
		(acc, { total }) => acc + total,
		0,
	);

	const categoriesCount = (query?.data?.source?.[type] ?? []).reduce(
		(acc, { count }) => acc + count,
		0,
	);

	const values = React.useMemo(() => {
		const dataSource = query?.data?.source?.[type] ?? [];

		return dataSource.map(({ tagId, tag, category, total }) => {
			const data = {
				total: total,
				absoluteTotal: Math.abs(total),
			};

			const colorName = FormatUtils.replace([
				category?.name,
				tag?.name,
			]).join('-');

			return {
				id: tagId,
				...data,
				label: TranslationService.getV2(
					'card.content.type.switch.customer',
					translationOptions,
				),
				fill: 'var(--color)',
				className: `asteria-color__${colorName}`,
				tooltip: (
					<InvoicePieTooltip
						translationOptions={translationOptions}
						tag={tag?.name}
						category={category?.name}
						data={data}
						clientType={type}
					/>
				),
			};
		});
	}, [query?.data?.source, translationOptions, type]);

	const onClick = React.useCallback(
		(tag) =>
			onAction?.('go', {
				path: '/',
				state: {
					settings: {
						filters: [
							{
								type: 'tag',
								category: { name: tag.category.name },
								tag: { name: tag.tag.name },
							},
						],
						graphOptions: {
							lineGraph: false,
							barGraph: true,
						},
					},
				},
			}),
		[onAction],
	);

	const actions = React.useMemo(
		() => ({
			onSubmit,
			onAction,
			forecast: () => onAction?.('go', `/forecaster/category/${type}`),
		}),
		[onAction, onSubmit, type],
	);

	return (
		<div className="flex flex-row gap-4 w-full">
			<ResponsiveContainer
				className={cn(
					'flex flex-col grow',
					'asteria-component__card-categories-content',
				)}
				minWidth={620}
			>
				<Group
					className={cn(
						'asteria-component__card-section',
						'asteria--type-switcher',
						'asteria-component__chip-group',
					)}
					direction="horizontal"
					horizontalAlign="left"
				>
					<Chip
						size="sm"
						label={TranslationService.getV2(
							'card.content.categories.switch.deposit',
							translationOptions,
						)}
						active={type === 'deposit'}
						onClick={() => setTypeType('deposit')}
					/>
					<Chip
						size="sm"
						label={TranslationService.getV2(
							'card.content.categories.switch.withdraw',
							translationOptions,
						)}
						active={type === 'withdraw'}
						onClick={() => setTypeType('withdraw')}
					/>
				</Group>
				{categoriesTotal ? (
					<>
						<div
							className={cn(
								'flex flex-col justify-self-center h-full',
								'asteria-component__pie-chart-wrapper',
							)}
						>
							<PieGraph
								data={values}
								dataKey="absoluteTotal"
								innerRadius="65%"
								centerText={TranslationService.getV2(
									'card.content.categories.chart.title',
									{
										...translationOptions,
										postfix: {
											...translationOptions?.postfix,
											'category-type': type,
										},
										data: {
											...translationOptions?.data,
											categories: {
												total: categoriesTotal,
												count: categoriesCount,
											},
										},
									},
								)}
								centerSubText={TranslationService.getV2(
									'card.content.categories.chart.content',
									{
										...translationOptions,
										postfix: {
											...translationOptions?.postfix,
											'category-type': type,
										},
										data: {
											...translationOptions?.data,
											categories: {
												total: categoriesTotal,
												count: categoriesCount,
											},
										},
									},
								)}
							/>
						</div>
						<div className="flex flex-col gap-4">
							{(query?.data?.source?.[type] ?? [])
								.sort((a, b) => b.total - a.total)
								.map(({ id, tag, category, total, count }) => {
									const progress =
										(total / categoriesTotal) * 100;

									const nextTranslationOptions = {
										...translationOptions,
										data: {
											...translationOptions?.data,
											label: FormatUtils.formatTag({
												category: category?.name,
												tag: tag?.name,
											}),
											category: {
												total: total,
												absoluteTotal: Math.abs(total),
												count: count,
												progress: progress,
											},
										},
										postfix: {
											...translationOptions?.postfix,
											'category-type': type,
											tag: tag?.name,
										},
									};

									const label = FormatUtils.formatTag({
										category: category?.name,
										tag: tag?.name,
									});

									const color = FormatUtils.replace([
										category?.name,
										tag?.name,
									]).join('-');

									return (
										<div className="flex" key={id}>
											<Group className="grow">
												<Group className="flex grow items-center">
													<TooltipWrapper
														tooltip={
															<InvoicePieTooltip
																translationOptions={
																	nextTranslationOptions
																}
																tag={tag?.name}
																category={
																	category?.name
																}
																clientType={
																	type
																}
																data={{
																	total: total,
																	absoluteTotal:
																		Math.abs(
																			total,
																		),
																}}
															/>
														}
													>
														<Chip
															variant="flat"
															label={label}
															colors={color}
															onClick={() =>
																onClick({
																	tag,
																	category,
																})
															}
														/>
													</TooltipWrapper>
													<Translation
														translationKey="card.content.categories.details.value"
														translationOptions={
															nextTranslationOptions
														}
														Component={Title}
														className="grow text-right pr-4"
														size="xxs"
													/>
												</Group>
												<Group className="px-4">
													<Progress
														color={{ type: color }}
														progress={progress}
													/>
													<Group className="flex grow">
														<Translation
															translationKey="card.content.categories.details.count"
															translationOptions={
																nextTranslationOptions
															}
															Component={Text}
															className="grow"
															size="xs"
														/>
														<Translation
															translationKey="card.content.categories.details.progress"
															translationOptions={
																nextTranslationOptions
															}
															Component={Text}
															size="xs"
														/>
													</Group>
												</Group>
											</Group>
										</div>
									);
								})}
						</div>
					</>
				) : (
					<Splash
						className="mt-4"
						path="pages.insights.categories.splash.empty"
						actions={actions}
					/>
				)}
			</ResponsiveContainer>
		</div>
	);
};

InvoicesContent.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
	}),
	onAction: PropTypes.func,
};

export default InvoicesContent;
