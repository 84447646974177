import { useSelector } from 'react-redux';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';

/**
 * @param { boolean } invert
 * @returns { boolean }
 */
export function useMigrated(invert) {
	return useSelector((store) => {
		const response = !!AppStore.selectors.user(store)?.username;

		if (invert) {
			return !response;
		}

		return response;
	});
}

export function useRedirectLink(from) {
	const accessToken = useSelector(AppStore.selectors.accessToken);

	return TranslationService.getV2(['migration.redirect.link'], {
		postfix: { from },
		data: { token: accessToken },
	});
}
