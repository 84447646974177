import React from 'react';

import { useSelector, useStore } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Progress from '@asteria/component-core/progress';
import Slideshow from '@asteria/component-core/slideshow';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import ButtonHelp from '../../components/button-help';
import { OnboardingGuideSwitch } from '../../components/integration-guide';
import IntegrationCancelModal from '../../components/modal-cancel';
import OnboardingWrapper from '../../components/onboarding-wrapper';
import { GuideContext } from '../../context';
import { useFlow, useIntegrationName, usePlaceholder } from '../../hooks';

import Actions from './actions';
import CustomIntegration from './custom';
import { useActions, useIntegrationState } from './hooks';
import { getRedirectURI, openWindow } from './utils';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 * @property { string } id
 * @property { string } type
 * @property { string } name
 */

function getContent(flow) {
	return flow?.steps?.['connecting']?.content;
}

/** @type { React.FC<Props> } */
const StepConnection = React.memo(function StepConnection(props) {
	const { id, onClose, onAction, onSubmit } = props;

	const store = useStore();
	const location = useLocation();

	const editing = location?.state?.editing;

	const [abortModalState, abortModalActions] = useToggleState(false);

	const guided = React.useContext(GuideContext);

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const type = integration?.type ?? props?.type;
	const key = integration?.key ?? integration?.name ?? props?.name;

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.connecting', {
			integration: {
				id: id,
				type: type,
				key: key,
			},
		});

		return () => {
			Analytics.endFlow('integrations.connecting');
		};
	}, [id, key, type]);

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.connecting');

			return onClose?.(event);
		},
		[onClose],
	);

	const abort = React.useCallback(
		async (event) => {
			Analytics.endFlow('integrations.connecting');

			const integration = IntegrationStore.selectors.integration(
				store.getState(),
				id,
			);

			const shouldDelete = !integration?.actions?.length && !editing;

			if (shouldDelete) {
				await onSubmit?.('integrations:delete', integration);
			}

			abortModalActions?.close?.();

			return onClose?.(event);
		},
		[abortModalActions, editing, id, onClose, onSubmit, store],
	);

	const back = React.useCallback(() => {
		Analytics.endFlow('integrations.connecting');

		const integration = IntegrationStore.selectors.integration(
			store.getState(),
			id,
		);

		const shouldDelete = !integration?.actions?.length && !editing;

		if (shouldDelete) {
			onSubmit?.('integrations:delete', integration);
		}

		if (shouldDelete) {
			return onAction?.('go', {
				path: `/onboarding/${type}/${key}/configuration`,
				state: {
					form: {
						config: { client: integration?.config?.client },
					},
					from: null,
				},
				replace: true,
			});
		}

		return onAction?.('go', {
			path: `/onboarding/${id}`,
			state: {
				form: {
					$id: id,
					config: { client: integration?.config?.client },
				},
			},
			replace: true,
		});
	}, [editing, id, key, onAction, onSubmit, store, type]);

	const integrationName = useIntegrationName(type, key);

	const [customActions, setCustomActions] = useActions(integration);

	const actions = React.useMemo(
		() => [].concat(integration?.actions ?? []).concat(customActions),
		[customActions, integration?.actions],
	);

	const handleSubmit = React.useCallback(
		(action, data) => {
			if (action === 'onboarding:action') {
				const event = data?.action?.action;

				if (
					[
						'browser.enable.popup',
						'token.access.update',
						'token.refresh.update',
					].includes(event)
				) {
					const integration = IntegrationStore.selectors.integration(
						store.getState(),
						id,
					);

					const URI = getRedirectURI(integration);

					openWindow(URI, {
						onError: () => {
							if (event === 'browser.enable.popup') {
								setCustomActions([
									{
										action: 'browser.enable.popup',
										status: 'PENDING',
									},
								]);
							}
						},
						onSuccess: () => {
							if (event === 'browser.enable.popup') {
								setCustomActions([
									{
										action: 'browser.enable.popup',
										status: 'PROCESSED',
									},
								]);
							}
						},
					});

					if (event === 'browser.enable.popup') {
						return;
					}
				}
			}

			return onSubmit?.(action, data);
		},
		[id, onSubmit, setCustomActions, store],
	);

	const hasPendingActions = actions.some(
		({ status }) => status === 'PENDING',
	);

	const content = useFlow(type, key, getContent);
	const placeholder = usePlaceholder(type, key, 'connecting');

	return (
		<>
			<IntegrationCancelModal
				open={abortModalState}
				id={id}
				onAction={onAction}
				execute={abort}
				onClose={abortModalActions.close}
			/>

			<Wrapper
				className={cn(
					'asteria-component__onboarding-step',
					'asteria--variant-status',
				)}
				variant="custom"
			>
				<Header onBack={back} onClose={close}>
					<div
						className={cn(
							'flex w-full justify-between flex-col tablet:flex-row items-start tablet:items-center gap-4',
						)}
					>
						<Translation
							translationKey={[
								`integrations.add.title`,
								`integrations.${type}.add.title`,
								`integrations.${key}.add.title`,
								`integrations.${type}.${key}.add.title`,
								`integrations.connecting.title`,
								`integrations.${type}.connecting.title`,
								`integrations.${key}.connecting.title`,
								`integrations.${type}.${key}.connecting.title`,
							]}
							data={{ name: integrationName }}
							Component={Title}
						/>

						{placeholder ? (
							<OnboardingGuideSwitch
								onClick={guided.toggle}
								active={guided.state}
							/>
						) : null}
					</div>
				</Header>
				<OnboardingWrapper placeholder={placeholder}>
					<Content>
						{!hasPendingActions ? (
							guided.state ? (
								<Contenter
									className="mb-4"
									content={content}
									data={{ integration: integration }}
								/>
							) : (
								<div className="flex flex-col">
									<div className="h-32 flex flex-col items-center justify-center gap-2 w-full">
										<Slideshow>
											{Array.from({ length: 3 }).map(
												(_, index) => (
													<Translation
														key={index}
														translationKey={[
															`integrations.connecting`,
															`integrations.${type}.connecting`,
															`integrations.${type}.${key}.connecting`,
															`integrations.connecting.slide`,
														]}
														data={{
															integration:
																integration,
															name: integrationName,
														}}
														translationOptions={{
															postfix: {
																type,
																key,
																step: index + 1,
															},
														}}
														Component={Text}
													/>
												),
											)}
										</Slideshow>

										<Progress progress={-1} />

										<Button
											variant="link"
											label={TranslationService.getV2([
												'button.cancel',
												'action.cancel',
											])}
											size="sm"
											onClick={abortModalActions.open}
										/>
									</div>
									<CustomIntegration
										integration={integration}
									/>
								</div>
							)
						) : null}

						<Actions
							actions={actions}
							integration={integration}
							onAction={onAction}
							onSubmit={handleSubmit}
						/>
					</Content>
					<Footer>
						<FooterSection position="first">
							<Button
								variant="tertiary"
								label={TranslationService.get(
									[
										'action.abort',
										'button.abort',
										'onboarding.modal.action',
										'onboarding.modal.action.abort',
										'onboarding.modal.flow-connecting.action',
										'onboarding.modal.flow-connecting.action.abort',
										`onboarding.modal.${type}.action`,
										`onboarding.modal.${type}.action.abort`,
										`onboarding.modal.${type}.flow-connecting.action`,
										`onboarding.modal.${type}.flow-connecting.action.abort`,
										`onboarding.modal.${key}.action`,
										`onboarding.modal.${key}.action.abort`,
										`onboarding.modal.${key}.flow-connecting.action`,
										`onboarding.modal.${key}.flow-connecting.action.abort`,
										`onboarding.modal.${type}.${key}.action`,
										`onboarding.modal.${type}.${key}.action.abort`,
										`onboarding.modal.${type}.${key}.flow-connecting.action`,
										`onboarding.modal.${type}.${key}.flow-connecting.action.abort`,
									],
									undefined,
									{
										integration: integration,
										name: integrationName,
									},
								)}
								onClick={abortModalActions.open}
							/>
						</FooterSection>
						<FooterSection position="last">
							<ButtonHelp
								onAction={onAction}
								onSubmit={onSubmit}
								extra={{
									from: 'onboarding',
									step: 'status',
									type,
									key,
									id,
								}}
							/>
						</FooterSection>
					</Footer>
					{guided.state && !!placeholder ? (
						<div className="asteria-component__onboarding-placeholder-wrapper">
							{placeholder}
						</div>
					) : null}
				</OnboardingWrapper>
			</Wrapper>
		</>
	);
});

StepConnection.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,

	id: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
};

/** @type { React.FC<Omit<Props, 'id'>> } */
const PageStatus = React.memo(function PageStatus(props) {
	const { id } = useParams();

	const location = useLocation();

	const status = useIntegrationState(id);

	const { type, key } = useSelector((store) => {
		const object = IntegrationStore.selectors.integration(store, id);

		return { type: object?.type, key: object?.key ?? object?.name };
	}, isEqual);

	const state = React.useMemo(
		() => ({ ...location.state, integration: { type, key } }),
		[key, location.state, type],
	);

	if (status === 'error') {
		return (
			<Navigate to={`/onboarding/${id}/error`} state={state} replace />
		);
	}

	if (status === 'success') {
		return (
			<Navigate to={`/onboarding/${id}/success`} state={state} replace />
		);
	}

	return <StepConnection {...props} type={type} name={key} id={id} />;
});

PageStatus.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageStatus;
