function formatValue(data) {
	return {
		count: data?.count ?? 0,
		total: data?.total?.display?.[0]?.total ?? 0,
	};
}

function format(response) {
	const data = {
		health: response?.health?.[0],
		source: {
			summary: formatValue(response?.source?.statistics?.summary),
			paid: formatValue(response?.source?.statistics?.paid),
			unpaid: formatValue(response?.source?.statistics?.unpaid),
			overdue: formatValue(response?.source?.statistics?.overdue),
			forecast: formatValue(response?.source?.statistics?.forecast),
			unsent: formatValue(response?.source?.statistics?.unsent),
			forecast_match: formatValue(
				response?.source?.statistics?.forecast_match,
			),
			credit: formatValue(response?.source_credit?.statistics?.summary),

			customers: {
				summary: formatValue(
					response?.source_customers?.statistics?.summary,
				),
				paid: formatValue(response?.source_customers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_customers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_customers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_customers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.source_customers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.source_customers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.source_customers_credit?.statistics?.summary,
				),
			},
			suppliers: {
				summary: formatValue(
					response?.source_suppliers?.statistics?.summary,
				),
				paid: formatValue(response?.source_suppliers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_suppliers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_suppliers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_suppliers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.source_suppliers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.source_suppliers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.source_suppliers_credit?.statistics?.summary,
				),
			},
		},
		target: {
			summary: formatValue(response?.target?.statistics?.summary),
			paid: formatValue(response?.target?.statistics?.paid),
			unpaid: formatValue(response?.target?.statistics?.unpaid),
			overdue: formatValue(response?.target?.statistics?.overdue),
			forecast: formatValue(response?.target?.statistics?.forecast),
			unsent: formatValue(response?.target?.statistics?.unsent),
			forecast_match: formatValue(
				response?.target?.statistics?.forecast_match,
			),
			credit: formatValue(response?.target_credit?.statistics?.summary),

			customers: {
				summary: formatValue(
					response?.target_customers?.statistics?.summary,
				),
				paid: formatValue(response?.target_customers?.statistics?.paid),
				unpaid: formatValue(
					response?.target_customers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.target_customers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.target_customers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.target_customers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.target_customers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.target_customers_credit?.statistics?.summary,
				),
			},
			suppliers: {
				summary: formatValue(
					response?.target_suppliers?.statistics?.summary,
				),
				paid: formatValue(response?.target_suppliers?.statistics?.paid),
				unpaid: formatValue(
					response?.target_suppliers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.target_suppliers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.target_suppliers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.target_suppliers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.target_suppliers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.target_suppliers_credit?.statistics?.summary,
				),
			},
		},
	};

	const keys = [
		...new Set([
			...Object.keys(data?.source?.customers ?? {}),
			...Object.keys(data?.source?.suppliers ?? {}),
		]),
	];

	data.source.summary = {
		count:
			(data?.source?.customers?.summary?.count ?? 0) +
			(data?.source?.suppliers?.summary?.count ?? 0),
		total:
			(data?.source?.customers?.summary?.total ?? 0) -
			(data?.source?.suppliers?.summary?.total ?? 0),
	};

	data.source = keys
		.filter(
			(key) =>
				key !== 'summary' && key !== 'customers' && key !== 'suppliers',
		)
		.reduce(
			(acc, key) => {
				const { total: customerTotal = 0, count: customerCount = 0 } =
					data.source?.customers?.[key] ?? {};

				const { total: supplierTotal = 0, count: supplierCount = 0 } =
					data.source?.suppliers?.[key] ?? {};

				acc[key] = {
					count: customerCount + supplierCount,
					total: customerTotal - supplierTotal,
				};

				return acc;
			},
			{ ...data.source },
		);

	return data;
}

export default format;
