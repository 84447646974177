import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { addMonths, endOfMonth, format, formatISO } from 'date-fns';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import formatNumber from '@asteria/utils-funcs/formatNumber';

// TODO: BAD!!!
import { LayoutContext } from '../../../../../../../widgets/full/context';

const TransactionHistoryGroupContent = React.memo(
	React.forwardRef((props) => {
		const { category, tag, types } = props;
		const [showHistorical, setShowHistorical] = React.useState(false);
		const { onSubmit } = React.useContext(LayoutContext);
		const [historical, setHistorical] = React.useState([]);

		const tagObject = useSelector((store) => {
			return (store?.app?.tags ?? []).find(({ name }) => name == tag);
		});

		const typesStr = types.join('.');

		React.useEffect(() => {
			setShowHistorical(false);
			setHistorical([]);
		}, [tag, typesStr, category]);

		React.useEffect(() => {
			if (!showHistorical) {
				return;
			}

			const fetchHistory = async () => {
				const types = typesStr.split('.');
				const date = new Date();
				const query = {
					startDate: addMonths(date, -1),
					endDate: formatISO(addMonths(endOfMonth(date), -1), {
						representation: 'date',
					}),
					types: types.map((type) => type.toUpperCase()),
				};

				if (tagObject) {
					query.filters = {
						tags: [tagObject._id],
					};
				}

				const transactions = await onSubmit?.(
					'transactions:list',
					query,
				);

				setHistorical(
					(transactions?.transactions ?? []).sort((a, b) => {
						const val = a.paymentDate - b.paymentDate;

						if (val !== 0) {
							return val;
						}

						return a.description.localeCompare(b.description);
					}),
				);
			};

			fetchHistory();
		}, [typesStr, onSubmit, showHistorical, tagObject]);

		const { setValue } = useFormContext();

		const copyTransaction = React.useCallback(
			(item, single = false) => {
				const date = new Date();
				const start = 0;
				const end = single ? 1 : 12 - date.getMonth();

				let transactionTag = tag;
				let transactionCategory = category;

				if (category === '$type') {
					transactionTag =
						item.meta.tags[item.meta.tags.length - 1].name;
					transactionCategory =
						item.meta.tags[item.meta.tags.length - 1].category.name;
				}

				for (let i = start; i < end; i += 1) {
					const id = uuid();
					const month = format(
						addMonths(new Date(date), i),
						'yyyy-MM-01',
					);

					const name = [
						item.type.toLowerCase(),
						'data',
						month,
						'FORECAST',
						'categories',
						transactionCategory,
						'tags',
						transactionTag,
						'transactions',
						id,
					].join('.');

					setValue(
						name,
						{
							uuid: id,
							description: item?.description,
							paymentDate: formatISO(
								new Date(item.paymentDate).setMonth(
									new Date(month).getMonth(),
								),
								{
									representation: 'date',
								},
							),
							value: 0,
							probability: 1,
							total: item.sums.original.total,
							currency: item.sums.original.currency,
							$category: [
								transactionCategory,
								transactionTag,
							].join('-'),
						},
						{ shouldTouch: true, shouldDirty: true },
					);
				}
			},
			[setValue, tag, category],
		);

		const copyAll = React.useCallback(
			(single) => {
				historical.forEach((item) => copyTransaction(item, single));
			},
			[historical, copyTransaction],
		);

		return (
			<div>
				<Button
					variant="primary"
					onClick={() => setShowHistorical(!showHistorical)}
					label="View historical"
				/>
				{showHistorical ? (
					<div className="flex flex-col">
						{historical.map((transaction) => (
							<div
								key={transaction?._id}
								className="flex gap-2 items-center p-2"
							>
								<Text className="grow">
									{transaction?.description}
								</Text>
								<Text className="pr-4">
									{format(
										transaction?.paymentDate
											? new Date(transaction.paymentDate)
											: new Date(),
										'yyyy-MM-dd',
									)}
								</Text>
								<Text className="pr-4">
									{formatNumber(
										transaction?.sums?.original?.total,
									)}
								</Text>
								<Button
									variant="secondary"
									onClick={() => copyTransaction(transaction)}
									label="Copy End of Year"
								/>
								<Button
									variant="primary"
									onClick={() =>
										copyTransaction(transaction, true)
									}
									label="Copy first month"
								/>
							</div>
						))}
						<div className="flex">
							<Button
								variant="secondary"
								onClick={() => copyAll()}
								label="Copy all end of year"
							/>
							<Button
								variant="primary"
								onClick={() => copyAll(true)}
								label="Copy all next month"
							/>
						</div>
					</div>
				) : null}
			</div>
		);
	}),
);

TransactionHistoryGroupContent.displayName = 'TransactionHistoryGroupContent';

TransactionHistoryGroupContent.propTypes = {
	onAction: PropTypes.func,

	types: PropTypes.arrayOf(PropTypes.string),
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default TransactionHistoryGroupContent;
