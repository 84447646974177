import { useCallback, useMemo, useState } from 'react';

/**
 * @param { boolean } [defaultValue]
 * @returns { [boolean, { open: () => void, close: () => void, toggle: () => void }] }
 */
function useToggleState(defaultValue = false) {
	const [state, setState] = useState(defaultValue);

	const toggle = useCallback(() => {
		setState((value) => !value);
	}, []);

	const open = useCallback(() => {
		setState(true);
	}, []);

	const close = useCallback(() => {
		setState(false);
	}, []);

	return [
		state,
		useMemo(() => ({ open, close, toggle }), [close, open, toggle]),
	];
}

export { useToggleState };
