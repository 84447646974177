import React from 'react';

import { useSelector, useStore } from 'react-redux';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import * as AppStore from '@asteria/datalayer/stores/app';

import { useSubscription } from '@asteria/utils-websocket/hooks';

const CashflowUpdater = React.memo(({ onSubmit }) => {
	const accessToken = useSelector(AppStore.selectors.accessToken);

	const store = useStore();

	const queryClient = useQueryClient();

	const onCashflowUpdate = React.useMemo(
		() =>
			AsteriaCore.utils.throttle(() => {
				const dates = (store.getState()?.graph?.data?.range ?? []).map(
					({ id }) => id,
				);

				for (const date of dates) {
					onSubmit?.('cashflow', { startDate: date });
				}

				onSubmit?.('cashflow:statistics');

				queryClient?.invalidateQueries?.({
					predicate: (query) =>
						(query?.queryKey ?? []).includes('card'),
				});
			}, 2_000),
		[onSubmit, queryClient, store],
	);

	const onNext = React.useCallback(
		(...args) => Promise.all([onCashflowUpdate(args)]),
		[onCashflowUpdate],
	);

	useSubscription({
		token: accessToken,
		query: `
			subscription TimesliceUpdated {
				transactionUpdated {
					_id
					paymentDate
				}
			}
		`,
		onNext: onNext,
	});

	useSubscription({
		token: accessToken,
		query: `
			subscription TimesliceCreated {
				transactionCreated {
					_id
					paymentDate
				}
			}
		`,
		onNext: onNext,
	});

	useSubscription({
		token: accessToken,
		query: `
			subscription TimesliceDeleted {
				transactionDeleted
			}
		`,
		onNext: onNext,
	});

	return null;
});

CashflowUpdater.displayName = 'Updater';
CashflowUpdater.propTypes = { onSubmit: PropTypes.func };

export default CashflowUpdater;
