import { useQuery } from '@tanstack/react-query';

export function useFeedback({ onSubmit, type = 'default' }) {
	return useQuery({
		queryKey: ['feedback', type],
		queryFn: () => onSubmit?.('feedback:fetch') ?? {},
		retry: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
}
