import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { formatISO, subYears } from 'date-fns';

import * as AppStore from '@asteria/datalayer/stores/app';

import { parseDate } from '@asteria/utils-funcs/normalize';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @param { Props } props */
export function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	const tagId = useSelector((store) => {
		const tags = AppStore.selectors.tags(store);
		const tag = tags.find((tag) => tag?.name === '$salary');

		return tag?._id ?? tag?.id ?? '$unknown';
	});

	return useQuery({
		queryKey: ['card', 'salaries', { startDate, endDate }, tagId],
		queryFn: async ({ meta }) => {
			const response = await onSubmit?.('card:fetch', {
				type: 'salaries',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subYears(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subYears(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				dataloader: meta?.dataloader,
			});

			return response;
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate && !!tagId,

		meta: { dataloader },
	});
}

export function useEmployee({
	initialFilter = { field: 'name', direction: 'ASC' },
	employee,
}) {
	const [filter, updateFilter] = React.useState(initialFilter);
	const setFilter = React.useCallback(
		(data) => {
			if (data?.field === filter?.field) {
				return updateFilter((prev) => ({
					...prev,
					direction: prev?.direction === 'DESC' ? 'ASC' : 'DESC',
				}));
			}

			return updateFilter((prev) => ({ ...prev, field: data?.field }));
		},
		[filter?.field],
	);

	const employees = React.useMemo(() => {
		const { field, direction } = filter;

		const getValue = (employee) =>
			field === 'salary' ? employee.salary?.current : employee[field];

		if (!employee?.length) {
			return [];
		}

		return employee.sort((a, b) => {
			let valueA = getValue(a) ?? null;
			let valueB = getValue(b) ?? null;

			if (typeof valueA === 'string' && typeof valueB === 'string') {
				return direction === 'ASC'
					? valueA.localeCompare(valueB)
					: valueB.localeCompare(valueA);
			}

			return direction === 'ASC' ? valueA - valueB : valueB - valueA;
		});
	}, [employee, filter]);

	return { employees, filter, setFilter };
}
