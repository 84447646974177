import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';

import { Content } from '../../basic';

import UnderConstructionModal from './modal';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const SalariesContent = React.memo(function SalariesContent(props) {
	const {
		translationOptions,
		startDate,
		variant,
		importing,
		loading,
		query,
		onAction,
	} = props;

	const [show, setShow] = React.useState(false);

	const openForecaster = React.useCallback(
		() => onAction?.('card:action', { type: 'salaries', date: startDate }),
		[onAction, startDate],
	);

	const openEmployeeList = React.useCallback(() => setShow(true), []);
	const closeEmployeeList = React.useCallback(() => setShow(false), []);

	return (
		<>
			<div className="flex flex-col gap-4">
				<Content.Title {...props} />
				<Content.Text
					{...props}
					showLoading
					loading={importing || loading}
				/>

				{query?.data?.employee?.length ? (
					<Button
						variant="link"
						size="sm"
						label={TranslationService.getV2(
							'card.button.modal',
							translationOptions,
						)}
						onClick={openEmployeeList}
					/>
				) : null}

				{(variant === 'today' || variant === 'future') &&
				!query?.data?.employee?.length ? (
					<Button
						variant="link"
						size="sm"
						label={TranslationService.getV2(
							'card.button',
							translationOptions,
						)}
						onClick={openForecaster}
					/>
				) : null}
			</div>
			<UnderConstructionModal
				open={show}
				{...props}
				onClose={closeEmployeeList}
			/>
		</>
	);
});

SalariesContent.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	startDate: PropTypes.string,
	query: PropTypes.object,
	importing: PropTypes.bool,
	loading: PropTypes.bool,
	translationOptions: PropTypes.object,
};

export default SalariesContent;
