import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const BetaInfoModal = (props) => {
	const { open, onClose, className, onSupportRequest, variant } = props;

	const title = TranslationService.get(
		[`beta.modal.title.variant-${variant}`, 'beta.modal.title'],
		'Beta Information',
	);

	const content = TranslationService.get(
		[`beta.modal.content.variant-${variant}`, 'beta.modal.content'],
		'We are currently in beta testing. While functional, you may encounter minor issues. Your feedback helps us improve!',
	);

	return (
		<Modal
			className={cn('asteria-component__beta-info-modal', className)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<Wrapper>
				<Header onClose={onClose}>{title}</Header>
				<Content>
					<Text>{content}</Text>
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="secondary"
							label={TranslationService.get(
								'button.support',
								'Contact Support',
							)}
							onClick={onSupportRequest}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.close',
								'action.close',
							])}
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

BetaInfoModal.displayName = 'BetaInfoModal';

BetaInfoModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSupportRequest: PropTypes.func,
	variant: PropTypes.string,
};

export default BetaInfoModal;
