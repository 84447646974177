import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';

const InvoicePieTooltip = (props) => {
	const { translationOptions, tag, data, clientType } = props;

	return (
		<div className="flex flex-row justify-between items-center gap-4">
			<Chip
				variant="simple"
				colors={tag}
				label={TranslationService.getV2('card.content.tag.label', {
					...translationOptions,
					data: data,
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
						tag: tag,
					},
				})}
			/>
			<Text>
				{TranslationService.getV2('card.content.tag.value', {
					...translationOptions,
					data: data,
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
						tag: tag,
					},
				})}
			</Text>
		</div>
	);
};

InvoicePieTooltip.propTypes = {
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
	}),
	tag: PropTypes.string,
	key: PropTypes.string,
	data: PropTypes.object,
	clientType: PropTypes.string,
};

export default InvoicePieTooltip;
