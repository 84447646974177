import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';

import '../styles.scss';

/**
 * @typedef Stepper
 * @property { array } open
 * @property { unknown[] } className
 * @property { onAction } onAction
 * @property { onAction } onSubmit
 */

/** @type { React.FC<Stepper> } */
const Stepper = React.memo(function Stepper(props) {
	const { className, steps, onAction, onSubmit } = props;

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	if (!steps?.length) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-component__stepper-wrapper',
				'flex flex-col',
				className,
			)}
		>
			{steps.map((step, index) => {
				const last = index >= steps?.length - 1;

				return (
					<div key={`step-${index}`} className="flex flex-row gap-4">
						<div className=" flex flex-col items-center">
							<div
								className={cn(
									'asteria-component__stepper-step-circle',
									'bg-gray-100 w-10 h-10 aspect-square rounded-full items-center justify-center flex',
								)}
							>
								<Text>{index + 1}</Text>
							</div>
							{!last ? (
								<div
									className={cn(
										'asteria-component__stepper-line',
										'w-1 h-full bg-gray-100',
									)}
								/>
							) : null}
						</div>
						<Contenter
							className={cn('pt-2', { 'pb-6': !last })}
							content={[step]}
							actions={actions}
						/>
					</div>
				);
			})}
		</div>
	);
});

Stepper.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.node),
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Stepper;
