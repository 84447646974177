import React from 'react';

import PropTypes from 'prop-types';
import { Bar, Tooltip } from 'recharts';

import { cn } from '@asteria/utils-funcs/classes';

import BarGraph from '../../../../chart/bar';
import { getName } from '../utils';

import GraphTooltip from './tooltip';

const IncomingExtra = React.memo(function IncomingExtra(props) {
	const { query, translationOptions, importing, loading, state } = props;

	const data = React.useMemo(() => {
		let forecast = query?.data?.source?.FORECAST?.total ?? 0;

		if (!translationOptions?.postfix?.past) {
			forecast =
				(query?.data?.source?.FORECAST?.total ?? 0) +
				(query?.data?.source?.PAID?.total ?? 0) +
				(query?.data?.source?.SIGNED?.total ?? 0) +
				(query?.data?.source?.UNPAID?.total ?? 0);
		}

		return [
			{
				paid: query?.data?.source?.PAID?.total ?? 0,
				overdue: query?.data?.source?.OVERDUE?.total ?? 0,
				unpaid: query?.data?.source?.UNPAID?.total ?? 0,
				signed: query?.data?.source?.SIGNED?.total ?? 0,
				forecast: forecast,
			},
		];
	}, [
		query?.data?.source?.FORECAST?.total,
		query?.data?.source?.OVERDUE?.total,
		query?.data?.source?.PAID?.total,
		query?.data?.source?.SIGNED?.total,
		query?.data?.source?.UNPAID?.total,
		translationOptions?.postfix?.past,
	]);

	const hasPromotion = state?.promotion?.visible;

	const [activeGroup, setActiveGroup] = React.useState(null);

	const handelMouseLeave = React.useCallback(() => setActiveGroup(null), []);

	const onEnterIncoming = React.useCallback(
		() => setActiveGroup?.('incoming'),
		[],
	);

	const onEnterForecast = React.useCallback(
		() => setActiveGroup('forecast'),
		[],
	);

	return (
		<div
			className={cn(
				'w-full flex flex-col',
				'asteria-component__card-chart',
			)}
		>
			<BarGraph
				data={hasPromotion ? [{}] : data}
				loading={importing || loading}
				translationOptions={translationOptions}
			>
				<defs>
					<pattern
						id="incoming-forecast"
						patternUnits="userSpaceOnUse"
						width="6"
						height="6"
						patternTransform="rotate(45)"
					>
						<rect
							width="6"
							height="6"
							fill="var(--color-tags-deposit-background, #2b99a4)"
						/>
						<rect width="3" height="6" fill="rgba(0, 0, 0, 0.2)" />
					</pattern>
				</defs>
				<Tooltip
					cursor={{
						fill:
							hasPromotion || importing || loading
								? 'transparent'
								: 'var(--color-graph-group-hover-background, var(--color-graph-group-normal-background, var(--color-graph-background, rgba(255, 255, 255, 0))))',
					}}
					content={(props) => (
						<GraphTooltip
							translationOptions={translationOptions}
							activeGroup={activeGroup}
							{...props}
						/>
					)}
				/>

				{hasPromotion ? (
					<Bar
						name={getName('loading', translationOptions)}
						dataKey="loading"
						fill="var(--color-skeleton-normal-background, var(--color-progress-background, rgba(235, 231, 226, 1)))"
						stackId="incoming"
					/>
				) : (
					[
						<Bar
							key="paid"
							name={getName('paid', translationOptions)}
							dataKey="paid"
							fill="var(--color-tags-deposit-background, rgb(0, 0, 0))"
							stackId="incoming"
							onMouseEnter={onEnterIncoming}
							onMouseLeave={handelMouseLeave}
						/>,
						<Bar
							key="unpaid"
							name={getName('unpaid', translationOptions)}
							dataKey="unpaid"
							fill="var(--color-tags-unpaid-background, rgb(253, 193, 41))"
							stackId="incoming"
							onMouseEnter={onEnterIncoming}
							onMouseLeave={handelMouseLeave}
						/>,
						<Bar
							key="signed"
							name={getName('signed', translationOptions)}
							dataKey="signed"
							fill="var(--color-tags-signed-background, rgb(253, 193, 41))"
							stackId="incoming"
							onMouseEnter={onEnterIncoming}
							onMouseLeave={handelMouseLeave}
						/>,
						<Bar
							key="overdue"
							name={getName('overdue', translationOptions)}
							dataKey="overdue"
							fill="var(--color-tags-overdue-background, rgb(168, 47, 44))"
							stackId="incoming"
							onMouseEnter={onEnterIncoming}
							onMouseLeave={handelMouseLeave}
						/>,

						<Bar
							key="forecast"
							name={getName('forecast', translationOptions)}
							dataKey="forecast"
							fill="url(#incoming-forecast)"
							stackId="forecast"
							onMouseEnter={onEnterForecast}
							onMouseLeave={handelMouseLeave}
						/>,
					]
				)}
			</BarGraph>
		</div>
	);
});

IncomingExtra.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	query: PropTypes.object,
	translationOptions: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	state: PropTypes.object,
};

export default IncomingExtra;
