import { useSelector } from 'react-redux';

import * as AppStore from '@asteria/datalayer/stores/app';

export function useName() {
	return useSelector((store) => {
		const user = AppStore.selectors.user(store);
		const company = AppStore.selectors.company(store);

		if (user?.fullName) {
			return user?.fullName;
		}

		if (user?.firstName || user?.lastName) {
			return [user?.firstName, user?.lastName].filter(Boolean).join(' ');
		}

		if (company?.name) {
			return company?.name;
		}

		return null;
	});
}
