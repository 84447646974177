import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';

import { useIntegrationName } from '../../../../hooks';

import StatusText from './text';

const Wrapper = React.memo(function Wrapper(props) {
	const { size, children, id, status } = props;

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const type = integration?.type;
	const key = integration?.key ?? integration?.name;

	const integrationName = useIntegrationName(type, key);

	const translationOptions = React.useMemo(
		() => ({
			postfix: {
				type: integration?.type,
				key: integration?.key ?? integration?.name,
				status: status,
				lastSync: !!integration?.lastSync,
				size: size,
			},
			data: { integration, integrationName },
		}),
		[integration, integrationName, size, status],
	);

	return (
		<div
			className={cn(
				'flex gap-2 items-center',
				'asteria-component__onboarding-box-status',
				{ [`asteria--status-${status}`]: status },
			)}
		>
			{children}
			<StatusText
				id={id}
				size={size}
				status={status}
				translationOptions={translationOptions}
			/>
		</div>
	);
});

Wrapper.propTypes = {
	children: PropTypes.node,
	size: PropTypes.string,
	id: PropTypes.string,
	status: PropTypes.string,
};

export default Wrapper;
