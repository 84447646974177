import React, { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
	addMonths,
	compareAsc,
	eachMonthOfInterval,
	format,
	formatISO,
	isFuture,
	isPast,
	isSameMonth,
	isThisMonth,
	parseISO,
	subMonths,
} from 'date-fns';
import { get, isEqual, set } from 'lodash-es';

import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as GraphStore from '@asteria/datalayer/stores/graph';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';
import * as FormatUtils from '@asteria/utils-funcs/format';
import useConfig from '@asteria/utils-hooks/useConfig';
import { useMemoizeHook } from '@asteria/utils-hooks/useDeep';

import { formatFormData, getProbability } from './utils';
import { useFormattedData } from './utils/formatFormData';
import { getPath } from './utils/getFormPaths';

const selectors = {
	tagById: createSelector(
		(store) => store?.app?.tags,
		(_, _id) => _id,
		(tags, _id) => (tags ?? []).find((value) => value?._id === _id),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	tagByName: createSelector(
		(store) => store?.app?.tags,
		(_, { categoryName, tagName }) => ({ categoryName, tagName }),
		(tags, { categoryName, tagName }) =>
			(tags ?? []).find(
				(value) =>
					value?.name === tagName &&
					value?.category?.name === categoryName,
			),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

export const useProperties = ({
	type,
	category,
	tag,
	useForm,
	useStaticForm,
}) => {
	const _id = useSelector((store) =>
		selectors.tagByName(store, { categoryName: category, tagName: tag }),
	)?._id;

	const colors = useCategoryColors({ type, category, tag });

	const probability = useDynamicTagProbability({
		type: type,
		tag: tag,
		category: category,
		useForm: useForm,
		useStaticForm: useStaticForm,
	});

	return useMemo(
		() => ({
			probability: probability,
			_id: _id,
			label: FormatUtils.formatTag({
				type: type,
				category: category,
				tag: tag,
			}),
			colors: colors,
		}),
		[_id, category, colors, probability, tag, type],
	);
};

export function useCategoryColors({ type, category, tag }) {
	const object = useSelector((store) =>
		ForecasterStore.selectors.tags.object(store, {
			type: type,
			categoryName: category,
			tagName: tag,
		}),
	);

	const tagColor = useSelector((store) =>
		ForecasterStore.selectors.tags.color(store, {
			categoryName: category,
			tagName: tag,
		}),
	);

	return useMemo(() => {
		const colors = [];

		if (tagColor) {
			return colors.concat([tagColor]);
		}

		if (object?.color) {
			return colors.concat(object?.color);
		}

		return colors.concat([
			type,
			FormatUtils.replace([category, tag]).join('-'),
		]);
	}, [tagColor, object?.color, type, category, tag]);
}

/**
 * @param { { type: string, category: string, tag: string, form: object } } options
 */
export function useManualTagProbability(options) {
	const { type, tag, category, form } = options;

	const data = Object.values(form?.[type]?.data ?? {})
		.filter((object) => {
			if (category !== '$type') {
				return object?.FORECAST?.categories?.[category]?.tags?.[tag];
			}

			return object?.FORECAST;
		})
		.map((object) => {
			if (category !== '$type') {
				return object?.FORECAST?.categories?.[category]?.tags?.[tag];
			}

			return object?.FORECAST;
		});

	const probability = useMemo(() => {
		if (!data.length) {
			return 1;
		}

		return getProbability(data);
	}, [data]);

	return useMemo(() => probability, [probability]);
}

/**
 * @param { { type: string, category: string, tag: string, useForm: (options: { name?: string | string }) => object, useStaticForm: (name: string) => object } } options
 * @returns { number }
 */
export function useDynamicTagProbability(options) {
	const { type, category, tag, useForm, useStaticForm } = options;

	const dates = Object.keys(useStaticForm([type, 'data'].join('.')) ?? {});

	const values = useForm({
		name: dates.map((date) =>
			getPath({
				type: type,
				date: date,
				category: category,
				tag: tag,
				field: 'value',
			}),
		),
	});

	const probabilities = useForm({
		name: dates.map((date) =>
			getPath({
				type: type,
				date: date,
				category: category,
				tag: tag,
				field: 'probability',
			}),
		),
	});

	const data = dates.map((_, index) => ({
		value: values[index] ?? 0,
		probability: probabilities[index] ?? 1,
	}));

	if (!data.length) {
		return 1;
	}

	return getProbability(data);
}

/**
 * @param { { category?: string, tag?: string, form?: object, history?: object } } [options]
 */
export function useManualFormData(options = {}) {
	let category = useSelector(ForecasterStore.selectors.navigation.category);
	let tag = useSelector(ForecasterStore.selectors.navigation.tag);

	if (options?.category !== undefined) {
		category = options?.category;
	}

	if (options?.tag !== undefined) {
		tag = options?.tag;
	}

	if (category === '$type') {
		category = null;
		tag = null;
	}

	const currentBalance = useSelector(AccountStore.selectors.currentBalance);
	const forecasterLength =
		useConfig('forecaster.target.length', { deep: true }) || 6;

	return useMemo(() => {
		return {
			...formatFormData(
				currentBalance,
				options?.form,
				category,
				tag,
				options?.history,
			),
			targetDate: addMonths(new Date(), forecasterLength - 1),
		};
	}, [
		currentBalance,
		options?.form,
		options?.history,
		category,
		tag,
		forecasterLength,
	]);
}

/**
 * @param { { category?: string, tag?: string, useForm: (options: { name: string | string }) => object, useStaticForm: (name: string) => object, history?: object, useCurrentBalance?: boolean, useUnpaidValues?: boolean } } options
 */
export function useDynamicFormData(options) {
	const {
		useForm,
		useStaticForm,
		history,

		useCurrentBalance,
		useUnpaidValues,
	} = options;

	let category = options?.category ?? null;
	let tag = options?.tag ?? null;

	if (category === '$type') {
		category = null;
		tag = null;
	}

	const currentBalance = useSelector(AccountStore.selectors.currentBalance);
	const forecasterLength =
		useConfig('forecaster.target.length', { deep: true }) || 6;

	const data = useMemoizeHook(useFormattedData, {
		currentBalance: currentBalance,
		history: history,
		useForm: useForm,
		useStaticForm: useStaticForm,
		category: category,
		tag: tag,

		useCurrentBalance: useCurrentBalance,
		useUnpaidValues: useUnpaidValues,
	});

	return useMemo(
		() => ({
			...data,
			targetDate: addMonths(new Date(), forecasterLength - 1),
		}),
		[data, forecasterLength],
	);
}

function formatCurrentData(form, tag) {
	const tagName = tag?.name;
	const categoryName = tag?.category?.name;

	const data = Object.fromEntries(
		Object.entries(form)
			.filter(([, value]) => value?.data)
			.map(([key, { data }]) => [
				key,
				Object.values(data)
					.filter(
						(object) =>
							object?.FORECAST?.categories?.[categoryName]
								?.tags?.[tagName]?.transactions,
					)
					.flatMap((object) =>
						Object.values(
							object?.FORECAST?.categories?.[categoryName]
								?.tags?.[tagName]?.transactions,
						),
					)
					.reduce(
						(acc, value) => {
							const total = value?.total ?? 0;
							const currency = value?.currency ?? 'SEK';

							return {
								...acc,
								count: acc.count + (total !== 0),
								total: {
									...acc.total,
									[currency]:
										(acc.total?.[currency] ?? 0) + total,
								},
							};
						},
						{ count: 0, total: {} },
					),
			]),
	);

	const count = (data?.deposit?.count ?? 0) + (data?.withdraw?.count ?? 0);

	let total = {};

	for (const currency in data?.deposit?.total ?? {}) {
		if (total[currency] === undefined) {
			total[currency] = 0;
		}

		total[currency] += data?.deposit?.total[currency];
	}

	for (const currency in data?.withdraw?.total ?? {}) {
		if (total[currency] === undefined) {
			total[currency] = 0;
		}

		total[currency] -= data?.withdraw?.total[currency];
	}

	return {
		...Object.fromEntries(
			Object.entries(data).map(([type, value]) => [
				type.toUpperCase(),
				Object.fromEntries(
					Object.entries(value).map(([key, value]) => [
						key,
						key === 'count'
							? value
							: Object.entries(value).map(
									([currency, total]) => ({
										currency: currency,
										total: total,
									}),
							  ),
					]),
				),
			]),
		),
		count: count,
		total: Object.entries(total).map(([currency, total]) => ({
			currency: currency,
			total: total,
		})),
	};
}

function formatStatistics(statistics) {
	const history = statistics?.transactions;
	const current = statistics?.current;

	const count = (history?.count ?? 0) + (current?.count ?? 0);

	const currency =
		history?.total?.display?.[0]?.currency ??
		current?.total?.[0]?.currency ??
		'SEK';

	const total =
		(history?.total?.display?.[0]?.total ?? 0) +
		(current?.total?.[0]?.total ?? 0);

	const DEPOSIT = {
		total: [
			{
				currency:
					history?.total?.DEPOSIT?.display?.[0]?.currency ??
					current?.DEPOSIT?.total?.[0]?.currency ??
					'SEK',
				total:
					(history?.total?.DEPOSIT?.display?.[0]?.total ?? 0) +
					(current?.DEPOSIT?.total?.[0]?.total ?? 0),
			},
		],
	};

	const WITHDRAW = {
		total: [
			{
				currency:
					history?.total?.WITHDRAW?.display?.[0]?.currency ??
					current?.WITHDRAW?.total?.[0]?.currency ??
					'SEK',
				total:
					(history?.total?.WITHDRAW?.display?.[0]?.total ?? 0) +
					(current?.WITHDRAW?.total?.[0]?.total ?? 0),
			},
		],
	};

	return {
		...statistics,
		overview: {
			count: count,
			total: [{ currency: currency, total: total }],
			DEPOSIT: DEPOSIT,
			WITHDRAW: WITHDRAW,
		},
	};
}

export function useTagDetails({ _id, form, onSubmit, label }) {
	const tag = useSelector((store) => selectors.tagById(store, _id));

	useEffect(() => {
		onSubmit?.('tag:statistics', _id);
	}, [_id, onSubmit]);

	const currentData = useMemo(
		() => formatCurrentData(form, tag),
		[form, tag],
	);

	const data = useMemo(
		() => ({
			...tag,
			label: label ?? tag?.name,
			statistics: formatStatistics({
				...tag?.statistics,
				current: currentData,
			}),
		}),
		[currentData, label, tag],
	);

	return useMemo(() => data, [data]);
}

/**
 * @param { string } date
 * @returns {{ isCurrent: boolean, isFuture: boolean, isPast: boolean }}
 */
export function parseDate(date) {
	const isCurrentMonth = isSameMonth(
		date instanceof Date ? date : parseISO(date),
		new Date(),
	);

	const isFutureMonth =
		!isCurrentMonth &&
		isFuture(date instanceof Date ? date : parseISO(date));

	const isPastMonth =
		!isCurrentMonth && isPast(date instanceof Date ? date : parseISO(date));

	return {
		isCurrent: isCurrentMonth,
		isFuture: isFutureMonth,
		isPast: isPastMonth,
	};
}

/**
 * @param { string } date
 * @returns {{ isCurrent: boolean, isFuture: boolean, isPast: boolean }}
 */
export function useDate(date) {
	return React.useMemo(() => parseDate(date), [date]);
}

export function useTagContent({
	type,
	category,
	tag,
	useForm,
	useStaticForm,
	key,
	history,
	props,
}) {
	const hasZeroFeature = useFeature('forecaster-actionbar-zero-content');

	const data = useDynamicFormData({
		category: category,
		tag: tag,

		useForm: useForm,
		useStaticForm: useStaticForm,

		history: history,

		useCurrentBalance: true,
	});

	const translationData = React.useMemo(
		() => ({
			...data,
			tag: FormatUtils.formatTag({
				type: type,
				category: category,
				tag: tag,
			}),
		}),
		[category, data, tag, type],
	);

	const isValidCategory = category && category !== '$type';

	const translations = React.useMemo(
		() => [
			...(hasZeroFeature
				? [
						{
							keys: [
								`forecaster.${key}.${type}.body.zero`,
							].concat(
								isValidCategory
									? [
											`forecaster.${key}.tag.${type}.body.zero`,
											`forecaster.${key}.tag.${type}.${category}.body.zero`,
											`forecaster.${key}.tag.${type}.${category}.${tag}.body.zero`,
									  ]
									: [],
							),
							validate: (data) =>
								data?.[type?.toUpperCase?.()]?.total === 0,
						},
						{
							keys: [`forecaster.${key}.body.zero`].concat(
								isValidCategory
									? [`forecaster.${key}.tag.body.zero`]
									: [],
							),
							validate: (data) =>
								data?.DEPOSIT?.total - data?.WITHDRAW?.total ===
								0,
						},
				  ]
				: []),

			{
				keys: [`forecaster.${key}.${type}.body`].concat(
					isValidCategory
						? [
								`forecaster.${key}.tag.${type}.body`,
								`forecaster.${key}.tag.${type}.${category}.body`,
								`forecaster.${key}.tag.${type}.${category}.${tag}.body`,
						  ]
						: [],
				),
				validate: (data) => data?.[type?.toUpperCase?.()]?.endDate,
			},
			{
				keys: [`forecaster.${key}.body`].concat(
					isValidCategory ? [`forecaster.${key}.tag.body`] : [],
				),
				validate: (data) => data?.endDate,
			},

			{
				keys: [`forecaster.${key}.${type}.body.empty`].concat(
					isValidCategory
						? [
								`forecaster.${key}.tag.${type}.body.empty`,
								`forecaster.${key}.tag.${type}.${category}.body.empty`,
								`forecaster.${key}.tag.${type}.${category}.${tag}.body.empty`,
						  ]
						: [],
				),
				validate: (data) => !data?.[type?.toUpperCase?.()]?.endDate,
			},
			{
				keys: [`forecaster.${key}.body.empty`].concat(
					isValidCategory ? [`forecaster.${key}.tag.body.empty`] : [],
				),
				validate: (data) => !data?.endDate,
			},
		],
		[category, hasZeroFeature, isValidCategory, key, tag, type],
	);

	for (const { keys, validate } of translations) {
		if (TranslationService.hasKey(keys) && validate(data)) {
			return (
				<Translation
					translationKey={keys}
					data={translationData}
					Component={Text}
					size="sm"
					{...props}
				/>
			);
		}
	}

	return null;
}

export function useGraphRange() {
	const currentDate = useSelector(ForecasterStore.selectors.navigation.date);

	return useMemo(() => {
		const date = currentDate
			? currentDate instanceof Date
				? currentDate
				: parseISO(currentDate)
			: new Date();

		return eachMonthOfInterval({
			start: subMonths(date, 2),
			end: addMonths(date, 2),
		});
	}, [currentDate]);
}

export function usePredictions() {
	return useSelector(GraphStore.selectors.predictions).reduce(
		(acc, { date: $date, revenue }) => {
			const date = parseISO($date);
			const fmt = format(date, 'yyyy-MM-01');

			acc[fmt] = revenue;

			return acc;
		},
		{},
	);
}

export function useAccumulativeForm({
	useForm,
	history: historyForm,
	types = ['deposit', 'withdraw'],
}) {
	const form = useForm();

	const currentBalance = useSelector(AccountStore.selectors.currentBalance);
	const predictions = usePredictions();

	const formValues = {};

	for (const type of types) {
		for (const date in historyForm?.[type]?.data ?? {}) {
			for (const status in historyForm?.[type]?.data?.[date]) {
				const path = [date, type, status, 'value'];
				const formPath = [type, 'data', date, status, 'value'];

				set(
					formValues,
					path,
					(get(formValues, path) || 0) +
						(get(historyForm, formPath) || 0),
				);
			}
		}

		for (const date in form?.[type]?.data ?? {}) {
			for (const status in form?.[type]?.data?.[date]) {
				const path = [date, type, status, 'value'];
				const formPath = [type, 'data', date, status, 'value'];

				set(
					formValues,
					path,
					(get(formValues, path) || 0) + (get(form, formPath) || 0),
				);
			}
		}
	}

	const entities = Object.entries(formValues).sort(([a], [b]) =>
		compareAsc(new Date(a), new Date(b)),
	);

	const currentMonthIndex = entities.findIndex(([date]) =>
		isThisMonth(parseISO(date)),
	);

	const history = entities
		.slice(0, currentMonthIndex)
		.reverse()
		.reduce(
			(acc, [date]) => {
				const nextDate = formatISO(addMonths(parseISO(date), 1), {
					representation: 'date',
				});

				const object = formValues?.[nextDate];

				const value =
					acc.$last?.value -
					['PAID'].reduce(
						(acc, status) =>
							acc +
							(object?.deposit?.[status]?.value || 0) -
							(object?.withdraw?.[status]?.value || 0),
						0,
					);

				acc[date] = { value: value, min: value, max: value };
				acc.$last = { value: value, min: value, max: value };

				return acc;
			},
			{
				$last: {
					value: currentBalance,
					min: currentBalance,
					max: currentBalance,
				},
			},
		);

	const future = entities.slice(currentMonthIndex).reduce(
		(acc, [date, object]) => {
			const forecast = ['FORECAST'].reduce(
				(acc, status) =>
					acc +
					(object?.deposit?.[status]?.value || 0) -
					(object?.withdraw?.[status]?.value || 0),
				0,
			);

			const unpaid = ['UNPAID', 'SIGNED'].reduce(
				(acc, status) =>
					acc +
					(object?.deposit?.[status]?.value || 0) -
					(object?.withdraw?.[status]?.value || 0),
				0,
			);

			const value = acc.$last.value + forecast + unpaid;

			let min = value + acc?.$last?.min;
			let max = value + acc?.$last?.max;

			if (predictions?.[date]) {
				min = value + (predictions?.[date]?.min ?? 0);
				max = value + (predictions?.[date]?.max ?? 0);

				acc.$last.min = predictions?.[date]?.min ?? 0;
				acc.$last.max = predictions?.[date]?.max ?? 0;
			}

			acc[date] = { value: value, min: min, max: max };

			acc.$lastValue = { value: value, min: min, max: max };
			acc.$last.value = value;

			return acc;
		},
		{
			$last: { value: currentBalance, min: 0, max: 0 },
			$lastValue: { value: currentBalance, min: 0, max: 0 },
		},
	);

	return { history, future };
}

export function useLoading() {
	const hasAutoSaveFeature = useFeature('forecaster-auto-save');

	const isIntegrationLoading = useSelector(
		IntegrationStore.selectors.isLoading,
	);

	const isForecasterLoading = useSelector(
		ForecasterStore.selectors.flags.isLoading,
	);

	const isForecasterSaving = useSelector(
		ForecasterStore.selectors.flags.isSaving,
	);

	const completedScenarioIsSaving = useSelector(
		ForecasterStore.selectors.flags.completedScenarioIsSaving,
	);

	const isSaving =
		(isForecasterSaving && completedScenarioIsSaving) ||
		(isForecasterSaving && !hasAutoSaveFeature);

	return React.useMemo(() => {
		if (isIntegrationLoading) {
			return 'integration';
		}

		if (isSaving) {
			return 'saving';
		}

		if (isForecasterLoading) {
			return 'loading';
		}

		return null;
	}, [isForecasterLoading, isIntegrationLoading, isSaving]);
}
