import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { useBlocker } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import PageHeader, {
	PageContent,
	PageTitle,
} from '@asteria/component-core/pageHeader';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import { FAQContent } from '@asteria/component-support/Guide';
import {
	FeatureFlag,
	Service as FeatureService,
	useBulkFeatures,
	useFeature,
} from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { useTourShowAction } from '@asteria/utils-hooks/tour';

import WelcomeContext from './context';
import { HelpModal } from './modals/Help';
import LinearPage from './pages/Linear';
import OverviewPage from './pages/Overview';
import QuizPage from './pages/Quiz';
import * as WelcomeSelectors from './selectors';

import './styles.scss';

function useActions({ validateWizardProgress, skipWizardProgress }) {
	const { actions: ctxActions, wizard: ctxWizard } =
		React.useContext(WelcomeContext);

	return useSelector(
		(store) => {
			const user = WelcomeSelectors.userQuestions(store);
			const company = WelcomeSelectors.companyQuestions(store);
			const completed = WelcomeSelectors.completedActions(store);
			const integrations = IntegrationStore.selectors.integrations(
				store,
				{ connected: true },
			);

			if (
				completed?.['connect:erp'] &&
				!integrations.some(({ type }) => type === 'erp')
			) {
				delete completed['connect:erp'];
			}

			if (
				completed?.['connect:bank'] &&
				!integrations.some(({ type }) => type === 'bank')
			) {
				delete completed['connect:bank'];
			}

			let actions = (ctxActions ?? []).filter(
				(object) =>
					!completed?.[object?.key] &&
					(!object?.feature ||
						FeatureService.isActive(object?.feature)),
			);

			if (skipWizardProgress) {
				actions = actions.filter(
					(object) => object?.variant !== 'wizard',
				);
			}

			if (validateWizardProgress) {
				actions = actions
					.map((object) => {
						const name = object?.key;
						const wizard = object?.props?.wizard;

						const step =
							user?.[name]?.progress ??
							company?.[name]?.progress ??
							0;

						const config = ctxWizard?.[wizard];
						const steps =
							(config?.steps ?? []).length + !!config?.done;

						const progress =
							object?.variant === 'wizard'
								? !step
									? 0
									: Math.min((step / steps) * 100, 100)
								: null;

						return { ...object, progress: progress };
					})
					.filter(
						(object) =>
							object?.variant !== 'wizard' ||
							object?.progress !== 100,
					);
			}

			return actions;
		},
		(a, b) => isEqual(a, b),
	);
}

const SkipButton = (props) => {
	const { onAction, validateWizardProgress, skipWizardProgress } = props;

	const handleSkip = React.useCallback(
		() => onAction?.('welcome:close'),
		[onAction],
	);

	const hasWelcomeForecaster = useBulkFeatures([
		['forecaster', 'widget-welcome-page-forecaster-redirect'],
	]);

	const actions = useActions({
		validateWizardProgress: validateWizardProgress,
		skipWizardProgress: skipWizardProgress,
	});

	if (!actions.length) {
		return (
			<div
				className={cn(
					'asteria-view__welcome__link',
					'asteria--state-completed',
				)}
			>
				<Button
					variant="primary"
					onClick={handleSkip}
					label={TranslationService.get([
						'view.welcome.link.skip',
						!hasWelcomeForecaster &&
							'view.welcome.link.skip.cashflow',
						hasWelcomeForecaster &&
							'view.welcome.link.skip.forecaster',
						'view.welcome.link.primary.skip',
						!hasWelcomeForecaster &&
							'view.welcome.link.primary.skip.cashflow',
						hasWelcomeForecaster &&
							'view.welcome.link.primary.skip.forecaster',
					])}
					analyticsKey="welcome.skip.primary"
				/>
			</div>
		);
	}

	return (
		<div className="asteria-view__welcome__link">
			<Text size="sm">
				{TranslationService.get([
					'view.welcome.text.skip',
					!hasWelcomeForecaster && 'view.welcome.text.skip.cashflow',
					hasWelcomeForecaster && 'view.welcome.text.skip.forecaster',
				])}
			</Text>
			<Button
				icon="onboarding-box"
				size="sm"
				iconSize="md"
				variant="link"
				label={TranslationService.get([
					'view.welcome.link.skip',
					!hasWelcomeForecaster && 'view.welcome.link.skip.cashflow',
					hasWelcomeForecaster && 'view.welcome.link.skip.forecaster',
				])}
				onClick={handleSkip}
				analyticsKey="welcome.skip"
			/>
		</div>
	);
};

SkipButton.displayName = 'SkipButton';

SkipButton.propTypes = {
	onAction: PropTypes.func,
	validateWizardProgress: PropTypes.bool,
	skipWizardProgress: PropTypes.bool,
};

const WelcomeFooter = (props) => {
	const {
		onAction,
		validateWizardProgress,
		skipWizardProgress,
		hideSkipButton,
	} = props;

	const dispatch = useDispatch();

	const hasAssistanceFeature = useFeature('welcome-request-assistance');

	const handleHelpClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({ type: ModalStore.MODAL_WINDOWS.WelcomeHelp }),
		);
	}, [dispatch]);

	return (
		<div className="asteria-view__welcome__footer">
			{hasAssistanceFeature ? (
				<div className="asteria-view__welcome__link">
					<Text size="sm">
						{TranslationService.get('view.welcome.text.help')}
					</Text>
					<Button
						size="sm"
						variant="link"
						label={TranslationService.get('view.welcome.link.help')}
						onClick={handleHelpClick}
						analyticsKey="welcome.help"
					/>
				</div>
			) : null}
			{!hideSkipButton ? (
				<SkipButton
					onAction={onAction}
					validateWizardProgress={validateWizardProgress}
					skipWizardProgress={skipWizardProgress}
				/>
			) : null}
		</div>
	);
};

WelcomeFooter.displayName = 'WelcomeFooter';
WelcomeFooter.propTypes = {
	onAction: PropTypes.func,
	validateWizardProgress: PropTypes.bool,
	skipWizardProgress: PropTypes.bool,
	hideSkipButton: PropTypes.bool,
};

const Welcome = (props) => {
	const { onAction, onSubmit, variant = 'default' } = props;

	const store = useStore();
	const dispatch = useDispatch();

	const hasLinearFeature = useFeature('welcome-variant-linear');
	const hasSupportFeature = useFeature('welcome-support-contact');

	const shouldBlock = React.useCallback(
		({ currentLocation, nextLocation }) => {
			return (
				!nextLocation?.state?.backgroundLocation &&
				currentLocation.pathname.includes('welcome') &&
				!nextLocation.pathname.includes('welcome') &&
				!WelcomeSelectors.isWelcomeShown(store.getState())
			);
		},
		[store],
	);

	useBlocker(shouldBlock);

	const [state, setPage] = React.useState(() => {
		const state = {
			page: 'overview',
			wizard: null,
			name: null,
		};

		if (hasLinearFeature) {
			state.page = 'linear';
		}

		return state;
	});

	React.useEffect(() => {
		if (hasLinearFeature) {
			setPage({ page: 'linear' });
		} else {
			setPage({ page: 'overview' });
		}
	}, [hasLinearFeature]);

	const handleAction = React.useCallback(
		(action, data) => {
			function updateQuestion(name, data) {
				const state = store.getState();

				const company = state?.app?.company;
				const user =
					state?.app?.user?.settings?.flags?.['welcome:questions'];

				dispatch(
					AppStore.setCompany({
						...company,
						questions: {
							...company?.questions,
							[name]: {
								...company?.questions?.[name],
								...data,
							},
						},
					}),
				);

				dispatch(
					AppStore.setUserSettingsFlags({
						'welcome:questions': {
							...user,
							[name]: { ...user, ...data },
						},
					}),
				);

				onAction?.('refreshUserSettings');

				return onAction?.('welcome:company:settings:save', {
					questions: { [name]: data },
				});
			}

			if (action === 'wizard:show') {
				setPage({
					page: 'quiz',
					name: data?.name,
					wizard: data?.wizard,
				});

				return;
			}

			Analytics.event('welcome.action', { action: action, data: data });

			if (action === 'welcome:step') {
				const name = data?.name;
				const step = data?.step;

				return updateQuestion(name, { progress: step });
			}

			if (action === 'wizard:done') {
				const name = data?.name;
				const form = data?.data;

				updateQuestion(name, { data: form });

				if (hasLinearFeature) {
					onAction?.('welcome:close');
				}
			}

			if (action === 'wizard:next') {
				const name = data?.name;
				const form = data?.data;

				if (form?.form) {
					updateQuestion(name, { data: form?.form });
				}
			}

			if (['wizard:cancel', 'wizard:done'].includes(action)) {
				if (!hasLinearFeature) {
					setPage({ page: 'overview' });
				}
				return;
			}

			if (action.startsWith('wizard:')) {
				return;
			}

			return onAction?.(action, data);
		},
		[dispatch, hasLinearFeature, onAction, store],
	);

	useTourShowAction();

	return (
		<div
			className={cn('asteria-view__welcome-wrapper', {
				['asteria--state-has-support']: hasSupportFeature,
			})}
		>
			<div
				className={cn('asteria-view__welcome', {
					[`asteria-view__welcome--page-${state.page}`]: state.page,
					[`asteria-view__welcome--variant-${variant}`]: variant,
				})}
			>
				{variant !== 'modal' ? (
					<PageHeader>
						<PageContent>
							<PageTitle
								title={TranslationService.get(
									'view.welcome.title',
								)}
							/>
						</PageContent>
					</PageHeader>
				) : null}
				<div className="asteria-view__welcome__content">
					{state.page === 'overview' ? (
						<OverviewPage onAction={handleAction} />
					) : null}
					{state.page === 'quiz' ? (
						<QuizPage
							name={state.name}
							wizard={state.wizard}
							onAction={handleAction}
						/>
					) : null}
					{state.page === 'linear' ? (
						<LinearPage onAction={handleAction} />
					) : null}
				</div>
				{variant !== 'modal' ? (
					<WelcomeFooter
						onAction={onAction}
						validateWizardProgress={state.page === 'overview'}
						skipWizardProgress
						hideSkipButton={state.page === 'quiz'}
					/>
				) : null}
			</div>
			<FeatureFlag feature="welcome-support-contact">
				<FAQContent onSubmit={onSubmit} onAction={onAction} />
			</FeatureFlag>
		</div>
	);
};

Welcome.displayName = 'Welcome';

Welcome.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	variant: PropTypes.oneOf(['default', 'modal']),
};

const WelcomeModal = (props) => {
	const { open, onClose, onAction } = props;

	return (
		<Modal open={open} onClose={onClose}>
			<Wrapper>
				<Header onClose={onClose} verticalAlign="center">
					{TranslationService.get('view.welcome.title')}
				</Header>
				<Content scroll>
					<Welcome variant="modal" onAction={onAction} />
				</Content>
				<Footer>
					<FooterSection position="last">
						<Button
							label={TranslationService.get([
								'action.close',
								'button.close',
								'welcome.modal.button.close',
							])}
							variant="tertiary"
							onClick={onClose}
							analyticsKey="welcome.modal.close"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

WelcomeModal.displayName = 'WelcomeModal';

WelcomeModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

export default Welcome;
export { HelpModal, WelcomeContext, WelcomeModal };
