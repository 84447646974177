import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { ConfirmationModal } from '@asteria/component-modal';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { useIntegrationName } from '../hooks';

/** @type { React.FC<{ open: boolean, onClose: import('react').MouseEventHandler, id: string, onSubmit: () => void, onAction: () => void }> } */
const IntegrationRemoveModal = React.memo(function IntegrationRemoveModal(
	props,
) {
	const { open, onClose, id, onSubmit } = props;

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const type = integration?.type;
	const key = integration?.key ?? integration?.name;

	const integrationName = useIntegrationName(type, key);

	const submit = useMutation({
		mutationFn: async () => onSubmit?.('integrations:delete', { id: id }),
		onSuccess: onClose,
	});

	const options = React.useMemo(
		() => ({
			postfix: { from: 'onboarding-remove', type, key },
			data: { integration, integrationName },
		}),
		[integration, integrationName, key, type],
	);

	return (
		<ConfirmationModal
			open={open}
			onClose={onClose}
			onSubmit={submit.mutateAsync}
			options={options}
		/>
	);
});

IntegrationRemoveModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	id: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default IntegrationRemoveModal;
