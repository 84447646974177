import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import { cn } from '@asteria/utils-funcs/classes';

import BreakdownDetailsTooltip from './tooltip-breakdown-details';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<{ value: unknown, index: number, total: number } & Pick<ChildrenProps, 'translationOptions'>> } */
const BreakdownGroup = React.memo(function BreakdownGroup(props) {
	const { value, translationOptions, index, total } = props;

	const paid = value?.paid?.total ?? 0;
	const forecast = value?.forecast?.total ?? 0;

	const currentTotal = paid + forecast;

	const color = cn({
		'bg-taxes-1': index + 1 === 1,
		'bg-taxes-2': index + 1 === 2,
		'bg-taxes-3': index + 1 === 3,
		'bg-taxes-4': index + 1 === 4,
		'bg-taxes-5': index + 1 === 5,
		'bg-taxes-6': index + 1 === 6,
		'bg-taxes-7': index + 1 === 7,
		'bg-taxes-8': index + 1 === 8,
		'bg-taxes-9': index + 1 === 9,
		'bg-taxes-10': index + 1 === 10,
	});

	if (!paid && !forecast) {
		return null;
	}

	return (
		<TooltipWrapper
			tooltip={
				<BreakdownDetailsTooltip
					value={value}
					color={color}
					translationOptions={translationOptions}
				/>
			}
		>
			<div
				style={{ width: `${(currentTotal / total) * 100}%` }}
				className={cn(
					'flex',
					'asteria-component__taxes-breakdown-group',
				)}
			>
				{paid ? (
					<div
						style={{
							width: `${(paid / currentTotal) * 100}%`,
						}}
						className={cn(
							`h-4`,
							color,
							'asteria-component__taxes-breakdown-item',
						)}
					/>
				) : null}
				{forecast ? (
					<div
						style={{
							width: `${(forecast / currentTotal) * 100}%`,
							backgroundImage: `var(--image)`,
						}}
						className={cn(
							`h-4`,
							color,
							'asteria-component__taxes-breakdown-item',
							'asteria-color__forecast',
						)}
					/>
				) : null}
			</div>
		</TooltipWrapper>
	);
});

BreakdownGroup.propTypes = {
	value: PropTypes.object,
	index: PropTypes.number,
	total: PropTypes.number,
	translationOptions: PropTypes.object,
};

export default BreakdownGroup;
