import React from 'react';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageSubtitle,
	PageTitle,
} from '@asteria/component-core/pageHeader';
import { Text, Title } from '@asteria/component-core/typography';

import IntegrationBox, {
	StatusIntegrationBox,
} from '@asteria/component-integrations-v2/components/box';
import MissingIntegrationSection from '@asteria/component-integrations-v2/components/missing';
import { SearchWrapper as OnboardingSearchWrapper } from '@asteria/component-integrations-v2/components/search';
import Notifications from '@asteria/component-notifications';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const Connected = React.memo(function Connected(props) {
	const { integrations, onAction, onSubmit, onOpen } = props;

	if (!integrations.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			<Translation
				translationKey="view.integrations.section.title"
				translationOptions={{
					postfix: { section: 'connected' },
					data: { count: integrations.length },
				}}
				Component={Title}
				size="xxs"
			/>
			<div className="grid md:grid-cols-2 gap-4">
				{integrations.map((object) => (
					<StatusIntegrationBox
						key={object?._id ?? object?.id}
						id={object?._id ?? object?.id}
						type={object?.type}
						name={object?.key ?? object?.name}
						onAction={onAction}
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="status"
					/>
				))}
			</div>
		</div>
	);
});

Connected.propTypes = {
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

const Available = React.memo(function Available(props) {
	const { connected, available, onAction, onSubmit, onOpen } = props;

	const exists = !!connected?.length;

	if (!connected?.length && !available?.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			{exists ? (
				<Translation
					translationKey="view.integrations.section.title"
					translationOptions={{ postfix: { section: 'available' } }}
					Component={Title}
					size="xxs"
				/>
			) : null}

			<div className="grid md:grid-cols-2 gap-4">
				{available.map((object) => (
					<IntegrationBox
						key={[object?.type, object?.key].join('-')}
						type={object?.type}
						name={object?.key}
						onAction={onAction}
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="connect"
					/>
				))}
			</div>
		</div>
	);
});

Available.propTypes = {
	available: PropTypes.arrayOf(PropTypes.object),
	connected: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

const OnboardingView = React.memo(function OnboardingView(props) {
	const { className, onAction, onSubmit } = props;

	const hasBankFeature = useFeature('bank-integrations');

	const onOpenConnected = React.useCallback(
		({ integration }) => {
			const id = integration?._id ?? integration?.id;

			return onAction?.('go', `/onboarding/${id}`);
		},
		[onAction],
	);

	const onOpenAvailable = React.useCallback(
		({ flow }) => {
			const type = flow?.type;
			const key = flow?.key;

			return onAction?.('go', `/onboarding/${type}/${key}`);
		},
		[onAction],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-4 pb-20',
				'asteria-view__onboarding',
				className,
			)}
		>
			<PageHeader>
				<PageContent>
					<PageTitle
						title={TranslationService.get(
							'view.integrations.title',
							// eslint-disable-next-line spellcheck/spell-checker
							'Bokföringssystem',
						)}
					/>
					<PageSubtitle>
						<Translation
							translationKey="view.integrations.sub-title"
							translationOptions={{
								postfix: {
									type: hasBankFeature ? null : 'erp',
								},
							}}
							Component={Text}
						/>
					</PageSubtitle>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-onboarding">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>
			<OnboardingSearchWrapper onAction={onAction} onSubmit={onSubmit}>
				{({ available, connected }) => (
					<div className="flex flex-col gap-8">
						<Connected
							integrations={connected}
							onAction={onAction}
							onSubmit={onSubmit}
							onOpen={onOpenConnected}
						/>
						<Available
							available={available}
							connected={connected}
							onAction={onAction}
							onSubmit={onSubmit}
							onOpen={onOpenAvailable}
						/>
						<MissingIntegrationSection
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					</div>
				)}
			</OnboardingSearchWrapper>
		</div>
	);
});

OnboardingView.displayName = 'OnboardingView';

OnboardingView.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default OnboardingView;
