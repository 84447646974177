/* eslint-disable spellcheck/spell-checker */
import * as Content from './content';

export default {
	'auth.2fa.action.abort': `Avbryt`,
	'auth.2fa.action.done': `Klart`,
	'auth.2fa.action.next': `Nästa`,
	'auth.2fa.code.error.message': `Behörighetskoden är inte giltig`,
	'auth.2fa.deactivate.step.0.content.0': `Fyll i en verifieringskod från din authenticator eller en reservkod för att de-aktivera 2-stegsverifieringen`,
	'auth.2fa.deactivate.step.0.input.label': `Verifieringskod`,
	'auth.2fa.deactivate.step.0.input.placeholder': `Exempel 987178`,
	'auth.2fa.deactivate.step.1.alert.content.0': `Du har inaktiverat tvåstegsverifiering.`,
	'auth.2fa.deactivate.step.1.alert.title': `Klar`,
	'auth.2fa.deactivate.title': `Tvåstegsverifiering`,
	'auth.2fa.setup.step.0.content.0': `Tvåstegsverifiering lägger till ett extra lager av skydd till ditt konto.`,
	'auth.2fa.setup.step.0.content.1': `När du loggar in måste du ange både ditt lösenord och även en verifieringskod. Denna verifieringskod kommer att genereras av en autentiseringsapp (Exempelvis Google Authenticator eller Microsoft Authenticator).`,
	'auth.2fa.setup.step.1.alert.content.0': `Om du inte kan tillhandahålla en verifierings- eller reservkod kommer du att förlora åtkomsten till ditt konto.`,
	'auth.2fa.setup.step.1.bullet.0': `En verifieringskod via en autentiseringsapp (Exempelvis Google Authenticator eller Microsoft Authenticator).`,
	'auth.2fa.setup.step.1.bullet.1': `En reservkod`,
	'auth.2fa.setup.step.1.content.0': `Med tvåstegsverifiering aktiverad behöver du alltid ditt lösenord och något av följande för att komma åt ditt konto:`,
	'auth.2fa.setup.step.1.title': `Viktiga saker att komma ihåg`,
	'auth.2fa.setup.step.2.manual.action': `Skanna streckkoden`,
	'auth.2fa.setup.step.2.manual.bullet.0': `1. Väl i appen, trycker du på +.`,
	'auth.2fa.setup.step.2.manual.bullet.1': `2. I “Nyckel” skriv din hemliga nyckel:`,
	'auth.2fa.setup.step.2.manual.bullet.2': `3. Tryck på klar.`,
	'auth.2fa.setup.step.2.manual.content.0': `Ange koden manuellt nedan`,
	'auth.2fa.setup.step.2.manual.content.1': `Ange den sexsiffriga verifieringskoden som genereras av appen i rutan nedan.`,
	'auth.2fa.setup.step.2.manual.input.label': `Ange din kod`,
	'auth.2fa.setup.step.2.manual.input.placeholder': `Exempel 987178`,
	'auth.2fa.setup.step.2.manual.secret.copy.action.tooltip': `Klicka för att kopiera hemlig kod`,
	'auth.2fa.setup.step.2.qr.action': `Kan du inte skanna streckkoden?`,
	'auth.2fa.setup.step.2.qr.content.0': `Skanna streckkoden nedan`,
	'auth.2fa.setup.step.2.qr.content.1': `Öppna Google Authenticator och tryck på "+"-knappen. Rikta sedan kameran mot QR-koden. Klart! Om du använder Microsoft Authenticator, öppna appen och klicka på "Verifierade ID'n" nere till höger. Klicka sedan på QR-koden uppe till höger och rikta kameran mot QR-koden. Klart! Om du inte har använt en authenticator tidigare kan appen fråga om du vill logga in på ett konto, som till exempel ett arbets- eller privatkonto. Detta steg är valfritt och det är inte ett krav att logga in eller koppla authenticatorn till ett specifikt konto. För andra authenticator-appar kan processen se annorlunda ut.`,
	'auth.2fa.setup.step.2.qr.content.2': `Ange din kod`,
	'auth.2fa.setup.step.2.qr.content.3': `Ange den sexsiffriga verifieringskoden som genereras av Authenticator-appen i rutan nedan.`,
	'auth.2fa.setup.step.2.qr.content.extra': `Om du inte har använt en authenticator tidigare kan appen fråga om du vill logga in på ett konto, som till exempel ett arbets- eller privatkonto. Detta steg är valfritt och det är inte ett krav att logga in eller koppla authenticatorn till ett specifikt konto. För andra authenticator-appar kan processen se annorlunda ut.`,
	'auth.2fa.setup.step.2.qr.google.bullet.0': `1. Öppna Google Authenticator och tryck på ""+""-knappen.`,
	'auth.2fa.setup.step.2.qr.google.bullet.1': `2. Rikta sedan kameran mot QR-koden. Klart!`,
	'auth.2fa.setup.step.2.qr.google.content.0': `Google Authenticator`,
	'auth.2fa.setup.step.2.qr.input.label': `Verifieringskod`,
	'auth.2fa.setup.step.2.qr.input.placeholder': `Exempel 987178`,
	'auth.2fa.setup.step.2.qr.microsoft.bullet.0': `1. Öppna Microsoft Authenticator och klicka på ""Verifierade ID'n"" nere till höger.`,
	'auth.2fa.setup.step.2.qr.microsoft.bullet.1': `2. Klicka sedan på QR-koden uppe till höger och rikta kameran mot QR-koden. Klart!`,
	'auth.2fa.setup.step.2.qr.microsoft.content.0': `Microsoft Authenticator`,
	'auth.2fa.setup.step.3.action.copy': `Kopiera reservkoder`,
	'auth.2fa.setup.step.3.content.0': `Dina reservkoder`,
	'auth.2fa.setup.step.3.content.1': `Skriv ut dessa säkerhetskoder eller skriv ner spara på en säker plats.`,
	'auth.2fa.setup.step.4.alert.content.0': `Du har aktiverat tvåstegsverifiering.`,
	'auth.2fa.setup.step.4.alert.title': `Klar`,
	'auth.2fa.setup.title': `Tvåstegsverifiering`,
	'auth.2fa.verify.step.0.content.0': `För att skydda din integritet, ange koden från din authenticator eller en av dina reservkoder`,
	'auth.2fa.verify.step.0.input.label': `Ange kod`,
	'auth.2fa.verify.step.0.input.placeholder': `Ange din kod`,
	'auth.2fa.verify.step.1.action.copy': `Kopiera reservkoder`,
	'auth.2fa.verify.step.1.action.reset': `Skapa reservkoder`,
	'auth.2fa.verify.step.1.content.0': `Dina reservkoder`,
	'auth.2fa.verify.step.1.content.1': `Skriv ut dessa säkerhetskoder eller skriv ner dem och spara på en säker plats.`,
	'auth.2fa.verify.step.1.content.2': `Skapa nya reservkoder`,
	'auth.2fa.verify.step.1.content.3': `Varje reservkod kan endast användas en gång. Endast den senaste uppsättningen reservkoder fungerar.`,
	'auth.2fa.verify.title': `Reservkoder`,
	'auth.code.label': `Authorization code`,
	'auth.companies.action.logout': `Logga ut`,
	'auth.companies.title': `Välj företag`,
	'auth.login.password.mismatch': `E-post eller lösenord är inkorrekt, försök igen`,
	'auth.login.user.not-found': `E-postadressen hittades inte`,
	'auth.password.forgot.action.submit': `Begär lösenord`,
	'auth.password.forgot.email.label': `E-post`,
	'auth.password.forgot.email.placeholder': `Ange din e-postadress`,
	'auth.password.forgot.label': `Glömt lösenord?`,
	'auth.password.forgot.success.action.submit': `Gå tillbaka till inloggningssidan`,
	'auth.password.forgot.success.alert.content': `Vi har skickat instruktioner till din e-mail om hur du du väljer ett nytt lösenord. När du valt ett nytt lösenord så gå tillbaka till sidan för att logga in.`,
	'auth.password.forgot.success.alert.title': `Du har begärt att återstålla ditt lösenord`,
	'auth.password.forgot.success.title': `Klart`,
	'auth.password.forgot.title': `Glömt lösenord`,
	'auth.password.reset.action.submit': `Spara`,
	'auth.password.reset.password1.label': `Nytt lösenord`,
	'auth.password.reset.success.alert.content': `Ditt lösenord har uppdaterats`,
	'auth.password.reset.success.alert.title': `Lösenord ändrat`,
	'auth.password.reset.title': `Återställ lösenord`,
	'auth.profile.change.action.submit': `Byt`,
	'auth.profile.change.title': `Välj företag`,
	'auth.signup.code.request': `Begär kod`,
	'authentication.login.error': `Fel användarnamn eller lösenord`,
	'login.title': `Logga in`,
	'asteria.information.mobile': `Vi jobbar på upplevelsen i mobilen. För att kunna använda Företagskollen rekommenderar vi dig att logga in på internetbanken på din dator`,
	'email.cusin-error.content.action': `Logga in`,
	'email.cusin-error.content.description': `Hej. Fel har uppstått i en av dina fakturafiler. Du hittar felmeddelandet när du loggar in i Asterias Fakturaportal.`,
	'email.forgot-password.content.description': `Det verkar som att du har problem med att logga in. Om du glömt ditt lösenord, tryck på knappen nedan. Annars kan du ignorera detta mail.`,
	'email.forgot-password.content.title': `Problem att logga in?`,
	'login.2fa.code.invalid': `Den angivna 2FA-koden är inte giltig.`,
	'login.action.signup': `Registrera dig`,
	'login.action.submit': `Logga in`,
	'login.email.label': `E-post`,
	'login.email.placeholder': `Din e-postadress`,
	'login.login.button': `Logga in`,
	'login.lostPassword.button': `Glömt lösenordet?`,
	'login.notRegistered.button': `Inte registrerad?`,
	'login.password.label': `Lösenord`,
	'login.password.placeholder': `Ditt lösenord`,
	'login.signup.label': `Saknar du konto?`,
	'login.username.label': `Användarnamn (e-postadress) `,
	'login.username.placeholder': `exempel@mail.com`,
	'lostPassword.backToLogin': `Tillbaka till logga in`,
	'mobile.message.text': `Vi jobbar på upplevelsen i mobilen. För att kunna använda tjänsten rekommenderar vi dig att logga in på internetbanken på din dator`,
	'page.reports.content': `Här finner du en sammanställning av de betalningsfiler som har skapats. Dessa filer tillsammans med övriga återrapporteringsalternativ erhålls från PayEx genom SFTP eller nedladdning från PayEx webbgränssnitt.`,
	'signup.action.login': `Logga in`,
	'signup.login.label': `Redan registrerad?`,
	'signup.action.submit': `Skapa ett konto`,
	'signup.agree.terms': `Genom att klicka på Skapa ett konto godkänner du våra`,
	'signup.alreadyRegistered.button': `Redan registrerad?`,
	'signup.changePasswordHint': `Om du vill ändra lösenordet, skriv in ett nytt. Lämna annars fältet tomt.`,
	'signup.code.error.invalid': `Ogiltig inbjudningskod`,
	'signup.company.error.empty': `Ange ditt företagsnamn.`,
	'signup.company.label': `Företag`,
	'signup.company.placeholder': `Fyll i företagets namn`,
	'signup.contact.address.label': `Postadress`,
	'signup.contact.address.placeholder': `Fyll i postadress`,
	'signup.contact.contactFirstName.label': `Kontakt - förnamn`,
	'signup.contact.contactFirstName.placeholder': `Förnamn`,
	'signup.contact.contactLastName.label': `Efternamn`,
	'signup.contact.contactLastName.placeholder': `Fyll i efternamn`,
	'signup.contact.email.label': `E-postadress`,
	'signup.contact.email.placeholder': `example@mail.com`,
	'signup.contact.phone.label': `Telefonnummer`,
	'signup.contact.phone.placeholder': `Fyll i telefonnumret`,
	'signup.contact.url.label': `Url`,
	'signup.contact.url.placeholder': `Fyll i url`,
	'signup.contact.zipCode.label': `Postnummer`,
	'signup.contact.zipCode.placeholder': `Fyll i postnummer`,
	'signup.email.label': `E-post`,
	'signup.email.placeholder': `Fyll i e-postadress`,
	'signup.firstname.label': `Förnamn`,
	'signup.firstname.placeholder': `Fyll i ditt förnamn`,
	'signup.invitationCode.label': `Inbjudningskod`,
	'signup.invitationCode.placeholder': `Fyll i inbjudningskod`,
	'signup.language.label': `Språk`,
	'signup.language.placeholder': `Välj ditt språk språk`,
	'signup.lastname.label': `Efternamn`,
	'signup.lastname.placeholder': `Fyll i ditt efternamn`,
	'signup.modal.code.email.label': `Ange e-post`,
	'signup.modal.code.email.placeholder': `example@mail.com`,
	'signup.modal.code.message.label': `Ange meddelande`,
	'signup.modal.code.message.placeholder': `Hej. Jag skulle vilja få en inbjudningskod för att börja arbeta med Smart Cash Flow. Kan du hjälpa mig?`,
	'signup.modal.code.success.content': `Tack för din förfrågan. Vi återkommer så snart som möjligt.`,
	'signup.modal.code.success.title': `Klar`,
	'signup.modal.code.title': `Begär registreringskod`,
	'signup.name.label': `Förnamn och efternamn`,
	'signup.newPassword.label': `Nytt lösenord`,
	'signup.newPassword.placeholder': `Fyll i nytt lösenord`,
	'signup.oldPassword.label': `Tidigare lösenord`,
	'signup.oldPassword.placeholder': `Fyll i tidigare lösenord`,
	'signup.organisationNumber.label': `Organisationsnummer`,
	'signup.organisationNumber.placeholder': `Fyll i Organisationsnummer`,
	'signup.organizationNumber.label': `Organisationsnummer`,
	'signup.orgnumber.error.empty': `Ange ditt organisationsnummer.`,
	'signup.password.error.empty': `Vänligen ange ditt lösenord.`,
	'signup.password.error.mismatch': `Lösenorden matchar inte`,
	'signup.password.error.missmatch': `Se till att ditt lösenord matchar.`,
	'signup.password.label': `Lösenord`,
	'signup.password.placeholder': `Fyll i lösenordet`,
	'signup.register.button': `Registrera`,
	'signup.rePassword.label': `Reptera nytt lösenord`,
	'signup.rePassword.placeholder': `Fyll i nytt lösenord igen`,
	'signup.terms.button': `Allmänna Villkor`,
	'signup.terms.title': `Användarvillkor för Asteria`,
	'signup.vatNumber.label': `Momsnummer`,
	'signup.vatNumber.placeholder': `Fyll i momsnumret igen`,
	'email.forgot-password.content.action': `Återställ ditt lösenord`,
	'email.forgot-password.footer.description': `Kopiera eller klistra in detta lösenord i din webbläsare:`,

	'notification.cashflow.invoices-customer.overdue.text': 'Ovanligt stora kundfakturor förfallna',
	'notification.currency.usd.title': 'Valutarisk',
	'notification.currency.usd.text': 'Poteniell valutarisk identifierad',
	'notification.credit.title': 'Har du kreditbehov?',
	'notification.credit.body': 'Klicka för att simulera limit från din bank',
	'action.view': 'Visa',
	'notification.credit.action': 'Simulera',
	'transaction.total.withdraw': '{{sums.display.total | number:true}} {{sums.display.currency}}',
	'transaction.total.foreign.withdraw': '{{sums.original.total | number:true}} {{sums.original.currency}}',
	'testing.this': 'New Stuff {{sums.original.currency}}',
	'transaction.total.currency.risk.tooltip': 'Probabal currency risk ± {{oracle.currency.min.display.total | number:false:false:true}}',
	'list.transactions.transaction.description': '',
	'list.transactions.transaction.risk': '±{{lowRisk | number:false:false:true}}',

	'transaction.details.client': 'Client',
	'transaction.details.status': 'Status',
	'transaction.details.transactiondate': 'Transactiondate',
	// 'transaction.details.transactionDate.value':
	// 	'{{#invoice}}{{invoice.dates.sent | date: "yyyy-MM-dd"}}{{/invoice}}{{^invoice}}{{#bank}}{{bank.dates.booked | date: "yyyy-MM-dd"}}{{/bank}}{{/invoice}}',
	'transaction.details.tags': 'All categories',
	'transaction.details.account': 'Bankaccount',

	'transaction.details.currencyloss': 'Valutaförlust',
	'transaction.details.currencygain': 'Valutavinst',

	'welcome.header.title': 'Fakturaköp',
	'transaction.details.currencyrisk.current': 'Current Rate',
	'transaction.details.currencyrisk.current.value': '1 {{item.sums.original.currency}} = {{item.sums.display.rate}} {{item.sums.display.currency}}',
	'transaction.details.currencyrisk.sent': 'Invoice sent date Rate',
	'transaction.details.currencyrisk.sent.value': '1 {{invoice.sums.original.currency}} = {{invoice.sums.display.rate}} {{invoice.sums.display.currency}}',
	'transaction.details.currencyrisk.paid.current': 'Paid rate',
	'transactions.group.title': '{{startDate | date:"MMMM, yyyy" | cap}}, In-/utbetalningar',
	'transactions.group.forecast.title': '{{startDate | date:"MMMM, yyyy") | cap}}, Prognos och obetalda in-/utbetalningar',

	'transactions.group.deposit.title': '{{startDate | date:"MMMM, yyyy" | cap}}, Inbetalningar',
	'transactions.group.deposit.forecast.title': '{{startDate | date:"MMMM, yyyy") | cap}}, Prognos och obetalda inbetalningar',
	'transactions.group.withdraw.title': '{{startDate | date:"MMMM, yyyy" | cap}}, Utbetalningar',
	'transactions.group.withdraw.forecast.title': '{{startDate | date:"MMMM, yyyy") | cap}}, Prognos och obetalda utbetalningar',

	'transactions.group.week.title': 'v. {{startDate | date:"I, yyyy" | cap}}, In-/utbetalningar',
	'transactions.group.week.forecast.title': 'v. {{startDate | date:"I, yyyy") | cap}}, Prognos och obetalda in-/utbetalningar',

	'security.level.1': 'Mycket svårt att prognostisera',
	'security.level.2': 'Svårt att prognostisera',
	'security.level.3': 'Något sannolikt',
	'security.level.4': 'Sannolikt',
	'security.level.5': 'Högst sannolikhet',

	'security.level.unpaid.1': 'Betalar oftast väldigt sent',
	'security.level.unpaid.2': 'Betalar sällan i tid',
	'security.level.unpaid.3': 'Betalar i tid',
	'security.level.unpaid.4': 'Betalar i tid',
	'security.level.unpaid.5': 'Betalar i förtid',

	'tags.invoices.customer': 'Customer Invoice',
	'tags.invoices.supplier': 'Supplier Invoice',

	'accounts.list.group.title': 'Konton i {{currency}}',
	'accounts.list.group.title.loading': 'Hämtar kontoinformation...',

	'action.abort': 'Abort',
	'action.submit': 'Submit',
	'action.save': 'Save',
	'action.send': 'Send',
	'action.back': 'Back',
	'action.next': 'Next',
	'action.move on': 'Move On',
	'action.done': 'Done',
	'action.close': 'Close',
	'action.cancel': 'Cancel',
	'action.continue': 'Continue',
	'action.clear': 'Clear',
	'action.share': 'Share',
	'action.integration.cancel': 'Inte just nu',
	'search.examples.description': 'Förslag på vad du kan söka eller filtrera på ( Sök på "nothing" för att få tomt resultat )',

	'selector.clients': 'Clients',
	'selector.currencies': 'Currencies',
	'selector.categories': 'Categories',
	'selector.services': 'Financial',
	'selector.status': 'Status',
	'selector.price': 'Price',
	'selector.status.paid': 'Paid',
	'selector.status.unpaid': 'Unpaid',
	'selector.status.forecast': 'Forecast',
	'selector.status.overdue': 'Overdue',
	'list.transactions.header.list.price': 'Price',

	'search.examples.status.unpaid': '{{value}} Unpaid',
	'search.examples.status.overdue': '{{value}} Overdue',
	'search.examples.status.forecast': 'Forecast',
	'search.examples.status.paid': 'Paid',

	'search.results.status.unpaid': '{{value}} Unpaid',
	'search.results.status.overdue': '{{value}} Overdue',
	'search.results.status.forecast': 'Forecast',
	'search.results.status.paid': 'Paid',

	'search.status.unpaid': 'Unpaid',
	'search.status.overdue': 'Overdue',
	'search.status.forecast': 'Forecast',
	'search.status.paid': 'Paid',

	'transactions.add.title': 'Förväntade händelser',
	'transactions.add.info': 'Lägg till händelser i din prognos för att se hur de påverkar ditt kassaflöde.',

	'integrations.add.title': 'Add Accounting Software',
	'integrations.bank.add.title': 'Add new bank',
	'integrations.erp.add.title': undefined,

	'integrations.add.content': 'För att aktivera kopplingen behöver du hämta en API-nyckel i ditt bokföringssystem och fylla i den här.',
	'integrations.erp.add.content': undefined,
	'integrations.erp.key.add.content': undefined,
	'integrations.erp.custom.add.content': 'Visma administration är installerad på din dator och en koppling mellan programmet och Asteria, som tillhandahåller tjänsten Företagskollen, krävs för att kunna ansluta ditt företags bokföringsdata till tjänsten. Notera att Swedbank och sparbankerna inte kan kontrollera eller garantera mjukvarans funktion. Vid frågor kontakta Asterias Kundtjänst. Nyckeln fungerar som en identifierare och säkerställer att du är du och ska därför hanteras som en värdehandling. Kopiera nyckeln och läs mer på hur du ska gå till väga under länklen "Hur ansluter jag Visma Administration" nedan.',

	'integrations.integration': '{{ integration.key }}',
	'integrations.erp.integration': undefined,
	'integrations.erp.key.integration': undefined,

	'integrations.add.help': 'Where do i find my API key',
	'integrations.erp.add.help': undefined,
	'integrations.erp.key.add.help': undefined,
	'integrations.connecting': 'Ansluter...',
	'integrations.erp.connecting': undefined,
	'integrations.erp.key.connecting': undefined,
	'integrations.actions': 'Actions needed to finish connecting',
	'integrations.erp.actions': undefined,
	'integrations.erp.key.actions': undefined,

	'integrations.missing': 'My accounting system is missing',
	'integrations.erp.missing': undefined,
	'integrations.select.label': 'I want to fetch information from',
	'integrations.erp.select.label': undefined,

	'integrations.success.title': 'Klar',
	'integrations.success.subtitle': 'Du har anslutit bokföringssystemet',
	'integrations.success.content': 'Under Företagskollen kommer du strax se en prognos av ditt företags utveckling',

	'integrations.missing.form.header': 'Your missing something',
	'integrations.missing.form.content': 'We are sorry that your system does not exist yet. We are constantly working to develop the service - help us by telling us which system you lack',
	'integrations.missing.form.placeholder': 'Choose accounting system',
	'integrations.missing.form.integrations.bokio': 'Bokio',
	'integrations.missing.form.integrations.dooer': 'Dooer',
	'integrations.missing.form.integrations.speedledger': 'Speedledger',
	'integrations.missing.form.integrations.custom': 'My accouning system is missing',
	'integrations.missing.form.integrations.custom.placeholder': 'Enter your accounting software',

	'integrations.action.next': undefined,
	'integrations.action.next.key': undefined,

	'integrations.missing.success.header': 'Your missing something',
	'integrations.missing.success.title': 'Tack!',
	'integrations.missing.success.subtitle': 'Registrerat förslag',
	'integrations.missing.success.integration': '{{{ integration | cap }}}',
	'integrations.missing.success.content': 'Vi jobbar kontinuerligt med att starta samarbete med flera bokföringssystem och förbättra Företagskollen. Välkommen tillbaka om ett tag för att se om ditt bokföringsystem har lagts till.',

	'integrations.list.header': 'Hantera integrationer',
	'integrations.list.title': undefined,
	'integrations.list.add': undefined,
	'integrations.list.add.tooltip': undefined,

	'integrations.list.title.empty': undefined,
	'integrations.list.erp.title.empty': 'No account software information available',
	'integrations.list.bank.title.empty': 'No bank information available',

	'integrations.list.erp.title': 'Manage accounting systems',
	'integrations.list.erp.add': 'Add new accounting system',
	'integrations.list.erp.add.tooltip': 'Add an new connection to one of your accounting systems',
	'integrations.list.bank.title': 'Manage banks',
	'integrations.list.bank.add': 'Add new bank',
	'integrations.list.bank.add.tooltip': 'Add an new connection to one of your banks',

	'form.error.label.required': 'Nödvändig',

	'integrations.list.action.delete': 'Delete',
	'integrations.list.action.delete.key': undefined,
	'integrations.list.action.enable': 'Enable',
	'integrations.list.action.enable.key': undefined,
	'integrations.list.action.disable': 'Disable',
	'integrations.list.action.disable.key': undefined,
	'integrations.list.action.fetch': 'Fetch now',
	'integrations.list.action.fetch.key': undefined,
	'integrations.list.action.edit': 'Edit',
	'integrations.list.action.edit.key': undefined,
	'integrations.list.item': '{{ name | cap }}',
	'integrations.list.item.key': undefined,
	'integrations.list.item.tooltip': '{{ state | cap }}',
	'integrations.list.item.key.tooltip': undefined,
	'integrations.list.item.not.connected.tooltip': 'Not connected',
	'integrations.list.item.key.not.connected.tooltip': undefined,
	'integrations.list.item.disabled.tooltip': 'Disabled',
	'integrations.list.item.key.disabled.tooltip': undefined,

	'integrations.list.item.delete': 'Are you sure?',
	'integrations.list.item.key.delete': undefined,

	'label.username': 'Username',
	'label.password': 'Password',
	'label.API-Key': 'API-Key',
	'label.guide': 'Guide',

	never: 'Repetera ej',
	weekly: 'Varje vecka',
	monthly: 'Varje månad',

	'graph.account.legend.credit': 'Beviljad kredit',
	'graph.account.legend.forecast': 'Prognos',
	'graph.account.legend.history': 'Kontobalans',
	'graph.account.legend.spread': 'Avvikelse',
	'graph.account.stacked.legend.credit': 'Beviljad kredit',
	'graph.account.stacked.legend.forecast': 'Prognos',
	'graph.account.stacked.legend.history': 'Kontobalans',
	'graph.account.stacked.legend.spread': 'Avvikelse',
	'graph.account.title': 'Kontobalans',
	'graph.badges.open.incoming': 'Obetalda inbetalningar',
	'graph.badges.open.outgoing': 'Obetalda utbetalningar',
	'graph.badges.overdue.incoming': 'Förfallna inbetalningar',
	'graph.badges.overdue.outgoing': 'Förfallna utbetalningar',
	'graph.balance.title': 'Kontobalans',
	'graph.bargraph.bar.deposit.badge.overdue.tooltip.label': 'Förfallna kundfakturor',
	'graph.bargraph.bar.deposit.badge.overdue.tooltip.title.subtext': 'Totalt {{badge.count}} stycken förfallna fakturor',
	'graph.bargraph.bar.deposit.badge.overdue.tooltip.total': '{{badge.value | number : false}}',
	'graph.bargraph.bar.deposit.forecast.tooltip.subtext': 'Sannolikt mellan {{bar.data.min | number : false}} och {{bar.data.max | number : false}}',
	'graph.bargraph.bar.deposit.tooltip.label': 'Totalt',
	'graph.bargraph.bar.deposit.tooltip.title': 'Inbetalningar',
	'graph.bargraph.bar.deposit.tooltip.total': '{{total | number : false}}',
	'graph.bargraph.bar.part.deposit.deposit.forecast.tooltip.label': 'Prognos inbetalningar',
	'graph.bargraph.bar.part.deposit.deposit.tooltip.label': 'Inbetalningar',
	'graph.bargraph.bar.part.deposit.deposit.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.deposit.deposit.unpaid.tooltip.label': 'Obetalda kundfakturor',
	'graph.bargraph.bar.part.deposit.forecast.tooltip.subtext': '',
	'graph.bargraph.bar.part.empty.tooltip.label': 'Transaktioner för kategorier saknas',
	'graph.bargraph.bar.part.invoices.customer.forecast.tooltip.label': 'Prognos kundfakturor',
	'graph.bargraph.bar.part.invoices.customer.forecast.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.invoices.customer.tooltip.label': 'Kundfakturor',
	'graph.bargraph.bar.part.invoices.customer.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.invoices.customer.unpaid.tooltip.label': 'Obetalda kundfakturor',
	'graph.bargraph.bar.part.invoices.supplier.forecast.tooltip.label': 'Prognos leverantörsfakturor',
	'graph.bargraph.bar.part.invoices.supplier.forecast.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.invoices.supplier.tooltip.label': 'Leverantörsfakturor',
	'graph.bargraph.bar.part.invoices.supplier.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.invoices.supplier.unpaid.tooltip.label': 'Obetalda leverantörsfakturor',
	'graph.bargraph.bar.part.salaries.salary.forecast.tooltip.label': 'Prognos löner',
	'graph.bargraph.bar.part.salaries.salary.forecast.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.salaries.salary.tooltip.label': 'Löner',
	'graph.bargraph.bar.part.salaries.salary.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.taxes.tax.forecast.tooltip.label': 'Prognos skatter',
	'graph.bargraph.bar.part.taxes.tax.forecast.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.taxes.tax.tooltip.label': 'Skatter',
	'graph.bargraph.bar.part.taxes.tax.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.transaction.card.forecast.tooltip.label': 'Prognos kortbetalningar',
	'graph.bargraph.bar.part.transaction.card.forecast.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.transaction.card.tooltip.label': 'Kortbetalningar',
	'graph.bargraph.bar.part.transaction.card.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.transaction.cash.forecast.tooltip.label': 'Prognos kontantbetalningar',
	'graph.bargraph.bar.part.transaction.cash.forecast.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.transaction.cash.tooltip.label': 'Kontantbetalningar',
	'graph.bargraph.bar.part.transaction.cash.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.transaction.transfer.forecast.tooltip.label': 'Prognos Kontoöverföringar',
	'graph.bargraph.bar.part.transaction.transfer.forecast.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.transaction.transfer.tooltip.label': 'Kontoöverföringar',
	'graph.bargraph.bar.part.transaction.transfer.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.withdraw.forecast.tooltip.subtext': '',
	'graph.bargraph.bar.part.withdraw.withdraw.forecast.tooltip.label': 'Prognos utbetalningar',
	'graph.bargraph.bar.part.withdraw.withdraw.tooltip.label': 'Utbetalningar',
	'graph.bargraph.bar.part.withdraw.withdraw.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.part.withdraw.withdraw.unpaid.tooltip.label': 'Obetalda leverantörsfakturor',
	'graph.bargraph.bar.transaction.bankgiro.tooltip.label': 'Bankgirobetalning',
	'graph.bargraph.bar.transaction.bankgiro.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.transaction.plusgiro.tooltip.label': 'Plusgirobetalning',
	'graph.bargraph.bar.transaction.plusgiro.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.transaction.swish.tooltip.label': 'Swishbetalning',
	'graph.bargraph.bar.transaction.swish.tooltip.total': '{{part.value | number : false}}',
	'graph.bargraph.bar.withdraw.badge.overdue.tooltip.label': 'Förfallna leverantörsfakturor',
	'graph.bargraph.bar.withdraw.badge.overdue.tooltip.title.subtext': 'Totalt {{badge.count}} stycken förfallna fakturor',
	'graph.bargraph.bar.withdraw.badge.overdue.tooltip.total': '{{badge.value | number : false}}',
	'graph.bargraph.bar.withdraw.forecast.tooltip.subtext': 'Sannolikt mellan {{bar.data.min | number : false}} och {{bar.data.max | number : false}}',
	'graph.bargraph.bar.withdraw.tooltip.label': 'Totalt',
	'graph.bargraph.bar.withdraw.tooltip.title': 'Utbetalningar',
	'graph.bargraph.bar.withdraw.tooltip.total': '{{total | number : false}}',
	'graph.cashflow.title': 'In-/utbetalningar',
	'graph.category.invoices': 'Fakturor',
	'graph.category.products': 'Produkter',
	'graph.category.salary': 'Löner',
	'graph.category.tax': 'Skatt',
	'graph.category.transactions': 'Transaktionstyper',
	'graph.controllers.menu.category.default.deposit': 'Inbetalningar',
	'graph.controllers.menu.category.default.forecast': 'Prognos',
	'graph.controllers.menu.category.default.overdue': 'Förfallna fakturor',
	'graph.controllers.menu.category.default.withdraw': 'Utbetalningar',

	'graph.controllers.menu.category.overdue': 'Förfallna fakturor',
	'graph.controllers.menu.category.unpaid': 'Obetalda fakturor',

	'graph.controllers.menu.category.invoices': 'Fakturor',
	'graph.controllers.menu.category.invoices.customer': 'Kundfakturor',
	'graph.controllers.menu.category.invoices.overdue': 'Förfallna fakturor',
	'graph.controllers.menu.category.invoices.supplier': 'Leverantörsfakturor',
	'graph.controllers.menu.category.invoices.unpaid': 'Obetalda fakturor',
	'graph.controllers.menu.category.salaries': 'Löner',
	'graph.controllers.menu.category.salaries.salary': 'Lön',
	'graph.controllers.menu.category.taxes': 'Skatter',
	'graph.controllers.menu.category.taxes.tax': 'Skatt',
	'graph.controllers.menu.category.transaction': 'Transaktionstyper',
	'graph.controllers.menu.category.transaction.bankgiro': 'Bankgirobetalningar',
	'graph.controllers.menu.category.transaction.card': 'Kortbetalningar',
	'graph.controllers.menu.category.transaction.cash': 'Kontantbetalningar',
	'graph.controllers.menu.category.transaction.plusgiro': 'Plusgirobetalning',
	'graph.controllers.menu.category.transaction.swish': 'Swishbetalning',
	'graph.controllers.menu.category.transaction.transfer': 'Överföringar',
	'graph.dot.value': '{{value}}',
	'graph.filter.balanceForecast': 'Prognos',
	'graph.filter.balanceHistory': 'Historisk',
	'graph.filter.bankgiro': 'Bankgirobetalning',
	'graph.filter.card': 'Kortbetalningar',
	'graph.filter.cash': 'Kontantbetalningar',
	'graph.filter.forecast': 'Prognos',
	'graph.filter.incoming': 'Inbetalningar',
	'graph.filter.invoiceClient': 'Kundfakturor',
	'graph.filter.invoiceSupplier': 'Leverantörsfakturor',
	'graph.filter.outgoing': 'Utbetalningar',
	'graph.filter.overdue': 'Förfallna',
	'graph.filter.plusgiro': 'Plusgirobetalning',
	'graph.filter.rims': 'Bildäck',
	'graph.filter.salary': 'Löner',
	'graph.filter.swish': 'Swishbetalning',
	'graph.filter.tax': 'Skatt',
	'graph.filter.taxSocial': 'Sociala Avgifter',
	'graph.filter.tire': 'Fälgar',
	'graph.filter.transfer': 'Överföringar',
	'graph.filter.unpaid': 'Obetalda fakturor',
	'graph.filter.vat': 'Moms',
	'graph.linegraph.dot.credit.tooltip.label': 'Beviljad kredit',
	'graph.linegraph.dot.credit.tooltip.total': '{{line.value | number : false}}',
	'graph.linegraph.dot.forecast.credit.tooltip.label': 'Beviljad kredit',
	'graph.linegraph.dot.today.credit.tooltip.label': 'Beviljad kredit',
	'graph.linegraph.dot.today.tooltip.label': 'Aktuell kontobalans',
	'graph.linegraph.dot.tooltip.forecast.label': 'Prognos kontobalans',
	'graph.linegraph.dot.tooltip.forecast.subtext': 'Sannolikt mellan {{line.min | number : false}} och {{line.max | number : false}}',
	'graph.linegraph.dot.tooltip.label': 'Kontobalans',
	'graph.linegraph.dot.tooltip.title': 'Kontobalans',
	'graph.linegraph.dot.tooltip.title.single.subtext': 'Inkluderar konto {{account.identifiers.number}}',
	'graph.linegraph.dot.tooltip.title.subtext': 'Inkluderar {{activeAccounts.length}} konton från internetbanken',
	'graph.linegraph.dot.tooltip.today.label': 'Aktuell kontobalans',
	'graph.linegraph.dot.tooltip.total': '{{line.value | number : false}}',
	'graph.menu.status.all': 'Alla',
	'graph.menu.status.forecast': 'Prognos',
	'graph.menu.status.overdue': 'Förfallna',
	'graph.menu.status.paid': 'Betalda',
	'graph.navigation.next': 'Visa mer framåt i tiden',
	'graph.navigation.previous': 'Visa mer bakåt i tiden',
	'graph.options.manageAccounts': 'Hantera konton',
	'graph.pie.tags.bankgiro': 'Bankgirobetalning',
	'graph.pie.tags.bankgiro.forecast': 'Prognos',
	'graph.pie.tags.bankgiro.unpaid': 'Kommande',
	'graph.pie.tags.card': 'Kortbetalningar',
	'graph.pie.tags.card.forecast': 'Prognos',
	'graph.pie.tags.cash': 'Kontantbetalningar',
	'graph.pie.tags.cash.forecast': 'Prognos',
	'graph.pie.tags.customer': 'Kundfakturor',
	'graph.pie.tags.customer.forecast': 'Prognos',
	'graph.pie.tags.customer.unpaid': 'Obetalda',
	'graph.pie.tags.misc': 'Övrigt',
	'graph.pie.tags.misc.forecast': 'Prognos',
	'graph.pie.tags.plusgiro': 'Plusgirobetalning',
	'graph.pie.tags.plusgiro.forecast': 'Prognos',
	'graph.pie.tags.plusgiro.unpaid': 'Kommande',
	'graph.pie.tags.salary': 'Löner',
	'graph.pie.tags.salary.forecast': 'Prognos',
	'graph.pie.tags.supplier': 'Leverantörsfakturor',
	'graph.pie.tags.supplier.forecast': 'Prognos',
	'graph.pie.tags.supplier.unpaid': 'Obetalda',
	'graph.pie.tags.swish': 'Swishbetalning',
	'graph.pie.tags.swish.forecast': 'Prognos',
	'graph.pie.tags.swish.unpaid': 'Kommande',
	'graph.pie.tags.tax': 'Skatter',
	'graph.pie.tags.tax.forecast': 'Prognos',
	'graph.pie.tags.transfer': 'Överföringar',
	'graph.pie.tags.transfer.forecast': 'Prognos',
	'graph.pie.tags.transfer.unpaid': 'Kommande',
	'graph.show_me_graph': 'Visa mig grafen',
	'graph.stacked.controllers.menu.category.default.overdue': 'Förfallna',
	'graph.stacked.controllers.menu.category.default.unpaid': 'Obetalda',
	'graph.timespan.months': 'Månader',
	'graph.timespan.weeks': 'Veckor',
	'graph.timespan.years': 'År',
	'graph.title': 'In-/utbetalningar',
	'graph.tooltip.account.risk': 'risk mellan {{from}} och {{to}}',
	'graph.tooltip.account.title': 'Kontobalans',
	'graph.tooltip.account.title.actual': 'Aktuell kontobalans',
	'graph.tooltip.account.title.current': 'Aktuell kontobalans',
	'graph.tooltip.account.title.expected': 'Prognos kontobalans',
	'graph.tooltip.account.tooltip.expected': 'Prognos kontobalans {{balance}}',
	'graph.tooltip.account.volatility': 'Sannolikt mellan {{top}} och {{bottom}}',
	'graph.tooltip.filter.empty': 'Transaktioner för kategorier saknas',
	'graph.tooltip.incoming.current.expected': 'Prognos inbetalningar totalt',
	'graph.tooltip.incoming.current.paid': 'Inbetalningar hittills',
	'graph.tooltip.incoming.current.unpaid': 'Obetalda kundfakturor',
	'graph.tooltip.incoming.expected': 'Prognos inbetalningar',
	'graph.tooltip.incoming.paid': 'Inbetalningar',
	'graph.tooltip.incoming.volatility': 'Sannolikt mellan {{top}} och {{bottom}}',
	'graph.tooltip.outgoing.current.expected': 'Prognos utbetalningar totalt',
	'graph.tooltip.outgoing.current.paid': 'Utbetalningar hittills',
	'graph.tooltip.outgoing.current.unpaid': 'Obetalda leverantörsfakturor',
	'graph.tooltip.outgoing.expected': 'Prognos utbetalningar',
	'graph.tooltip.outgoing.paid': 'Utbetalningar',
	'graph.tooltip.outgoing.volatility': 'Sannolikt mellan  {{top}} och {{bottom}}',
	'graph.tooltip.unpaid.incoming.title': 'Obetalda kundfakturor',
	'graph.tooltip.unpaid.outgoing.title': 'Obetalda leverantörsfakturor',
	'graph.tooltip.unpaid.title': 'Obetalda fakturor',
	'graph.xaxis.button': 'Visa transaktioner för denna period',
	'graph.xaxis.future.label': '{{ date | date : "MMMM" | capitalize }}',
	'graph.xaxis.future.month.prefix': '{{ date | date : "MMMM"| capitalize }}',
	'graph.xaxis.future.year.prefix': '{{ date | date : "yyyy"| capitalize }}',
	'graph.xaxis.history.month.label': '{{ date | date : "MMMM"| capitalize }}',
	'graph.xaxis.history.month.prefix': '{{ date | date : "MMMM"| capitalize }}',
	'graph.xaxis.history.week.label': '{{ date | date : "[v.]W" }}',
	'graph.xaxis.history.year.label': '{{ date | date : "yyyy" }}',
	'graph.xaxis.history.year.prefix': '{{ date | date : "yyyy" | capitalize }}',
	'graph.xaxis.month': 'MMM',
	'graph.xaxis.quarter': 'Q',
	'graph.xaxis.today': 'Idag',
	'graph.xaxis.today.label': '{{ date | date : "MMMM"| capitalize }}',
	'graph.xaxis.today.prefix': 'Idag',
	'graph.xaxis.today.month.prefix': '{{ date | date : "MMMM"| capitalize }}',
	'graph.xaxis.today.year.prefix': '{{ date | date : "yyyy" | capitalize }}',
	'graph.xaxis.week': '[v.]W',
	'graph.xaxis.year': 'yyyy',

	'cashflow.tooltip.value': '{{value.display.total | number}}',

	'cashflow.tooltip.deposit.title': 'Inbetalningar',

	'cashflow.tooltip.deposit.paid.title': 'Inbetalningar',
	'cashflow.tooltip.deposit.unpaid.title': '',
	'cashflow.tooltip.deposit.forecast.title': '',
	'cashflow.tooltip.deposit.overdue.title': '',
	'cashflow.tooltip.deposit.signed.title': '',

	'cashflow.tooltip.deposit.label': 'Inbetalningar',
	'cashflow.tooltip.deposit.forecast.label': 'Prognos inbetalningar',
	'cashflow.tooltip.deposit.unpaid.label': 'Obetalda fakturor',
	'cashflow.tooltip.deposit.signed.label': 'Signerade',
	'cashflow.tooltip.deposit.overdue.label': 'Förfallna fakturor',

	'cashflow.tooltip.withdraw.title': 'Utbetalningar',
	'cashflow.tooltip.withdraw.unpaid.title': '',
	'cashflow.tooltip.withdraw.forecast.title': '',
	'cashflow.tooltip.withdraw.overdue.title': '',
	'cashflow.tooltip.withdraw.signed.title': '',
	'cashflow.tooltip.withdraw.label': 'Utbetalningar',
	'cashflow.tooltip.withdraw.forecast.label': 'Prognos utbetalningar',
	'cashflow.tooltip.withdraw.unpaid.label': 'Obetalda fakturor',
	'cashflow.tooltip.withdraw.signed.label': 'Signerade',
	'cashflow.tooltip.withdraw.overdue.label': 'Förfallna fakturor',

	'cashflow.tooltip.invoices-customer.label': 'Kundfakturor',
	'cashflow.tooltip.invoices-customer.forecast.label': 'Prognos kundfakturor',
	'cashflow.tooltip.invoices-customer.unpaid.label': 'Obetalda kundfakturor',
	'cashflow.tooltip.invoices-customer.overdue.label': 'Förfallna kundfakturor',

	'cashflow.tooltip.invoices-supplier.label': 'Leverantörsfakturor',
	'cashflow.tooltip.invoices-supplier.forecast.label': 'Prognos leverantörsfakturor',
	'cashflow.tooltip.invoices-supplier.unpaid.label': 'Obetalda leverantörsfakturor',
	'cashflow.tooltip.invoices-supplier.overdue.label': 'Förfallna leverantörsfakturor',

	'cashflow.tooltip.taxes-tax.label': 'Skatter',
	'cashflow.tooltip.taxes-tax.forecast.label': 'Prognos skatter',

	'cashflow.tooltip.salaries-salary.label': 'Löner',
	'cashflow.tooltip.salaries-salary.forecast.label': 'Prognos löner',

	'cashflow.tooltip.transactions-transfer.label': 'Överföringar',

	'cashflow.tooltip.transactions-payment.label': 'Bg/PG-betalningar',

	'cashflow.tooltip.total.label': 'Totalt',
	'cashflow.tooltip.total.span.label': 'Sannolikt mellan {{value.min.display.total | number : false}} och {{value.max.display.total | number : false}}',

	'cashflow.tooltip.currency.risk.title': '',
	'cashflow.tooltip.currency.risk.account.label': 'Valutarisk i {{value.original.currency}}',

	'cashflow.tooltip.currencies.risk.label': 'Valutarisk i {{value.count}} valutor',
	'cashflow.tooltip.currencies.risk.value': '±{{value.display.total | number : false : false : true }}',
	'cashflow.tooltip.currencies.risk.account.label': '',
	'cashflow.tooltip.currencies.risk.account.value': '{{value.fraction | mul: 100 | number : false : false : true }}%',

	'cashflow.tooltip.currencies.risk.rest': '+{{rest}} till valutor',

	'cashflow.tooltip.currency.risk.deposit.forecast.label': 'Prognos valutarisk i {{value.original.currency}}',
	'cashflow.tooltip.currency.risk.deposit.unpaid.label': 'Obetald valutarisk i {{value.original.currency}}',

	'cashflow.tooltip.currency.risk.withdraw.forecast.label': 'Prognos valutarisk i {{value.original.currency}}',
	'cashflow.tooltip.currency.risk.withdraw.unpaid.label': 'Obetald valutarisk i {{value.original.currency}}',

	'cashflow.tooltip.currency.risk.account.value': '±{{value.display.total | number : false : false : true }}',
	'cashflow.tooltip.currencies.value': '{{value.fraction | mul: 100 | number : false : false : true }}%',

	'cashflow.tooltip.account.title': 'Kontobalans',
	'cashflow.tooltip.account.label': 'Inkluderar {{bankAccounts.length}} konton från internetbanken',
	'cashflow.tooltip.account.paid.label': 'Kontobalans',
	'cashflow.tooltip.account.paid.current.label': 'Aktuell kontobalans',
	'cashflow.tooltip.account.forecast.title': '',
	'cashflow.tooltip.account.forecast.label': 'Prognos kontonbalans',
	'cashflow.tooltip.account.forecast.span.label': 'Sannolikt mellan {{value.min.display.total | number : false}} och {{value.max.display.total | number : false}}',

	'cashflow.tooltip.account.credit.label': 'Beviljad kredit',
	'cashflow.tooltip.currency.risk': 'Valutarisk',
	'cashflow.overview.accountbalance.current.tooltip': 'Sannolikt mellan {{statistics.account.expected.min | number : false}} och {{statistics.account.expected.max | number : false}}',

	'header.title': 'Företagskollen',
	'header.account.title': 'Aktuell kontobalans',
	'header.company.currency.risk': 'Ditt företag har en valutarisk. Klicka för att se hur du effektivt kan hantera din valutarisk',

	'faq.title': 'Hjälp & Support',
	'faq.content': 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Incidunt fugit ea optio voluptatibus, quisquam excepturi repudiandae cupiditate delectus error veniam, esse unde quas consectetur. Porro eveniet ea dicta voluptatum recusandae.',
	'faq.content.title': 'Vanliga frågor och svar',
	'faq.content.button.show.more': 'Show more',
	'faq.content.button.report': 'Skapa ärende eller rapportera fel',

	'support.form.title': 'Support',
	'support.form.placeholder.subject': 'Ange ämne',
	'support.form.placeholder.email': 'Skriv din e-postadress',
	'support.form.placeholder.description': 'Skriv ditt meddelande här',
	'support.form.label.agree': 'Jag godkänner att Asteria kontaktar mig för support av Kassaflöde pä ovan nämnda e-postaddress',

	'support.success.title': 'Support',
	'support.success.content': 'Ditt support ärende nummer är {{ticketNumber}}',
	'notitification.hide': 'Dölj händelse',
	'notifications.title': 'Händelser',
	'notifications.action.currency.risk': 'Se min risk',
	'notifications.currency.risk.content': 'Your risk is {{ sums.system.total }} {{ sums.system.currency }}',

	'services.credit.title': 'Finansieringsbehov',
	'services.credit.input.label': 'Möjligt finansieringsbehov',
	'services.credit.help.link': 'Hur fungerar Finansiering?',
	'services.credit.sent.title': 'Tack',
	'services.credit.sent.pretext': 'Finanseringsbehov',
	'services.credit.sent.total': '{{credit | number}}',
	'services.credit.sent.description': 'Du kommer få fylla i detta belopp manuellt igen på nästa sida.',

	'client.overview.list.nothing': 'No information about clients',

	'client.overview.title.details': '{{{ client.name }}}',
	'client.overview.title.details.TYPE': undefined,
	'client.overview.title.list': 'Client list',

	'client.overview.details.alert.title': 'Lorem, ipsum.',
	'client.overview.details.TYPE.alert.title': undefined,
	'client.overview.details.alert.content': 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita, magnam.',
	'client.overview.details.TYPE.alert.content': undefined,
	'client.overview.details.alert.link': 'Lorem, ipsum dolor.',
	'client.overview.details.TYPE.alert.link': undefined,

	'client.overview.details.header': 'Lorem, ipsum dolor.',
	'client.overview.details.TYPE.header': undefined,
	'client.overview.details.header.filter': 'Filtrera på kund',
	'client.overview.details.TYPE.header.filter': undefined,

	'client.overview.details.info.address': 'Address',
	'client.overview.details.TYPE.info.address': undefined,
	'client.overview.details.info.city': 'City',
	'client.overview.details.TYPE.info.city': undefined,
	'client.overview.details.info.client.number': 'Client number',
	'client.overview.details.TYPE.info.client.number': undefined,
	'client.overview.details.info.organization.number': 'Org.nr',
	'client.overview.details.TYPE.info.organization.number': undefined,
	'client.overview.details.info.status': 'Status',
	'client.overview.details.TYPE.info.status': undefined,

	'client.overview.details.info.status.value': undefined,
	'client.overview.details.info.status.value.active': 'Client is active',
	'client.overview.details.info.status.value.inactive': 'Client is not active',
	'client.overview.details.TYPE.info.status.value': undefined,
	'client.overview.details.TYPE.info.status.value.active': undefined,
	'client.overview.details.TYPE.info.status.value.inactive': undefined,

	'client.overview.details.phone': 'Phone',
	'client.overview.details.TYPE.phone': undefined,

	'client.overview.details.statistics.forecast': 'Forecast',
	'client.overview.details.TYPE.statistics.forecast': undefined,
	'client.overview.details.statistics.overdue': 'Overdue',
	'client.overview.details.TYPE.statistics.overdue': undefined,
	'client.overview.details.statistics.paid': 'Paid',
	'client.overview.details.TYPE.statistics.paid': undefined,
	'client.overview.details.statistics.total': 'Total',
	'client.overview.details.TYPE.statistics.total': undefined,
	'client.overview.details.statistics.unpaid': 'Unpaid',
	'client.overview.details.TYPE.statistics.unpaid': undefined,

	'selector.services.credit': 'Finanseringsbehov',

	'transactions.group.edit.total.tooltip': 'Adjustable tooltip',
	'list.transactions.transaction.status.unpaid': 'Unpaid',
	'list.transactions.transaction.status.overdue': 'Overdue',
	'list.transactions.transaction.status.signed': 'Signed',

	'list.transactions.transaction.status.unpaid.tooltip': 'Unpaid',
	'list.transactions.transaction.status.overdue.tooltip': 'Overdue',
	'list.transactions.transaction.status.overdue.signed': 'Signed',

	'currencies.account.missing.helptext': 'Du har inget valutakonto',
	'currencies.account.missing.helpurl': 'https://www.google.com',
	'list.transactions.transaction.currency.helptext': 'Behöver du hjälp med valuta ?',
	'list.transactions.transaction.currency.helplink': 'https://www.google.com',

	'list.transactions.header.list.tags': 'Kategorier',

	'transactions.list.empty': 'Inga transaktioner',

	'feedback.text': 'Hur upplever du lägg till ditt bokföringssystem?',
	'feedback.rating.min': 'Svårt',
	'feedback.rating.max': 'Lätt',
	'feedback.form.header': 'Lämna feedback',
	'feedback.form.description': 'Eftersom dina synpunkter är anonyma kan vi inte svara dig. Om du vill ha hjälp eller ställa en fråga är du välkommen att',
	'feedback.form.contact': 'kontakta oss',
	'feedback.form.rate.label': 'Var det svårt eller lätt att granska prognosen?',
	'feedback.form.comment.label': 'Hur kan vi göra det här bättre? (Frivilligt)',
	'feedback.form.comment.placeholder': 'Uppge into personliga uppgifter här.',
	'feedback.form.submit.label': 'Skicka',
	'feedback.success.header': 'Lämna feedback',
	'feedback.success.title': 'Vi har tagit emot din feedback.',
	'feedback.success.description': 'Den hjälper oss att bli bättre.',
	'feedback.success.close': 'Stäng',
	'probability.level.0': 'Osannolikt',
	'probability.level.1': 'Mycket svårt att prognostisera',
	'probability.level.2': 'Svårt att prognostisera',
	'probability.level.3': 'Något sannolik',
	'probability.level.4': 'Sannolik',
	'probability.level.5': 'Hög sannolikhet',
	'welcome.card.title': 'Invoices are a good fertilizer when the company is to grow.',
	'welcome.card.btnLabel': 'Sell Invoices',
	'welcome.card.description': 'We buy your invoices and take over the entire credit risk.',
	'welcome.card.descriptionDetail': 'You do not have to wait for payment and can let the money work where it does the best good.',
	'welcome.card.subDescription': `Your company's liquidity will improve and you can improve your cash flow.`,

	'splash.returning.user.title': 'Välkommen tillbaka',
	'splash.returning.user.title2': 'Dina transaktioner läses in',
	'splash.returning.user.text.intro': 'Det var ett tag sedan du loggade in sist så vi behöver läsa in dina transaktioner och kategorier igen.',
	'splash.returning.user.text': 'Du kan se nere till höger på skärmen när inläsningen förväntas bli klar.',
	'splash.returning.user.button': 'Fortsätt',

	'welcomeForm.alert.title': 'Klart! Din bokföringsdata lases nu in',
	'welcomeForm.form.heading': 'Företagsinformation',
	'welcomeForm.form.heading.2': 'Företrädare för bolaget, te.x. VD eller annan med Fullmakt',
	'welcomeForm.form.field.label.1': 'Foretagsnamn',
	'welcomeForm.form.field.label.2': 'Bransch / Bolagets Huvudsakliga verksamhet',
	'welcomeForm.form.field.label.3': 'Postadress',
	'welcomeForm.form.field.label.4': 'Postnr',
	'welcomeForm.form.field.label.5': 'Bransch / Bolagets Huvudsakliga verksamhet',
	'welcomeForm.form.field.label.6': 'Land',
	'welcomeForm.form.field.2.label.1': 'Förnamn',
	'welcomeForm.form.field.2.label.2': 'Efternamn',
	'welcomeForm.form.field.2.label.3': 'Personnummer',
	'welcomeForm.form.field.2.label.4': 'Bransch / Bolagets Huvudsakliga verksamhet',
	'welcomeForm.form.field.2.label.5': 'E.post',

	'welcomeForm.form.field.placeholder.1': 'Gravmaskinistena AB',
	'welcomeForm.form.field.placeholder.2': 'Gravmaskinistena AB',
	'welcomeForm.form.field.placeholder.3': 'Vasagatan 1',
	'welcomeForm.form.field.placeholder.4': '95331',
	'welcomeForm.form.field.placeholder.5': 'Haparanda',
	'welcomeForm.form.field.placeholder.6': '43-120 Mark och grundarbeten',
	'welcomeForm.form.field.placeholder.7': 'Sverige',
	'welcomeForm.form.field.2.placeholder.1': 'Sven',
	'welcomeForm.form.field.2.placeholder.2': 'Karlsson',
	'welcomeForm.form.field.2.placeholder.3': '123456-7890',
	'welcomeForm.form.field.2.placeholder.4': '+4670123456789',
	'welcomeForm.form.field.2.placeholder.5': 'sven@gravmaskinisten.se',

	'welcomeForm.step.7.alert.title': 'Klart!',
	'welcomeForm.step.7.alert.maintext': 'Du har svarat på alla frågor.',
	'welcomeForm.step.7.alert.subtext': 'PayEx går igenom dina svar och din bokföringsdata för att hjälpa dig hitta rätt finansiering.',

	'notification.alert.title.success': 'Klart! PayEx har godkänt din begäran om Fakturaköp',
	'notification.alert.title.error': 'PayEx kunde tyvärr inte godkänna din förfrågan om Fakturaköp. Läs mer nedan.',
	'notification.alert.title.info': 'PayEx granskar din ansökan. Du kommer att få en bekräftelse med nästa steg här.',

	'form.rejected.QA.section.heading': 'Vanliga frågor och svar',
	'form.rejected.question.1': 'Hur kan jag få hjälp med mina problem med mitt bokföringssystem?',
	'form.rejected.answer.1': 'aLorem ipsum dolor sit amet, quo no phaedrum nominati rationibus, vim tota dicit ea. Sea vidisse hendrerit contentiones eu, usu te vide constituam, tamquam ponderum at eos. df',
	'form.rejected.question.2': 'Vilka webbläsare fungerar Företagskollen i?',
	'form.rejected.question.3': 'Hur kan jag rapportera fel med min data?',

	'aside.title': 'Finansiella tjänster',
	'aside.description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id feugiat venenatis cursus sociis sit cras non dignissim semper. Quam malesuada cras ut feugiat viverra aliquet amet.',
	'aside.subDescription': 'Id cras consectetur auctor tortor, quis. Morbi sodales tempus, condimentum tellus.',
	'aside.button.label': 'Hitta till finansiella tjänster.',

	'account.list.heading': 'Genom att koppla ditt bokföringssystem får du följande fördelar',
	'account.list.text.1': 'En mer träffsäker prognos på ditt kassaflöde.',
	'account.list.text.2': 'Bättre översikt med fler kategorier t.ex skatt, lön och kortbetalningar.',
	'account.list.text.3': 'Se kommande kundfakturor och leverantörsbetalningar.',
	// Factoring/Cart
	'side.drawer.header.title': 'Invoice purchases',
	'side.drawer.content.title': 'To send for invoice purchase',
	'side.drawer.content.detail.invoice.heading.name': 'Invoice',
	'side.drawer.content.detail.customer.heading.name': 'Customer',
	'side.drawer.content.detail.payment.heading.name': 'Payment Date',
	'side.drawer.content.detail.category.heading.name': 'Category',
	'side.drawer.content.detail.status.heading.name': 'Status',
	'side.drawer.content.detail.cost.heading.name': 'Price',
	'side.drawer.content.detail.amount.heading.name': 'Amount',
	'side.drawer.content.detail.amount.total.heading': 'In Total',

	//Credits Vendor Path Component Translations
	'credits.mobile.landing.title': 'Se din Kreditlimit',
	'credits.mobile.landing.description': 'Do you want to be able to quickly see how much limit you can get for your credit? Start your application by connecting your accounting system. No obligation.',
	'credits.mobile.landing.login.title': 'Previous application',
	'credits.mobile.landing.delete.date.button.title': 'Delete my data now',
	'credits.mobile.landing.delete.data.header.title': 'Delete data',
	'credits.mobile.landing.delete.data.content.title': 'Are you things that you want to delete your data.',
	'credits.mobile.landing.delete.date.description': 'Your data is shared with the bank and Asteria. Your data is automatically deleted after 30 days.',
	'credits.mobile.landing.bookkeeping.downloading.title': 'Add accounting system',
	'credits.mobile.landing.bookkeeping.downloading.loader.title': 'Connects to Fortnox',
	'credits.mobile.landing.bookkeeping.downloading.alert.title': 'Loading complete',
	'credits.mobile.landing.bookkeeping.delete.complete.title': 'Data Removed',
	'credits.mobile.landing.statement.error.alert.title': 'Your financial statements for the previous year are not closed in yours accounting system, at your application the bank will be informed if it is still open.',
	'button.abort': 'Cancel',
	'button.continue': 'Next',
	'button.cancel': 'Cancel',
	'button.save': 'Save',
	'button.remove': 'Remove',

	'yaxis.label.SEK': 'MKr',
	'yaxis.label.SEK.0': 'Kr',
	'yaxis.label.SEK.1': 'TKr',
	'yaxis.label.SEK.2': 'MKr',

	'forecaster.progress.full': 'Klar',

	'snackbar.forecaster.type.complete.title': 'Klart!',
	'snackbar.forecaster.type.deposit.complete.content': 'Du är klar med Inbetalningar ({{ data.data.total | number }})',
	'snackbar.forecaster.type.withdraw.complete.content': 'Du är klar med Utbetalningar ({{ data.data.total | number }})',
	'snackbar.forecaster.type.complete.action.label': 'Ångra',

	'snackbar.forecaster.tag.complete.title': 'Klart!',
	'snackbar.forecaster.tag.complete.content': 'Du är klar med {{ data.label }} ({{ data.data.total | number }})',
	'snackbar.forecaster.tag.complete.action.label': 'Ångra',

	'snackbar.forecaster.tag.moved.existing.title': 'Data successfully moved to existing category',
	'snackbar.forecaster.tag.moved.existing.content': 'Data successfully moved from {{{ data.source.label }}} to {{{ data.destination.label }}} ({{ data.total | number }})',
	'snackbar.forecaster.withdraw.tag.moved.existing.content': 'Data successfully moved from {{{ data.source.label }}} to {{{ data.destination.label }}} ({{ data.total | number:true }})',
	'snackbar.forecaster.tag.moved.new.title': 'Data successfully moved to new category',
	'snackbar.forecaster.tag.moved.new.content': 'Data successfully moved from {{{ data.source.label }}} to {{{ data.destination.label }}} ({{ data.total | number }})',
	'snackbar.forecaster.withdraw.tag.moved.new.content': 'Data successfully moved from {{{ data.source.label }}} to {{{ data.destination.label }}} ({{ data.total | number:true }})',
	'snackbar.forecaster.tag.removed.title': 'Data removed',
	'snackbar.forecaster.tag.removed.content': 'Data successfully removed from {{{ data.label }}}',

	'forecaster.account.balance.overview.label': 'Kontobalans',
	'forecaster.account.balance.deposit.label': 'Incomming',
	'forecaster.account.balance.withdraw.label': 'Outgoing',
	'forecaster.account.balance.status.OVERDUE.label': 'OVERDUE: {{{ label }}}',
	'forecaster.account.balance.status.PAID.label': 'PAID: {{{ label }}}',
	'forecaster.account.balance.status.UNPAID.label': 'UNPAID: {{{ label }}}',
	'forecaster.account.balance.status.FORECAST.label': 'FORECAST: {{{ label }}}',
	'forecaster.account.balance.withdraw.total': '{{ total | number : true }}',

	'forecaster.header.account': 'Forecasted account balance in {{ endDate | date: "MMMM, yyyy" | cap }}',
	'forecaster.header.total': '{{ total | number }}',
	'forecaster.overview.tag.progress.tooltip': '{{ progress | number : false : false : true }}% ( {{ total | number }} from {{ typeTotal | number }} )',

	'transaction.details.sidebar.header': 'Transaction',
	'transaction.details.sidebar.content.overview.label': 'Transaction via Internet',
	'transaction.details.sidebar.content.overview.amount': '{{ data.sums.display.total | number }} {{ data.sums.display.currency }}',
	'transaction.details.sidebar.content.overview.amount.WITHDRAW': '{{ data.sums.display.total | number : true }} {{ data.sums.display.currency }}',
	'transaction.details.sidebar.content.overview.date': 'Created at {{ data.paymentDate | date : "yyyy-MM-dd" }}',
	'transaction.details.sidebar.content.print': 'Print transaction details',

	'transaction.details.sidebar.feedback.description': 'Deserunt quia animi sunt. Maxime id consequatur deleniti. Rem ut ab.',
	'transaction.details.sidebar.feedback.min': 'Bad',
	'transaction.details.sidebar.feedback.max': 'Good',

	'transaction.details.header.overview': 'Details',
	'transaction.details.header.account': 'Account information',
	'transaction.details.header.risk': 'Risk',

	'transaction.details.transactionDate': 'Transaction date',
	'transaction.details.date.booked': 'Booking date',
	'transaction.details.bank.reference': 'Bank Reference',
	'transaction.details.reference': 'Reference',
	'transaction.details.message': 'Message',
	'transaction.details.company': 'Company name',
	'transaction.details.currency': 'Currency',
	'transaction.details.account.name': 'Account name',
	'transaction.details.account.bank': 'Bank',

	'transaction.details.account.clearing': 'Clearing',
	'transaction.details.account.iban': 'IBAN',
	'transaction.details.account.bic': 'BIC',

	'transaction.details.transactionDate.value': '{{ value | date : "yyyy-MM-dd" }}',
	'transaction.details.date.booked.value': '{{ value | date : "yyyy-MM-dd" }}',

	'widget.navigation.accounts': 'Bankkonton',
	'widget.navigation.cashflow': 'Översikt',
	'widget.navigation.clients': 'Kunder',
	'widget.navigation.financial': 'Finansiering',
	'widget.navigation.financial.credit': 'Kredit',
	'widget.navigation.forecaster': 'Prognos',
	'widget.navigation.help': 'Hjälp',
	'widget.navigation.help.feedback': 'Ge feedback',
	'widget.navigation.logout': 'Logga ut',
	'widget.navigation.onboarding': 'Anslutningar',
	'widget.navigation.overview': 'Valutaöversikt',
	'widget.navigation.settings': 'Inställningar',
	'widget.navigation.statement': 'Årsrapport',
	'widget.navigation.streamline': 'Strömlinjeformad',
	'widget.navigation.toggle': 'Expandera',
	'widget.navigation.toggle.open': 'Minimera',
	'widget.navigation.welcome': 'Introduktion',

	'translation.code.sv': 'Svenska',
	'translation.code.en': 'English',
	'translation.code.fr': 'Français',
	'translation.code.es': 'Español',
	'translation.code.pt': 'Português',

	'financial.title': 'Finanseringsbehov',

	'financial.navigation.fx': 'Valuta',
	'financial.navigation.credit': 'Finanseringsbehov',
	'financial.navigation.factoring': 'Fakturaköp',

	'financial.factoring.placeholder.action': 'Check it',
	'financial.factoring.placeholder.content': '[Factoring] Officiis aliquam consequuntur ea et quis quo.',
	'financial.factoring.placeholder.title': 'Financial - Factoring',
	'financial.placeholder.action': 'Check it',
	'financial.placeholder.content': '[Financial] Nobis facilis voluptate repudiandae tempore sapiente.',
	'financial.placeholder.title': 'Financial',
	'forecaster.overview.action': 'Check it',
	'forecaster.overview.content': '[Overview] Dolorum aperiam recusandae velit dolorum aut omnis.',
	'forecaster.overview.title': 'Data-Driven guide',
	'forecaster.withdraw.action': 'Check it',
	'forecaster.withdraw.content': 'Se över dina utgifter för kvartal 1, 2023',
	'forecaster.withdraw.title': 'Din prognos har updaterats',
	'onboarding.bank.finished.action': 'Check it',
	'onboarding.bank.finished.content': '[Bank] Placeat molestiae ducimus soluta maxime optio quis fugiat.',
	'onboarding.bank.finished.title': 'Onboarding - Accounting Software',
	'onboarding.erp.finished.action': 'Check it',
	'onboarding.erp.finished.content': '[ERP] Suscipit animi quisquam asperiores ut quae omnis.',
	'onboarding.erp.finished.title': 'Onboarding - Bank',

	'forecaster.graph.option.settings': 'Settings',
	'forecaster.graph.option.account.balance': 'Show account balance graph',
	'forecaster.graph.option.show.history': 'Show history in graph',
	'forecaster.graph.option.show.original.forecast': 'Show original forecast in graph',

	'tour.cashflow.integrations.loading.title': 'Inläsning av transaktioner',
	'tour.cashflow.integrations.loading.description': 'Här ser du om nya kategorier och transaktioner läses in.',
	'tour.cashflow.navigation.title': 'Navigation i Företagskollen',
	'tour.cashflow.navigation.description': 'Här hittar du olika flikar och funktioner i företagskollen.',
	'tour.cashflow.navigation.cashflow.title': 'Kassaflödesöversikt',
	'tour.cashflow.navigation.cashflow.description': 'I den här fliken ser du en översikt över ditt företags kassaflöde och med möjlighet att söka bland kategorier och transaktioner.',
	'tour.cashflow.navigation.forecaster.title': 'I den här fliken ser du en översikt över ditt företags kassaflöde och med möjlighet att söka bland kategorier och transaktioner.',
	'tour.cashflow.navigation.forecaster.description': 'I den här fliken kan du se hur din prognos ser ut och hantera & justera prognosen efter ditt företags behov.',
	'tour.cashflow.navigation.financial.title': 'Finansieringsbehov',
	'tour.cashflow.navigation.financial.description': 'I den här fliken kan du räkna ut vilket finansieringsbehov som skulle kunna passa dig.',
	'tour.cashflow.navigation.onboarding.title': 'Hantera bokföringssystem',
	'tour.cashflow.navigation.onboarding.description': 'I den här fliken kan du hantera bokföringssystem. ',
	'tour.cashflow.navigation.settings.title': 'Inställningar',
	'tour.cashflow.navigation.settings.description': 'I den här fliken kan du hantera olika inställningar i Företagskollen',
	'tour.cashflow.help.title': 'Hjälp & Support',
	'tour.cashflow.help.description': 'Klicka här för att få hjälp med hur tjänsten fungerar.',
	'tour.cashflow.graph.deposit.title': 'Inbetalningar',
	'tour.cashflow.graph.deposit.description': 'Stapeln visar inbetalningar – klicka på den för att se vilka transaktioner som ingår i den valda perioden.',
	'tour.cashflow.graph.withdraw.title': 'Utbetalningar',
	'tour.cashflow.graph.withdraw.description': 'Stapeln visar utbetalningar – klicka på den för att se vilka transaktioner som ingår i den valda perioden.',
	'tour.cashflow.graph.overdue.title': 'Förfallna fakturor',
	'tour.cashflow.graph.overdue.description': 'En röd bricka visar hur många obetalda fakturor som har passerat betalningsdatum.',
	'tour.cashflow.graph.forecast.title': 'Prognos',
	'tour.cashflow.graph.forecast.description': 'Prognosen är en indikation på framtida inbetalningar och utbetalningar. Den baseras på tidigare genomförda transaktioner.',
	'tour.cashflow.search.title': 'Översikt och kategorier',
	'tour.cashflow.search.description': 'Sök kategorier, kunder, transaktioner eller status på den där fakturan för en förenklad översikt över dina kategorier och ditt ',
	'tour.cashflow.graph.pagination.title': 'Tidslinje',
	'tour.cashflow.graph.pagination.description': 'Klicka på pilarna för att se historiska transaktioner eller en prognos.',
	'tour.cashflow.notifications.title': 'Notifikationer',
	'tour.cashflow.notifications.description': 'Här kan du få notiser om händelser i ditt kassaflöde.',
	'tour.cashflow.graph.period.title': 'Period',
	'tour.cashflow.graph.period.description': 'Här väljer du vilken period som ska visas i grafen. Du kan välja veckor, månader eller år.',
	'tour.cashflow.transactions.title': 'Transaktioner',
	'tour.cashflow.transactions.description': 'Här ser du om du har några nya transaktioner. Klicka för att visa transaktionerna.',

	'tour.financial.credit.graph.title': 'Kontobalansgraf',
	'tour.financial.credit.graph.description': 'Här ser du din kontobalans med möjlig avvikelse.',
	'tour.financial.credit.resizer.title': 'Anpassa finansieringsbehov',
	'tour.financial.credit.resizer.description': 'Klicka och drag i den röda rektangeln som syns i kontobalansgrafen för själv räkna ut vilket finansieringsbehov som skulle kunna passa dig.',
	'tour.financial.credit.input.title': 'Fyll i finansieringsbehov',
	'tour.financial.credit.input.description': 'Du kan även genom att fylla i här anpassa vilket finansieringsbehov som skulle kunna passa dig.',
	'tour.financial.credit.help.title': 'Hur fungerar finansieringsbehov?',
	'tour.financial.credit.help.description': 'Klicka här för att läsa på om hur finanseringsbehov fungerar.',
	'tour.financial.credit.button.done.title': 'Bekräfta finansieringsbehov',
	'tour.financial.credit.button.done.description': 'När du räknat ut ditt finansieringsbehov kan du klicka här, så skickas du vidare till kontaktformuläret.',

	'tour.forecaster.overview.progress.title': 'Status på prognos',
	'tour.forecaster.overview.progress.description': 'Genom att gå igenom dina in-/utbetalningar och kategorier får du en starkare prognos.',
	'tour.forecaster.navigation.title': 'Gå igenom in-/utbetalningar',
	'tour.forecaster.navigation.description': 'För att får en så pricksäker prognos som möjligt behöver du gå igenom både inbetalningar och utbetalningar.',
	'tour.forecaster.navigation.categories.title': 'Gå igenom kategorier',
	'tour.forecaster.navigation.categories.description': 'Gå igenom dina kategorier, de med mest påverkan på din framtida kontobalans syns här.',
	'tour.forecaster.categories.title': 'Lägg till kategori',
	'tour.forecaster.categories.description': 'Genom att klicka här kan man lägga till kategorier att prognostisera.',
	'tour.forecaster.overview.total.title': 'Din framtida kontobalans',
	'tour.forecaster.overview.total.description': 'Här ser du hur din framtida kontobalans kan se ut.',
	'tour.forecaster.graph.past.title': 'Din historiska period',
	'tour.forecaster.graph.past.description': 'För att se tidigare betalningsperioder kan du gå tillbaka i historien. Du kan inte göra ändringar i historiska händelser.',
	'tour.forecaster.graph.current.title': 'Nuvarande period',
	'tour.forecaster.graph.current.description': 'Pågående period består av löpande betalningar, kommande betalningar och prognostiserade betalningar.',
	'tour.forecaster.graph.input.title': 'Justera belopp för period',
	'tour.forecaster.graph.input.description': 'Du kan klicka här och fylla i det förväntade beloppet',
	'tour.forecaster.graph.input.probability.title': 'Justera sannolikheten',
	'tour.forecaster.graph.input.probability.description': 'Genom att klicka här kan du välja hur sannolikt beloppet inträffar',
	'tour.forecaster.graph.input.done.title': 'Godkänn period',
	'tour.forecaster.graph.input.done.description': 'Genom att justera belopp på nuvarande period eller klicka på godkänn går du vidare till nästa period att granska',
	'tour.forecaster.graph.future.title': 'Kommande betalningar',
	'tour.forecaster.graph.future.description': 'Kommande betalningar baseras på historiska betalningar. Du kan justera och lägga till nya betalningar.',
	'tour.forecaster.graph.button.drag.title': 'Drag och släpp för att justera belopp',
	'tour.forecaster.graph.button.drag.description': 'Genom att klicka här kan du dra och släppa stapeln till ditt önskade belopp',
	'tour.forecaster.graph.button.create.title': 'Lägg till belopp',
	'tour.forecaster.graph.button.create.description': 'Genom att klicka här lägger du till nytt belopp och kan efter det justera efter önskat belopp',
	'tour.forecaster.graph.button.transactions.title': 'Transaktionslista',
	'tour.forecaster.graph.button.transactions.description': 'Genom att klicka här kan du se dina prognostiserade transaktioner',
	'tour.forecaster.footer.content.title': 'Översikt av kategori',
	'tour.forecaster.footer.content.description': 'Här kan du se totalen per kategori och snabbt välja om du vill gå vidare',
	'tour.forecaster.footer.actions.title': 'Hantera prognos',
	'tour.forecaster.footer.actions.description': 'Här kan du välja att avbryta, gå till föregående eller nästa kategori eller spara dina ändringar.',
	'tour.forecaster.settings.title': 'Prognosinställningar',
	'tour.forecaster.settings.description': 'Här kan du hitta inställningar till din prognos och hantera scenarios.',

	'credit.settings.graph': 'Show in/-outgoing graph',
	'credit.settings.statement': 'Show yearly statement view',

	'teaser.cashflow.forecast.text': 'Did you know that your forecast will be much more on point if you go over and validate your forecast?',
	'teaser.cashflow.forecast.button.adjust': 'Adjust forecast',

	'teaser.credit.erp.button.add': 'Add business software',
	'teaser.credit.erp.text': 'Connect to your accounting system to see your invoices, more history and this get a better overview for the forcest and future with Företagskollen.',

	'teaser.credit.forecaster.text': 'Your overdraft application will be better if you keep your forecast up to date',
	'teaser.credit.forecaster.button.adjust': 'Validate forecast',
	'teaser.credit.forecaster.text.scenario.uncompleted': 'You havent completed your forecast for {{ count }} month, please validate before continue with overdraft application',

	'tour.previous': 'Previous',
	'tour.next': 'Next',

	'list.column.settings.title': 'Column management',
	'list.settings.title1': 'The list and columns',
	'list.settings.text': `Select which columns and in which order to display them, click and move the handle on the right to reposition the columns as needed. A minimum of 3 and a maximum of 7 columns can be active at a time.`,
	'list.settings.title.active.columns': `Selected columns`,
	'list.settings.text.active.columns': `{{ columns }} columns`,
	'list.settings.title.columns': `Available columns`,
	'list.settings.not.available.columns': `No more available columns to enable`,

	'list.column.description': 'Transaction name',
	'list.column.paymentDate': 'Payment date',
	'list.column.tags': 'Categories',
	'list.column.status': 'Status',
	'list.column.total': 'Amount',
	'list.column.risk': 'Currency risk',
	'list.column.currency': 'Currency',

	'transaction.list.settings.content.title': 'Transaction list',
	'transaction.list.settings.compress.label': `Show a more compact view to be able to see more details or more transactions at a time`,
	'transaction.list.settings.title': 'Appearance',

	'signup.title': 'Sign up',
	'signup.organizationNumber.placeholder': null,
	'onboarding.layout.splash.title': 'Koppla på ditt bokföringssystem',
	'onboarding.layout.splash.content': 'Koppla på ditt bokföringssystem för att se dina fakturor, mer historik och på så sätt få en bättre överblick för framtiden med Företagskollen. *',
	'onboarding.layout.splash.action': 'Koppla bokföringssystem',

	'graph.forecaster.teaser.title': 'This is your forecast',
	'graph.forecaster.teaser.content': `We recommend you to go over and validate it to get as stable forecast as possible.`,
	'graph.forecaster.teaser.action.submit': `Adjust forecast`,
	'graph.forecaster.teaser.action.skip': `Skip`,

	'onboarding.intro.title': 'Get started',

	'onboarding.intro.startSelector': 'To make full use of Smart Cash Flow, you just have to add your business and/or banking software. It should take you less than 10 minutes.',

	'onboarding.intro.description': '',
	'onboarding.intro.erp.title': 'Add Business software',
	'onboarding.intro.erp.description': 'Add your business software to get your ... Lorem ipsum dolor sit amet, an debitis indoctum persequeris mel. Vis eu nemore habemus mentitum, labores adipisci eum te.',
	'onboarding.intro.bank.title': 'Add Bank',
	'onboarding.intro.bank.description': 'Add your bank to get your ... Lorem ipsum dolor sit amet, an debitis indoctum persequeris mel. Vis eu nemore habemus mentitum, labores adipisci eum te.',

	'onboarding.intro.submit': 'Next',

	'snackbar.financial.credit.approve.title': 'Credit application succeded',
	'snackbar.financial.credit.approve.content': 'You have applied for {{ data.credit | number }}. A advisor will get back to you when your application has been reviewed.',

	'onboarding.modal.incomming.title': 'Add incoming payments 2023',
	'onboarding.modal.incomming.clients.label': 'How many clients will you invoice?',
	'onboarding.modal.incomming.amount.label': 'How much will you invoice for?',
	'onboarding.modal.incomming.payments-cash.label': 'Cash payments?',
	'onboarding.modal.incomming.payments-cash.label.true': 'Yes',
	'onboarding.modal.incomming.payments-cash.label.false': 'No',
	'onboarding.modal.incomming.payments-cash-amount.label': 'How much in cash payments?',
	'onboarding.modal.incomming.payments-card.label': 'Card payments?',
	'onboarding.modal.incomming.payments-card.label.true': 'Yes',
	'onboarding.modal.incomming.payments-card.label.false': 'No',
	'onboarding.modal.incomming.payments-card-amount.label': 'How much in card payments?',
	'onboarding.modal.incomming.payments-other.label': 'Other incoming payments?',
	'onboarding.modal.incomming.payments-other.label.true': 'Yes',
	'onboarding.modal.incomming.payments-other.label.false': 'No',
	'onboarding.modal.incomming.payments-other-amount.label': 'How much will you invoice for?',

	'onboarding.modal.incomming.button.cancel': 'Cancel',
	'onboarding.modal.incomming.button.submit': 'Submit',

	'onboarding.modal.outgoing.title': 'Add outgoing payments 2023',
	'onboarding.modal.outgoing.salary.label': 'How much salary will you pay?',
	'onboarding.modal.outgoing.expenses.label': 'How much will you buy this year?',
	'onboarding.modal.outgoing.button.categories': 'Categories',
	'onboarding.modal.outgoing.rent.label': 'Rent',
	'onboarding.modal.outgoing.insurance.label': 'Insurance',
	'onboarding.modal.outgoing.material.label': 'Material',
	'onboarding.modal.outgoing.payments-other.label': 'Other outgoing payments?',
	'onboarding.modal.outgoing.payments-other.label.true': 'Yes',
	'onboarding.modal.outgoing.payments-other.label.false': 'No',
	'onboarding.modal.outgoing.payments-other-amount.label': 'How much?',
	'onboarding.modal.outgoing.button.cancel': 'Cancel',
	'onboarding.modal.outgoing.button.submit': 'Submit',

	'onboarding.intro.bank.text': 'Bank lorem ipsum dolor sit amet, an debitis indoctum persequeris mel. Vis eu nemore habemus mentitum, labores adipisci eum te.',
	'onboarding.intro.erp.text': 'ERP lorem ipsum dolor sit amet, an debitis indoctum persequeris mel. Vis eu nemore habemus mentitum, labores adipisci eum te.',

	'onboarding.intro.help': 'I dont have access',
	'onboarding.intro.help.link': 'Need help',
	'action.add': 'Add',
	'onboarding.intro.text': 'To make full use of Smart Cash Flow, you just have to add your business and/or banking software. It should take you less than 10 minutes.',
	'onboarding.action.continue': 'Continue',
	'onboarding.action.continue.erp': 'Continue with Bank',
	'onboarding.action.continue.bank': 'Continue with Business software',

	'onboarding.intro.importing': 'Importing',

	'onboarding.intro.done': 'Done',

	'onboarding.modal.company.title': 'Company information',
	'onboarding.modal.company.name.label': 'Company name',
	'onboarding.modal.company.country.label': 'Country',
	'onboarding.modal.company.employees.label': 'Employees',
	'onboarding.modal.company.type.label': 'Type of company',
	'onboarding.modal.company.type.sole-trader': 'Sole trader',
	'onboarding.modal.company.type.limited-company': 'Limited company',
	'onboarding.modal.company.type.trading-partnership': 'Trading partnership',
	'onboarding.modal.company.type.economic-association': 'Economic association',
	'onboarding.modal.company.type.non-profit-association': 'Non-profit association',
	'onboarding.modal.company.type.community-association': 'Community association',
	'onboarding.modal.company.branch.label': 'Branch',
	'onboarding.modal.company.branch.it': 'IT',
	'onboarding.modal.company.role.label': 'Role',
	'onboarding.modal.company.role.owner': 'Owner',
	'onboarding.modal.company.role.ceo': 'CEO',
	'onboarding.modal.company.role.cto': 'CTO',
	'onboarding.modal.company.role.product-manager': 'Product manager',
	'onboarding.modal.company.role.sale-manager': 'Sale Manager',
	'onboarding.modal.company.accounting.label': 'Accounting method',
	'onboarding.modal.company.accounting.quartely': 'Quartely',
	'onboarding.modal.company.seasonal.label': 'Seasonal business',
	'onboarding.modal.company.seasonal.label.true': 'Yes',
	'onboarding.modal.company.seasonal.label.false': 'No',
	'onboarding.modal.company.season-active.label': 'Most active season',
	'onboarding.modal.company.season-active.winter': 'Winter',
	'onboarding.modal.company.season-active.spring': 'Spring',
	'onboarding.modal.company.season-active.summer': 'Summer',
	'onboarding.modal.company.season-active.autumn': 'Autumn',
	'onboarding.modal.company.button.cancel': 'Cancel',
	'onboarding.modal.company.button.submit': 'Submit',

	'onboarding.modal.currency.title': 'Currency information',
	'onboarding.modal.currency.multi.label': 'Buying more than in one currency',
	'onboarding.modal.currency.multi.label.true': 'Yes',
	'onboarding.modal.currency.multi.label.false': 'No',
	'onboarding.modal.currency.currencies.label': 'Currencies',
	'onboarding.modal.currency.risk.label': 'Currency risk for me is important when',
	'onboarding.modal.currency.risk.label.value': '0',
	'onboarding.modal.currency.risk.label.help': '{{ value }}%',
	'onboarding.modal.currency.button.cancel': 'Cancel',
	'onboarding.modal.currency.button.submit': 'Submit',

	'onboarding.modal.threshold.title': 'Cash flow threshhold',
	'onboarding.modal.threshold.subtitle': 'Notify me when',
	'onboarding.modal.threshold.cashflow-below.label': 'Cash flow is getting below',
	'onboarding.modal.threshold.cashflow-below.help': '{{ value }}%',
	'onboarding.modal.threshold.overdue.label': '0',
	'onboarding.modal.threshold.overdue.help': '{{ value }} days overdue',
	'onboarding.modal.threshold.unpaid.label': '0',
	'onboarding.modal.threshold.unpaid.help': '{{ value }} Unpaid overvoices',
	'onboarding.modal.threshold.forecast.label': '0',
	'onboarding.modal.threshold.forecast.help': '{{ value }} New forecast updates',
	'onboarding.modal.threshold.customer.label': '0',
	'onboarding.modal.threshold.customer.help': '{{ value }} Customer behavior',
	'onboarding.modal.threshold.button.cancel': 'Cancel',
	'onboarding.modal.threshold.button.submit': 'Submit',

	'welcome.information.title': 'Help us with some information',
	'welcome.section.deposit.title': 'Add your incoming payments',
	'welcome.section.deposit.text': 'Add your revenue for {{ today | date: "yyyy" }}<br />Lorem ipsum dolor sit amet, an debitis indoctum persequeris mel. Vis eu nemore habemus mentitum, labores adipisci eum te.',
	'welcome.section.withdraw.title': 'Add your outgoing payments',
	'welcome.section.withdraw.text': 'Add your revenue for {{ today | date: "yyyy" }}<br/>Lorem ipsum dolor sit amet, an debitis indoctum persequeris mel. Vis eu nemore habemus mentitum, labores adipisci eum te. ',

	'welcome.company.title': 'Company information',
	'welcome.section.information.title': 'Add company information',
	'welcome.section.information.text': 'Add your company information, role, branch, etc',
	'welcome.section.currency.title': 'Currency information',
	'welcome.section.currency.text': 'Potential  currencies etc',
	'welcome.section.threshold.title': 'Cash flow threshhold',
	'welcome.section.threshold.text': 'Insights and notifications settings',

	'welcome.extra.title': 'Almost done',
	'welcome.extra.subtitle': 'Add company information',
	'welcome.extra.content': 'Add your company information, role, branch, etc',
	'welcome.extra.liquidity.label': 'What is the hardest with liquidity?',
	'welcome.extra.liquidity.placeholder': 'To know if i can pay salaries in the upcoming 3 months',
	'welcome.extra.cash-handling.label': 'How do you handle cash flow today?',
	'welcome.extra.cash-handling.placeholder': 'I dont do it',
	'welcome.extra.expectation.label': 'What do you want to see in cash flow?',
	'welcome.extra.expectation.placeholder': 'I wanna get answer of all above',
	'welcome.extra.cashflow.label': 'How did you hear about Cash flow / Asteria?',
	'welcome.extra.cashflow.placeholder': 'Internet',
	'welcome.extra.share-accountant.label': 'Do you wanna share with your accountant/advisory?',
	'welcome.extra.share-accountant.placeholder': 'Enter email',

	'welcome.support.text': 'I dont have access.',
	'welcome.support.action': 'Need help',

	'onboarding.modal.help.title': 'Need help to add bank',
	'onboarding.modal.help.email.label': 'Send information to email',
	'onboarding.modal.help.email.placeholder': 'example@mail.com',
	'onboarding.modal.help.message.label': 'Add help message',
	'onboarding.modal.help.message.placeholder': 'Hey, can you please help me with my integrations?',
	'onboarding.modal.help.button.cancel': 'Cancel',
	'onboarding.modal.help.button.submit': 'Send',

	'welcome.loading.category': 'Loading',
	'welcome.placeholder.loading.text': 'Loading is in progress.<br/>It can take up to 24 hours. Thanks for your patience!',
	'welcome.placeholder.loading.erp.text': 'Loading of your transactions from the accounting software is in progress.<br/>It can take up to 24 hours. Thanks for your patience!',
	'welcome.placeholder.loading.bank.text': 'Loading of your transactions from the internet bank is in progress.<br/>It can take up to 24 hours. Thanks for your patience!',
	'welcome.placeholder.loading.both.text': 'Loading of your transactions from your integrations is in progress.<br/>It can take up to 24 hours. Thanks for your patience!',
	'welcome.placeholder.done.both.categories': 'You should now be able to see transactions and categories such as',
	'welcome.placeholder.done.close': 'Go to Företagskollen',
	'welcome.action.integration.bank.title': 'Connect your bank',
	'welcome.action.integration.bank.content': 'Lorem ipsum dolor sit amet, summo noster verear sit te, eos et regione urbanitas, tota consequuntur eu cum. ',
	'welcome.action.integration.erp.title': 'Connect your accounting software',
	'welcome.action.integration.erp.content': 'Connect your accounting system to see your invoices, more history and thus get a better overview for the future with Företagskollen.',
	'welcome.action.question.title': 'Tell us about your business',
	'welcome.action.question.content': 'By telling us about your business and your needs, you help us improve the forecast and overview of your liquidity.',
	'welcome.action.integration.status.done': 'Done',
	'welcome.action.integration.status.loading': 'Importing',
	'welcome.action.integration.status.error': 'Error',
	'welcome.action.integration.title': 'Recommended actions',
	'welcome.action.integration.subtitle': 'While the transactions are loading, we suggest the following steps to get started. It takes less than 5 minutes.',

	'view.welcome.title': 'Företagskollen',
	'view.welcome.text.help': 'Need help getting started?',
	'view.welcome.link.help': 'I need help',
	'view.welcome.text.skip': 'I want to do this later',
	'view.welcome.link.skip': 'Skip and go to the liquidity overview',
	'welcome.action.question.status.done': 'Done',
	'welcome.action.complete.text': 'Congratulations! You have successfully completed all the recommended actions.',
	'welcome.question.extension.link.open': 'Show more',
	'welcome.question.extension.link.close': 'Show less',
	'status.SIGNED': 'Signed',
	'search.examples.status.signed': 'Signed',
	'graph.spread.faction.0': 'Mest sannolikt',
	'graph.spread.faction.10': 'Mest sannolikt',
	'graph.spread.faction.20': 'Mest sannolikt',
	'graph.spread.faction.30': 'Något sannolikt',
	'graph.spread.faction.40': 'Något sannolikt',
	'graph.spread.faction.50': 'Något sannolikt',
	'graph.spread.faction.60': 'Låg sannolikt',
	'graph.spread.faction.70': 'Låg sannolikt',
	'graph.spread.faction.80': 'Låg sannolikt',
	'graph.spread.faction.90': 'Mycket låg sannolikt',
	'graph.spread.faction.100': 'Mycket låg sannolikt',

	'datepicker.repeatable.label': 'Reptera',
	'datepicker.repeatable.value.never': 'Never',
	'datepicker.repeatable.value.weekly': 'Weekly',
	'datepicker.repeatable.value.monthly': 'Monthly',
	'datepicker.repeatable.value.quarterly': 'Quarterly',
	'datepicker.repeatable.value.other': 'Other',
	'datepicker.modal.repeatable.startDate.label': 'Från',
	'datepicker.modal.repeatable.every.label': 'Varje',
	'datepicker.modal.repeatable.type.label': 'Månad',
	'datepicker.modal.repeatable.endDate.label': 'Slutdatum',
	'datepicker.modal.repeatable.title': 'Repetera',
	'graph.account.legend.paid.negative': 'Negativ kontobalans',
	'graph.account.legend.forecast.negative': 'Negativ prognos',
	'graph.account.legend.spread.negative': 'Negativ avvikelse',

	'forecaster.linegraph.dot.date': '{{{ date|date:"MMMM"|capitalize }}}',
	'forecaster.linegraph.dot.date.startOfYear': '{{{ date|date:"MMMM `yy"|capitalize }}}',

	'forecaster.navigation.hard': 'Hard to forecast',
	'forecaster.navigation.all': 'All categories',

	'tags.deposit.transaction.transfer': `Incoming {{ #translate }} transaction, transfer, transaction.transfer, transaction-transfer, tags.transaction, tags.transfer, tags.transaction.transfer, tags.transaction-transfer {{ /translate }}`,
	'tags.deposit.transaction.payment': `Incoming {{ #translate }} transaction, payment, transaction.payment, transaction-payment, tags.transaction, tags.payment, tags.transaction.payment, tags.transaction-payment {{ /translate }}`,
	'tags.deposit.transaction.swish': `Incoming {{ #translate }} transaction, swish, transaction.swish, transaction-swish, tags.transaction, tags.swish, tags.transaction.swish, tags.transaction-swish {{ /translate }}`,
	'tags.deposit.transaction.cash': `Incoming {{ #translate }} transaction, cash, transaction.cash, transaction-cash, tags.transaction, tags.cash, tags.transaction.cash, tags.transaction-cash {{ /translate }}`,
	'tags.deposit.transaction.card': `Incoming {{ #translate }} transaction, card, transaction.card, transaction-card, tags.transaction, tags.card, tags.transaction.card, tags.transaction-card {{ /translate }}`,
	// 'tags.deposit.invoices.customer': `Incoming {{ #translate }} invoices, customer, invoices.customer, invoices-customer, tags.invoices, tags.customer, tags.invoices.customer, tags.invoices-customer {{ /translate }}`,
	// 'tags.deposit.deposit.misc': `Incoming {{ #translate }} deposit, misc, deposit.misc, deposit-misc, tags.deposit, tags.misc, tags.deposit.misc, tags.deposit-misc {{ /translate }}`,
	'tags.deposit.taxes.tax': `Incoming {{ #translate }} taxes, tax, taxes.tax, taxes-tax, tags.taxes, tags.tax, tags.taxes.tax, tags.taxes-tax {{ /translate }}`,
	'tags.deposit.forecaster.adjusted': `Incoming {{ #translate }} forecaster, adjusted, forecaster.adjusted, forecaster-adjusted, tags.forecaster, tags.adjusted, tags.forecaster.adjusted, tags.forecaster-adjusted {{ /translate }}`,
	'tags.withdraw.transaction.transfer': `Outgoing {{ #translate }} transaction, transfer, transaction.transfer, transaction-transfer, tags.transaction, tags.transfer, tags.transaction.transfer, tags.transaction-transfer {{ /translate }}`,
	'tags.withdraw.transaction.payment': `Outgoing {{ #translate }} transaction, payment, transaction.payment, transaction-payment, tags.transaction, tags.payment, tags.transaction.payment, tags.transaction-payment {{ /translate }}`,
	'tags.withdraw.transaction.swish': `Outgoing {{ #translate }} transaction, swish, transaction.swish, transaction-swish, tags.transaction, tags.swish, tags.transaction.swish, tags.transaction-swish {{ /translate }}`,
	'tags.withdraw.transaction.cash': `Outgoing {{ #translate }} transaction, cash, transaction.cash, transaction-cash, tags.transaction, tags.cash, tags.transaction.cash, tags.transaction-cash {{ /translate }}`,
	'tags.withdraw.transaction.card': `Outgoing {{ #translate }} transaction, card, transaction.card, transaction-card, tags.transaction, tags.card, tags.transaction.card, tags.transaction-card {{ /translate }}`,
	// 'tags.withdraw.withdraw.misc': `Outgoing {{ #translate }} withdraw, misc, withdraw.misc, withdraw-misc, tags.withdraw, tags.misc, tags.withdraw.misc, tags.withdraw-misc {{ /translate }}`,
	// 'tags.withdraw.invoices.supplier': `Outgoing {{ #translate }} invoices, supplier, invoices.supplier, invoices-supplier, tags.invoices, tags.supplier, tags.invoices.supplier, tags.invoices-supplier {{ /translate }}`,
	// 'tags.withdraw.salaries.salary': `Outgoing {{ #translate }} salaries, salary, salaries.salary, salaries-salary, tags.salaries, tags.salary, tags.salaries.salary, tags.salaries-salary {{ /translate }}`,
	'tags.withdraw.taxes.tax': `Outgoing {{ #translate }} taxes, tax, taxes.tax, taxes-tax, tags.taxes, tags.tax, tags.taxes.tax, tags.taxes-tax {{ /translate }}`,
	'tags.withdraw.forecaster.adjusted': `Outgoing {{ #translate }} forecaster, adjusted, forecaster.adjusted, forecaster-adjusted, tags.forecaster, tags.adjusted, tags.forecaster.adjusted, tags.forecaster-adjusted {{ /translate }}`,

	'tags.deposit.custom': 'Incoming {{ #translate }} null {{ /translate }}',
	'tags.withdraw.custom': 'Outgoing {{ #translate }} null {{ /translate }}',

	'forecaster.probability.prompt.apply': 'Yes',
	'forecaster.probability.prompt.cancel': 'No',

	'forecaster.account.balance.deposit.spread': `Sannolikt mellan {{ min | number : false}} och {{ max | number : false}}`,
	'forecaster.account.balance.withdraw.spread': `Sannolikt mellan {{ min | number : true}} och {{ max | number : true}}`,

	'forecaster.actionbar.body.zero': 'Your forecast is empty. Let\'s try to fill your forecast till {{ targetDate|date:"MMMM `yy"|capitalize }}',
	'forecaster.actionbar.deposit.body.zero': 'Your incoming forecast is empty. Let\'s try to fill your forecast till {{ targetDate|date:"MMMM `yy"|capitalize }}',
	'forecaster.actionbar.tag.body.zero': 'Your {{{ tag }}} is empty. Let\'s try to fill your forecast till {{ targetDate|date:"MMMM `yy"|capitalize }}',
	'forecaster.actionbar.tag.deposit.body.zero': 'Your {{{ tag }}} is empty in incoming. Let\'s try to fill your forecast till {{ targetDate|date:"MMMM `yy"|capitalize }}',
	'forecaster.actionbar.tag.withdraw.body.zero': 'Your {{{ tag }}} is empty in outgoing. Let\'s try to fill your forecast till {{ targetDate|date:"MMMM `yy"|capitalize }}',
	'forecaster.actionbar.withdraw.body.zero': 'Your outgoing forecast is empty. Let\'s try to fill your forecast till {{ targetDate|date:"MMMM `yy"|capitalize }}',

	'datepicker.day': 'Day',
	'datepicker.week': 'Week',
	'datepicker.month': 'Month',
	'datepicker.year': 'Year',

	// 'datepicker.day.value.format': null,
	'datepicker.week.value.format': '{{ date | date:"ww, yyyy" }}',
	'datepicker.month.value.format': '{{ date | date:"MMMM, yyyy" | capitalize }}',
	'datepicker.year.value.format': '{{ date | date:"yyyy" }}',

	// 'tag.edit.remove.content.info': null,
	// 'tag.edit.remove.content.info.question': null,

	// 'tag.edit.remove.content.info.empty': null,
	// 'tag.edit.remove.content.info.question.empty': null,

	// 'tag.edit.remove.content.info.history': null,
	// 'tag.edit.remove.content.info.question.history': null,
	// 'tag.edit.remove.content.info.bullet.0.history': null,
	// 'tag.edit.remove.content.info.bullet.1.history': null,
	// 'tag.edit.remove.content.info.bullet.2.history': null,

	// 'tag.edit.remove.content.info.current': null,
	// 'tag.edit.remove.content.info.question.current': null,
	// 'tag.edit.remove.content.info.bullet.0.current': null,
	// 'tag.edit.remove.content.info.bullet.1.current': null,
	// 'tag.edit.remove.content.info.bullet.2.current': null,

	// 'tag.edit.remove.content.info.content': null,
	// 'tag.edit.remove.content.info.question.content': null,
	// 'tag.edit.remove.content.info.bullet.0.content': null,
	// 'tag.edit.remove.content.info.bullet.1.content': null,
	// 'tag.edit.remove.content.info.bullet.2.content': null,

	// 'tag.deselect.title': null,
	// 'tag.deselect.content.info': null,
	// 'tag.deselect.content.info.question': null,

	// 'tag.deselect.content.info.deposit': null,
	// 'tag.deselect.content.info.question.deposit': null,
	// 'tag.deselect.content.info.bullet.0.deposit': null,
	// 'tag.deselect.content.info.bullet.1.deposit': null,

	// 'tag.deselect.content.info.withdraw': null,
	// 'tag.deselect.content.info.question.withdraw': null,
	// 'tag.deselect.content.info.bullet.0.withdraw': null,
	// 'tag.deselect.content.info.bullet.1.withdraw': null,

	// 'tag.deselect.content.info.empty': null,
	// 'tag.deselect.content.info.question.empty': null,

	'datepicker.navigation.item.day.year': `{{ date | date:"yyyy" }}`,
	'datepicker.navigation.item.day.month': `{{ date | date:"MMMM" | capitalize }}`,
	'datepicker.navigation.item.week': `{{ date | date:"MMMM, yyyy" }}`,
	'datepicker.navigation.item.month': `{{ date | date:"yyyy" }}`,
	'datepicker.navigation.item.year': `{{ startDate | date:"yyyy" }} - {{ endDate | date:"yyyy" }}`,
	'datepicker.content.header.day': `{{ date | date:"eeeeee" | capitalize }}`,
	'datepicker.calendar.day.cell': `{{ date | date:"d" }}`,
	'datepicker.calendar.week.cell': `{{ date | date:"II" }}`,
	'datepicker.calendar.month.cell': `{{ date | date:"MMM" | capitalize }}`,
	'datepicker.calendar.year.cell': `{{ date | date:"yyyy" }}`,

	// 'integration.missing.title': 'Saknar du ditt bokföringssystem?',
	// 'integration.missing.description':
	// 	'Genom att rapportera ditt saknade bokföringssystem kan vi fånga upp intresset från våran användare och prioritera att skapa en integration mot bokföringssystemet så fort som möjligt.',
	// 'integration.missing.input.label': 'Fyll i ditt bokföringssystem',
	// 'integration.missing.input.placeholder': 'Jag saknar...',
	// 'integration.missing.button.submit': 'Skicka',

	// 'virtual.printer.title':
	// 	'Ingen av mina bokföringssystem finns tillgängliga',
	// 'virtual.printer.description':
	// 	'Med våran virtuella skrivare kan du komma igång med ett par enkla steg. Allt du behöver göra är att installera en mjukvara i din dator.',
	// 'virtual.printer.install.link': 'Installera virtuell skrivare',

	'layout.header.settings': `{{ fullName }}`,

	'invoices.table.row.cell.invoice-number': `{{ meta.invoiceNumber }}`,
	'invoices.table.row.cell.client-number': `{{ meta.clientNumber }}`,
	'invoices.table.row.cell.total': `{{#isCreditInvoice}}{{ sums.original.total | number:true:false:false:false }}{{/isCreditInvoice}}{{^isCreditInvoice}}{{ sums.original.total | number:false:false:true:false }}{{/isCreditInvoice}}`,
	'invoices.table.row.cell.tax': `{{#isCreditInvoice}}{{ sums.original.tax | number:true:false:false:false }}{{/isCreditInvoice}}{{^isCreditInvoice}}{{ sums.original.tax | number:false:false:true:false }}{{/isCreditInvoice}}`,
	'invoices.table.row.cell.invoice-date-sent': `{{ dates.invoiceSent | date: "yyyy-MM-dd" }}`,
	'invoices.table.row.cell.invoice-date-due': `{{ dates.invoiceDue | date: "yyyy-MM-dd" }}`,
	'invoices.table.row.cell.currency': `{{ sums.original.currency }}`,

	'invoices.search.quick.amount.label': `{{ from | number:false:false:true  }} - {{ to | number:false:false:true  }}`,
	'invoices.search.quick.amount.same.label': `{{ from | number:false:false:true  }}`,
	'invoices.actionbar.currency.chip.label': `{{ currency }} ({{ count }}) - {{ total | number:false:false:true }}`,
	/*
	'page.invoices.review.title': 'Fakturor',
	'page.invoices.review.placeholder.title':
		'{{invoices.length}} fakturor att godkänna',
	'page.invoices.review.placeholder.subtext':
		'Du kommer nu att få godkänt {{invoices.length}} fakturor.',
	'page.invoices.review.placeholder.content':
		' [page.invoices.review.placeholder.content] Fusce quis odio a lectus imperdiet pulvinar non nec est. Nam viverra tristique arcu, id ornare justo consequat a. Aenean molestie ex dui, et accumsan neque vestibulum id.',
	'page.invoices.review.placeholder.link': 'Vad händer nu?',
	 */

	'invoices.search.quick.date.label': `{{ startDate|date:"yyyy-MM-dd" }} - {{ endDate|date:"yyyy-MM-dd" }}`,
	'invoices.search.quick.date.start.label': `{{ startDate|date:"yyyy-MM-dd" }}`,
	'invoices.search.quick.date.end.label': `{{ endDate|date:"yyyy-MM-dd" }}`,
	'invoices.search.quick.client.label': `{{{ name }}}`,
	'invoices.search.quick.currency.label': `{{ currency }}`,

	'invoice.modal.details.section.item.number.value': `{{ invoice.meta.invoiceNumber }}`,
	'invoice.modal.details.section.item.dates.sent.value': `{{ invoice.dates.invoiceSent | date:"yyyy-MM-dd" }}`,
	'invoice.modal.details.section.item.dates.due.value': `{{ invoice.dates.invoiceDue | date:"yyyy-MM-dd" }}`,
	'invoice.modal.details.section.item.message.value': `{{ invoice.meta.message }}`,

	'layout.header.navigation.item.home.label': 'Home',
	'layout.header.navigation.item.users.label': 'Users',
	'layout.header.navigation.item.invoices.label': 'Invoices',
	'layout.header.navigation.item.companies.label': 'Companies',
	'layout.header.navigation.item.settings.label': 'Settings',
	'layout.header.navigation.item.logout.label': 'Log out',
	'layout.header.navigation.item.documentation.label': 'Documentation',
	'layout.header.navigation.item.banks.label': 'Banks',
	'layout.header.navigation.item.conditions.label': 'Conditions',
	'layout.footer.copyright': '© {{today|date:"yyyy"}}',

	'admin.company.short.details.orgnumber': `Org number: {{ company.orgnumber | empty:" - " }}`,
	'admin.company.short.details.users': `Users: {{ company.users.length }}`,
	'admin.company.short.details.createdAt': `Created at: {{ company.createdAt | date:"yyyy-MM-dd" }}`,
	'admin.company.short.details.bank': `Bank: {{ service.data.bank | empty:" - " | capitalize }}`,
	'admin.company.short.details.integrations': `Integrations: {{ company.integrations.length }}`,
	'admin.company.short.details.service': `Service: {{ service.data.service | empty:" - " | capitalize }}`,

	'admin.list.company.details.item.name.label': 'Company name',
	'admin.list.company.details.item.name.value': '{{ company.name }}',
	'admin.list.company.details.item.users.label': 'Users',
	'admin.list.company.details.item.users.value': '{{ company.users.length }}',
	'admin.list.company.details.item.number.label': 'Organization number',
	'admin.list.company.details.item.number.value': '{{ company.orgnumber }}',
	'admin.list.company.details.item.software.label': 'Business software',
	'admin.list.company.details.item.software.value': '{{ company.erp }}',
	'admin.list.company.details.item.invoices.count.label': 'Sent invoices',
	'admin.list.company.details.item.invoices.count.value': `{{ company.invoices.count }}`,
	'admin.list.company.details.item.invoices.total.label': `Sent invoices amount`,
	'admin.list.company.details.item.invoices.total.value': `{{ company.invoices.amount | number:false:false:true }}`,

	'admin.list.company.details.item.bank.label': `Bank`,
	'admin.list.company.details.item.bank.value': `{{ service.data.bank | capitalize }}`,
	'admin.list.company.details.item.agreements.label': `Agreements`,
	'admin.list.company.details.item.country.label': `Country`,
	'admin.list.company.details.item.country.value': `{{ company.settings.country | capitalize }}`,
	'admin.list.company.details.item.email.label': `Email`,
	'admin.list.company.details.item.email.value': `{{ company.contact.email }}`,
	'admin.list.company.details.item.phone.label': `Phone`,
	'admin.list.company.details.item.phone.value': `{{ company.contact.phone }}`,
	'admin.list.company.details.item.address.label': `Address`,
	'admin.list.company.details.item.address.value': `{{ company.contact.address }}`,
	'admin.list.company.details.item.service.type.label': `Service`,
	'admin.list.company.details.item.service.type.value': `{{ service.data.service | capitalize }}`,

	'admin.list.company.details.item.service.lending.label': `Lending`,
	'admin.list.company.details.item.service.lending.value': `{{ service.data.lending | capitalize }}`,
	'admin.list.company.details.item.service.lending.receivable.value': `Fakturabelåning`,
	'admin.list.company.details.item.service.lending.not-receivable.value': `Inte fakturabelåning`,

	'admin.list.company.details.item.service.version.label': `API version`,
	'admin.list.company.details.item.service.version.value': `Version {{ service.data.version }}`,

	'admin.list.company.details.item.createdAt.label': 'Registered at',
	'admin.list.company.details.item.createdAt.value': `{{ company.createdAt | date:"yyyy-MM-dd @ HH:mm" }}`,
	'admin.list.company.details.item.lastLoggedIn.label': 'Last time logged in',
	'admin.list.company.details.item.lastLoggedIn.value': `{{ company.lastLoggedIn | date:"yyyy-MM-dd @ HH:mm" }}`,
	'admin.list.company.details.item.state.label': 'Status',

	'admin.list.company.details.item.delivery-method.label': 'Delivery method',

	'admin.list.user.details.item.fullName.label': 'Name',
	'admin.list.user.details.item.fullName.value': '{{ user.fullName }}',
	'admin.list.user.details.item.firstname.label': 'First name',
	'admin.list.user.details.item.firstname.value': '{{ user.firstName }}',
	'admin.list.user.details.item.lastname.label': 'Last name',
	'admin.list.user.details.item.lastname.value': '{{ user.lastName }}',
	'admin.list.user.details.item.company.label': 'Company',
	'admin.list.user.details.item.email.label': 'Email',
	'admin.list.user.details.item.email.value': '{{ user.username }}',
	'admin.list.user.details.item.status.label': 'Status',
	'admin.list.user.details.item.createdAt.label': 'Created at',
	'admin.list.user.details.item.createdAt.value': '{{ user.createdAt | date:"yyyy-MM-dd @ HH:mm" }}',
	'admin.list.user.details.item.invitedAt.label': 'Invited at',
	'admin.list.user.details.item.invitedAt.value': '{{ user.invitedAt | date:"yyyy-MM-dd @ HH:mm" }}',
	'admin.list.user.details.item.lastLoggedIn.label': 'Last time logged in',
	'admin.list.user.details.item.lastLoggedIn.value': `{{ user.lastLoggedIn | date:"yyyy-MM-dd @ HH:mm" }}`,

	'admin.user.status.label': '{{ status }}',
	'admin.status.INVITED.label': 'Waiting for user to login',
	'admin.status.PENDING.label': 'Waiting for integration',
	'admin.user.status.PENDING.label': 'Waiting for invitation',
	'admin.status.READY.label': 'Ready to be approved',
	'admin.user.status.ACTIVE.label': 'Active',
	'admin.status.VERIFICATION.label': 'Quality Assurance & Verification',
	'admin.status.AWAITING_LAYOUT.label': 'Waiting for layout mapping',
	'admin.status.AWAITING_INVOICE.label': 'Waiting for invoice',
	'admin.status.APPROVED.label': 'Approved',
	'admin.company.status.PENDING.label': 'Waiting for integration',
	'admin.company.status.DELIVERY-DISABLED.label': 'Disabled',
	'admin.company.status.DELIVERY-ENABLED.label': 'Enabled',
	'admin.list.user.status.last.login': '{{ data | date:"yyyy-MM-dd @ HH:mm" }}',
	'admin.user.status.2FA-INACTIVE.label': 'User does not use 2FA',
	'admin.user.status.2FA-PENDING.label': 'User needs to setup 2FA application',
	'admin.user.status.2FA-ACTIVE.label': 'User has active 2FA',

	'admin.admin-user.role.ADMIN': 'Super Admin',
	'admin.admin-user.role.PARTNER': 'Administratör',
	'admin.admin-user.role.MANAGER': 'Beställare',
	'admin.admin-user.role.EDITOR': 'Redigerare',
	'admin.admin-user.role.VIEWER': 'Supporthandlägare',

	'admin.admin-user.role.user.COMPANYADMIN': 'Company Admin',
	'admin.admin-user.role.user.USER': 'User',
	'admin.admin-user.role.user.VIEW': 'Viewer',
	'admin.admin-user.role.user.LIMITED_VIEW': 'Limited view',

	'admin.page.companies.quick-filters.status.all.label': 'All ({{ count }})',
	'admin.page.companies.quick-filters.status.active.label': 'Active ({{ count }})',
	'admin.page.companies.quick-filters.status.inactive.label': 'Not active ({{ count }})',

	'admin.page.companies.quick-filters.state.invited.label': 'Invited ({{ count }})',
	'admin.page.companies.quick-filters.state.pending.label': 'Pending ({{ count }})',
	'admin.page.companies.quick-filters.state.ready.label': 'Ready ({{ count }})',
	'admin.page.companies.quick-filters.state.verification.label': 'Quality Assurance & Verification ({{ count }})',
	'admin.page.companies.quick-filters.state.awaiting_invoice.label': 'Awaiting invoice ({{ count }})',
	'admin.page.companies.quick-filters.state.awaiting_layout.label': 'Awaiting layout ({{ count }})',
	'admin.page.companies.quick-filters.state.approved.label': 'Approved ({{ count }})',

	'admin.page.companies.quick-filters.bank.label': 'Bank: {{ type | capitalize }} ({{ count }})',

	'admin.page.invoices.table.empty': null,
	'admin.page.invoices.table.empty.type-title': 'No Invoices Found',
	'admin.page.invoices.table.empty.type-title.initial': 'All clear!',
	'admin.page.invoices.table.empty.type-content': 'Try adjusting your filters or use the search bar to find specific invoices.',
	'admin.page.invoices.table.empty.type-content.initial': 'No invoices need your attention right now. Try adjusting your filters or adding new invoices to see more.',

	'invoice.batch.form.field.from.content': '{{SELL.numbers | first | empty:"0"}}',
	'invoice.batch.form.field.to.content': '{{SELL.numbers | last | empty:"0"}}',
	'invoice.batch.form.field.totalPledge.content': '{{SELL.total | toFixed}}',
	'invoice.batch.form.field.advance.content': '{{NOSELL_1.numbers}}',
	'invoice.batch.form.field.relatedCompany.content': '{{NOSELL_2.numbers}}',
	'invoice.batch.form.field.counterclaim.content': '{{NOSELL_3.numbers}}',
	'invoice.batch.form.field.foreignInvoice.content': '{{NOSELL_4.numbers}}',
	'invoice.batch.form.field.longerPaymentTerms.content': '{{NOSELL_5.numbers}}',
	'invoice.batch.form.field.otherReason.content': '{{NOSELL_6.numbers}}',
	'invoice.batch.form.field.totalNotPledge.content': '{{nosell | toFixed}}',
	'invoice.batch.form.field.creditInvoices.content': '{{CREDIT.numbers}}',
	'invoice.batch.form.field.totalCreditInvoices.content': '{{credit | toFixed}}',
	'invoice.batch.form.field.total.content': '{{total | toFixed}}',
	'invoice.batch.form.field.excludeAccountsReceivable.content': '{{total | toFixed}}',

	'onboarding.vprint.step.connecting.link': 'https://{{integration._id}}@dev-printer.asteria.ai',
	'onboarding.vprint.step.connecting.link.uri': 'https://{{integration._id}}:{{integration._id}}@dev-printer.asteria.ai',

	'snackbar.admin.user.admin.create.error.title': `Something went wrong...`,
	'snackbar.admin.user.admin.create.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.user.admin.create.success.title': 'Success',
	'snackbar.admin.user.admin.create.success.content': null,
	'snackbar.admin.user.admin.update.error.title': `Something went wrong...`,
	'snackbar.admin.user.admin.update.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.user.admin.update.success.title': 'Success',
	'snackbar.admin.user.admin.update.success.content': null,
	'snackbar.admin.user.admin.remove.error.title': `Something went wrong...`,
	'snackbar.admin.user.admin.remove.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.user.admin.remove.success.title': 'Success',
	'snackbar.admin.user.admin.remove.success.content': null,

	'snackbar.admin.company.create.error.title': `Something went wrong...`,
	'snackbar.admin.company.create.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.company.create.success.title': 'Success',
	'snackbar.admin.company.create.success.content': null,
	'snackbar.admin.company.update.error.title': `Something went wrong...`,
	'snackbar.admin.company.update.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.company.update.success.title': 'Success',
	'snackbar.admin.company.update.success.content': null,
	'snackbar.admin.company.service.create.error.title': `Something went wrong...`,
	'snackbar.admin.company.service.create.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.company.service.update.error.title': `Something went wrong...`,
	'snackbar.admin.company.service.update.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.company.remove.error.title': `Something went wrong...`,
	'snackbar.admin.company.remove.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.company.remove.success.title': 'Success',
	'snackbar.admin.company.remove.success.content': null,

	'snackbar.admin.user.create.error.title': `Something went wrong...`,
	'snackbar.admin.user.create.error.content': `We can't create a user with e-mail "{{ data.input.username }}". Details: {{ data.error.message }}`,
	'snackbar.admin.user.create.success.title': 'User successfully created',
	'snackbar.admin.user.create.success.content': `User with username "{{ data.input.username }}" successfully created`,
	'snackbar.admin.user.update.error.title': `Something went wrong...`,
	'snackbar.admin.user.update.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.user.update.success.title': 'Success',
	'snackbar.admin.user.update.success.content': null,
	'snackbar.admin.user.remove.error.title': `Something went wrong...`,
	'snackbar.admin.user.remove.error.content': `Details: {{ data.error.message }}`,
	'snackbar.admin.user.remove.success.title': 'Success',
	'snackbar.admin.user.remove.success.content': null,
	'snackbar.admin.user.invite.success.title': 'Success',
	'snackbar.admin.user.invite.success.content': null,
	'snackbar.admin.user.invite.error.title': `Something went wrong...`,
	'snackbar.admin.user.invite.error.content': `Details: {{ data.error.message }}`,

	'snackbar.admin.client.update.title': 'Client successfully updated',
	'snackbar.admin.client.update.content': `Client with ID '{{ data.variables.input._id }}' successfully updated`,

	'snackbar.admin.invoice.update.title': 'Invoice successfully updated',
	'snackbar.admin.invoice.update.content': `Invoice with ID '{{ data.variables.input.id }}' successfully updated`,

	'snackbar.admin.invoice.remove.title': 'Invoice successfully removed',
	'snackbar.admin.invoice.remove.content': `Invoice with ID '{{ data.form.id }}' successfully removed`,

	'snackbar.admin.invoice.rows.update.title': 'Invoice rows successfully updated',
	'snackbar.admin.invoice.rows.update.content': `Rows for the invoice '{{ data.variables.form.id }}' successfully updated`,

	'admin.modal.invoice.address.input.label.type-name': 'Name',
	'admin.modal.invoice.address.input.placeholder.type-name': 'eg. John Doe',
	'admin.modal.invoice.address.input.label.type-email': 'E-mail',
	'admin.modal.invoice.address.input.placeholder.type-email': 'eg. john@example.com',
	'admin.modal.invoice.address.input.label.type-street': 'Street',
	'admin.modal.invoice.address.input.placeholder.type-street': null,
	'admin.modal.invoice.address.input.label.type-street2': 'C/O',
	'admin.modal.invoice.address.input.placeholder.type-street2': null,
	'admin.modal.invoice.address.input.label.type-city': 'City',
	'admin.modal.invoice.address.input.placeholder.type-city': null,
	'admin.modal.invoice.address.input.label.type-zipcode': 'ZipCode',
	'admin.modal.invoice.address.input.placeholder.type-zipcode': null,
	'admin.modal.invoice.address.input.label.type-country': 'Country',
	'admin.modal.invoice.address.input.placeholder.type-country': null,
	'admin.modal.invoice.address.input.label.type-phone': 'Phone',
	'admin.modal.invoice.address.input.placeholder.type-phone': null,
	'admin.modal.invoice.address.input.label.type-mobile': 'Mobile',
	'admin.modal.invoice.address.input.placeholder.type-mobile': null,

	'admin.invoice.details.field.label.type-organization-number': 'Organization number',
	'admin.invoice.details.field.content.type-organization-number': '{{ value }}',
	'admin.invoice.details.field.content.type-organization-number.empty': '-',
	'admin.invoice.details.field.label.type-vat-number': 'VAT number',
	'admin.invoice.details.field.content.type-vat-number': '{{ value }}',
	'admin.invoice.details.field.content.type-vat-number.empty': '-',
	'admin.invoice.details.field.label.type-language': 'Language',
	'admin.invoice.details.field.content.type-language': '{{ language }}',
	'admin.invoice.details.field.content.type-language.empty': '-',
	'admin.invoice.details.field.label.type-method': 'Method',
	'admin.invoice.details.field.content.type-method': '{{ value }}',
	'admin.invoice.details.field.content.type-method.empty': '-',
	'admin.invoice.details.field.label.type-terms': 'Terms',
	'admin.invoice.details.field.content.type-terms': '{{ value }}',
	'admin.invoice.details.field.content.type-terms.empty': '-',
	'admin.invoice.details.field.label.type-payment-terms': 'Payment terms',
	'admin.invoice.details.field.content.type-payment-terms': '{{ value }}',
	'admin.invoice.details.field.content.type-payment-terms.empty': '-',
	'admin.invoice.details.field.label.type-order-id': 'Order ID',
	'admin.invoice.details.field.content.type-order-id': '{{ value }}',
	'admin.invoice.details.field.content.type-order-id.empty': '-',
	'admin.invoice.details.field.label.type-buyer-order-number': 'Buyer Order No.',
	'admin.invoice.details.field.content.type-buyer-order-number': '{{ value }}',
	'admin.invoice.details.field.content.type-buyer-order-number.empty': '-',
	'admin.invoice.details.field.label.type-buyer-reference': 'Buyer Reference',
	'admin.invoice.details.field.content.type-buyer-reference': '{{{ value }}}',
	'admin.invoice.details.field.content.type-buyer-reference.empty': '-',
	'admin.invoice.details.field.label.type-seller-reference': 'Seller Reference',
	'admin.invoice.details.field.content.type-seller-reference': '{{{ value }}}',
	'admin.invoice.details.field.content.type-seller-reference.empty': '-',
	'admin.invoice.details.field.label.type-delivery-address': 'Delivery Address',
	'admin.invoice.details.field.content.type-delivery-address': '{{{ value.street }}}, {{{ value.city }}}, {{{ value.country }}}',
	'admin.invoice.details.field.content.type-delivery-address.empty': '-',

	'admin.page.invoices.table.header.label.section-rows': null,
	'admin.page.invoices.table.header.label.section-rows.type-productId': 'Product ID',
	'admin.page.invoices.table.header.label.section-rows.type-description': 'Description',
	'admin.page.invoices.table.header.label.section-rows.type-qty': 'QTY',
	'admin.page.invoices.table.header.label.section-rows.type-qtyUnit': 'Unit',
	'admin.page.invoices.table.header.label.section-rows.type-price': 'Price',
	'admin.page.invoices.table.header.label.section-rows.type-discount': 'Discount',
	'admin.page.invoices.table.header.label.section-rows.type-discountType': 'Discount type',
	'admin.page.invoices.table.header.label.section-rows.type-tax': 'VAT',
	'admin.page.invoices.table.header.label.section-rows.type-total': 'Total',

	'partner.admin.title': 'Loading...',
	'app.loading.title': 'Loading...',

	'card.content.text': null,
	'card.content.text.variant-incoming': {
		type: 'contenter',
		content: [
			{
				as: 'Group',
				props: { children: Content.Source({ variant: 'incoming' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Target({ variant: 'incoming' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Accuracy({ variant: 'incoming' }) },
			},
		],
	},
	'card.content.text.variant-outgoing': {
		type: 'contenter',
		content: [
			{
				as: 'Group',
				props: { children: Content.Source({ variant: 'outgoing' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Target({ variant: 'outgoing' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Accuracy({ variant: 'outgoing' }) },
			},
		],
	},
	'card.content.text.variant-account': {
		type: 'contenter',
		content: [
			{
				as: 'Group',
				props: { children: Content.Source({ variant: 'account' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Target({ variant: 'account' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Accuracy({ variant: 'account' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Credit({ variant: 'account' }) },
			},
		],
	},
	'card.content.text.variant-profit': {
		type: 'contenter',
		content: [
			{
				as: 'Group',
				props: { children: Content.Source({ variant: 'profit' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Target({ variant: 'profit' }) },
			},
			{
				as: 'Group',
				props: { children: Content.Diff({ variant: 'profit' }) },
			},
		],
	},

	'card.content.title.onboarding-loading.status-created': 'Created {{ integrationName }}',
	'card.content.title.onboarding-loading.status-loading': 'Loading {{ integrationName }}',
	'card.content.title.onboarding-loading.status-connected': 'Connected {{ integrationName }}',

	'card.content.extra.tooltip.variant-forecast-status.section-overview.section-type-deposit': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-overview.section-type-deposit.actual`,
								align: 'center',
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-overview.section-type-deposit.forecasted`,
								align: 'center',
							},
						},
					],
				},
			},
		],
	},

	'card.content.extra.tooltip.variant-forecast-status.section-details.section-type-deposit.value-type-performance': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-deposit.value-type-performance.amount`,
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-deposit.value-type-performance.percentage`,
							},
						},
					],
				},
			},
		],
	},

	'card.content.extra.tooltip.variant-forecast-status.section-details.section-type-deposit.value-type-total': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-deposit.value-type-total.actual`,
								align: 'center',
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-deposit.value-type-total.forecasted`,
								align: 'center',
							},
						},
					],
				},
			},
		],
	},

	'card.content.extra.tooltip.variant-forecast-status.section-overview.section-type-withdraw': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-overview.section-type-withdraw.actual`,
								align: 'center',
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-overview.section-type-withdraw.forecasted`,
								align: 'center',
							},
						},
					],
				},
			},
		],
	},

	'card.content.extra.tooltip.variant-forecast-status.section-details.section-type-withdraw.value-type-performance': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-withdraw.value-type-performance.amount`,
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-withdraw.value-type-performance.percentage`,
							},
						},
					],
				},
			},
		],
	},

	'card.content.extra.tooltip.variant-forecast-status.section-details.section-type-withdraw.value-type-total': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-withdraw.value-type-total.target`,
								align: 'center',
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-details.section-type-withdraw.value-type-total.source`,
								align: 'center',
							},
						},
					],
				},
			},
		],
	},

	'card.content.extra.tooltip.variant-forecast-status.section-help': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-help.incoming`,
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-status.section-help.outgoing`,
							},
						},
					],
				},
			},
		],
	},

	'time.selector.popup.custom.label.date-range-custom': '{{ startDate | date:"yyyy-MM-dd" }} - {{ endDate | date:"yyyy-MM-dd" }}',
	'time.selector.input.value.date-range-default': '{{ date | date:"MMMM, yyyy" | capitalize }}',
	'time.selector.input.value.date-range-custom': '{{ date.startDate | date:"MMMM, yyyy" | capitalize }} - {{ date.endDate | date:"MMMM, yyyy" | capitalize }}',

	'card.content.extra.tooltip.variant-forecast-actions.section-help': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-actions.section-help.content.0`,
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-actions.section-help.content.1`,
							},
						},
					],
				},
			},
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-actions.section-help.content.2`,
							},
						},
						{
							as: 'Text',
							props: {
								children: `card.content.extra.tooltip.variant-forecast-actions.section-help.content.3`,
							},
						},
					],
				},
			},
		],
	},
	'card.content.extra.content.variant-forecast-actions.section-help.section-type-created': `Forecast created/updated: {{ scenario.updatedAt | date:"MMMM d, yyyy" | capitalize }}`,
	'card.content.extra.content.variant-forecast-actions.section-help.section-type-updated': `Last edited by: {{ scenario.updatedByEntity.fullName }} ({{ scenario.updatedByEntity.username }})`,
	'card.content.extra.content.variant-forecast-actions.section-help.section-type-updated.entity-integration': `Last edited by: {{ integration | capitalize }}`,

	'clients.list.quick.actions.date': null,
	'clients.list.quick.actions.date.variant-start': '{{ date.startDate | date:"yyyy-MM-dd" }}',
	'clients.list.quick.actions.date.variant-full': '{{ date.startDate | date:"yyyy-MM-dd" }} - {{ date.endDate | date:"yyyy-MM-dd" }}',

	'auth.password.reset.password2.placeholder': '',
	'auth.password.reset.password2.label': 'Repeat new password',
	'auth.password.reset.password1.placeholder': '',
	'auth.password.reset.success.action.submit': 'Log in',
	'auth.welcome.title': 'Choose password',
	'auth.welcome.success.alert.title': 'Thanks',
	'auth.welcome.success.alert.content': 'Thank you! You may now proceed to the Invoice Portal',
	'auth.welcome.auth.password1.label': 'Password',
	'auth.welcome.auth.password2.label': 'Confirm password',
	'auth.welcome.user.firstName.label': 'First name',
	'auth.welcome.user.lastName.label': 'Last name',
	'auth.welcome.company.name.label': 'Company name',
	'auth.welcome.company.orgnumber.label': 'Organization number',
	'auth.welcome.success.action.submit': 'Start',
	'auth.welcome.action.submit': 'Continue',
	'snackbar.auth.2fa.codes.copy.title': 'Success',
	'snackbar.auth.2fa.codes.copy.content': '{{ data.codes.length }} recovery codes successfully copied',
	'snackbar.auth.2fa.codes.refresh.title': 'Success',
	'snackbar.auth.2fa.codes.refresh.content': `All of your recovery codes have been successfully updated. Previous codes don't work anymore. Please copy and save new recovery codes.`,
	'snackbar.auth.2fa.secret.copy.content': 'Secret code has been copied to your clipboard',
	'snackbar.auth.2fa.secret.copy.title': 'Clipboard',
	'settings.user.2fa.action.view': 'View backup codes',
	'settings.user.2fa.title': 'Two-factor authentication',
	'settings.user.2fa.action.activate': 'Enable two-factor authentication',
	'settings.user.2fa.action.deactivate': 'Disable two-factor authentication',
	'settings.user.2fa.active.content': 'Two-factor authentication is enabled.',
	'settings.user.2fa.inactive.content': 'Two-factor authentication is not enabled.',

	'auth.code.title': 'Two-factor authentication',
	'auth.code.placeholder': 'XXXXXX',

	'auth.setup.2fa.title': 'Setup 2FA',

	'auth.welcome.auth.password1.placeholder': 'XXXXX',
	'auth.welcome.auth.password2.placeholder': 'XXXXX',
	'auth.welcome.user.firstName.placeholder': 'John',
	'auth.welcome.user.lastName.placeholder': 'Doe',
	'auth.welcome.company.name.placeholder': 'John Doe AB',
	'auth.welcome.company.orgnumber.placeholder': 'XXXXXX-XXXX',

	'auth.2fa.qr.image': `url('{{{ base64 }}}')`,

	'card.trends.tooltip': null,
	'card.trends.tooltip.variant-invoices': null,
	'card.trends.tooltip.variant-invoices.trends-up': `Trends UP`,
	'card.trends.tooltip.variant-invoices.trends-down': `Trends DOWN`,

	'card.title.variant-invoices': 'Invoices',

	'card.content.client_type.switch.customers.variant-invoices': 'Customers ({{ data.customers.summary.count }})',
	'card.content.client_type.switch.suppliers.variant-invoices': 'Suppliers ({{ data.suppliers.summary.count }})',

	'card.content.summary.count.variant-invoices': 'Total ({{ data.summary.count }})',
	'card.content.summary.count.variant-invoices.client_type-customers': 'Total ({{ data.customers.summary.count }})',
	'card.content.summary.count.variant-invoices.client_type-suppliers': 'Total ({{ data.suppliers.summary.count }})',

	'card.content.summary.total.variant-invoices': '{{ data.summary.total | number }} {{globals.company.settings.currency}}',
	'card.content.summary.total.variant-invoices.client_type-customers': '{{ data.customers.summary.total | number }} {{globals.company.settings.currency}}',
	'card.content.summary.total.variant-invoices.client_type-suppliers': '{{ data.suppliers.summary.total | number: true }} {{globals.company.settings.currency}}',

	'card.content.tag.label.variant-invoices.tag-paid': 'Paid ({{count}})',

	'card.content.tag.label.variant-invoices.tag-unpaid': 'Unpaid ({{count}})',

	'card.content.tag.label.variant-invoices.tag-overdue': 'Overdue ({{count}})',

	'card.content.tag.label.variant-invoices.tag-unsent': 'Unsent ({{count}})',

	'card.content.tag.label.variant-invoices.tag-credit': 'Credited ({{count}})',

	'card.content.tag.value.variant-invoices': '{{total | number}}',
	'card.content.tag.value.variant-invoices.client_type-customers': '{{total | number}}',
	'card.content.tag.value.variant-invoices.client_type-suppliers': '{{total | number: true}}',

	'card.content.summary.total.tooltip.label.variant-invoices.client_type-customers': 'Customers ({{ data.customers.summary.count }})',
	'card.content.summary.total.tooltip.total.variant-invoices.client_type-customers': '{{ data.customers.summary.total | number }} {{globals.company.settings.currency}}',
	'card.content.summary.total.tooltip.label.variant-invoices.client_type-suppliers': 'Suppliers ({{ data.suppliers.summary.count }})',
	'card.content.summary.total.tooltip.total.variant-invoices.client_type-suppliers': '{{ data.suppliers.summary.total | number: true }} {{globals.company.settings.currency}}',

	'integrations.add.actionbar.title.type-none': 'Gain a holistic view of your finances',
	'integrations.add.actionbar.title.type-erp': 'Enhance efficiency and uncover potential savings.',
	'integrations.add.actionbar.title.type-bank': 'Deepen your insight into cash flow trends',

	'integrations.add.actionbar.content.type-none': 'Gain a holistic view of your finances by connecting your Bank and Business Software — from real-time transactions and detailed invoice tracking to comprehensive cash flow analyses and precise forecasting.',
	'integrations.add.actionbar.content.type-erp': 'Connect your Business Software to delve into detailed invoice analytics and vendor management. Access extensive reports on your operational finances to enhance efficiency and uncover potential savings.',
	'integrations.add.actionbar.content.type-bank': 'Connect your Bank to deepen your insight into cash flow trends. See how your financial decisions affect your bottom line in real-time and improve your forecasting accuracy.',

	'integrations.add.actionbar.action.skip': 'Skip',
	'integrations.add.actionbar.action.add.type-none': 'Connect Integration',
	'integrations.add.actionbar.action.add.type-erp': 'Connect Accounting Software',
	'integrations.add.actionbar.action.add.type-bank': 'Connect Bank',

	'modal.card.contenter.variant-under-construction.version-2': {
		type: 'contenter',
		content: [
			{
				as: 'TextGroup',
				props: {
					children: [
						{
							as: 'Text',
							props: {
								children: `modal.card.extra.variant-under-construction.text.0`,
							},
						},
						{
							as: 'ListGroup',
							props: {
								children: [
									{
										as: 'ListHeader',
										props: {
											children: [
												{
													as: 'ListCell',
													props: {
														children: [
															{
																as: 'Text',
																props: { weight: 'medium', children: `modal.card.extra.variant-under-construction.list.title` },
															},
														],
													},
												},
											],
										},
									},
									{
										as: 'ListItem',
										props: {
											children: [
												{
													as: 'ListCell',
													props: {
														children: [
															{
																as: 'Text',
																props: { children: `modal.card.extra.variant-under-construction.list.item.0` },
															},
														],
													},
												},
											],
										},
									},
									{
										as: 'ListItem',
										props: {
											children: [
												{
													as: 'ListCell',
													props: {
														children: [
															{
																as: 'Text',
																props: { children: `modal.card.extra.variant-under-construction.list.item.1` },
															},
														],
													},
												},
											],
										},
									},
									{
										as: 'ListItem',
										props: {
											children: [
												{
													as: 'ListCell',
													props: {
														children: [
															{
																as: 'Text',
																props: { children: `modal.card.extra.variant-under-construction.list.item.2` },
															},
														],
													},
												},
											],
										},
									},
								],
							},
						},
						{
							as: 'Text',
							props: {
								children: `modal.card.extra.variant-under-construction.text.1`,
							},
						},
					],
				},
			},
		],
	},
};
