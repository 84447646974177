import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../constants';

const initialState = {
	translations: {},
	language: null,
	loaded: false,

	loading: true,
};

export const languageSlice = createSlice({
	name: 'language',
	initialState: initialState,
	reducers: {
		setTranslations: (state, action) => {
			state.translations = action.payload;
		},
		setLanguage: (state, action) => {
			state.language = action.payload;
		},
		setTranslationsLoaded: (state, action) => {
			state.loaded = action.payload;
		},

		start: (state) => {
			state.loading = true;
		},
		stop: (state) => {
			state.loading = false;
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const {
	setTranslations,
	setLanguage,
	setTranslationsLoaded,
	start,
	stop,
} = languageSlice.actions;

export default languageSlice.reducer;
