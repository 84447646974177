import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { merge } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Form, { Wrapper as FormWrapper, Input } from '@asteria/component-form';

import { useTranslationFn } from '@asteria/language/hooks';
import { cn } from '@asteria/utils-funcs/classes';

import { AuthContext } from '../context';
import Languages from '../languages';
import { useModalActions } from '../modal-collection';
import TermsAndConditionsModal from '../modals/terms';
import { defaultReducer, formatAuthResponse, formatError } from '../utils';

const STEPS = [null, 'COMPANY', 'SUCCESS'];

const StepUser = React.memo(function StepUser() {
	const { trigger, getValues, setError } = useFormContext();

	const translate = useTranslationFn();

	const onChange = React.useCallback(async () => {
		const [password1, password2] = getValues([
			'auth.password1',
			'auth.password2',
		]);

		if (password1 && password2) {
			await trigger(['auth.password1', 'auth.password2']);

			if (password1 !== password2) {
				setError('auth.password2', {
					type: 'error',
					message: translate([
						'signup.password.error.mismatch',
						'auth.signup.password.error.mismatch',
					]),
				});
			}
		}
	}, [getValues, setError, translate, trigger]);

	return [
		<Input
			key="password1"
			type="password"
			name="auth.password1"
			placeholder={translate('auth.welcome.auth.password1.placeholder')}
			label={translate('auth.welcome.auth.password1.label')}
			required
			onChange={onChange}
			autoFocus
		/>,
		<Input
			key="password2"
			type="password"
			name="auth.password2"
			placeholder={translate('auth.welcome.auth.password2.placeholder')}
			label={translate('auth.welcome.auth.password2.label')}
			required
			onChange={onChange}
		/>,
		<Input
			key="fullName"
			name="user.fullName"
			placeholder={translate('auth.welcome.user.fullName.placeholder')}
			label={translate('auth.welcome.user.fullName.label')}
		/>,
	];
});

const StepCompany = React.memo(function StepCompany() {
	const translate = useTranslationFn();

	return [
		<Input
			key="name"
			name="company.name"
			placeholder={translate('auth.welcome.company.name.placeholder')}
			label={translate('auth.welcome.company.name.label')}
			autoFocus
		/>,
		<Input
			key="orgnumber"
			name="company.orgnumber"
			placeholder={translate(
				'auth.welcome.company.orgnumber.placeholder',
			)}
			label={translate('auth.welcome.company.orgnumber.label')}
		/>,
	];
});

const StepSuccess = React.memo(function StepSuccess() {
	const translate = useTranslationFn();

	return (
		<Alert
			title={translate('auth.welcome.success.alert.title')}
			level="success"
		>
			<Text>{translate('auth.welcome.success.alert.content')}</Text>
		</Alert>
	);
});

const WelcomeContent = React.memo(function WelcomeContent({
	loading,
	error,
	back,
	step,
}) {
	const { onAction, logo } = React.useContext(AuthContext);

	const [search] = useSearchParams();
	const navigate = useNavigate();

	const translate = useTranslationFn();

	const { getValues } = useFormContext();
	const { errors } = useFormState({ name: ['password1', 'password2'] });

	const next = React.useCallback(() => {
		const accessToken = getValues('global.accessToken');

		onAction?.('auth:token', { accessToken: accessToken, valid: true });

		search.delete('code');
		search.delete('partnerId');

		return navigate(['/', search.toString()].filter(Boolean).join('?'));
	}, [getValues, navigate, onAction, search]);

	return (
		<Wrapper scroll>
			<Header logo={logo} onBack={back} verticalAlign="center">
				{translate(
					[
						'auth.title',
						`auth.welcome.title`,
						step === 'SUCCESS'
							? `auth.welcome.success.title`
							: null,
					].filter(Boolean),
				)}
			</Header>
			<Content scroll>
				{error ? (
					<Alert level="error">
						<Text>{error}</Text>
					</Alert>
				) : null}
				<FormWrapper scroll>
					<Content scroll>
						{step === null ? <StepUser /> : null}
						{step === 'COMPANY' ? <StepCompany /> : null}
						{step === 'SUCCESS' ? <StepSuccess /> : null}
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						label={translate([
							'action.back',
							`auth.welcome.action.back`,
						])}
						onClick={back}
						variant="secondary"
					/>
				</FooterSection>
				<FooterSection position="last">
					{step === 'SUCCESS' ? (
						<Button
							label={translate([
								'action.submit',
								`auth.welcome.action.submit`,
								`auth.welcome.success.action.submit`,
							])}
							onClick={next}
							variant="primary"
						/>
					) : (
						<Button
							type="submit"
							label={translate([
								'action.submit',
								`auth.welcome.action.submit`,
							])}
							variant="primary"
							loading={loading}
							disabled={loading || Object.keys(errors).length}
						/>
					)}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

WelcomeContent.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.string,
	step: PropTypes.string,
	back: PropTypes.func,
};

const Welcome = React.memo((props) => {
	const { className } = props;

	const {
		onAction,
		onSubmit,
		partnerId: defaultPartnerId,

		languages,
		onLanguageChange,
	} = React.useContext(AuthContext);

	const { open } = useModalActions();

	const [search] = useSearchParams();
	const navigate = useNavigate();
	const translate = useTranslationFn();

	const [{ loading, error, step, data }, update] = React.useReducer(
		defaultReducer,
		{ loading: false, error: null, step: STEPS[0], data: null },
	);

	const back = React.useCallback(() => {
		search.delete('code');
		search.delete('partnerId');

		return navigate(
			['/auth/login', search.toString()].filter(Boolean).join('?'),
		);
	}, [navigate, search]);

	const handleSubmit = React.useCallback(
		async (form) => {
			update({ type: 'START' });

			if (form.step === null) {
				let response;

				try {
					response = await onSubmit?.('auth:welcome', {
						...form,
						step: STEPS.indexOf(form.step),
						user: Object.fromEntries(
							Object.entries({
								firstName: form?.user?.firstName,
								lastName: form?.user?.lastName,
								fullName: form?.user?.fullName,
							}).filter(([, value]) => value),
						),
					});
				} catch (err) {
					return update({
						type: 'FAILURE',
						payload: formatError({ error: err, form }),
					});
				}

				const { accessToken, refreshToken } =
					formatAuthResponse(response);

				if (accessToken) {
					onAction?.('auth:token', {
						accessToken,
						refreshToken,
						wait: true,
					});
				}

				if (response?.next) {
					return update({
						type: 'NEXT',
						payload: {
							step: 'COMPANY',
							data: {
								company: response?.company,
								global: { accessToken },
							},
						},
					});
				}

				return update({
					type: 'NEXT',
					payload: {
						step: 'SUCCESS',
						data: { global: { accessToken } },
					},
				});
			}

			if (form?.step === 'COMPANY') {
				try {
					await onSubmit?.('auth:welcome', {
						...form,
						step: STEPS.indexOf(form.step),
						company: Object.fromEntries(
							Object.entries({
								name: form?.company?.name,
								orgnumber: form?.company?.orgnumber,
							}).filter(([, value]) => value),
						),
					});
				} catch (err) {
					return update({
						type: 'FAILURE',
						payload: formatError({ error: err, form }),
					});
				}
			}

			const index = STEPS.indexOf(form?.step);

			return update({
				type: 'NEXT',
				payload: {
					step: STEPS[index + 1],
					data: { global: form?.global },
				},
			});
		},
		[onAction, onSubmit],
	);

	const partnerId = search.get('partnerId') ?? defaultPartnerId;
	const secret = search.get('code');

	const values = React.useMemo(
		() =>
			merge(
				{},
				{ auth: { partnerId: partnerId, secret: secret }, step: step },
				data ?? {},
			),
		[partnerId, secret, step, data],
	);

	const onTermsOpen = React.useCallback(() => {
		open(<TermsAndConditionsModal />);
	}, [open]);

	if (!partnerId || !secret) {
		return (
			<Navigate
				to={['/auth/login', search.toString()]
					.filter(Boolean)
					.join('?')}
				replace
			/>
		);
	}

	return (
		<div
			className={cn(
				'asteria-view__auth-content',
				'asteria--variant-welcome',
				className,
			)}
		>
			<Form onSubmit={handleSubmit} values={values}>
				<WelcomeContent
					loading={loading}
					error={error}
					step={step}
					back={back}
				/>
			</Form>

			{step === null ? (
				<Text
					size="sm"
					align="center"
					className="asteria-view__auth-terms"
				>
					<span>
						{translate([
							'signup.agree.terms',
							'auth.welcome.terms.label',
						])}
					</span>
					<Button
						size="sm"
						label={translate([
							'signup.terms.button',
							'auth.welcome.terms.action',
						])}
						type="button"
						variant="link"
						onClick={onTermsOpen}
					/>
				</Text>
			) : null}

			{languages ? <Languages onChange={onLanguageChange} /> : null}
		</div>
	);
});

Welcome.displayName = 'Welcome';

Welcome.propTypes = {
	className: PropTypes.string,
};

export default Welcome;
