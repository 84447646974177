import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import IntegrationCancelModal from '@asteria/component-integrations-v2/components/modal-cancel';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { useToggleState } from '@asteria/utils-funcs/state';

const Loading = React.memo(function Loading(props) {
	const { translationOptions, onAction, onSubmit, children, buttonProps } =
		props;

	const [abortModalState, abortModalActions] = useToggleState(false);

	const integration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				status: 'INITIATING',
			})[0],
		isEqual,
	);

	const id = integration?._id ?? integration?.id;

	const cancel = useMutation({
		mutationFn: async () => onSubmit('integrations:delete', { id: id }),
	});

	return (
		<>
			<IntegrationCancelModal
				open={abortModalState}
				id={id}
				onAction={onAction}
				execute={cancel.mutateAsync}
				onClose={abortModalActions.close}
			/>
			<div className="flex flex-col items-center justify-center">
				{typeof children === 'function'
					? children({ ...props, integration })
					: children}
				<Button
					variant="link"
					label={TranslationService.getV2(
						['card.content.loading.action'],
						{
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								action: 'cancel',
							},
						},
					)}
					onClick={abortModalActions.open}
					{...buttonProps}
				/>
			</div>
		</>
	);
});

Loading.propTypes = {
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	children: PropTypes.node,
	buttonProps: PropTypes.object,
};

export default Loading;
