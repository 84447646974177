import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Skeleton from '@asteria/component-core/skeleton';
import Table, {
	TableCell,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import { Text, TextGroup } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import { Content } from '../../basic';

import Chart from './chart';
import { SORTING } from './constants';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const CardContent = React.memo(function CardContent(props) {
	const {
		query,
		translationOptions,
		onAction,
		variant,
		loading,
		importing,
		state,
	} = props;

	const [sortBy, setSortBy] = React.useState(null);

	const [open, actions] = useToggleState();

	const setSorting = React.useCallback((key) => {
		setSortBy((current) => {
			if (!current || current?.key !== key) {
				return { key, direction: 'DESC' };
			}

			if (current?.direction === 'DESC') {
				return { ...current, direction: 'ASC' };
			}

			return null;
		});
	}, []);

	const breakdown = React.useMemo(() => {
		const values = [...(query?.data?.source?.breakdown ?? [])];

		if (sortBy?.key) {
			values.sort((a, b) => {
				if (sortBy?.key === SORTING.AMOUNT) {
					if (sortBy?.direction === 'DESC') {
						return b.paid.total - a.paid.total;
					}

					return a.paid.total - b.paid.total;
				}

				if (sortBy?.key === SORTING.NAME) {
					if (sortBy?.direction === 'DESC') {
						return b?.name?.localeCompare?.(a.name) ?? -1;
					}

					return a?.name?.localeCompare?.(b.name) ?? -1;
				}

				return 0;
			});
		}

		return values;
	}, [query?.data?.source?.breakdown, sortBy?.key, sortBy?.direction]);

	const onRedirect = React.useCallback(
		() => onAction?.('card:action', { type: 'taxes' }),
		[onAction],
	);

	const animated = loading || importing;
	const placeholder = animated || state?.promotion?.visible;

	return (
		<div
			className={cn(
				'flex flex-col gap-4',
				'asteria-component__card-content-inner',
			)}
		>
			<TextGroup>
				<Content.Title
					{...props}
					showLoading={!state?.promotion?.visible && animated}
					loading={!state?.promotion?.visible && animated}
				/>
				<Content.Text
					{...props}
					showLoading={!state?.promotion?.visible && animated}
					loading={!state?.promotion?.visible && animated}
				/>
			</TextGroup>
			<Chart {...props} />
			{placeholder ? (
				<div className="grid grid-cols-[20px_2fr_1fr] gap-x-4 gap-y-2">
					{Array.from({ length: 3 }).flatMap((_, i) =>
						Array.from({ length: 3 }).map((_, j) => (
							<Skeleton key={i + j} animated={animated} />
						)),
					)}
				</div>
			) : breakdown.length ? (
				<Button
					size="sm"
					variant="link"
					label={TranslationService.getV2(['card.content.action'], {
						...translationOptions,
						postfix: {
							'action-type': 'collapse',
							'action-variant': open ? 'open' : 'closed',
							...translationOptions?.postfix,
						},
					})}
					onClick={actions.toggle}
				/>
			) : variant !== 'past' ? (
				<div className="asteria-component__card-content-actions">
					<Button
						size="sm"
						variant="link"
						label={TranslationService.getV2(
							['card.content.action'],
							{
								...translationOptions,
								postfix: {
									'action-type': 'redirect',
									'action-variant': 'link',
									...translationOptions?.postfix,
								},
							},
						)}
						onClick={onRedirect}
					/>
				</div>
			) : null}
			{open ? (
				<Table>
					<TableHeader>
						<TableCell className="asteria--variant-name">
							<Button
								size="sm"
								variant="table-filters"
								active={sortBy?.key === SORTING.NAME}
								icon="chevron-down"
								iconActive={
									sortBy?.direction === 'DESC'
										? 'chevron-down'
										: 'chevron-up'
								}
								iconPosition="last"
								label={TranslationService.getV2(
									['card.content.table.header.label'],
									{
										...translationOptions,
										postfix: {
											key: 'name',
											...translationOptions?.postfix,
										},
									},
								)}
								onClick={() => setSorting(SORTING.NAME)}
							/>
						</TableCell>
						<TableCell className="asteria--variant-status">
							<Button
								size="sm"
								variant="table-filters"
								// active={sortBy?.key === SORTING.STATUS}
								// icon="chevron-down"
								// iconActive={
								// 	sortBy?.direction === 'DESC'
								// 		? 'chevron-down'
								// 		: 'chevron-up'
								// }
								// iconPosition="last"
								label={TranslationService.getV2(
									['card.content.table.header.label'],
									{
										...translationOptions,
										postfix: {
											key: 'status',
											...translationOptions?.postfix,
										},
									},
								)}
								// onClick={() => setSorting(SORTING.STATUS)}
							/>
						</TableCell>
						<TableCell className="asteria--variant-amount">
							<Button
								size="sm"
								variant="table-filters"
								active={sortBy?.key === SORTING.AMOUNT}
								icon="chevron-down"
								iconActive={
									sortBy?.direction === 'DESC'
										? 'chevron-down'
										: 'chevron-up'
								}
								iconPosition="last"
								label={TranslationService.getV2(
									['card.content.table.header.label'],
									{
										...translationOptions,
										postfix: {
											key: 'amount',
											...translationOptions?.postfix,
										},
									},
								)}
								onClick={() => setSorting(SORTING.AMOUNT)}
							/>
						</TableCell>
					</TableHeader>
					{breakdown.map((value, index) => (
						<TableRow key={value?.id}>
							<TableCell className="asteria--variant-name">
								<Prefix
									custom
									className={cn({
										'bg-taxes-1': index + 1 === 1,
										'bg-taxes-2': index + 1 === 2,
										'bg-taxes-3': index + 1 === 3,
										'bg-taxes-4': index + 1 === 4,
										'bg-taxes-5': index + 1 === 5,
										'bg-taxes-6': index + 1 === 6,
										'bg-taxes-7': index + 1 === 7,
										'bg-taxes-8': index + 1 === 8,
										'bg-taxes-9': index + 1 === 9,
										'bg-taxes-10': index + 1 === 10,
									})}
								/>
								<Translation
									translationKey="card.content.table.row.label"
									translationOptions={{
										...translationOptions,
										postfix: {
											key: 'name',
											...translationOptions?.postfix,
										},
										data: {
											...translationOptions?.data,
											current: value,
										},
									}}
									Component={Text}
									size="sm"
								/>
							</TableCell>
							<TableCell className="asteria--variant-status" />
							<TableCell className="asteria--variant-amount">
								<TextGroup className="flex flex-col items-end">
									<Translation
										translationKey="card.content.table.row.label"
										translationOptions={{
											...translationOptions,
											postfix: {
												key: 'amount',
												'sub-key': 'paid',
												...translationOptions?.postfix,
											},
											data: {
												...translationOptions?.data,
												current: value,
											},
										}}
										Component={Text}
										size="sm"
									/>
									<Translation
										translationKey="card.content.table.row.label"
										translationOptions={{
											...translationOptions,
											postfix: {
												key: 'amount',
												'sub-key': 'forecast',
												...translationOptions?.postfix,
											},
											data: {
												...translationOptions?.data,
												current: value,
											},
										}}
										Component={Text}
										className="text-content-text-muted"
										size="xs"
									/>
								</TextGroup>
							</TableCell>
						</TableRow>
					))}
				</Table>
			) : null}
			{open && breakdown.length && variant !== 'past' ? (
				<div className="asteria-component__card-content-actions">
					<Button
						size="sm"
						variant="primary"
						label={TranslationService.getV2(
							['card.content.action'],
							{
								...translationOptions,
								postfix: {
									'action-type': 'redirect',
									'action-variant': 'primary',
									...translationOptions?.postfix,
								},
							},
						)}
						onClick={onRedirect}
					/>
				</div>
			) : null}
			<Content.Loading {...props} />
			<Content.Onboarding {...props} />
		</div>
	);
});

CardContent.displayName = 'CardContent';

CardContent.propTypes = {
	className: PropTypes.string,

	query: PropTypes.object,
	translationOptions: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	variant: PropTypes.string,

	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default CardContent;
