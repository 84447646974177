import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useRequest } from './hooks';
import SalariesContentV1 from './v1/content';
import SalariesLoadingV1 from './v1/loading';
import SalariesPromotionV1 from './v1/promotion';
import SalariesPromotionV2 from './v2/promotion';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { required: ['erp', 'bank'], optional: 'both' },
	card: {
		content: {
			title: { trends: true },
			default: {
				view: {
					v1: (props) => <SalariesContentV1 {...props} />,
					v2: (props) => <SalariesPromotionV2 {...props} />,
				},
			},
			importing: {
				view: {
					v1: (props) => <SalariesLoadingV1 {...props} />,
					v2: (props) => <SalariesPromotionV2 {...props} />,
				},
			},
			fetching: {
				view: {
					v1: (props) => <SalariesLoadingV1 {...props} />,
					v2: (props) => <SalariesPromotionV2 {...props} />,
				},
			},
			promotion: {
				view: {
					v1: (props) => <SalariesPromotionV1 {...props} />,
					v2: (props) => <SalariesPromotionV2 {...props} />,
				},
			},
			loading: {
				v1: (props) => <SalariesLoadingV1 {...props} />,
				v2: (props) => <SalariesPromotionV2 {...props} />,
			},
		},
	},
};

/** @type { React.FC<Props> } */
const SalaryCard = React.memo(function SalaryCard(props) {
	const query = useRequest(props);

	return (
		<BasicCard {...props} type="salaries" query={query} config={CONFIG} />
	);
});

SalaryCard.displayName = 'SalaryCard';

SalaryCard.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SalaryCard;
