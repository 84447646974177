import { addMonths, endOfMonth, format } from 'date-fns';

export const initialState = {
	auth: {
		accessToken:
			localStorage.getItem('wingsToken') ??
			sessionStorage.getItem('wingsToken') ??
			null,
		loggedIn: null,
	},
	mode: null,
	register2FA: window.localStorage.getItem('Asteria2FA') === 'true',
	currentDate: format(addMonths(new Date(), -3), 'yyyy-MM-dd'),
	// currentDate: format(addMonths(new Date(), -4), 'yyyy-MM-dd'),
	timesize: 'month',
	selectedTypes: ['DEPOSIT', 'WITHDRAW'],
	settings: {
		currencies: [],
		clients: { sorting: 'THIS_YEAR', type: 'CUSTOMER' },
	},
	config: {},
	user: {
		settings: {
			layout: {
				graph: {
					layout: 'grouped',
				},
			},
		},
		feedback: {},
	},
	partner: null,
	company: null,
	statement: null,
	currencies: [
		{
			id: 'SEK',
			code: 'SEK',
		},
		{
			id: 'EUR',
			code: 'EUR',
		},

		{
			id: 'USD',
			code: 'USD',
		},
	],
	tags: [],
	clients: [],
	statuses: ['PAID', 'UNPAID', 'SIGNED', 'FORECAST', 'OVERDUE'],
	filters: [],
	selectedDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
	standalone: true,
};
