import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import Prefix, { MultiPrefix } from '@asteria/component-prefix';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import BreakdownGroup from './breakdown';
import BreakdownTooltip from './tooltip-breakdown';
import BreakdownDetailsTooltip from './tooltip-breakdown-details';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<{ loading: boolean, importing: boolean }> } */
const Placeholder = React.memo(function Placeholder({ loading, importing }) {
	const [percentage, setPercentage] = React.useState(0);

	React.useEffect(() => {
		if (!importing) {
			return;
		}

		setPercentage(0);

		const intervalRef = setInterval(() => {
			setPercentage((prev) => {
				if (prev >= 100) {
					return 0;
				}

				return prev + 20;
			});
		}, 500);

		return () => {
			clearInterval(intervalRef);
		};
	}, [importing]);

	return (
		<div
			className={cn(
				`h-4 w-full bg-skeleton-normal-background relative overflow-hidden`,
				{ 'animate-pulse': loading && !importing },
				{ 'bg-[#F4F3F0]': importing },
				'asteria-component__taxes-breakdown-item',
			)}
		>
			<div
				className="absolute top-0 bottom-0 bg-[#ECE8E2]"
				style={{ width: `${percentage}%` }}
			/>
		</div>
	);
});

Placeholder.propTypes = {
	loading: PropTypes.bool,
	importing: PropTypes.bool,
};

/** @type { React.FC<ChildrenProps> } */
const Chart = React.memo(function Chart(props) {
	const { query, translationOptions, loading, importing, state } = props;

	const paid = query?.data?.source?.statistics?.paid?.total ?? 0;
	const forecast = query?.data?.source?.statistics?.forecast?.total ?? 0;
	const total = paid + forecast;

	const breakdown = (query?.data?.source?.breakdown ?? []).slice(0, 10);

	const animated = loading || importing;
	const placeholder = animated || state?.promotion?.visible;

	return (
		<div className="flex flex-col gap-2">
			<div className={cn('flex', 'asteria-component__taxes-breakdown')}>
				{placeholder ? (
					<Placeholder loading={loading} importing={importing} />
				) : breakdown.length ? (
					breakdown.map((value, index) => {
						return (
							<BreakdownGroup
								key={value?._id ?? value?.id}
								total={total}
								value={value}
								index={index}
								translationOptions={translationOptions}
							/>
						);
					})
				) : (
					<TooltipWrapper
						tooltip={
							<BreakdownDetailsTooltip
								value={query?.data?.source?.statistics}
								color="bg-tags-taxes-tax-background"
								translationOptions={{
									...translationOptions,
									postfix: {
										'tooltip-variant': 'overall',
										...translationOptions?.postfix,
									},
								}}
							/>
						}
					>
						<div
							className={cn(
								'flex w-full',
								'asteria-component__taxes-breakdown-group',
							)}
						>
							{paid ? (
								<div
									style={{
										width: `${(paid / total) * 100}%`,
									}}
									className={cn(
										`h-4 bg-tags-taxes-tax-background`,
										'asteria-component__taxes-breakdown-item',
									)}
								/>
							) : null}
							{forecast ? (
								<div
									style={{
										width: `${(forecast / total) * 100}%`,
										backgroundImage: `var(--image)`,
									}}
									className={cn(
										`h-4 bg-tags-taxes-tax-background`,
										'asteria-component__taxes-breakdown-item',
										'asteria-color__forecast',
									)}
								/>
							) : null}
						</div>
					</TooltipWrapper>
				)}
			</div>
			{!placeholder && (paid || forecast) ? (
				<div className="flex gap-4">
					{paid ? (
						<TooltipWrapper
							tooltip={
								breakdown?.length ? (
									<BreakdownTooltip
										breakdown={breakdown}
										translationOptions={translationOptions}
									/>
								) : null
							}
						>
							<div className="flex items-center gap-1">
								{breakdown?.length ? (
									<MultiPrefix className="asteria-component__taxes-breakdown">
										{breakdown.map((value, index) => (
											<Prefix
												key={value?._id ?? value?.id}
												custom
												className={cn({
													'bg-taxes-1':
														index + 1 === 1,
													'bg-taxes-2':
														index + 1 === 2,
													'bg-taxes-3':
														index + 1 === 3,
													'bg-taxes-4':
														index + 1 === 4,
													'bg-taxes-5':
														index + 1 === 5,
													'bg-taxes-6':
														index + 1 === 6,
													'bg-taxes-7':
														index + 1 === 7,
													'bg-taxes-8':
														index + 1 === 8,
													'bg-taxes-9':
														index + 1 === 9,
													'bg-taxes-10':
														index + 1 === 10,
												})}
											/>
										))}
									</MultiPrefix>
								) : (
									<Prefix colors="taxes-tax" />
								)}
								<Translation
									translationKey="card.content.legend"
									translationOptions={{
										...translationOptions,
										postfix: {
											legend: 'taxes',
											'legend-variant': breakdown?.length
												? 'merged'
												: 'single',
											...translationOptions?.postfix,
										},
									}}
									Component={Text}
									size="sm"
								/>
							</div>
						</TooltipWrapper>
					) : null}
					{forecast ? (
						<div className="flex items-center gap-1">
							<Prefix colors="forecast" />
							<Translation
								translationKey="card.content.legend"
								translationOptions={{
									...translationOptions,
									postfix: {
										legend: 'forecast',
										'legend-variant': breakdown?.length
											? 'merged'
											: 'single',
										...translationOptions?.postfix,
									},
								}}
								Component={Text}
								size="sm"
							/>
						</div>
					) : null}
				</div>
			) : null}
		</div>
	);
});

Chart.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default Chart;
