import React from 'react';

import { useDispatch } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import * as LanguageStore from '@asteria/datalayer/stores/language';

import { TranslationService } from '@asteria/language';
import TranslationContext from '@asteria/language/context';

import * as TranslationsAPI from '../api/translations';

/** @type { React.FC<React.PropsWithChildren<{ partnerId: string, language: string, enabled: boolean }>> } */
const TranslationsWrapper = React.memo(function TranslationsWrapper(props) {
	const { children, partnerId, enabled, language: defaultLanguage } = props;

	const dispatch = useDispatch();

	const [language, setLanguage] = React.useState(defaultLanguage ?? 'sv');
	const [ready, setReady] = React.useState({});

	const query = useQuery({
		queryKey: ['widget', 'language', partnerId ?? 'NONE', language],
		queryFn: async ({ signal }) => {
			dispatch(LanguageStore.start());

			return TranslationsAPI.fetch({
				language: language,
				partnerId: partnerId,
				signal: signal,
			});
		},
		enabled: enabled && !!language,
		placeholderData: null,
	});

	React.useEffect(() => {
		if (query.data) {
			TranslationService.code = language;
			TranslationService.setTranslations(query.data);

			setReady((state) => ({ ...state, [language]: true }));

			dispatch(LanguageStore.stop());
		}
	}, [query.data]);

	const ctx = React.useMemo(
		() => ({
			language,
			onLanguageChange: setLanguage,

			loading: query.isFetching,
			ready: ready[language] ?? false,

			t: TranslationService.getV2,

			data: query.data,
		}),
		[language, query.data, query.isFetching, ready],
	);

	if (enabled && query?.data === null) {
		return null;
	}

	return (
		<TranslationContext.Provider value={ctx}>
			{children}
		</TranslationContext.Provider>
	);
});

TranslationsWrapper.propTypes = {
	children: PropTypes.node,
	partnerId: PropTypes.string,
	language: PropTypes.string,
	enabled: PropTypes.bool,
};

export default TranslationsWrapper;
