import React from 'react';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

export const LANGUAGES = [
	{
		code: 'sv',
		// eslint-disable-next-line spellcheck/spell-checker
		name: TranslationService.get('translation.swedish', 'Swedish'),
	},
	{
		code: 'en',
		// eslint-disable-next-line spellcheck/spell-checker
		name: TranslationService.get('translation.english', 'English'),
	},
	{
		code: 'no',
		// eslint-disable-next-line spellcheck/spell-checker
		name: TranslationService.get('translation.norwegian', 'Norwegian'),
		feature: 'language-no',
	},
	{
		code: 'da',
		// eslint-disable-next-line spellcheck/spell-checker
		name: TranslationService.get('translation.danish', 'Danish'),
		feature: 'language-da',
	},
	{
		code: 'fi',
		// eslint-disable-next-line spellcheck/spell-checker
		name: TranslationService.get('translation.finnish', 'Finnish'),
		feature: 'language-fi',
	},
];

export function useLanguages() {
	const languagesConfiguration = useConfig('widget.languages', {
		deep: true,
		defaultValue: LANGUAGES,
	});

	return React.useMemo(
		() =>
			languagesConfiguration
				?.filter(
					(object) =>
						FeatureService.isActive(object?.feature) ||
						!object?.feature,
				)
				?.map((item) => {
					const name =
						item?.name?.__type === 'Translation'
							? TranslationService.getV2(item?.name?.value)
							: item?.name;

					return {
						...item,
						name: name,
					};
				}),
		[languagesConfiguration],
	);
}
