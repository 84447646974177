import { useCallback, useContext, useLayoutEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import TranslationContext from '@asteria/language/context';

export * as auth from './auth';

export function useInvalidateOnUnmount() {
	const queryClient = useQueryClient();

	useLayoutEffect(() => {
		return () => {
			queryClient.clear();
			queryClient.invalidateQueries({ refetchType: 'none' });
		};
	}, [queryClient]);
}

export function useDevicePixelRatio() {
	useLayoutEffect(() => {
		try {
			document.documentElement.style.setProperty(
				'--browser-zoom',
				window.devicePixelRatio,
			);
		} catch (err) {
			//
		}
	}, []);
}

export function useLanguageChangeCallback() {
	const { onLanguageChange } = useContext(TranslationContext);

	return useCallback((code) => onLanguageChange(code), [onLanguageChange]);
}
