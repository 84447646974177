import React from 'react';

import PropTypes from 'prop-types';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
	YAxis,
} from 'recharts';

import { useMediaQuery } from '@asteria/component-tools/mediaquery';

import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';

import CustomLegend from './components/legend';
import { useTogglePlaceholder, useUniqueBarCount } from './hooks';
import { getAxisValue } from './utils';

import './styles.scss';

const BarGraph = React.memo(function BarGraph(props) {
	const { data, loading, placeholder, translationOptions, children, legend } =
		props;

	const isTablet = useMediaQuery('(max-width: 640px)');

	const placeholderData = useTogglePlaceholder({
		initial: placeholder?.initial,
		toggle: placeholder?.toggle,
		enabled: loading,
	});

	const placeholderBars = useUniqueBarCount(children);

	const graphData = React.useMemo(() => {
		if (loading) {
			return placeholderData;
		}

		return data;
	}, [data, loading, placeholderData]);

	const getDomain = React.useCallback(([dataMin, dataMax]) => {
		const roundedMin = getAxisValue(dataMin);
		const roundedMax = getAxisValue(dataMax);

		let maxValue = Math.max(roundedMax, 100);
		let minValue = Math.min(roundedMin, -100);

		if (loading) {
			maxValue = Math.max(roundedMax, 1000);
			minValue = Math.min(roundedMax, -1000);
		}

		if (isNaN(maxValue)) {
			maxValue = 100;
		}

		if (isNaN(minValue)) {
			minValue = -100;
		}

		if (dataMin >= 0 && dataMax >= 0) {
			return [0, maxValue];
		}

		if (dataMin <= 0 && dataMax <= 0) {
			return [minValue, 0];
		}

		return [minValue, maxValue];
	}, []);

	return (
		<ResponsiveContainer
			className={cn('asteria__component-chart', 'asteria--type-bar')}
			width="100%"
			height="100%"
		>
			<BarChart
				className="text-xs"
				barGap={20}
				barSize={80}
				data={graphData}
				margin={{ top: 10 }}
			>
				<YAxis
					tickCount={3}
					domain={getDomain}
					axisLine={false}
					tickLine={false}
					width={64}
					tickFormatter={(value) =>
						formatNumber(value, false, false, false, {
							useReduction: true,
						})
					}
					className="text-xs truncate text-graph-yaxis-part-hover-text"
					hide={isTablet}
				/>

				<CartesianGrid
					stroke="var(--color-graph-yaxis-part-line-normal-background,var(--color-graph-part-line-background,#cfcfcf))"
					strokeWidth={0.5}
					vertical={false}
				/>
				<Legend
					content={
						legend ? (
							legend
						) : (
							<CustomLegend
								graphData={graphData}
								translationOptions={translationOptions}
								loading={loading}
							/>
						)
					}
				/>
				{loading
					? new Array(placeholderBars).fill().map((_, index) => {
							return (
								<Bar
									key={`placeholder-${index}`}
									className="asteria--animation-loading"
									fill="var(--color-skeleton-normal-background, var(--color-progress-background, rgba(235, 231, 226, 1)))"
									dataKey="loading"
									animationDuration={500}
									animationBegin={(100 * index) % 500}
								/>
							);
					  })
					: children}
				<ReferenceLine
					className="asteria__component-reference-gap"
					y={0}
					stroke="white"
					strokeWidth={4}
				/>
				<ReferenceLine
					y={0}
					stroke="var(--color-graph-yaxis-part-line-normal-zero, var(--color-graph-part-line-zero, var(--color-zero-normal, var(--color-zero, rgba(207, 207, 207, 1)))))"
					// strokeWidth={2}
				/>
			</BarChart>
		</ResponsiveContainer>
	);
});

BarGraph.propTypes = {
	data: PropTypes.array,
	loading: PropTypes.bool,
	legend: PropTypes.node,
	placeholder: PropTypes.object,
	children: PropTypes.node,
	translationOptions: PropTypes.object,
};

export default BarGraph;
