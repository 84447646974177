import React from 'react';

import PropTypes from 'prop-types';

import { useMigrated } from './hooks';

/** @type { React.FC<{ children: React.ReactNode, invert: boolean }> } */
const MigrationWrapper = React.memo(function MigrationWrapper(props) {
	const { children, invert } = props;

	const isMigrated = useMigrated(invert);

	if (isMigrated) {
		return null;
	}

	return children;
});

MigrationWrapper.displayName = 'MigrationWrapper';

MigrationWrapper.propTypes = {
	children: PropTypes.node,
	invert: PropTypes.bool,
};

export default MigrationWrapper;
