import React from 'react';

import PropTypes from 'prop-types';
import { Bar, Tooltip } from 'recharts';

import { cn } from '@asteria/utils-funcs/classes';

import BarGraph from '../../../../../chart/bar';
import { getName } from '../../utils';

import SalaryLegend from './salary-legend';
import GraphTooltip from './tooltip';

const SalaryGraph = React.memo(function SalaryGraph(props) {
	const { query, translationOptions, importing, loading, state } = props;

	const data = React.useMemo(
		() => [
			{
				salaries: query?.data?.source?.statistics?.paid?.total ?? 0,
				forecast: query?.data?.source?.statistics?.forecast?.total ?? 0,
			},
		],
		[
			query?.data?.source?.statistics?.forecast?.total,
			query?.data?.source?.statistics?.paid?.total,
		],
	);

	const hasPromotion = state?.promotion?.visible;

	const hasActual = !!data?.[0]?.salaries;
	const hasForecast = !!data?.[0]?.forecast;

	return (
		<div
			className={cn(
				'w-full flex flex-col',
				'asteria-component__card-chart',
			)}
		>
			<BarGraph
				data={hasPromotion ? [{}] : data}
				loading={importing || loading}
				placeholder={{ initial: -900, toggle: -300 }}
				translationOptions={translationOptions}
				legend={
					<SalaryLegend
						hasActual={hasActual}
						hasForecast={hasForecast}
					/>
				}
			>
				<defs>
					<pattern
						id="salaries"
						patternUnits="userSpaceOnUse"
						width="6"
						height="6"
						patternTransform="rotate(45)"
					>
						<rect
							width="6"
							height="6"
							fill="var(--color-tags-salaries-salary-background, rgb(80, 177, 208))"
						/>
						<rect width="3" height="6" fill="rgba(0, 0, 0, 0.2)" />
					</pattern>
				</defs>
				<Tooltip
					cursor={{
						fill:
							hasPromotion || importing || loading
								? 'transparent'
								: 'var(--color-graph-group-hover-background, var(--color-graph-group-normal-background, var(--color-graph-background, rgba(255, 255, 255, 0))))',
					}}
					content={(props) => (
						<GraphTooltip
							translationOptions={{
								...translationOptions,
								postfix: {
									...translationOptions?.postfix,
									type: 'salary',
								},
							}}
							{...props}
						/>
					)}
				/>

				{hasPromotion ? (
					<Bar
						name={getName('loading', translationOptions)}
						dataKey="loading"
						fill="var(--color-skeleton-normal-background, var(--color-progress-background, rgba(235, 231, 226, 1)))"
						stackId="salaries"
					/>
				) : (
					[
						hasActual || !hasForecast ? (
							<Bar
								key="salaries"
								name={getName(
									'salaries-salary',
									translationOptions,
								)}
								dataKey="salaries"
								fill="var(--color-tags-salaries-salary-background, rgb(80, 177, 208))"
								stackId="salaries"
							/>
						) : null,
						hasForecast ? (
							<Bar
								key="forecast"
								name={getName(
									'salaries-salary',
									translationOptions,
								)}
								dataKey="forecast"
								fill="url(#salaries)"
								stackId="forecast"
							/>
						) : null,
					].filter(Boolean)
				)}
			</BarGraph>
		</div>
	);
});

SalaryGraph.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	query: PropTypes.object,
	translationOptions: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	state: PropTypes.object,
};

export default SalaryGraph;
