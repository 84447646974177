import React from 'react';

import PropTypes from 'prop-types';

import ControlledSelect, {
	Option,
	Select,
} from '@asteria/component-form/select';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useLanguages } from '@asteria/utils-hooks/useLanguages';

import './index.scss';

const LanguageSelector = React.memo((props) => {
	const { className, onChange, language } = props;

	const SelectComp = onChange ? Select : ControlledSelect;

	const languages = useLanguages();

	return (
		<SelectComp
			name="global.language"
			className={cn('asteria-component__settings-language', className)}
			label={TranslationService.get(['settings.language'])}
			title={TranslationService.get([
				'language.select.title',
				'language.select.dropdown.title',
			])}
			analyticsKey="settings.language"
			onChange={onChange}
			value={language}
		>
			{(languages ?? []).map(({ code }) => (
				<Option key={code} value={code}>
					{TranslationService.get(`translation.code.${code}`)}
				</Option>
			))}
		</SelectComp>
	);
});

LanguageSelector.displayName = 'LanguageSelector';

LanguageSelector.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	language: PropTypes.string,
};

export default LanguageSelector;
