import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import TrendIcon from '../../../components/trends';

function Summary(props) {
	const { translationOptions, clientType, query } = props;

	const trendDirection = React.useMemo(() => {
		const source = clientType
			? query?.data?.source?.[clientType]?.summary?.count ?? 0
			: query?.data?.source?.[clientType]?.summary?.count ?? 0;
		const target = clientType
			? query?.data?.target?.summary?.count ?? 0
			: query?.data?.source?.summary?.count ?? 0;

		if (source > target) {
			return 'up';
		}

		return 'down';
	}, [query?.data, clientType]);

	return (
		<div
			className={cn(
				'flex flex-col gap-1 py-4',
				'asteria-component__card-title',
			)}
		>
			<Text size="sm">
				{TranslationService.getV2('card.content.summary.count', {
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
					},
				})}
			</Text>
			<div className="flex items-center">
				<TooltipWrapper
					tooltip={
						<div className="grid grid-cols-[minmax(0,_1fr)_auto] gap-4">
							{clientType === 'customers' || !clientType ? (
								<>
									<Translation
										translationKey="card.content.summary.total.tooltip.label"
										translationOptions={{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'customers',
											},
										}}
										Component={Text}
									/>
									<Translation
										translationKey="card.content.summary.total.tooltip.total"
										translationOptions={{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'customers',
											},
										}}
										Component={Text}
									/>
								</>
							) : null}
							{clientType === 'suppliers' || !clientType ? (
								<>
									<Translation
										translationKey="card.content.summary.total.tooltip.label"
										translationOptions={{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'suppliers',
											},
										}}
										Component={Text}
									/>
									<Translation
										translationKey="card.content.summary.total.tooltip.total"
										translationOptions={{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'suppliers',
											},
										}}
										Component={Text}
									/>
								</>
							) : null}
						</div>
					}
				>
					<Translation
						translationKey="card.content.summary.total"
						translationOptions={{
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								client_type: clientType,
							},
						}}
						Component={Title}
						size="sm"
					/>
				</TooltipWrapper>

				<TrendIcon
					className="mx-2"
					direction={trendDirection}
					query={query}
					postfix={translationOptions.postfix}
				/>
			</div>
		</div>
	);
}

Summary.propTypes = {
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
		data: PropTypes.shape({
			startDate: PropTypes.date,
		}),
	}),
	clientType: PropTypes.string,
	query: PropTypes.object,
};

export default Summary;
