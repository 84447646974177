import React from 'react';

import PropTypes from 'prop-types';

import { useGenericProperties } from '@asteria/component-feedback';
import FeedbackModal from '@asteria/component-feedback/FormModal';
import FeedbackSuccessModal from '@asteria/component-feedback/SuccessModal';
import { useUnreadMessage } from '@asteria/component-support/hooks';
import {
	Service as FeatureService,
	useFeature,
} from '@asteria/component-tools/featureflag';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { useBackgroundLocation } from '@asteria/utils-hooks/navigation';
import useConfig from '@asteria/utils-hooks/useConfig';

import NavigationItem from './NavigationItem';
import Companies from './companies';
import NavigationContext from './context';
import Logo from './logo';

import './styles.scss';

function useNavigation(options) {
	const { onNavigate, onValidate } = options;

	const location = useBackgroundLocation();
	const active = location?.pathname;

	const handleNavigate = React.useCallback(
		(path) => {
			if (path === active) {
				return;
			}

			if (onValidate) {
				const response = onValidate?.(path);

				if (!response) {
					return;
				}
			}

			return onNavigate?.(path);
		},
		[active, onNavigate, onValidate],
	);

	return React.useMemo(
		() => ({ onNavigate: handleNavigate, current: active }),
		[active, handleNavigate],
	);
}

const Navigation = React.memo((props) => {
	const { onAction, onSubmit: onSubmitFn } = props;

	const { onNavigate, current } = useNavigation(props);

	const isWelcomePage = current.includes('/welcome');

	const [isOpen, setOpen] = React.useState(false);

	const hasNavigationFeature = useFeature('widget.navigation');
	const hasLogoutFeature = useFeature('navigation.logout');
	const hasHelpFeature = useFeature('navigation.help');
	const hasStackedFeature = useFeature('widget.navigation.stacked');
	const hasHorizontalFeature = useFeature('widget.navigation.horizontal');
	const hasLabelFeature = useFeature('asteria-navigation-with-label');
	const hasCollapseFeature = useFeature('widget.navigation.collapse');
	const hasMultiCompaniesFeature = useFeature(
		'widget-navigation-multi-companies',
	);

	const variant = React.useMemo(() => {
		if (props.variant) {
			return props.variant;
		}

		if (hasStackedFeature) {
			return 'stacked';
		}

		return 'default';
	}, [props.variant, hasStackedFeature]);

	const direction = React.useMemo(() => {
		if (props.direction) {
			return props.direction;
		}

		if (hasHorizontalFeature) {
			return 'horizontal';
		}

		return 'vertical';
	}, [hasHorizontalFeature, props.direction]);

	const logo = useConfig('widget')?.navigation?.logo;

	const configRoutes = useConfig('widget.navigation');

	const $routes = (configRoutes ?? [])
		.filter(({ feature }) => !feature || FeatureService.isActive(feature))
		.filter((object) => !isWelcomePage || object?.alwaysVisible)
		.filter(({ slug }) => !['collapse', 'logout', 'help'].includes(slug));

	const ContextValue = React.useMemo(() => ({ current: current }), [current]);

	const unreadMessages = useUnreadMessage({ onSubmit: onSubmitFn });

	const shouldShowLabel = React.useMemo(
		() => (hasCollapseFeature ? isOpen : hasLabelFeature),
		[hasCollapseFeature, hasLabelFeature, isOpen],
	);

	const CollapseRoute = React.useMemo(() => {
		const route =
			(configRoutes ?? []).find(({ slug }) => slug === 'collapse') ?? {};

		return {
			slug: 'collapse',
			icon: isOpen ? 'chevron-left' : 'chevron-right',
			label: [
				'widget.navigation.toggle',
				isOpen
					? 'widget.navigation.toggle.open'
					: 'widget.navigation.toggle.closed',
			],
			action: ['toggle:menu'],
			...route,
		};
	}, [configRoutes, isOpen]);

	const LogoutRoute = React.useMemo(() => {
		const route =
			(configRoutes ?? []).find(({ slug }) => slug === 'logout') ?? {};

		return {
			slug: 'logout',
			path: '/logout',
			icon: 'logout',
			label: 'widget.navigation.logout',
			action: ['go', '/logout'],
			...route,
		};
	}, [configRoutes]);

	const HelpRoute = React.useMemo(() => {
		const route =
			(configRoutes ?? []).find(({ slug }) => slug === 'help') ?? {};

		return {
			slug: 'help',
			icon: 'help',
			label: 'widget.navigation.help',
			badge: unreadMessages,
			type: 'dropdown',
			props: {
				placement: 'top',
			},
			children: {
				type: 'manual',
				data: [
					{
						slug: 'feedback',
						action: ['navigation:feedback'],
						label: 'widget.navigation.help.feedback',
						feature: 'menu-item-help-feedback',
					},
					{
						slug: 'tour',
						action: ['navigation:tour'],
						label: {
							key: [
								'app.float.menu.guide',
								'widget.navigation.help.guide',
							],
							default: 'Start an interactive guide',
						},
					},
					{
						slug: 'support',
						action: ['go', '/support'],
						badge: unreadMessages,

						label: {
							key: [
								'app.float.menu.support',
								'widget.navigation.help.support',
							],
							default: 'Help & Support',
						},
					},
				],
			},
			...route,
		};
	}, [configRoutes, unreadMessages]);

	const handleFeedbackSubmit = React.useCallback(
		(data) => onAction?.('updateFeedback', data),
		[onAction],
	);

	const { state, loading, onOpen, onClose, onSubmit, rating } =
		useGenericProperties({ onSubmit: handleFeedbackSubmit });

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'navigation:feedback') {
				onOpen?.();
				return;
			}

			if (action === 'toggle:menu') {
				Analytics.event('navigation.toggle', {
					prev: isOpen,
					current: !isOpen,
				});

				setOpen((value) => !value);
				return;
			}

			return onAction?.(action, data);
		},
		[isOpen, onAction, onOpen],
	);

	const handleNavigate = React.useCallback(
		(...args) => {
			const [path] = args;

			if (isWelcomePage) {
				return onAction?.('go', path);
			}

			return onNavigate(...args);
		},
		[isWelcomePage, onAction, onNavigate],
	);

	const routes = []
		.concat($routes)
		.concat(hasCollapseFeature ? CollapseRoute : [])
		.concat(hasHelpFeature ? HelpRoute : [])
		.concat(hasLogoutFeature ? LogoutRoute : []);

	let hasCollapse = false,
		hasIcon = false;

	for (const route of routes) {
		if (route?.icon) {
			hasIcon = true;
		}

		if (route?.sub?.length) {
			hasCollapse = true;
		}
	}

	if (!hasNavigationFeature || !routes.length) {
		return null;
	}

	return [
		<FeedbackModal
			key="feedback-modal"
			open={state === 'OPEN'}
			type="generic"
			onClose={onClose}
			onSubmit={onSubmit}
			hideContactUs
			loading={loading}
			rating={rating}
		/>,
		<FeedbackSuccessModal
			key="feedback-success-modal"
			open={state === 'DONE'}
			type="generic"
			onClose={onClose}
		/>,
		<NavigationContext.Provider
			key="navigation-context"
			value={ContextValue}
		>
			<div
				className={cn('asteria-component__navigation', {
					[`asteria-component__navigation--variant-${variant}`]:
						variant,
					[`asteria-component__navigation--direction-${direction}`]:
						direction,
					'asteria-component__navigation--has-collapse': hasCollapse,
					'asteria-component__navigation--has-icon': hasIcon,
					'asteria-component__navigation--has-label': shouldShowLabel,
				})}
			>
				{logo ? <Logo src={logo?.src} onAction={onAction} /> : null}

				{hasMultiCompaniesFeature ? (
					<Companies
						open={shouldShowLabel}
						onAction={onAction}
						onSubmit={onAction}
					/>
				) : null}

				{routes.map((route, index) => (
					<NavigationItem
						key={route?.path ?? index}
						variant={variant}
						onNavigate={handleNavigate}
						onAction={handleAction}
						route={route}
						showLabel={shouldShowLabel}
						direction={direction}
					/>
				))}
			</div>
		</NavigationContext.Provider>,
	];
});

Navigation.displayName = 'Navigation';

Navigation.propTypes = {
	type: PropTypes.oneOf(['browser', 'memory', 'hash']),
	variant: PropTypes.oneOf(['default', 'stacked']),
	direction: PropTypes.oneOf(['horizontal', 'vertical']),

	active: PropTypes.string,
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onValidate: PropTypes.func,
};

Navigation.defaultProps = {
	type: 'memory',
};

export default Navigation;
