export const initialState = {
	items: [],
	$form: null,
	navigation: { key: null, _id: null },
};

export const INTEGRATION_STATUS = {
	ERROR: 'error',
	DISABLED: 'disabled',
	IMPORTING: 'importing',
	INITIATING: 'initiating',
	OUTDATED: 'outdated',
	CONNECTED: 'connected',
};

export const INTEGRATION_STATUS_PRIORITY = {
	[INTEGRATION_STATUS.INITIATING]: 0,
	[INTEGRATION_STATUS.IMPORTING]: 1,
	[INTEGRATION_STATUS.DISABLED]: 2,
	[INTEGRATION_STATUS.OUTDATED]: 3,
	[INTEGRATION_STATUS.ERROR]: 4,
	[INTEGRATION_STATUS.CONNECTED]: 5,
};
