import React from 'react';

import PropTypes from 'prop-types';

import Skeleton from '@asteria/component-core/skeleton';

import { cn } from '@asteria/utils-funcs/classes';

import ResponsiveContainer from '../../../components/responsive-container';
import { Content } from '../../basic';

import Extra from './content';

const PromotionView = React.memo(function PromotionView(props) {
	return (
		<div>
			<ResponsiveContainer
				className="asteria-component__card-chart-content"
				minWidth={640}
			>
				<div
					className={cn(
						'asteria-component__card-title',
						'flex flex-col gap-4',
					)}
				>
					<Skeleton animated={props?.importing} />
					<Content.Title {...props} />
				</div>
				<Extra {...props} />
				<Content.Text {...props} />
			</ResponsiveContainer>
			<Content.Loading {...props} />
		</div>
	);
});

PromotionView.propTypes = { importing: PropTypes.bool };

export default PromotionView;
