import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import BetaSign from '@asteria/component-beta-sign';
import Form, { Wrapper as FormWrapper, Input } from '@asteria/component-form';
import List, { ListCell, ListItem } from '@asteria/component-list';

import { useTranslationFn } from '@asteria/language/hooks';
import { cn } from '@asteria/utils-funcs/classes';
import { findParentByClassname } from '@asteria/utils-funcs/node';

import { AuthContext } from '../context';
import Languages from '../languages';
import { defaultReducer, formatAuthResponse, formatError } from '../utils';

function StepBasic() {
	const { passwordReset } = React.useContext(AuthContext);

	const translate = useTranslationFn();

	const [search] = useSearchParams();
	const navigate = useNavigate();

	const onResetButtonClick = React.useCallback(
		() =>
			navigate(
				['/auth/reset', search.toString()].filter(Boolean).join('?'),
			),
		[navigate, search],
	);

	return (
		<>
			<Input
				type="email"
				name="username"
				placeholder={translate('login.username.placeholder')}
				label={translate('login.username.label')}
				required
				autoFocus
			/>
			<div
				className={cn(
					'relative',
					'asteria-view__auth-content-section',
					'asteria--variant-password',
				)}
			>
				{passwordReset ? (
					<Button
						variant="link"
						className="absolute top-0 right-0"
						label={translate(['auth.password.forgot.label'])}
						size="sm"
						onClick={onResetButtonClick}
					/>
				) : null}

				<Input
					type="password"
					name="password"
					label={translate('login.password.label')}
					placeholder={translate('login.password.placeholder')}
					required
				/>
			</div>
		</>
	);
}

function Step2FA() {
	const translate = useTranslationFn();

	return (
		<>
			<Input
				type="string"
				name="code"
				placeholder={translate('auth.code.placeholder')}
				label={translate('auth.code.label')}
				required
				autoFocus
			/>
		</>
	);
}

function StepCompanies({ data }) {
	const { setValue } = useFormContext();

	const onSelect = React.useMemo(
		() => (id) => (event) => {
			setValue('companyId', id);

			const form = findParentByClassname(
				event.target,
				'asteria-component__form',
			);

			form?.requestSubmit?.();
		},
		[setValue],
	);

	return (
		<List size="lg">
			{(data ?? []).map((object) => (
				<ListItem
					key={object?._id ?? object?.id}
					onClick={onSelect(object?._id ?? object?.id)}
				>
					<ListCell>
						<Text>{object?.name}</Text>
					</ListCell>
					<ListCell>
						<Button icon="chevron-right" size="sm" />
					</ListCell>
				</ListItem>
			))}
		</List>
	);
}

StepCompanies.propTypes = { data: PropTypes.arrayOf(PropTypes.object) };

const LoginPage = React.memo((props) => {
	const { className } = props;

	const {
		onAction,
		onSubmit,
		partnerId,
		logo,
		signup,
		languages,
		homepage,
		onLanguageChange,
	} = React.useContext(AuthContext);

	const [search] = useSearchParams();
	const navigate = useNavigate();

	const translate = useTranslationFn();

	const [{ loading, error, step, data }, update] = React.useReducer(
		defaultReducer,
		{ loading: false, error: null, step: null, data: null },
	);

	const handleSubmit = React.useCallback(
		async (form) => {
			update({ type: 'START' });

			let response;

			try {
				response = await onSubmit?.('auth:login', form);
			} catch (err) {
				return update({
					type: 'FAILURE',
					payload: formatError({ error: err, form }),
				});
			}

			if (!response?.ok) {
				if (response?.companies?.length) {
					return update({
						type: 'NEXT',
						payload: { step: 'COMPANY', data: response?.companies },
					});
				}

				if (response?.data?.hasTwoFactor) {
					return update({
						type: 'NEXT',
						payload: { step: '2FA_CODE' },
					});
				}

				const message = response?.error ?? 'Something went wrong';

				return update({
					type: 'FAILURE',
					payload: formatError({
						error: { message: message },
						form: form,
					}),
				});
			}

			const { accessToken, refreshToken } = formatAuthResponse(response);

			onAction?.('auth:token', {
				accessToken,
				refreshToken,
				valid: true,
			});

			update({ type: 'STOP' });

			return navigate(['/', search.toString()].filter(Boolean).join('?'));
		},
		[navigate, onAction, onSubmit, search],
	);

	const onSignupButtonClick = React.useCallback(
		() =>
			navigate(
				['/auth/signup', search.toString()].filter(Boolean).join('?'),
			),
		[navigate, search],
	);

	const defaultValues = React.useMemo(
		() => ({ partnerId: partnerId }),
		[partnerId],
	);

	return (
		<div
			className={cn(
				'asteria-view__auth-content',
				'asteria--variant-login',
				{ [`asteria--state-step-${step}`]: step },
				className,
			)}
		>

			<Form defaultValues={defaultValues} onSubmit={handleSubmit}>
				<Wrapper scroll>
					<Header logo={logo}>
						{translate(['auth.title', `login.title`])}
					</Header>
					<Content scroll>
						{error ? (
							<Alert level="error">
								<Text>{error}</Text>
							</Alert>
						) : null}
						<FormWrapper>
							<Content>
								{step === null ? <StepBasic /> : null}
								{step === '2FA_CODE' ? <Step2FA /> : null}
								{step === 'COMPANY' ? (
									<StepCompanies data={data} />
								) : null}
							</Content>
						</FormWrapper>
					</Content>
					<Footer>
						<FooterSection position="last">
							<Button
								type="submit"
								label={translate([
									'action.submit',
									`login.action.submit`,
								])}
								variant="primary"
								loading={loading}
								disabled={loading}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Form>
			{signup ? (
				<Text
					size="sm"
					align="center"
					className="asteria-view__auth-switcher"
				>
					<span>{translate(['login.signup.label'])}</span>
					<Button
						size="sm"
						label={translate(['login.action.signup'])}
						type="button"
						variant="link"
						onClick={onSignupButtonClick}
					/>
				</Text>
			) : null}

			{languages ? <Languages onChange={onLanguageChange} /> : null}

			{homepage ? (
				<Text
					size="sm"
					align="center"
					className="asteria-view__auth-homepage"
				>
					<span>
						{translate(['auth.back'], { default: 'Back to' })}
					</span>
					<Button
						size="sm"
						label={translate(['auth.back.link.label'], {
							default: 'www.asteria.ai',
						})}
						href={translate(['auth.back.link.href'], {
							default: 'https://www.asteria.ai/',
						})}
						variant="link"
					/>
				</Text>
			) : null}
		</div>
	);
});

LoginPage.displayName = 'LoginPage';

LoginPage.propTypes = {
	className: PropTypes.string,
};

export default LoginPage;
