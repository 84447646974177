import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper from '@asteria/component-core/wrapper';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const STORAGE_KEY = 'AsteriaWhatsNewBannerDismissed';

// News indicator component - a small animated circle
const NewsIndicator = () => (
	<span className="asteria-component__indicator"></span>
);

const WhatsNewBanner = (props) => {
	const {
		className,
		onClick,
		disableDismiss = false,
		forceShow = false,
	} = props;
	const [isDismissed, setIsDismissed] = useState(true); // Start hidden until we check storage

	useEffect(() => {
		// If forceShow is true, always show the banner regardless of localStorage
		if (forceShow) {
			setIsDismissed(false);
			return;
		}

		const dismissed = localStorage.getItem(STORAGE_KEY);
		setIsDismissed(!!dismissed);
	}, [forceShow]);

	const handleDismiss = (e) => {
		e.stopPropagation();

		// If disableDismiss is true, don't allow dismissal
		if (disableDismiss) {
			return;
		}

		localStorage.setItem(STORAGE_KEY, 'true');
		setIsDismissed(true);
	};

	const handleClick = (e) => {
		onClick?.(e);

		// Only set localStorage if dismissal is allowed
		if (!disableDismiss) {
			localStorage.setItem(STORAGE_KEY, 'true');
			setIsDismissed(true);
		}
	};

	if (isDismissed) {
		return null;
	}

	const text = TranslationService.getV2(['whats.new.banner.text'], {
		default:
			'Discover the new financial dashboard – an improved way to track your cash flow, invoices, and business performance.',
	});

	const link = TranslationService.getV2(['whats.new.banner.link'], {
		default: 'Read more',
	});

	return (
		<FeatureFlag feature="whats-new-notify">
			<Wrapper
				className={cn(
					'asteria-component__splash asteria--variant-whats-new',
					className,
				)}
			>
				<div
					className="asteria-component__splash__inner"
					onClick={handleClick}
				>
					<Text size="sm" className="flex items-center">
						<NewsIndicator /> {text}
					</Text>
					<Button
						variant="link"
						size="sm"
						label={link}
						onClick={handleClick}
					/>
				</div>
				<Button
					variant="link"
					icon="close"
					aria-label="Dismiss"
					size="sm"
					onClick={handleDismiss}
				/>
			</Wrapper>
		</FeatureFlag>
	);
};

WhatsNewBanner.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	disableDismiss: PropTypes.bool,
	forceShow: PropTypes.bool,
};

export default WhatsNewBanner;
