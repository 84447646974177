import React from 'react';

import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import Navigation from '@asteria/component-navigation';
import { useFeature } from '@asteria/component-tools/featureflag';

const NavigationLogic = React.memo((props) => {
	const { onAction, onSubmit, view } = props;

	const location = useLocation();

	const hasWelcomeNavigationFeature = useFeature('welcome-navigation');

	const onNavigate = React.useCallback(
		(path) => onAction?.('redirect', path),
		[onAction],
	);

	if (view === 'welcome' && !hasWelcomeNavigationFeature) {
		return null;
	}

	return (
		<Navigation
			onNavigate={onNavigate}
			onSubmit={onSubmit}
			onAction={onAction}
			active={location.pathname}
		/>
	);
});

NavigationLogic.displayName = 'NavigationLogic';
NavigationLogic.propTypes = {
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
	view: PropTypes.string,
};

export default NavigationLogic;

/*

import { parsePath, parseView } from '@asteria/utils-funcs/navigation';

function useTopNavigation(options) {
	const { view, setView } = options;

	const onNavigate = React.useCallback(
		(path) => {
			setView(parsePath(path));
		},
		[setView],
	);

	const active = React.useMemo(() => parseView(view), [view]);

	return { onNavigate: onNavigate, active: active };
}

const NavigationLogic = React.memo((props) => {
	const { view, onAction } = props;

	const { onNavigate, active } = useTopNavigation({
		view: view.state,
		setView: view.set,
	});

	const onValidate = React.useCallback(
		(path) => {
			if (view?.state !== 'forecaster' || path === '/forecaster') {
				return true;
			}

			onAction?.('forecaster:validate', path);
			return false;
		},
		[onAction, view?.state],
	);

	return (
		<Navigation
			type="memory"
			onNavigate={onNavigate}
			onValidate={onValidate}
			active={active}
			onAction={onAction}
		/>
	);
});

NavigationLogic.displayName = 'NavigationLogic';

NavigationLogic.propTypes = {
	view: PropTypes.shape({
		state: PropTypes.any,
		set: PropTypes.func,
		reset: PropTypes.func,
	}),
	onAction: PropTypes.func,
};

export default NavigationLogic;

 */
