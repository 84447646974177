import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';

import './styles.scss';

const Item = React.memo(function Slider(props) {
	const { translationOptions, action, onAction } = props;

	const onClick = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: 'card-slider',
				from: translationOptions?.postfix?.variant,
				data: action,
				startDate: translationOptions?.data?.startDate,
				endDate: translationOptions?.data?.endDate,
			}),
		[
			action,
			onAction,
			translationOptions?.data?.endDate,
			translationOptions?.data?.startDate,
			translationOptions?.postfix?.variant,
		],
	);

	const removeAction = React.useCallback(() => {
		onAction?.('card:action', {
			type: 'action-remove',
			data: {
				type: action?.type,
				timestamp: {
					startDate: translationOptions?.data?.startDate,
					endDate: translationOptions?.data?.endDate,
				},
			},
		});
	}, [
		action?.type,
		onAction,
		translationOptions?.data?.endDate,
		translationOptions?.data?.startDate,
	]);

	if (!action) {
		return null;
	}

	return (
		<div className="flex flex-col gap-1 items-center justify-center w-full">
			<Button
				className="self-end top-2 right-2 absolute"
				icon="close"
				size="xs"
				iconSize="sm"
				onClick={removeAction}
			/>
			<Translation
				className="text-center px-4"
				translationKey={['card.content.bullet', 'card.slider.item']}
				translationOptions={{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						action: action?.type,
						...action?.postfix,
					},
					data: {
						...translationOptions?.data,
						action: action?.data,
						score: action?.score,
					},
				}}
				size="md"
				Component={Text}
			/>
			<Button
				variant="link"
				label={TranslationService.getV2(
					['card.content.bullet.action', 'card.slider.item.action'],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							action: action?.type,
							...action?.postfix,
						},
						data: {
							...translationOptions?.data,
							action: action?.data,
							score: action?.score,
						},
					},
				)}
				size="sm"
				onClick={onClick}
			/>
		</div>
	);
});

Item.propTypes = {
	action: PropTypes.object,
	translationOptions: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Item;
