import React from 'react';

import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { useFeature } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import IntegrationBox, { StatusIntegrationBox } from '../components/box';
import MissingIntegrationSection from '../components/missing';
import { SearchWrapper } from '../components/search';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 */

const Connected = React.memo(function Connected(props) {
	const { onAction, onSubmit, onOpen, integrations } = props;

	if (!integrations.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			<Translation
				translationKey="view.integrations.section.title"
				translationOptions={{
					postfix: { section: 'connected' },
					data: { count: integrations.length },
				}}
				Component={Title}
				size="xs"
			/>
			<div className="grid gap-4">
				{integrations.map((object) => (
					<StatusIntegrationBox
						key={object?._id ?? object?.id}
						id={object?._id ?? object?.id}
						type={object?.type}
						name={object?.key ?? object?.name}
						onAction={onAction}
						size="sm"
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="status"
					/>
				))}
			</div>
		</div>
	);
});

Connected.propTypes = {
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

const Available = React.memo(function Available(props) {
	const { available, connected, onAction, onSubmit, onOpen } = props;

	// const exists = !!connected?.length;

	if (!connected?.length && !available?.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			{/* {exists ? (
				<Translation
					translationKey="view.integrations.section.title"
					translationOptions={{ postfix: { section: 'available' } }}
					Component={Title}
					size="xs"
				/>
			) : null} */}

			<div className="grid gap-4">
				{available.map((object) => (
					<IntegrationBox
						key={[object?.type, object?.key].join('-')}
						type={object?.type}
						name={object?.key}
						onAction={onAction}
						size="sm"
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="connect"
					/>
				))}
			</div>
		</div>
	);
});

Available.propTypes = {
	available: PropTypes.arrayOf(PropTypes.object),
	connected: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

/** @type { React.FC<Props> } */
const PageConnect = React.memo(function PageConnect(props) {
	const { className, onClose, onAction, onSubmit } = props;

	const location = useLocation();
	const hasBankFeature = useFeature('bank-integrations');

	const type = location?.state?.type;

	// const onOpenConnected = React.useCallback(
	// 	({ integration }) => {
	// 		const id = integration?._id ?? integration?.id;

	// 		return onAction?.('go', `/onboarding/${id}`);
	// 	},
	// 	[onAction],
	// );

	const onOpenAvailable = React.useCallback(
		({ flow }) => {
			const type = flow?.type;
			const key = flow?.key;

			return onAction?.('go', `/onboarding/${type}/${key}`);
		},
		[onAction],
	);

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				'asteria--variant-connect',
				className,
			)}
		>
			<Header onClose={onClose}>
				{TranslationService.getV2(['onboarding.modal.title'], {
					postfix: { variant: 'connect' },
				})}
			</Header>
			<Content scroll>
				<div className="flex flex-col gap-4 overflow-hidden">
					<Translation
						translationKey="onboarding.modal.content"
						translationOptions={{
							postfix: {
								variant: 'connect',
								type: type ?? (hasBankFeature ? null : 'erp'),
							},
						}}
						Component={Text}
						size="sm"
					/>

					<SearchWrapper
						size="sm"
						active={type}
						onAction={onAction}
						onSubmit={onSubmit}
					>
						{({ available, connected }) => (
							<div
								className={cn(
									'pr-2 asteria-scroll',
									'asteria-component__onboarding-connect-list',
								)}
							>
								<div className="flex flex-col gap-8">
									{/* <Connected
										integrations={connected}
										onAction={onAction}
										onSubmit={onSubmit}
										onOpen={onOpenConnected}
									/> */}
									<Available
										available={available}
										connected={connected}
										onAction={onAction}
										onSubmit={onSubmit}
										onOpen={onOpenAvailable}
									/>
									<MissingIntegrationSection
										size="sm"
										onAction={onAction}
										onSubmit={onSubmit}
									/>
								</div>
							</div>
						)}
					</SearchWrapper>
				</div>
			</Content>
			<Footer>
				<FooterSection position="first"></FooterSection>
				<FooterSection position="last"></FooterSection>
			</Footer>
		</Wrapper>
	);
});

PageConnect.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageConnect;
