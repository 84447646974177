import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import DatePickerContent from './content';
import {
	useChange,
	useDatepickerType,
	useDatepickerValue,
	useDefaultChange,
} from './hooks';

const DatepickerModal = React.memo(function DatepickerModal(props) {
	const {
		open,
		onClose,

		className,
		types,
		variant = 'default',
		layout = 'default',
		behavior = 'default',
		size = 'medium',
		locale = TranslationService.code || 'en',

		startDate,
		endDate,
		disabledDates,

		isPastDisabled,
		isFutureDisabled,

		repeatable,
		repeatableValue,
		onRepeatableChange,

		contentRef,
		footer,
	} = props;

	const value = useDatepickerValue(props);
	const [type, setType] = useDatepickerType(props);

	const onDefaultChange = useDefaultChange(props);

	const onChange = useChange(props, {
		value,
		onClose: onClose,
		repeatable: repeatableValue,
		onDefaultChange: onDefaultChange,
	});

	return (
		<Modal
			className={cn('asteria-component__datepicker-modal', className)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<Wrapper>
				<Header onClose={onClose}>
					{TranslationService.getV2(['datepicker.modal.title'])}
				</Header>
				<Content className="gap-2">
					<DatePickerContent
						locale={locale}
						type={type}
						variant={variant}
						value={value}
						behavior={behavior}
						layout={layout}
						isPastDisabled={isPastDisabled}
						isFutureDisabled={isFutureDisabled}
						startDate={startDate}
						endDate={endDate}
						disabledDates={disabledDates}
						onTypeSelect={setType}
						onChange={onChange}
						ref={contentRef}
						size={size}
						repeatable={repeatable}
						types={types}
						onRepeatableChange={onRepeatableChange}
					/>
				</Content>
				{footer ? (
					<Footer>
						<FooterSection position="last">
							<Button
								variant="tertiary"
								onClick={onClose}
								label={TranslationService.getV2([
									'button.close',
									'action.close',
								])}
							/>
						</FooterSection>
					</Footer>
				) : null}
			</Wrapper>
		</Modal>
	);
});

DatepickerModal.displayName = 'DatepickerModal';

DatepickerModal.propTypes = {
	open: PropTypes.any,
	onClose: PropTypes.any,
	className: PropTypes.any,
	types: PropTypes.any,
	variant: PropTypes.any,
	layout: PropTypes.any,
	behavior: PropTypes.any,
	size: PropTypes.any,
	locale: PropTypes.any,
	startDate: PropTypes.any,
	endDate: PropTypes.any,
	disabledDates: PropTypes.any,
	isPastDisabled: PropTypes.any,
	isFutureDisabled: PropTypes.any,
	repeatable: PropTypes.any,
	repeatableValue: PropTypes.any,
	onRepeatableChange: PropTypes.any,
	contentRef: PropTypes.any,
	footer: PropTypes.bool,
};

export default DatepickerModal;
