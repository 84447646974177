import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Table, {
	TableCell,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

const SORTING = {
	NAME: 'NAME',
	STATUS: 'STATUS',
	AMOUNT: 'AMOUNT',
};

/** @type { React.FC<ChildrenProps> } */
const CardContent = React.memo(function CardContent(props) {
	const { query, translationOptions, onAction, variant } = props;

	const [sortBy, setSortBy] = React.useState(null);

	const [open, actions] = useToggleState();

	const setSorting = React.useCallback((key) => {
		setSortBy((state) => {
			if (!state || state?.key !== key) {
				return { key, direction: 'DESC' };
			}

			if (state?.direction === 'DESC') {
				return { ...state, direction: 'ASC' };
			}

			return null;
		});
	}, []);

	const breakdown = React.useMemo(() => {
		const values = [...(query?.data?.source?.breakdown ?? [])];

		if (sortBy?.key) {
			values.sort((a, b) => {
				if (sortBy?.key === SORTING.AMOUNT) {
					if (sortBy?.direction === 'DESC') {
						return b.paid.total - a.paid.total;
					}

					return a.paid.total - b.paid.total;
				}

				if (sortBy?.key === SORTING.NAME) {
					if (sortBy?.direction === 'DESC') {
						return b?.name?.localeCompare?.(a.name) ?? -1;
					}

					return a?.name?.localeCompare?.(b.name) ?? -1;
				}

				return 0;
			});
		}

		return values;
	}, [query?.data?.source?.breakdown, sortBy?.key, sortBy?.direction]);

	const onRedirect = React.useCallback(
		() => onAction?.('card:action', { type: 'taxes' }),
		[onAction],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-4',
				'asteria-component__card-content-inner',
			)}
		>
			<TextGroup>
				<Content.Title {...props} />
				<Content.Text {...props} />
			</TextGroup>
			{breakdown.length ? (
				<Button
					size="sm"
					variant="link"
					label={TranslationService.getV2(['card.content.action'], {
						...translationOptions,
						postfix: {
							'action-type': 'collapse',
							'action-variant': open ? 'open' : 'closed',
							...translationOptions?.postfix,
						},
					})}
					onClick={actions.toggle}
				/>
			) : variant !== 'past' ? (
				<div className="asteria-component__card-content-actions">
					<Button
						size="sm"
						variant="link"
						label={TranslationService.getV2(
							['card.content.action'],
							{
								...translationOptions,
								postfix: {
									'action-type': 'redirect',
									'action-variant': 'link',
									...translationOptions?.postfix,
								},
							},
						)}
						onClick={onRedirect}
					/>
				</div>
			) : null}
			{open ? (
				<Table>
					<TableHeader>
						<TableCell className="asteria--variant-name">
							<Button
								size="sm"
								active={sortBy?.key === SORTING.NAME}
								icon="chevron-down"
								iconActive={
									sortBy?.direction === 'DESC'
										? 'chevron-down'
										: 'chevron-up'
								}
								iconPosition="last"
								label={TranslationService.getV2(
									['card.content.table.header.label'],
									{
										...translationOptions,
										postfix: {
											key: 'name',
											...translationOptions?.postfix,
										},
									},
								)}
								onClick={() => setSorting(SORTING.NAME)}
							/>
						</TableCell>
						<TableCell className="asteria--variant-status">
							<Button
								size="sm"
								// active={sortBy?.key === SORTING.STATUS}
								// icon="chevron-down"
								// iconActive={
								// 	sortBy?.direction === 'DESC'
								// 		? 'chevron-down'
								// 		: 'chevron-up'
								// }
								// iconPosition="last"
								label={TranslationService.getV2(
									['card.content.table.header.label'],
									{
										...translationOptions,
										postfix: {
											key: 'status',
											...translationOptions?.postfix,
										},
									},
								)}
								// onClick={() => setSorting(SORTING.STATUS)}
							/>
						</TableCell>
						<TableCell className="asteria--variant-amount">
							<Button
								size="sm"
								active={sortBy?.key === SORTING.AMOUNT}
								icon="chevron-down"
								iconActive={
									sortBy?.direction === 'DESC'
										? 'chevron-down'
										: 'chevron-up'
								}
								iconPosition="last"
								label={TranslationService.getV2(
									['card.content.table.header.label'],
									{
										...translationOptions,
										postfix: {
											key: 'amount',
											...translationOptions?.postfix,
										},
									},
								)}
								onClick={() => setSorting(SORTING.AMOUNT)}
							/>
						</TableCell>
					</TableHeader>
					{breakdown.map((value) => (
						<TableRow key={value?.id}>
							<TableCell className="asteria--variant-name">
								<Translation
									translationKey="card.content.table.row.label"
									translationOptions={{
										...translationOptions,
										postfix: {
											key: 'name',
											...translationOptions?.postfix,
										},
										data: {
											...translationOptions?.data,
											current: value,
										},
									}}
									Component={Text}
									size="sm"
								/>
							</TableCell>
							<TableCell className="asteria--variant-status" />
							<TableCell className="asteria--variant-amount">
								<TextGroup className="flex flex-col items-end">
									<Translation
										translationKey="card.content.table.row.label"
										translationOptions={{
											...translationOptions,
											postfix: {
												key: 'amount',
												'sub-key': 'paid',
												...translationOptions?.postfix,
											},
											data: {
												...translationOptions?.data,
												current: value,
											},
										}}
										Component={Text}
										size="sm"
									/>
									<Translation
										translationKey="card.content.table.row.label"
										translationOptions={{
											...translationOptions,
											postfix: {
												key: 'amount',
												'sub-key': 'forecast',
												...translationOptions?.postfix,
											},
											data: {
												...translationOptions?.data,
												current: value,
											},
										}}
										Component={Text}
										className="text-content-text-muted"
										size="xs"
									/>
								</TextGroup>
							</TableCell>
						</TableRow>
					))}
				</Table>
			) : null}
			{open && breakdown.length && variant !== 'past' ? (
				<div className="asteria-component__card-content-actions">
					<Button
						size="sm"
						variant="primary"
						label={TranslationService.getV2(
							['card.content.action'],
							{
								...translationOptions,
								postfix: {
									'action-type': 'redirect',
									'action-variant': 'primary',
									...translationOptions?.postfix,
								},
							},
						)}
						onClick={onRedirect}
					/>
				</div>
			) : null}
		</div>
	);
});

CardContent.displayName = 'CardContent';

CardContent.propTypes = {
	className: PropTypes.string,

	query: PropTypes.object,
	translationOptions: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	variant: PropTypes.string,
};

export default CardContent;
