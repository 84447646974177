import React from 'react';

import PropTypes from 'prop-types';

import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Dialog from '@asteria/component-dialog';
import Contenter from '@asteria/component-tools/contenter';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useRedirectLink } from '../hooks';
import MigrationWrapper from '../wrapper';

/**
 * @typedef { <TResponse = Promise<unknown>>(action: string, data?: unknown) => TResponse } onAction
 *
 * @typedef Props
 * @property { string } className
 * @property { onAction } onAction
 * @property { onAction } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 * @property { boolean } open
 */

/** @type { React.FC<Props> } */
const MigrationDialog = React.memo(function MigrationDialog(props) {
	const { className, onAction, onSubmit, onClose, open } = props;

	const hasRedirectFeature = useFeature('migration-dialog-redirect');
	const redirectUri = useRedirectLink('dialog');

	const actions = React.useMemo(
		() => ({ onAction, onSubmit, onClose }),
		[onAction, onClose, onSubmit],
	);

	const data = React.useMemo(() => ({ redirectUri }), [redirectUri]);

	const content = useConfig(
		['migration.dialog.content'].concat(
			hasRedirectFeature ? ['migration.dialog.redirect.content'] : [],
		),
		{ deep: true },
	);

	return (
		<FeatureFlag feature="migration-dialog">
			<MigrationWrapper>
				<Dialog
					open={open}
					className={cn(
						'asteria-component__migration-dialog',
						className,
					)}
					placement="bottom-right"
				>
					<Wrapper>
						<Header onClose={onClose}>
							{TranslationService.getV2([
								'migration.dialog.title',
							])}
						</Header>
						<Content>
							<Contenter
								content={content}
								actions={actions}
								data={data}
							/>
						</Content>
					</Wrapper>
				</Dialog>
			</MigrationWrapper>
		</FeatureFlag>
	);
});

MigrationDialog.displayName = 'MigrationDialog';

MigrationDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MigrationDialog;
