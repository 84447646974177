import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';

import { useTranslationFn } from '@asteria/language/hooks';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

// Old
// const footerLinkGroups = [
// 	{
// 		key: 'type-about',
// 		header: 'login.footer.link.type-about.header',
// 		links: ['type-about', 'type-for-partners', 'type-resources'],
// 	},
// 	{
// 		key: 'type-privacy',
// 		header: 'login.footer.link.type-privacy.header',
// 		links: ['type-cookie', 'type-data-processing', 'type-privacy'],
// 	},
// 	{
// 		key: 'type-social',
// 		header: 'login.footer.link.type-social.header',
// 		links: ['type-facebook', 'type-instagram', 'type-linkedin'],
// 	},
// ];

// Updated
const footerLinkGroupsNew = [
	{
		key: 'type-merged',
		links: [
			'type-cookie',
			'type-data-processing',
			'type-privacy',
			'type-linkedin',
			'type-documentation',
			'type-api',
		],
	},
];

const Footer = React.memo(function Footer(props) {
	const { onAction } = props;
	const translate = useTranslationFn();

	const content = useConfig('pages.auth.footer.config', { deep: true });

	const actions = React.useMemo(() => ({ onAction }), [onAction]);

	return (
		<div
			className={cn(
				'asteria-component__auth-footer',
				'w-full shadow flex-row px-4 md:px-20',
			)}
		>
			<div
				className={cn(
					'asteria-component__auth-footer-content',
					'flex h-auto py-24',
					'gap-8 md:gap-2',
					'items-start',
					'justify-around xl:justify-between',
					'w-auto md:w-full',
					'flex-col md:flex-row ',
				)}
			>
				<div className="flex flex-col gap-2">
					<Text size="md" className="font-medium">
						{translate('login.footer.title')}
					</Text>
					<Text size="sm">{translate('login.footer.copyright')}</Text>
					<Contenter content={content} actions={actions} />
				</div>
				<div
					className={cn(
						'asteria-component__auth-footer-links',
						'flex h-auto flex-col xl:flex-row gap-8',
					)}
				>
					{footerLinkGroupsNew.map((group) => (
						<div
							key={group.key}
							className={cn(
								'asteria-component__auth-footer-links-group',
								{ 'lg:!flex-row': !group.header },
							)}
						>
							{group.header && (
								<Text weight="bold" size="lg">
									{group.header}
								</Text>
							)}
							{group.links.map((linkKey) => (
								<Button
									key={linkKey}
									size="sm"
									variant="flat"
									href={translate(
										`login.footer.link.${linkKey}.path`,
									)}
									label={translate(
										`login.footer.link.${linkKey}.label`,
									)}
									target="_blank"
								/>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
});

Footer.displayName = 'Footer';

Footer.propTypes = {
	onAction: PropTypes.func,
};

export default Footer;
