import { SupportService } from '@asteria/backend-utils-services';

export async function sendContenterData({ accessToken, type, data }) {
	return SupportService.custom(
		{
			query: `
				mutation SendContenterData($input: [ContenterDataInput!]!) {
					sendContenterData(input: $input) {
						ok
						error
					}
				}
			`,
			variables: { input: { type: type, data: data } },
		},
		{ token: accessToken },
	);
}
