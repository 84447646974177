import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useBlocker } from 'react-router-dom';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import ConfirmationModal from '../../../modals/confirmation';

const LeaveWrapper = (props) => {
	const { children, variant, onAction } = props;

	const { getValues } = useFormContext();

	const shouldBlock = React.useCallback(
		({ currentLocation, nextLocation }) => {
			return (
				!nextLocation?.state?.backgroundLocation &&
				currentLocation.pathname.includes('forecaster/action') &&
				!nextLocation.pathname.includes('forecaster/action') &&
				!!getValues('$touched')
			);
		},
		[getValues],
	);

	const blocker = useBlocker(shouldBlock);

	const onModalClose = React.useCallback(() => blocker.reset(), [blocker]);

	const handleAction = React.useCallback(
		async (action, data) => {
			if (action === 'discard') {
				blocker.proceed();

				return onAction?.('form:discard', { skipRedirect: true });
			}

			if (action === 'save') {
				await onAction?.('form:save', { form: cloneDeep(getValues()) });
				await onAction?.('action:remove');

				blocker.proceed();

				return;
			}

			return onAction?.(action, data);
		},
		[blocker, getValues, onAction],
	);

	if (variant !== 'action') {
		return children;
	}

	return (
		<>
			<ConfirmationModal
				open={blocker.state === 'blocked'}
				onClose={onModalClose}
				onAction={handleAction}
			/>
			{children}
		</>
	);
};

LeaveWrapper.displayName = 'LeaveWrapper';

LeaveWrapper.propTypes = {
	children: PropTypes.node,
	variant: PropTypes.string,
	onAction: PropTypes.func,
};

export default LeaveWrapper;
