import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { Translation, TranslationService } from '@asteria/language';
import * as FormatUtils from '@asteria/utils-funcs/format';

const InvoicePieTooltip = (props) => {
	const { translationOptions, category, tag, data, clientType } = props;

	const label = FormatUtils.formatTag({ category, tag });

	return (
		<div className="flex flex-row justify-between items-center gap-4">
			<Chip
				variant="simple"
				colors={FormatUtils.replace([category, tag]).join('-')}
				label={TranslationService.getV2('card.content.tag.label', {
					...translationOptions,
					data: { ...data, label: label },
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
						tag: tag,
					},
				})}
			/>
			<Translation
				translationKey="card.content.tag.value"
				translationOptions={{
					...translationOptions,
					data: { ...data, label: label },
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
						tag: tag,
					},
				}}
				Component={Text}
			/>
		</div>
	);
};

InvoicePieTooltip.propTypes = {
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
	}),
	category: PropTypes.string,
	tag: PropTypes.string,
	data: PropTypes.object,
	clientType: PropTypes.string,
};

export default InvoicePieTooltip;
