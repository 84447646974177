import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';

import { FormContext } from '../context';

import './index.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode } children
 * @property { 'onChange' | 'onBlur' | 'onSubmit' | 'onTouched' | 'all' } mode https://react-hook-form.com/docs/useform#mode
 * @property { 'onChange' | 'onBlur' | 'onSubmit' } reValidateMode https://react-hook-form.com/docs/useform#reValidateMode
 * @property { unknown } defaultValues https://react-hook-form.com/docs/useform#defaultValues
 * @property { unknown } values https://react-hook-form.com/docs/useform#values
 * @property { string } analyticsKey
 * @property { (data: unknown) => Promise<unknown> } onSubmit
 * @property { boolean } editing
 * @property { unknown } resetOptions
 */

/**
 * @type { React.FC<Props> }
 */
const Form = React.memo(
	React.forwardRef(function Form(props, ref) {
		const {
			className,
			children,
			defaultValues,
			values,
			analyticsKey,
			onSubmit,
			editing = true,
			mode,
			reValidateMode,
			resetOptions,
			...formProps
		} = props;

		const formRef = React.useRef(null);

		const nextRef = useCombinedRefs(ref, formRef);

		const methods = useForm({
			defaultValues: defaultValues,
			values: values,
			mode: mode,
			reValidateMode: reValidateMode,
			resetOptions: resetOptions,
		});

		const onAnalyticsSubmit = React.useCallback(
			(data) => {
				Analytics.event('form.submit', {
					form: data,
					className: className,
					analyticsKey: analyticsKey || className,
				});

				return onSubmit?.(data);
			},
			[onSubmit, className, analyticsKey],
		);

		const onAnalyticsError = React.useCallback(
			(errors) => {
				const hasError = Object.keys(errors ?? {}).length;

				if (!hasError) {
					return;
				}

				Analytics.event('form.error', {
					errors: errors,
					className: className,
					analyticsKey: analyticsKey || className,
				});
			},
			[analyticsKey, className],
		);

		const handleSubmit = React.useCallback(
			async (event) => {
				event.stopPropagation();

				const data = await methods.handleSubmit(
					onAnalyticsSubmit,
					onAnalyticsError,
				)(event);

				return data;
			},
			[methods, onAnalyticsError, onAnalyticsSubmit],
		);

		const ctx = React.useMemo(() => ({ ref: formRef }), []);

		if (!editing) {
			return children;
		}

		return (
			<FormProvider {...methods}>
				<FormContext.Provider value={ctx}>
					<form
						className={cn('asteria-component__form', className)}
						onSubmit={handleSubmit}
						ref={nextRef}
						{...formProps}
					>
						{children}
					</form>
				</FormContext.Provider>
			</FormProvider>
		);
	}),
);

Form.displayName = 'Form';

Form.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,

	/**
	 * https://react-hook-form.com/docs/useform#mode
	 *
	 * Validation strategy before submitting behaviour.
	 */
	mode: PropTypes.oneOf([
		'onChange',
		'onBlur',
		'onSubmit',
		'onTouched',
		'all',
	]),

	/**
	 * https://react-hook-form.com/docs/useform#reValidateMode
	 *
	 * Validation strategy after submitting behaviour.
	 */
	reValidateMode: PropTypes.oneOf(['onChange', 'onBlur', 'onSubmit']),

	/**
	 * https://react-hook-form.com/docs/useform#defaultValues
	 *
	 * Default values for the form.
	 */
	defaultValues: PropTypes.object,

	/**
	 * https://react-hook-form.com/docs/useform#values
	 *
	 * Reactive values to update the form values.
	 */
	values: PropTypes.object,

	analyticsKey: PropTypes.string,
	onSubmit: PropTypes.func,
	editing: PropTypes.bool,

	resetOptions: PropTypes.object,
};

ComponentService.register('Form', Form, {
	className: { type: 'string' },
	children: { type: 'node' },
	mode: {
		type: 'enum',
		options: ['onChange', 'onBlur', 'onSubmit', 'onTouched', 'all'],
	},
	reValidateMode: {
		type: 'enum',
		options: ['onChange', 'onBlur', 'onSubmit'],
	},
	defaultValues: { type: 'object' },
	values: { type: 'object' },
	analyticsKey: { type: 'string' },
	onSubmit: { type: 'func' },
	editing: { type: 'boolean' },
	resetOptions: { type: 'object' },
});

export default Form;
