import React from 'react';

import PropTypes from 'prop-types';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const SalariesLoading = React.memo(function SalariesLoading(props) {
	const { importing, loading } = props;

	return (
		<div className="flex flex-col gap-4">
			<Content.Title {...props} />
			<Content.Text
				{...props}
				showLoading
				loading={importing || loading}
			/>
		</div>
	);
});

SalariesLoading.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	importing: PropTypes.bool,
	loading: PropTypes.bool,
	translationOptions: PropTypes.object,
};

export default SalariesLoading;
