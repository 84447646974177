import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

const WhatsNewModal = (props) => {
	const { onAction, onSubmit, open, onClose, className } = props;

	const release = useConfig('release.content', { deep: true });

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	// const imageUrl = useConfig(['pages.insights.release.image'], { deep: true });

	const title = TranslationService.getV2(['whats.new.modal.title'], {
		default: 'Insights – better overview and easier decisions.',
	});

	return (
		<Modal
			className={cn('asteria-component__whats-new-modal', className)}
			open={open}
			onClose={onClose}
			size="xl"
		>
			<Wrapper scroll>
				<Header onClose={onClose}>{title}</Header>
				<Content>
					<div className="space-y-8">
						<section className="mb-8">
							<Contenter content={release} actions={actions} />
						</section>
					</div>
				</Content>
				<Footer>
					<FooterSection position="last">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
							])}
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

WhatsNewModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
};

export default WhatsNewModal;
