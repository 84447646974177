import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const ForecastPrefix = (props) => {
	const { className } = props;

	return (
		<svg width="12" height="12" className={className}>
			<defs>
				<pattern
					id="forecast"
					patternUnits="userSpaceOnUse"
					width="6"
					height="6"
					patternTransform="rotate(45)"
				>
					<rect width="6" height="6" fill="rgb(237, 237, 237)" />
					<rect width="3" height="6" fill="rgba(0, 0, 0, 0.2)" />
				</pattern>
			</defs>
			<rect
				width="12"
				height="12"
				style={{
					fill: `url(#forecast)`,
				}}
			/>
		</svg>
	);
};

ForecastPrefix.propTypes = {
	className: PropTypes.string,
};

export default ForecastPrefix;
