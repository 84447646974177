import React from 'react';

import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Icon from '@asteria/component-core/icon';
// import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import BetaSign from '@asteria/component-beta-sign';
import Contenter from '@asteria/component-tools/contenter';

import { useTranslationFn } from '@asteria/language/hooks';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';
import { useLanguages } from '@asteria/utils-hooks/useLanguages';

import { AuthContext } from '../context';

import './styles.scss';

const Header = React.memo(function Header(props) {
	const languages = useLanguages();

	const { onAction } = props;
	const translate = useTranslationFn();

	const [language, setLanguage] = React.useState(
		localStorage.getItem('asteriaLanguage') ?? 'sv',
	);

	const { onLanguageChange, languages: withLanguages } =
		React.useContext(AuthContext);

	const content = useConfig('pages.auth.header.config', { deep: true });

	const actions = React.useMemo(() => ({ onAction }), [onAction]);

	const onChange = React.useMemo(
		() => (code) => () => {
			localStorage.setItem('asteriaLanguage', code);

			setLanguage(code);

			return onLanguageChange?.(code);
		},
		[onLanguageChange],
	);

	return (
		<div
			className={cn(
				'asteria-component__auth-header',
				'h-16 bg-white flex-row w-full shadow items-center justify-between gap-4 px-2 md:px-20 z-10',
			)}
		>
			<div className="flex flex-row gap-4 items-center">
				<Contenter content={content} actions={actions} />
				<Text size="sm">{translate('login.header.title')}</Text>
			</div>
			<div className="flex items-center gap-8">
				{withLanguages ? (
					<Dropdown
						className="asteria-component__dropdown-language"
						toggle={{
							icon: 'triangle-down',
							iconActive: 'triangle-up',
							iconPosition: 'last',
							children: <Icon icon="world" />,
						}}
					>
						{languages.map((item) => (
							<DropdownItem
								key={item.code}
								size="sm"
								active={item.code === language}
								onClick={onChange(item.code)}
							>
								{item.name}
							</DropdownItem>
						))}
					</Dropdown>
				) : null}
				<BetaSign className="pointer-events-none" />
			</div>
		</div>
	);
});

Header.displayName = 'Header';

Header.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Header;
