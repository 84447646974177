import React from 'react';

import { useMutation } from '@tanstack/react-query';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Translation, TranslationService } from '@asteria/language';

import Modal from './modal';

/**
 * @typedef { import('./types').Props } BaseProps
 *
 * @typedef Props
 * @property { { postfix?: unknown, data?: unknown } } options
 * @property { import('react').MouseEventHandler } onDiscard
 * @property { import('react').MouseEventHandler } onSubmit
 */

/** @type { React.FC<Omit<BaseProps, 'children'> & Props> } */
const ConfirmationModal = React.memo(function ConfirmationModal(props) {
	const { options, onClose, onDiscard = onClose, onSubmit = onClose } = props;

	const discard = useMutation({ mutationFn: onDiscard });
	const submit = useMutation({ mutationFn: onSubmit });

	return (
		<Modal size="sm" {...props}>
			<Wrapper>
				<Header onClose={onClose}>
					{TranslationService.getV2(
						['modal.confirmation.title'],
						options,
					)}
				</Header>
				<Content>
					<Translation
						translationKey="modal.confirmation.content"
						translationOptions={options}
						Component={Text}
					/>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.getV2(
								['modal.confirmation.action'],
								{
									...options,
									postfix: {
										action: 'discard',
										...options?.postfix,
									},
								},
							)}
							onClick={discard.mutate}
							loading={discard.isLoading}
							disabled={discard.isLoading || submit.isLoading}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.getV2(
								['modal.confirmation.action'],
								{
									...options,
									postfix: {
										action: 'submit',
										...options?.postfix,
									},
								},
							)}
							onClick={submit.mutate}
							loading={submit.isLoading}
							disabled={discard.isLoading || submit.isLoading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
});

ConfirmationModal.displayName = 'ConfirmationModal';

ConfirmationModal.propTypes = { ...Modal.propTypes };

export default ConfirmationModal;
