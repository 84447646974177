import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import List, { ListCell, ListFilter, ListItem } from '@asteria/component-list';

import { Translation } from '@asteria/language';

/**
 * @typedef FilterItemProps
 * @property { string } name
 * @property { object } filter
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 */

/** @type { React.FC<FilterItemProps> } */
const FilterItem = React.memo(function ModalContent(props) {
	const { filter, name, onAction } = props;

	const sortBy = React.useCallback(() => {
		return onAction?.('list:filter', { field: name });
	}, [name, onAction]);

	return (
		<ListCell className={`asteria--type-${name}`}>
			<div
				onClick={sortBy}
				className="flex w-full flex-row items-center gap-2"
			>
				<Translation
					translationKey={'card.salaries.employees.list.title'}
					translationOptions={{
						postfix: { field: name },
					}}
					Component={Text}
				/>
				{filter?.field === name && filter?.direction === 'ASC' ? (
					<Icon icon="triangle-down" />
				) : null}
				{filter?.field === name && filter?.direction === 'DESC' ? (
					<Icon icon="triangle-up" />
				) : null}
			</div>
		</ListCell>
	);
});

FilterItem.propTypes = {
	name: PropTypes.object,
	filter: PropTypes.object,
	onAction: PropTypes.func,
};

/**
 * @typedef Props
 * @property { object } data
 * @property { object } translationOptions
 * @property { object } filter
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 */

/** @type { React.FC<Props> } */
const EmployeeList = React.memo(function ModalContent(props) {
	const { data, filter, onAction } = props;

	return (
		<List className="asteria-component__employees-list">
			<ListFilter>
				<ListItem>
					<FilterItem
						name="name"
						filter={filter}
						onAction={onAction}
					/>
					<FilterItem
						name="status"
						filter={filter}
						onAction={onAction}
					/>
					<FilterItem
						name="salary"
						filter={filter}
						onAction={onAction}
					/>
				</ListItem>
			</ListFilter>
			{data?.map((object) => {
				return (
					<ListItem key={object?._id ?? object?.id}>
						<ListCell className="asteria--type-name">
							<Text>{object?.name}</Text>
						</ListCell>
						<ListCell className="asteria--type-status">
							<Text>{object?.status}</Text>
						</ListCell>
						<ListCell className="asteria--type-salary">
							<div className="flex flex-col gap-1">
								<Text>{object?.salary?.current}</Text>
								<Text size="sm">
									{object?.salary?.forecast}
								</Text>
							</div>
						</ListCell>
					</ListItem>
				);
			})}
		</List>
	);
});

EmployeeList.propTypes = {
	data: PropTypes.object,
	filter: PropTypes.object,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
};

export default EmployeeList;
