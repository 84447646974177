import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title, UnorderedList } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Form from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import MigrationWrapper from '../wrapper';

import './styles.scss';

const MigrationBannerList = React.memo(function MigrationBannerList() {
	const listValues = React.useMemo(
		() => [
			{
				icon: 'check',
				value: TranslationService.getV2('migration.banner.list.item-1'),
			},
			{
				icon: 'check',
				value: TranslationService.getV2('migration.banner.list.item-2'),
			},
			{
				icon: 'check',
				value: TranslationService.getV2('migration.banner.list.item-3'),
			},
			{
				icon: 'check',
				value: TranslationService.getV2('migration.banner.list.item-4'),
			},
		],
		[],
	);

	return (
		<div
			className={cn(
				'flex flex-1 flex-col gap-2',
				'asteria-component__migration-banner-list',
			)}
		>
			<Text size="md">
				{TranslationService.getV2(['migration.banner.list.title'])}
			</Text>
			<UnorderedList className="flex flex-col gap-1" items={listValues} />
		</div>
	);
});

MigrationBannerList.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/**
 * @typedef { <TResponse = Promise<unknown>>(action: string, data?: unknown) => TResponse } onAction
 *
 * @typedef Props
 * @property { string } className
 * @property { onAction } onAction
 * @property { onAction } onSubmit
 */

/** @type { React.FC<Props> } */
const MigrationBanner = React.memo(function MigrationBanner(props) {
	const { className, onSubmit } = props;

	const [error, setError] = React.useState(null);

	const privateEmail = useSelector(
		(store) => AppStore.selectors.user(store)?.settings?.privateEmail,
	);

	const defaultValues = React.useMemo(
		() => ({
			username: privateEmail,
			newpassword: '',
		}),
		[privateEmail],
	);

	const updateUser = useMutation({
		mutationFn: async (form) => {
			return onSubmit?.('user:settings:save', {
				form: form,
			});
		},
		onError: (error) => {
			return setError?.(error?.message);
		},
	});

	return (
		<FeatureFlag feature="migration-banner">
			<MigrationWrapper>
				<Wrapper
					className={cn(
						'w-full rounded-lg',
						'asteria-component__migration-banner',
						className,
					)}
				>
					<Header>
						<Title size="md">
							{TranslationService.getV2([
								'migration.banner.title',
							])}
						</Title>
					</Header>
					<Content className="gap-4">
						<Text size="md">
							{TranslationService.getV2([
								'migration.banner.subtitle',
							])}
						</Text>
						<div className="flex flex-row gap-2">
							<MigrationBannerList />
							<div
								className={cn(
									'flex w-full flex-1 bg-white rounded-lg border border-solid border-wrapper-normal-border p-6',
									'asteria-component__migration-banner-form',
								)}
							>
								<Form
									className="gap-4"
									defaultValues={defaultValues}
									onSubmit={updateUser.mutate}
								>
									{error && (
										<Alert level="error" className="py-2">
											<Text size="sm">{error}</Text>
										</Alert>
									)}
									<Input
										size="sm"
										name="username"
										type="email"
										placeholder={TranslationService.getV2(
											'migration.banner.input.name.placeholder',
										)}
										required
									/>
									<Input
										size="sm"
										name="newpassword"
										type="password"
										placeholder={TranslationService.getV2(
											'migration.banner.input.password.placeholder',
										)}
										required
									/>
									<Button
										className="self-start"
										variant="primary"
										label={TranslationService.getV2(
											'migration.banner.button.submit',
										)}
										size="sm"
										type="submit"
										onClick={onSubmit}
									/>
								</Form>
							</div>
						</div>
					</Content>
				</Wrapper>
			</MigrationWrapper>
		</FeatureFlag>
	);
});

MigrationBanner.displayName = 'MigrationBanner';

MigrationBanner.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MigrationBanner;
