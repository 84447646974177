import React from 'react';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import { FormContext } from './context';
import { useDebounceFormValues } from './hooks';

/**
 * @type { React.FC<{ debounce: number }> }
 */
const AutoSave = React.memo(function AutoSave(props) {
	const { debounce = 0 } = props;

	const initialRef = React.useRef(false);
	const ctx = React.useContext(FormContext);

	const form = useDebounceFormValues({ delay: debounce });

	const onSubmit = React.useCallback(
		() =>
			ctx?.ref?.current?.dispatchEvent?.(
				new Event('submit', { cancelable: true, bubbles: true }),
			),
		[ctx?.ref],
	);

	React.useEffect(() => {
		if (initialRef.current) {
			onSubmit();
		}

		initialRef.current = true;
	}, [onSubmit, debounce, form]);

	return null;
});

AutoSave.displayName = 'AutoSave';

AutoSave.propTypes = { debounce: PropTypes.number };

ComponentService.register('FormAutoSave', AutoSave, {});

export default AutoSave;
