import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { sizeClasses } from '@asteria/component-core/utils';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';
import useColors from '@asteria/utils-hooks/useColors';

import './styles.scss';

/**
 * @typedef Props
 * @property { string | string[] } colors
 * @property { React.ReactNode } tooltip
 * @property { string } className
 * @property { boolean } custom
 * @property { import('react').MouseEventHandler } onClick
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { unknown } style
 */

/** @type { React.FC<Props> } */
const Prefix = React.memo(function Prefix(props) {
	const { colors = '', tooltip, className, onClick, custom, size } = props;

	const { custom: customColors, typed: typedColors } = useColors(colors);

	const style = React.useMemo(() => {
		if (!customColors.length) {
			return {};
		}

		return { backgroundColor: customColors };
	}, [customColors]);

	return (
		<TooltipWrapper tooltip={tooltip}>
			<span
				className={cn(
					'asteria-component__prefix',
					{ 'asteria--state-custom': custom },
					sizeClasses({ size: size }),
					typedColors,
					className,
				)}
				style={{ ...props?.style, ...style }}
				onClick={onClick}
			/>
		</TooltipWrapper>
	);
});

Prefix.displayName = 'Prefix';

Prefix.propTypes = {
	className: PropTypes.string,
	colors: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	tooltip: PropTypes.node,
	onClick: PropTypes.func,
	size: SizeProp(),
	custom: PropTypes.bool,
	style: PropTypes.object,
};

ComponentService.register('Prefix', Prefix, {
	className: { type: 'string' },
	colors: { type: 'array', of: { type: 'string' } },
	tooltip: { type: 'node' },
	onClick: { type: 'function' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
});

export default Prefix;
