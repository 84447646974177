/**
 * @param { import('./types').CashflowValue } deposit
 * @param { import('./types').CashflowValue } withdraw
 */
function format(deposit, withdraw) {
	const paid =
		(deposit?.PAID?.sums?.original?.total ?? 0) -
		(withdraw?.PAID?.sums?.original?.total ?? 0);

	const forecast =
		(deposit?.FORECAST?.sums?.original?.total ?? 0) -
		(withdraw?.FORECAST?.sums?.original?.total ?? 0);

	return {
		statistics: {
			paid: {
				total: paid * -1,
				currency:
					deposit?.PAID?.sums?.original?.currency ??
					withdraw?.PAID?.sums?.original?.currency ??
					'SEK',
			},
			forecast: {
				total: forecast * -1,
				currency:
					deposit?.FORECAST?.sums?.original?.currency ??
					withdraw?.FORECAST?.sums?.original?.currency ??
					'SEK',
			},
			trends: forecast !== 0 ? (paid / forecast) * 100 : 0,
		},
	};
}

export default format;
