import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Slider from '../../../components/slider';
import { Content } from '../../basic';

import HealthTrends from './components/trends';

import '../styles.scss';

const HealthExtra = React.memo(function HealthExtra(props) {
	const { query, translationOptions } = props;

	const hiddenActions = useSelector(
		(store) => store?.app?.user?.settings?.flags?.['actions:hidden'] ?? [],
		isEqual,
	);

	const filteredActions = React.useMemo(() => {
		const actions = query?.data?.source?.actions ?? [];

		return actions.filter(
			({ type }) =>
				!hiddenActions.some(
					({ type: hiddenType }) => hiddenType === type,
				),
		);
	}, [hiddenActions, query?.data?.source?.actions]);

	return (
		<div className="flex flex-col gap-4">
			<HealthTrends {...props} />
			<Slider {...props} items={filteredActions} />

			{translationOptions?.postfix?.onboarding !== 'both' ? (
				<Content.Text {...props} className="text-center" />
			) : null}
		</div>
	);
});

HealthExtra.displayName = 'HealthExtra';

HealthExtra.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	query: PropTypes.object,
	translationOptions: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default HealthExtra;
