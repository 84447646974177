import React from 'react';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

/**
 * @typedef Props
 * @property { React.ReactNode } children
 * @property { string } className
 */

/** @type { React.FC<Props> } */
const MultiPrefix = React.memo(
	React.forwardRef(function MultiPrefix(props, ref) {
		const { children, className } = props;

		return (
			<div
				className={cn('asteria-component__multi-prefix', className)}
				ref={ref}
			>
				{React.Children.toArray(children).reverse()}
			</div>
		);
	}),
);

MultiPrefix.displayName = 'MultiPrefix';

MultiPrefix.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

ComponentService.register('MultiPrefix', MultiPrefix, {
	className: { type: 'string' },
	children: { type: 'node' },
});

export default MultiPrefix;
