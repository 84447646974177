import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as FinancialStore from '@asteria/datalayer/stores/financial';
import * as ModalStore from '@asteria/datalayer/stores/modals';

export function useNavigation({ navigate, onForecasterValidate }) {
	const dispatch = useDispatch();

	const location = useLocation();
	const pathnameRef = React.useRef(location.pathname);

	const settingsAsPage = useFeature('menu-item-settings-view');
	const statementAsPage = useFeature('menu-item-statement-view');
	const overviewAsPage = useFeature('menu-item-overview-view');
	const accountsAsPage = useFeature('menu-item-accounts-view');
	const clientsAsPage = useFeature('menu-item-clients-view');

	React.useEffect(() => {
		pathnameRef.current = location.pathname;
	}, [location.pathname]);

	const go = useCallback(
		(path, data) => {
			if (typeof path === 'number') {
				return navigate(path, data);
			}

			if (path.startsWith('/forecaster')) {
				return navigate(path);
			}

			if (path.startsWith('/insights')) {
				return navigate(path, data);
			}

			if (path.startsWith('/financial')) {
				const parts = path.split('/').filter(Boolean);

				const type = parts?.[1];

				if (type) {
					dispatch(FinancialStore.navigation.go({ type: type }));
				}

				navigate('/financial', data);

				return;
			}

			if (path === '/credit') {
				dispatch(FinancialStore.navigation.go({ type: 'credit' }));
				navigate('/financial/credit', data);

				return;
			}

			if (path === '/onboarding') {
				navigate('/onboarding', data);
				return;
			}

			if (path.startsWith('/onboarding')) {
				navigate(path, {
					...data,
					state: { ...data?.state, backgroundLocation: location },
				});

				return;
			}

			if (path === '/clients') {
				if (clientsAsPage) {
					return navigate('/clients', {
						...data,
						defaultValues:
							data?.defaultValues ?? data?.state?.defaultValues,
						values: data?.values ?? data?.state?.values,
					});
				}

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.ClientOverview,
						data: {
							defaultValues:
								data?.defaultValues ??
								data?.state?.defaultValues,
							values: data?.values ?? data?.state?.values,
						},
					}),
				);
			}

			if (path === '/accounts') {
				if (accountsAsPage) {
					return navigate('/accounts', data);
				}

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.BankAccounts,
					}),
				);
			}

			// if (path === '/welcome') {
			// 	return dispatch(
			// 		ModalStore.open({
			// 			type: ModalStore.MODAL_WINDOWS.ViewWelcome,
			// 		}),
			// 	);
			// }

			if (path === '/settings') {
				if (settingsAsPage) {
					return navigate('/settings', data);
				}

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.Settings,
					}),
				);
			}

			if (path === '/guide') {
				return dispatch(
					ModalStore.open({ type: ModalStore.MODAL_WINDOWS.Guide }),
				);
			}

			if (path === '/support') {
				return dispatch(
					ModalStore.open({ type: ModalStore.MODAL_WINDOWS.Support }),
				);
			}

			if (path === '/overview') {
				if (overviewAsPage) {
					return navigate('/overview', data);
				}

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.Currency,
					}),
				);
			}

			if (path === '/integrations') {
				return go('/onboarding');
			}

			if (path === '/integrations/connect') {
				return go('/onboarding/connect');
			}

			if (path === '/integrations/add/erp') {
				return go('/onboarding/connect', { state: { type: 'erp' } });
			}

			if (path === '/integrations/add/bank') {
				return go('/onboarding/connect', { state: { type: 'bank' } });
			}

			if (path.startsWith('/integrations/edit/')) {
				const [, , , id] = path.split('/');
				return go(`/onboarding/${id}`);
			}

			if (path === '/statement') {
				if (statementAsPage) {
					return navigate('/statement', data);
				}

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.Statement,
					}),
				);
			}

			if (
				pathnameRef.current === '/forecaster' &&
				!data?.skipValidation
			) {
				onForecasterValidate('/');
			} else {
				navigate('/', data);
			}

			if (path === '/logout') {
				window.localStorage.removeItem('wingsToken');
				window.localStorage.removeItem('wingsRefreshToken');

				const url = new URL('', window.location.origin);
				url.search = window.location.search;

				window.location.replace(url.toString());
			}
		},
		[dispatch, location, navigate, onForecasterValidate],
	);

	return go;
}
