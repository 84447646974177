import { useCallback, useEffect, useMemo, useState } from 'react';

import { isAfter, parseISO } from 'date-fns';

import Analytics from '@asteria/utils-analytics';

/**
 * @param { unknown } value
 * @returns { Date }
 */
export function useCurrentDate(value) {
	return useMemo(() => {
		let date = null;

		if (value?.startDate) {
			date = parseISO(value?.startDate);
		}

		if (Array.isArray(value)) {
			date = parseISO(value[0]);
		}

		if (typeof value === 'string') {
			date = parseISO(value);
		}

		if (!date) {
			return new Date();
		}

		return date;
	}, [value]);
}

export function useDatepickerValue(props) {
	let { value } = props;

	if (typeof value === 'string' && value.includes('T')) {
		value = value.split('T')[0];
	}

	return useMemo(() => value, [value]);
}

/**
 * @param {*} props
 * @returns { [ boolean, { open: import('react').MouseEventHandler, close: import('react').MouseEventHandler } ] }
 */
export function useOpenState(props) {
	const { name, active, onOpen, onClose, analyticsKey } = props;

	const [isOpen, setOpen] = useState(false);

	useEffect(() => {
		if (active !== undefined) {
			setOpen(active);
		}
	}, [active]);

	const handleOpen = useCallback(
		(event) => {
			Analytics.event('form.datepicker.open', {
				name: name,
				analyticsKey: analyticsKey || name,
			});

			event?.preventDefault();
			event?.stopPropagation();

			if (!active) {
				setOpen(true);

				if (onOpen) {
					onOpen(event);
				}
			}
		},
		[active, analyticsKey, name, onOpen],
	);

	const handleClose = useCallback(
		(event) => {
			Analytics.event('form.datepicker.close', {
				name: name,
				analyticsKey: analyticsKey || name,
			});

			event?.preventDefault();
			event?.stopPropagation();

			if (!active) {
				setOpen(false);

				if (onClose) {
					onClose(event);
				}
			}
		},
		[active, analyticsKey, name, onClose],
	);

	return [
		isOpen,
		useMemo(
			() => ({ open: handleOpen, close: handleClose }),
			[handleClose, handleOpen],
		),
	];
}

/**
 * @param { unknown } props
 * @returns {[string, () => import('react').Dispatch<string>]}
 */
export function useDatepickerType(props) {
	const { type: propType = 'day', types } = props;

	const [type, setType] = useState(() => {
		if (types?.length) {
			if (types.includes(propType)) {
				return propType;
			}

			return types[0];
		}

		return propType;
	});

	useEffect(() => {
		setType(() => {
			if (types?.length) {
				if (types.includes(propType)) {
					return propType;
				}

				return types[0];
			}

			return propType;
		});
	}, [propType]);

	return [type, setType];
}

/**
 * @param { unknown } props
 * @returns { (value: string, extra?: unknown) => void }
 */
export function useDefaultChange(props) {
	const { name, onChange, analyticsKey } = props;

	return useCallback(
		(value, extra) => {
			Analytics.event('form.datepicker.change', {
				value: value,
				name: name,
				analyticsKey: analyticsKey || name,
				extra: extra,
			});

			return onChange?.({
				target: { name: name, value: value },
				extra: extra,
			});
		},
		[analyticsKey, name, onChange],
	);
}

/**
 * @param { unknown } props
 * @param {{ value: unknown, onClose: import('react').MouseEventHandler, repeatable: unknown, onDefaultChange: ReturnType<typeof useDefaultChange> }} options
 * @returns { (date: unknown) => void }
 */
export function useChange(
	props,
	{ value, onClose, repeatable, onDefaultChange },
) {
	const { variant = 'default' } = props;

	return useCallback(
		(date) => {
			if (date === null || variant === 'default') {
				onDefaultChange(date, { repeatable: repeatable });
				onClose();
				return;
			}

			if (variant === 'multiple') {
				let values = [].concat(value).filter(Boolean);

				const index = values.indexOf(date);

				if (index === -1) {
					values = [...values, date];
				} else {
					values.splice(index, 1);
				}

				onDefaultChange(values, { repeatable: repeatable });
				return;
			}

			if (variant === 'range') {
				let values = value;

				if (
					!values?.startDate ||
					(values?.startDate && values?.endDate)
				) {
					values = { startDate: date, endDate: null };
				} else {
					values.endDate = date;
				}

				const { startDate, endDate } = values;

				if (
					startDate &&
					endDate &&
					isAfter(parseISO(startDate), parseISO(endDate))
				) {
					values = { startDate: endDate, endDate: startDate };
				}

				onDefaultChange(values, { repeatable: repeatable });
				return;
			}
		},
		[variant, onDefaultChange, repeatable, onClose, value],
	);
}

/**
 *
 * @param { unknown } props
 * @param { { value: unknown, onDefaultChange: ReturnType<typeof useDefaultChange> } } options
 * @returns { (date: unknown) => void }
 */
export function useChipDismiss(props, { value, onDefaultChange }) {
	const { variant = 'default' } = props;

	return useCallback(
		(date) => {
			if (variant === 'multiple') {
				let values = [...value];

				const index = values.indexOf(date);
				values.splice(index, 1);

				onDefaultChange(values);
			}

			if (variant === 'range') {
				const { startDate, endDate } = value;

				let values = { startDate: startDate, endDate: endDate };

				if (date === startDate) {
					values.startDate = null;
				}

				if (date === endDate) {
					values.endDate = null;
				}

				onDefaultChange(values);
			}
		},
		[variant, value, onDefaultChange],
	);
}
