import React, { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';
import { Content } from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import { Wrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import LanguageSelector from '../language';

import RemoveButton from './RemoveButton';
import User2FA from './User2FA';

const UserSettings = (props) => {
	const { onAction, onSubmit = onAction, active, error, extra } = props;

	const { getValues, setError, clearErrors } = useFormContext();

	const handleChange = React.useCallback(() => {
		const { password, newpassword, passwordConfirm } = [
			'password',
			'newpassword',
			'passwordConfirm',
		].reduce(
			(acc, key) => ({
				...acc,
				[key]: getValues(['user', key].join('.')),
			}),
			{},
		);

		let shouldResetPasswordError = false,
			shouldResetConfirmPasswordError = false;

		if (newpassword === passwordConfirm) {
			shouldResetConfirmPasswordError = true;
		}

		if (!password && (newpassword || passwordConfirm)) {
			setError('user.password', {
				type: 'required',
			});
		} else {
			shouldResetPasswordError = true;
		}

		const errors = [
			shouldResetPasswordError ? 'user.password' : null,
			shouldResetConfirmPasswordError ? 'user.passwordConfirm' : null,
		].filter(Boolean);

		if (errors.length) {
			clearErrors(errors);
		}
	}, [clearErrors, getValues, setError]);

	const newPassword = getValues('user.newpassword');
	const passwordConfirm = getValues('user.passwordConfirm');

	const handleBlur = useCallback(() => {
		if (newPassword && passwordConfirm && newPassword !== passwordConfirm) {
			setError('user.passwordConfirm', {
				message: TranslationService.get(
					'signup.password.error.mismatch',
				),
				type: 'error',
			});
		} else {
			clearErrors('user.passwordConfirm');
		}
	}, [clearErrors, newPassword, passwordConfirm, setError]);

	return (
		<Wrapper>
			<Content>
				{error && (
					<Alert level="error">
						<Text>{error}</Text>
					</Alert>
				)}
				<FeatureFlag feature="user-settings-language">
					<LanguageSelector onAction={onAction} />
				</FeatureFlag>

				<Input
					type="text"
					name="user.fullName"
					label={TranslationService.get('signup.fullName.label')}
					placeholder={TranslationService.get(
						'signup.fullName.placeholder',
					)}
					direction="vertical"
				/>

				{extra}

				<Input
					type="password"
					name="user.password"
					label={{
						value: TranslationService.get(
							'signup.oldPassword.label',
						),
						tooltip: TranslationService.get(
							'signup.changePasswordHint',
							'If you would like to change the password type a new one. Otherwise leave this blank.',
						),
					}}
					placeholder={TranslationService.get(
						'signup.oldPassword.placeholder',
					)}
					direction="vertical"
					onChange={handleChange}
					required={newPassword || passwordConfirm}
				/>
				<Input
					type="password"
					name="user.newpassword"
					label={TranslationService.get('signup.newPassword.label')}
					placeholder={TranslationService.get(
						'signup.newPassword.placeholder',
					)}
					direction="vertical"
					onChange={handleChange}
					onBlur={handleBlur}
					required={passwordConfirm}
				/>
				<Input
					type="password"
					name="user.passwordConfirm"
					label={TranslationService.get('signup.rePassword.label')}
					placeholder={TranslationService.get(
						'signup.rePassword.placeholder',
					)}
					direction="vertical"
					onChange={handleChange}
					onBlur={handleBlur}
					required={newPassword}
				/>

				<User2FA onAction={onAction} onSubmit={onSubmit} />

				<FeatureFlag feature="settings-user-remove">
					<RemoveButton key={active} onAction={onAction} />
				</FeatureFlag>
			</Content>
		</Wrapper>
	);
};

UserSettings.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	active: PropTypes.string,
	error: PropTypes.string,
	extra: PropTypes.node,
};

export default UserSettings;
