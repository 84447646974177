import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import { useGenericProperties } from '@asteria/component-feedback';
import FeedbackModal from '@asteria/component-feedback/FormModal';
import FeedbackSuccessModal from '@asteria/component-feedback/SuccessModal';

import { TranslationService } from '@asteria/language';

import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

function useButtonConfig(props) {
	const { version, config } = props;

	return getVersion(config?.card?.footer?.discard?.button, version);
}

/** @type { React.FC<ChildrenProps> } */
const ActionDiscard = React.memo(function ActionDiscard(props) {
	const { type, onAction, translationOptions } = props;

	const config = useButtonConfig(props);

	const onFeedbackSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	const handleFeedbackSubmit = React.useCallback(
		(data) => {
			onAction?.('updateFeedback', {
				...data,
				feedbackKey: `card-dismiss-${type}`,
			});

			return onAction?.('card:set', { visible: false });
		},
		[onAction, type],
	);

	const { state, loading, onOpen, onClose, onSubmit, rating } =
		useGenericProperties({ onSubmit: handleFeedbackSubmit });

	if (config === false) {
		return null;
	}

	const analyticsKey =
		config?.analyticsKey ?? type
			? `card.${type}.footer.action.dismiss`
			: 'card.footer.action.dismiss';

	return (
		<>
			<FooterSection position="first">
				<Button
					variant={config?.variant ?? 'link'}
					size={config?.size ?? 'sm'}
					label={
						config?.label ??
						TranslationService.getV2(['card.footer.action'], {
							...translationOptions,
							postfix: {
								action: 'dismiss',
								...translationOptions.postfix,
							},
						})
					}
					icon={config?.icon}
					iconPosition={config?.iconPosition}
					analyticsKey={analyticsKey}
					analytics={translationOptions?.data}
					onClick={onOpen}
				/>
			</FooterSection>
			<FeedbackModal
				open={state === 'OPEN'}
				type="card-dismiss"
				feedbackKey={`card-dismiss-${type}`}
				onClose={onClose}
				onSubmit={onSubmit}
				onSupportRequest={onFeedbackSupportRequest}
				loading={loading}
				rating={rating}
				translationOptions={{
					data: {
						label: TranslationService.getV2(
							['card.title'],
							translationOptions,
						),
					},
				}}
			/>
			<FeedbackSuccessModal
				key="feedback-success-modal"
				open={state === 'DONE'}
				type="generic"
				onClose={onClose}
			/>
		</>
	);
});

ActionDiscard.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
	config: PropTypes.object,
};

export default withConfig(ActionDiscard, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.discard, version);
});
