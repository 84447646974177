import React from 'react';

import PropTypes from 'prop-types';

import Prefix, { ForecastPrefix } from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';

import '../../styles.scss';

/**
 * @type { React.FC<{ hasActual?: boolean, hasForecast?: boolean }> }
 */
const SalaryLegend = ({ hasActual, hasForecast }) => {
	if (!hasActual && !hasForecast) {
		return null;
	}

	return (
		<div className="flex flex-wrap justify-center gap-4 mt-4">
			{hasActual ? (
				<div className="flex items-center gap-2">
					<Prefix colors={['salaries-salary']} />
					<span className="text-content-text-muted">
						{TranslationService.getV2('tags.salaries.salary')}
					</span>
				</div>
			) : null}

			{hasForecast ? (
				<div className="flex items-center gap-2">
					<ForecastPrefix className="asteria-component__graph-legend-prefix" />
					<span className="text-content-text-muted">
						{TranslationService.getV2(
							'graph.account.legend.forecast',
						)}
					</span>
				</div>
			) : null}
		</div>
	);
};

SalaryLegend.propTypes = {
	hasActual: PropTypes.bool,
	hasForecast: PropTypes.bool,
};

export default SalaryLegend;
