import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import List, { ListCell, ListFilter, ListItem } from '@asteria/component-list';
import Prefix from '@asteria/component-prefix';

import { Translation } from '@asteria/language';

import { getSalaryColor } from '../utils';

/**
 * @typedef FilterItemProps
 * @property { string } name
 * @property { object } filter
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 */

/** @type { React.FC<FilterItemProps> } */
const FilterItem = React.memo(function ModalContent(props) {
	const { filter, name, onAction } = props;

	const sortBy = React.useCallback(() => {
		return onAction?.('list:filter', { field: name });
	}, [name, onAction]);

	return (
		<ListCell className={`asteria--type-${name}`}>
			<div
				onClick={sortBy}
				className="flex w-full flex-row items-center gap-2"
			>
				<Translation
					translationKey={'card.salaries.employees.list.title'}
					translationOptions={{
						postfix: { field: name },
					}}
					weight="medium"
					Component={Text}
				/>
				{filter?.field === name && filter?.direction === 'ASC' ? (
					<Icon icon="triangle-down" />
				) : null}
				{filter?.field === name && filter?.direction === 'DESC' ? (
					<Icon icon="triangle-up" />
				) : null}
				{filter?.field !== name ? <div className="h-4 w-4" /> : null}
			</div>
		</ListCell>
	);
});

FilterItem.propTypes = {
	name: PropTypes.object,
	filter: PropTypes.object,
	onAction: PropTypes.func,
};

/**
 * @typedef Props
 * @property { object } data
 * @property { object } translationOptions
 * @property { object } filter
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 */

/** @type { React.FC<Props> } */
const EmployeeList = React.memo(function ModalContent(props) {
	const { data, filter, onAction } = props;

	return (
		<List className="asteria-component__employees-list">
			<ListFilter>
				<ListItem>
					<FilterItem
						name="name"
						filter={filter}
						onAction={onAction}
					/>
					<FilterItem
						name="status"
						filter={filter}
						onAction={onAction}
					/>
					<FilterItem
						name="salary"
						filter={filter}
						onAction={onAction}
					/>
				</ListItem>
			</ListFilter>
			{data?.map((object, index) => {
				return (
					<ListItem key={object?._id ?? object?.id}>
						<ListCell className="asteria--type-name flex flex-row gap-2 items-center">
							<Prefix
								custom
								className={getSalaryColor?.({
									index: index,
									variant: 'bg',
								})}
							/>
							<Translation
								translationKey={
									'card.salaries.employees.list.content'
								}
								translationOptions={{
									postfix: { field: 'name' },
									data: object,
								}}
								Component={Text}
							/>
						</ListCell>
						<ListCell className="asteria--type-status">
							<Translation
								translationKey={
									'card.salaries.employees.list.content'
								}
								translationOptions={{
									postfix: { field: 'status' },
									data: object,
								}}
								Component={Text}
							/>
						</ListCell>
						<ListCell className="asteria--type-salary">
							<div className="flex flex-col gap-1">
								<Translation
									translationKey={
										'card.salaries.employees.list.content'
									}
									translationOptions={{
										postfix: { field: 'salary-current' },
										data: object,
									}}
									Component={Text}
								/>
								<Translation
									className="text-content-text-muted"
									translationKey={
										'card.salaries.employees.list.content'
									}
									translationOptions={{
										postfix: { field: 'salary-forecast' },
										data: object,
									}}
									size="sm"
									Component={Text}
								/>
							</div>
						</ListCell>
					</ListItem>
				);
			})}
		</List>
	);
});

EmployeeList.propTypes = {
	data: PropTypes.object,
	filter: PropTypes.object,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
};

export default EmployeeList;
