import React from 'react';

import PropTypes from 'prop-types';

import Slideshow from '@asteria/component-core/slideshow';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import CardLoading from '../../../components/loading';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const SalariesLoading = React.memo(function SalariesLoading(props) {
	const { translationOptions, importing } = props;

	if (!importing) {
		return null;
	}

	return (
		<div className="p-2 w-full">
			<CardLoading {...props} buttonProps={{ size: 'sm' }}>
				<Slideshow>
					<Translation
						translationKey="card.content.chart.loading"
						translationOptions={translationOptions}
						Component={Text}
						size="sm"
					/>
					<Translation
						translationKey="card.content.loading.slide.0"
						translationOptions={translationOptions}
						Component={Text}
						size="sm"
					/>
					<Translation
						translationKey="card.content.loading.slide.1"
						translationOptions={translationOptions}
						Component={Text}
						size="sm"
					/>
					<Translation
						translationKey="card.content.loading.slide.2"
						translationOptions={translationOptions}
						Component={Text}
						size="sm"
					/>
				</Slideshow>
			</CardLoading>
		</div>
	);
});

SalariesLoading.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	importing: PropTypes.bool,
	loading: PropTypes.bool,
	translationOptions: PropTypes.object,
};

export default SalariesLoading;
