import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const SalariesPromotion = React.memo(function SalariesPromotion(props) {
	const { translationOptions, importing, loading } = props;

	return (
		<div className="flex flex-col gap-4">
			<Content.Text
				{...props}
				showLoading
				loading={importing || loading}
			/>
			<Translation
				translationKey="card.content.text.promotion.sub"
				translationOptions={translationOptions}
				Component={Text}
				size="sm"
			/>
		</div>
	);
});

SalariesPromotion.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	importing: PropTypes.bool,
	loading: PropTypes.bool,
	translationOptions: PropTypes.object,
};

export default SalariesPromotion;
