import React from 'react';

import PropTypes from 'prop-types';

import Slideshow from '@asteria/component-core/slideshow';
import { Text } from '@asteria/component-core/typography';

import { useIntegrationName } from '@asteria/component-integrations-v2/hooks';

import { Translation } from '@asteria/language';

import CardLoading from '../../../components/loading';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

const LoadingContent = React.memo(function LoadingContent(props) {
	const { translationOptions, integration } = props;

	const integrationName = useIntegrationName(
		integration?.type,
		integration?.key,
	);

	return (
		<Slideshow>
			<Translation
				translationKey="card.content.loading.slide.integration"
				translationOptions={{
					...translationOptions,
					data: {
						...translationOptions?.data,
						integrationName: integrationName,
					},
				}}
				Component={Text}
				size="sm"
			/>
			<Translation
				translationKey="card.content.loading.slide.0"
				translationOptions={translationOptions}
				Component={Text}
				size="sm"
			/>
			<Translation
				translationKey="card.content.loading.slide.1"
				translationOptions={translationOptions}
				Component={Text}
				size="sm"
			/>
			<Translation
				translationKey="card.content.loading.slide.2"
				translationOptions={translationOptions}
				Component={Text}
				size="sm"
			/>
		</Slideshow>
	);
});

LoadingContent.propTypes = {
	translationOptions: PropTypes.object,
	integration: PropTypes.object,
};

/** @type { React.FC<ChildrenProps> } */
const Loading = React.memo(function Loading(props) {
	return (
		<CardLoading {...props} buttonProps={{ size: 'sm' }}>
			{(props) => <LoadingContent {...props} />}
		</CardLoading>
	);
});

Loading.propTypes = {
	translationOptions: PropTypes.object,
	onSubmit: PropTypes.func,
};

export default Loading;
