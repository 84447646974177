import React from 'react';

import PropTypes from 'prop-types';

import Splash from '@asteria/component-splash';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

import MigrationWrapper from '../wrapper';

import '../styles.scss';

/**
 * @typedef { <TResponse = Promise<unknown>>(action: string, data?: unknown) => TResponse } onAction
 *
 * @typedef Props
 * @property { string } className
 * @property { onAction } onAction
 * @property { onAction } onSubmit
 */

/** @type { React.FC<Props> } */
const MigrationSuccess = React.memo(function MigrationSuccess(props) {
	const { className, onAction, onSubmit } = props;

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	return (
		<FeatureFlag feature="migration-success">
			<MigrationWrapper invert>
				<Splash
					className={cn(
						'asteria-component__migration-alert',
						'asteria--variant-success',
						className,
					)}
					path="migration.success"
					actions={actions}
				/>
			</MigrationWrapper>
		</FeatureFlag>
	);
});

MigrationSuccess.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MigrationSuccess;
