import React from 'react';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';

import { Translation } from '@asteria/language';
import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';
import { useRequestLoader } from '@asteria/utils-hooks/useDataLoader';

import {
	useActiveDate,
	useOnboardingState,
	useTranslationOptions,
} from '../../hooks';
import Health from '../health';
import { PopupModal } from '../popup';
import Trends from '../trends';

import { useDateRange } from './hooks';

import './styles.scss';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Props> } */
const Input = React.memo(function Input(props) {
	const {
		className,
		onAction,
		onSubmit,
		translationOptions,
		dataloader: initialDataloader,
	} = props;

	const [open, actions] = useToggleState();
	const date = useActiveDate();
	const dateRange = useDateRange(date);

	const dataloader = useRequestLoader({ onSubmit });
	const onboarding = useOnboardingState();
	const query = useQuery({
		queryKey: ['time-selector', date, 'statistics'],
		queryFn: async ({ signal, meta }) => {
			return onSubmit?.('time:selector:statistics', {
				date,
				signal,
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		meta: { dataloader: initialDataloader ?? dataloader },
	});

	const extra = useTranslationOptions({
		query,
		date,
		notconnected: onboarding === 'none',
	});

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:select') {
				actions?.close?.();
			}

			// if (action === 'time:selector:range') {
			// 	if (data?.startDate && data?.endDate) {
			// 		actions?.close?.();
			// 	}
			// }

			return onAction?.(action, data);
		},
		[actions, onAction],
	);

	const loading = query?.isFetching || onboarding === 'none';

	return (
		<>
			<div
				className={cn(
					'grid p-4 border border-solid rounded cursor-pointer',
					'bg-time-selector-input-normal-background border-time-selector-input-normal-border',
					'hover:bg-time-selector-input-hover-background hover:border-time-selector-input-hover-border',
					{
						'bg-time-selector-input-focus-background border-time-selector-input-focus-border hover:bg-time-selector-input-focus-background hover:border-time-selector-input-focus-border':
							open,
					},
					'asteria-component__time-selector-input',
					stateClasses({ active: open }),
					className,
				)}
				onClick={actions.toggle}
			>
				<Health
					direction="horizontal"
					label={{ as: Title, props: { size: 'sm' } }}
					score={
						onboarding === 'none' ? 0 : query?.data?.health?.score
					}
					extra={extra}
					loading={loading}
				/>
				<Translation
					translationKey="time.selector.input.value"
					defaultText='{{ date | date:"MMMM, yyyy" | capitalize }}'
					Component={Text}
					size="sm"
					translationOptions={{
						postfix: { 'date-range': dateRange },
						data: { date, onboarded: onboarding === 'none' },
					}}
					className="asteria--type-date"
				/>
				<div
					className={cn(
						'flex items-center gap-2',
						'asteria-component__time-selector-input-section',
						'asteria--type-amount',
					)}
				>
					<Translation
						translationKey="time.selector.month.balance"
						defaultText="{{ balance.value | number }}"
						translationOptions={extra}
						Component={Title}
						size="sm"
						loading={loading}
					/>

					{!loading ? (
						<Trends
							direction={query?.data?.trends?.direction}
							extra={extra}
						/>
					) : null}
				</div>

				<TooltipWrapper
					tooltip={TranslationService.getV2(
						['time.selector.date.tooltip'],
						translationOptions,
					)}
				>
					<Icon icon="date" className="asteria--type-datepicker" />
				</TooltipWrapper>
			</div>
			<PopupModal
				{...props}
				onAction={handleAction}
				open={open}
				onClose={actions.close}
			/>
		</>
	);
});

Input.displayName = 'Input';

Input.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	dataloader: PropTypes.object,
};

export default Input;
