import { TranslationService } from '@asteria/language';

export function onUserLoad(data) {
	window?.gtag?.('config', 'G-Q6Y8W2ZHR8', {
		user_id: data?._id ?? data?.id,
	});

	TranslationService.updateGlobals({ user: data });
}

export function onCompanyLoad(data) {
	TranslationService.updateGlobals({ company: data });
}

export function onIntegrationsLoad(data) {
	TranslationService.updateGlobals({ integrations: data });
}
