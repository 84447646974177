import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

export function getKeyPermutations(key = '', delimiter = '.', initial = []) {
	return key
		.toString()
		.split('.')
		.reduce(
			(acc, item) => [
				...acc,
				acc.length > 0
					? [acc[acc.length - 1], item.toLowerCase()].join(delimiter)
					: item,
			],
			initial,
		);
}

export function getName(name, translationOptions) {
	return TranslationService.getV2(
		[
			...getKeyPermutations(`${name}`, '.', ['cashflow.tooltip']).map(
				(key) => `${key}.label`,
			),
		],
		{
			...translationOptions,
		},
	);
}

export function getSalaryColor({ index = 0, variant, className }) {
	const classIndex = index + 1;

	if (variant === 'bg') {
		return cn(
			{
				'bg-salaries-1': classIndex === 1,
				'bg-salaries-2': classIndex === 2,
				'bg-salaries-3': classIndex === 3,
				'bg-salaries-4': classIndex === 4,
				'bg-salaries-5': classIndex === 5,
				'bg-salaries-6': classIndex === 6,
				'bg-salaries-7': classIndex === 7,
				'bg-salaries-8': classIndex === 8,
				'bg-salaries-9': classIndex === 9,
				'bg-salaries-10': classIndex >= 10,
			},
			className,
		);
	}

	return cn(
		{
			'fill-salaries-1': classIndex === 1,
			'fill-salaries-2': classIndex === 2,
			'fill-salaries-3': classIndex === 3,
			'fill-salaries-4': classIndex === 4,
			'fill-salaries-5': classIndex === 5,
			'fill-salaries-6': classIndex === 6,
			'fill-salaries-7': classIndex === 7,
			'fill-salaries-8': classIndex === 8,
			'fill-salaries-9': classIndex === 9,
			'fill-salaries-10': classIndex >= 10,
		},
		className,
	);
}
