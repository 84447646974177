import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import { formatIntegration } from '../utils';

// eslint-disable-next-line no-unused-vars
const INTEGRATIONS = [
	{
		type: 'erp',
		key: 'testIntegration',
		name: 'testIntegration',
		status: { state: 'IDLE' },
		config: { connected: true },
		lastSync: new Date().toISOString(),
		disabled: false,
	},
	{
		type: 'bank',
		key: 'asteriaBank',
		name: 'asteriaBank',
		status: { state: 'IDLE' },
		config: { connected: true },
		lastSync: new Date().toISOString(),
		disabled: false,
	},
].map((object, index) => ({ ...object, id: index + 1, _id: index + 1 }));

export const state = {
	loading: createSelector(
		(store) =>
			(store?.integrations?.items ?? []).filter((object) =>
				['INITIATING', 'IMPORTING'].includes(object?.status?.state),
			),
		(value) => value ?? [],
	),
	initiating: createSelector(
		(store) =>
			(store?.integrations?.items ?? []).filter((object) =>
				['INITIATING'].includes(object?.status?.state),
			),
		(value) => value ?? [],
	),
	fetching: createSelector(
		(store) =>
			(store?.integrations?.items ?? []).filter((object) =>
				['IMPORTING'].includes(object?.status?.state),
			),
		(value) => value ?? [],
	),
};

export const form = createSelector(
	(store) => store?.integrations?.$form,
	(value) => value ?? null,
);

export const isLoading = createSelector(
	(store) =>
		(store?.integrations?.items ?? [])
			.filter((object) => object?.status?.state)
			.map((object) => object?.status?.state),
	(value) => value.includes('INITIATING'),
);

export const isFetching = createSelector(
	(store) =>
		(store?.integrations?.items ?? [])
			.filter((object) => object?.status?.state)
			.map((object) => object?.status?.state),
	(value) => value.includes('IMPORTING'),
);

export const hasIntegrations = createSelector(
	(store) => store?.integrations?.items ?? [],
	(value) => value.length > 0,
);

export const hasConnectedIntegrations = createSelector(
	(store) => store?.integrations?.items ?? [],
	(_, type, key) => ({ type, key }),
	(value, { type = null, key = null }) =>
		value.some(
			(value) =>
				value?.config?.connected &&
				(type === null || value?.type === type) &&
				(key === null || value?.key === key),
		),
);

export const hasConnectedERP = createSelector(
	(store) => store?.integrations?.items ?? [],
	(value) =>
		value.some(
			(object) => object?.type === 'erp' && object?.config?.connected,
		),
);

export const hasERP = createSelector(
	(store) => store?.integrations?.items ?? [],
	(value) => value.some((object) => object?.type === 'erp'),
);

export const byType = createSelector(
	(store) => store?.integrations?.items ?? [],
	(_, type) => type,
	(value, type) => value.filter((object) => object?.type === type),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

function isIntegrationValid(
	object,
	{ type, connected, status, filters, key, disabled },
) {
	let isValid = true;

	if (isValid && type) {
		isValid = object?.type === type;
	}

	if (isValid && connected) {
		isValid = object?.config?.connected === connected;
	}

	if (isValid && key) {
		isValid = object?.key === key;
	}

	if (isValid && status) {
		isValid =
			status === 'ERROR'
				? object?.status?.state === status ||
				  object?.config?.errors?.length
				: object?.status?.state === status;
	}

	if (isValid && filters?.length) {
		isValid = filters.some((value) =>
			isIntegrationValid(object, {
				type: value?.type,
				connected: value?.connected,
				status: value?.status,
				key: value?.key,
			}),
		);
	}

	if (isValid && disabled) {
		isValid = object?.disabled === disabled;
	}

	return isValid;
}

export const integrations = createSelector(
	(store) => store?.integrations?.items ?? [],
	(_, options = {}) => options,
	(objects, { type, connected, status, filters, key, disabled }) =>
		objects
			.filter((object) =>
				isIntegrationValid(object, {
					type: type,
					connected: connected,
					status: status,
					filters: filters,
					key: key,
					disabled: disabled,
				}),
			)
			.map((object) => formatIntegration(object)),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const integration = createSelector(
	(store) => store?.integrations?.navigation ?? {},
	(store) => store?.integrations?.items ?? [],
	(_, ID) => ID,
	(navigation, objects, id) => {
		if (id === '__SUCCESS__') {
			return formatIntegration({
				...navigation,
				id: navigation._id,
				name: navigation.key,
				status: { state: 'IDLE' },
				config: { connected: true },
			});
		}

		if (id === '__ERROR__') {
			return formatIntegration({
				...navigation,
				id: navigation._id,
				name: navigation.key,
				status: { state: 'ERROR' },
				config: {
					connected: false,
					errors: [{ code: 404, message: 'Not found' }],
				},
			});
		}

		return formatIntegration(
			objects.find((object) => {
				const ID = object?._id ?? object.id;

				return ID === id;
			}),
		);
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
