import React from 'react';

import PropTypes from 'prop-types';

import Feedback from '@asteria/component-feedback';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const FeedbackButtons = React.memo(function FeedbackButtons(props) {
	const { onAction, onSubmit, state, type, translationOptions } = props;

	const onFeedbackSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	if (state?.feedback?.visible) {
		return null;
	}

	return (
		<div
			className={cn(
				'w-full justify-center flex',
				'asteria-component__card-section',
				'asteria--variant-card-rating',
			)}
		>
			<Feedback
				description={TranslationService.get('feedback.text')}
				feedbackKey={`card-${type}`}
				type="card"
				maxText={TranslationService.getV2(['feedback.rating'], {
					postfix: {
						...translationOptions.postfix,
						value: 'max',
					},
					data: translationOptions?.data,
				})}
				minText={TranslationService.getV2(['feedback.rating'], {
					postfix: {
						...translationOptions.postfix,
						value: 'min',
					},
					data: translationOptions?.data,
				})}
				values={[1, 2, 3, 4, 5]}
				translationOptions={{
					data: {
						label: TranslationService.getV2(
							['card.title'],
							translationOptions,
						),
					},
				}}
				rating
				onAction={onAction}
				onSubmit={onSubmit}
				onSupportRequest={onFeedbackSupportRequest}
			/>
		</div>
	);
});

FeedbackButtons.displayName = 'FeedbackButtons';

FeedbackButtons.propTypes = {
	type: PropTypes.string,
	state: PropTypes.object,
	translationOptions: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default FeedbackButtons;
