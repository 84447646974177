import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useRedirectLink } from '../hooks';
import MigrationWrapper from '../wrapper';

import './styles.scss';

/**
 * @typedef { <TResponse = Promise<unknown>>(action: string, data?: unknown) => TResponse } onAction
 *
 * @typedef Props
 * @property { string } className
 * @property { onAction } onAction
 * @property { onAction } onSubmit
 */

/** @type { React.FC<Props> } */
const MigrationAlert = React.memo(function MigrationAlert(props) {
	const { className, onAction, onSubmit } = props;

	const onClick = React.useCallback(
		() => onAction?.('migration', { from: 'alert', type: 'action' }),
		[onAction],
	);

	const hasRedirectFeature = useFeature('migration-alert-warning-redirect');
	const redirectUri = useRedirectLink('alert-warning');

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	const alertActions = React.useMemo(() => {
		const primary = {
			variant: 'primary',
			label: TranslationService.getV2(['migration.alert.action'], {
				postfix: { variant: 'warning' },
			}),
		};

		if (hasRedirectFeature) {
			primary.href = redirectUri;
			primary.target = '_blank';
		} else {
			primary.onClick = onClick;
		}

		return [primary];
	}, [hasRedirectFeature, onClick, redirectUri]);

	return (
		<FeatureFlag feature="migration-alert-warning">
			<MigrationWrapper>
				<Alert
					className={cn(
						'asteria-component__migration-alert',
						className,
					)}
					level="warning"
					icon="alert"
					title={TranslationService.getV2(['migration.alert.title'], {
						postfix: { variant: 'warning' },
						actions: actions,
					})}
					actions={alertActions}
					direction="horizontal"
				>
					<Translation
						translationKey="migration.alert.content"
						translationOptions={{
							postfix: { variant: 'warning' },
							actions: actions,
						}}
						Component={Text}
						size="sm"
					/>
				</Alert>
			</MigrationWrapper>
		</FeatureFlag>
	);
});

MigrationAlert.displayName = 'MigrationAlert';

MigrationAlert.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MigrationAlert;
