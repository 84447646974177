import React from 'react';

import PropTypes from 'prop-types';

import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useRedirectLink } from '../hooks';
import MigrationWrapper from '../wrapper';

import './styles.scss';

/**
 * @typedef { <TResponse = Promise<unknown>>(action: string, data?: unknown) => TResponse } onAction
 *
 * @typedef Props
 * @property { string } className
 * @property { onAction } onAction
 * @property { onAction } onSubmit
 */

/** @type { React.FC<Props> } */
const MigrationSplash = React.memo(function MigrationSplash(props) {
	const { className, onAction, onSubmit } = props;

	const hasRedirectFeature = useFeature('migration-splash-redirect');
	const redirectUri = useRedirectLink('splash');

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	const data = React.useMemo(() => ({ redirectUri }), [redirectUri]);

	const content = useConfig(
		['migration.splash.content'].concat(
			hasRedirectFeature ? ['migration.splash.redirect.content'] : [],
		),
		{ deep: true },
	);

	return (
		<FeatureFlag feature="migration-splash">
			<MigrationWrapper>
				<Wrapper
					className={cn(
						'h-full',
						'asteria-component__migration-splash',
						className,
					)}
				>
					<Header>
						{TranslationService.getV2(['migration.splash.title'])}
					</Header>
					<Content className="h-full">
						<Contenter
							content={content}
							actions={actions}
							data={data}
						/>
					</Content>
				</Wrapper>
			</MigrationWrapper>
		</FeatureFlag>
	);
});

MigrationSplash.displayName = 'MigrationSplash';

MigrationSplash.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MigrationSplash;
