import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { sizeClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { React.ReactNode } tooltip
 * @property { string } className
 * @property { import('react').MouseEventHandler } onClick
 * @property { 'sm' | 'md' | 'lg' } size
 */

/** @type { React.FC<Props> } */
const AccountBalancePrefix = React.memo(function AccountBalancePrefix(props) {
	const { tooltip, className, onClick, size } = props;

	return (
		<TooltipWrapper tooltip={tooltip}>
			<span
				className={cn(
					'asteria-component__prefix',
					'asteria--type-account',
					sizeClasses({ size: size }),
					className,
				)}
				onClick={onClick}
			>
				<svg>
					<line x1="0" x2="100%" y1="50%" y2="50%" fill="red" />

					<defs>
						<circle
							id="account-dot-circle"
							cx="50%"
							cy="50%"
							r="6"
						/>

						<clipPath id="insideAccountDot">
							<use xlinkHref="#account-dot-circle" />
						</clipPath>
					</defs>

					<use
						id="account-dot"
						xlinkHref="#account-dot-circle"
						clipPath="url(#insideAccountDot)"
					/>
				</svg>
			</span>
		</TooltipWrapper>
	);
});

AccountBalancePrefix.displayName = 'AccountBalancePrefix';
AccountBalancePrefix.propTypes = {
	tooltip: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
	size: SizeProp(),
};

export default AccountBalancePrefix;
