import React from 'react';

import { useFeature } from '@asteria/component-tools/featureflag';

import BaseCard from '../../../../base';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';
import ActionConnect from '../components/action-connect';
import ActionDiscard from '../components/action-discard';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/**
 * @param { ChildrenProps } props
 * @returns { boolean }
 */
function useDiscardButtonVisibility(props) {
	const { type } = props;

	const hasDiscardFeature = useFeature('streamlined.discard');
	const hasOnboardingConnectOnlyFeature = useFeature(
		'streamlined.onboarding.connect.only',
	);

	return (
		hasDiscardFeature ||
		(hasOnboardingConnectOnlyFeature && type === 'onboarding')
	);
}

/**
 * @param { ChildrenProps } props
 * @returns { boolean }
 */
function useConnectButtonVisibility(props) {
	const { type } = props;

	const hasOnboardingConnectOnlyFeature = useFeature(
		'streamlined.onboarding.connect.only',
	);

	return !hasOnboardingConnectOnlyFeature || type === 'onboarding';
}

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	const isDiscardVisible = useDiscardButtonVisibility(props);
	const isConnectVisible = useConnectButtonVisibility(props);

	return (
		<>
			{isDiscardVisible ? <ActionDiscard {...props} /> : null}
			{isConnectVisible ? <ActionConnect {...props} /> : null}
		</>
	);
});

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.promotion?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const PromotionView = React.memo(function PromotionView(props) {
	const hasFloatingIntegrationsActionbar = useFeature(
		'streamlined.floating.integrations',
	);

	const isDiscardVisible = useDiscardButtonVisibility(props);
	const isConnectVisible = useConnectButtonVisibility(props);

	if (
		hasFloatingIntegrationsActionbar ||
		(!isDiscardVisible && !isConnectVisible)
	) {
		return null;
	}

	return (
		<BaseCard.Footer>
			<ViewContentConfig {...props} />
		</BaseCard.Footer>
	);
});

export default withConfig(PromotionView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.promotion, version);
});

export { ViewContentConfig as ViewContent };
