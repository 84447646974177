import React from 'react';

import PropTypes from 'prop-types';

import { sizeClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Props> } */
const Content = React.memo(function Content(props) {
	const {
		size,
		closeOnClickOutside,
		closeOnEscape,
		onClose,
		className,
		children,
	} = props;

	const ref = React.useRef(null);
	const clickRef = React.useRef(null);

	React.useEffect(() => {
		if (!closeOnEscape) {
			return;
		}

		const close = (e) => {
			if (e.keyCode === 27) {
				onClose?.(e);
			}
		};
		document.addEventListener('keydown', close);

		return () => {
			document.removeEventListener('keydown', close);
		};
	}, [onClose, closeOnEscape]);

	const onMouseDown = React.useCallback(
		(event) => {
			if (closeOnClickOutside) {
				clickRef.current = null;

				if (event?.target?.isSameNode?.(ref.current)) {
					clickRef.current = event.target;
				}
			}
		},
		[closeOnClickOutside],
	);

	const onMouseUp = React.useCallback(
		(event) => {
			if (closeOnClickOutside) {
				if (
					event?.target?.isSameNode?.(ref.current) &&
					clickRef?.current
				) {
					return onClose?.(event);
				}
			}
		},
		[closeOnClickOutside, onClose],
	);

	return (
		<div
			className={cn(
				'asteria-component__backdrop',
				'asteria-component__backdrop--variant-modal',
				sizeClasses({ size: size }),
			)}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			tabIndex="-1"
			ref={ref}
		>
			<div
				className={cn(
					'asteria-component__modal',
					sizeClasses({ size: size }),
					className,
				)}
			>
				{children}
			</div>
			<div className={cn('asteria-component__background', className)} />
		</div>
	);
});

Content.propTypes = {
	className: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	size: PropTypes.string,
	closeOnClickOutside: PropTypes.bool,
	closeOnEscape: PropTypes.bool,
	children: PropTypes.node,
};

export default Content;
