export const FEEDBACK_THEMES = [
	'general',
	'navigation',
	'forecast',
	'support',
	'other',
];

export const DISMISS_THEMES = [
	'not-required',
	'not-understand',
	'already-setup',
	'not-relevant',
	'integrations-issues',
	'improve-experience',
	'other',
];
