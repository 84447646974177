import React from 'react';

import { useSelector } from 'react-redux';

import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';

import {
	AccountCard,
	ClientPerformanceCard,
	ForecastActionsCard,
	ForecastCard,
	ForecastStatusCard,
	HealthCard,
	IncomingCard,
	InvoicesCard,
	OnboardingCard,
	OutgoingCard,
	ProfitCard,
	SalaryCard,
	TaxesCard,
	UnderConstructionCard,
} from '@asteria/component-card';
import CategoriesCard from '@asteria/component-card/cards/categories';
import FlexBox, { FlexItem } from '@asteria/component-flex-box';
import { AddIntegrationActionbar } from '@asteria/component-integrations-v2';
import { MigrationSplash } from '@asteria/component-migration';
import { SupportFloatButton } from '@asteria/component-support';
import TimeSelector from '@asteria/component-time-selector';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';
import { useRequestLoader } from '@asteria/utils-hooks/useDataLoader';

import { useMigrated } from '../../components/migration/hooks';

import { CARDS, GRID } from './constants';
import StreamlinedViewFeedback from './feedback';
import StreamlineTitle from './title';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 * @property { number } size
 */

/** @type { React.FC<{ children: React.ReactNode, fit?: boolean }> } */
const CardWrapper = function CardWrapper(props) {
	const { children, fit } = props;

	if (fit) {
		return (
			<FlexItem {...props}>
				<div className="w-full">{children}</div>
			</FlexItem>
		);
	}

	return <FlexItem {...props} />;
};

CardWrapper.propTypes = { children: PropTypes.node, fit: PropTypes.bool };

function useRange(date) {
	if (typeof date === 'string') {
		return {
			startDate: formatISO(startOfMonth(parseDate(date)), {
				representation: 'date',
			}),
			endDate: formatISO(endOfMonth(parseDate(date)), {
				representation: 'date',
			}),
		};
	}

	return {
		startDate: date?.startDate,
		endDate: date?.endDate,
	};
}

/** @type { React.FC<Props> } */
const RenderGridItem = React.memo(function RenderGridItem({
	itemId,
	range,
	onAction,
	onSubmit,
	dataloader,
	size,
}) {
	const hasDisableFeatureFlag = useFeature(`card.${itemId}.disabled`);

	if (hasDisableFeatureFlag) {
		return null;
	}

	if (itemId === CARDS.HEALTH) {
		return (
			<HealthCard
				size={size}
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.FORECAST_ACTIONS) {
		return (
			<ForecastActionsCard
				size={size}
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.ACCOUNT) {
		return (
			<AccountCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: {
						animated: true,
						fit: true,
						size: size,
					},
				}}
			/>
		);
	}

	if (itemId === CARDS.INCOMING) {
		return (
			<IncomingCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.INVOICES) {
		return (
			<InvoicesCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.CLIENT_PERFORMANCE) {
		return (
			<ClientPerformanceCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.PROFIT) {
		return (
			<ProfitCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.UNDER_CONSTRUCTION) {
		return (
			<UnderConstructionCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
				modal
				// experimental
			/>
		);
	}

	if (itemId === CARDS.OUTGOING) {
		return (
			<OutgoingCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.ONBOARDING) {
		return (
			<OnboardingCard
				dataloader={dataloader}
				version={2}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.SALARIES) {
		return (
			<SalaryCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.TAXES) {
		return (
			<TaxesCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.FORECAST_STATUS) {
		return (
			<ForecastStatusCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.FORECAST) {
		return (
			<ForecastCard
				dataloader={dataloader}
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.CATEGORIES) {
		return (
			<CategoriesCard
				dataloader={dataloader}
				version={1}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true, size: size },
				}}
			/>
		);
	}

	if (itemId === CARDS.MIGRATION_SPLASH) {
		return (
			<CardWrapper fit animated>
				<MigrationSplash onAction={onAction} onSubmit={onSubmit} />
			</CardWrapper>
		);
	}

	return null;
});

RenderGridItem.propTypes = {
	itemId: PropTypes.string,
	range: PropTypes.shape({
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
	size: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	dataloader: PropTypes.object,
};

function FlexGrid({ content, ...props }) {
	return (content ?? []).map((args, index) => {
		if (args?.type === 'flex') {
			const children = <FlexGrid content={args?.children} {...props} />;

			return (
				<FlexBox
					key={index}
					direction={args?.direction}
					size={args?.size}
					breakpointWidth={args?.breakpointWidth}
					breakpointSize={args?.breakpointSize}
				>
					{children}
				</FlexBox>
			);
		}

		if (args?.type === 'card') {
			return (
				<RenderGridItem
					key={args?.id ?? index}
					itemId={args?.id}
					size={args?.size}
					{...props}
				/>
			);
		}

		return null;
	});
}

/** @type { React.FC<Props> } */
const StreamlineView = React.memo(function StreamlineView(props) {
	const { className, onAction, onSubmit } = props;

	const dataloader = useRequestLoader({ onSubmit });

	const hasConnectedErp = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				connected: true,
				status: 'IDLE',
			})?.length,
	);

	const hasConnectedBank = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'bank',
				connected: true,
				status: 'IDLE',
			})?.length,
	);

	const hasConnectedOnboarding = React.useMemo(
		() => hasConnectedBank && hasConnectedErp,
		[hasConnectedBank, hasConnectedErp],
	);

	const [date, setDate] = React.useState(new Date().toISOString());
	const range = useRange(date);

	const isMigrated = useMigrated();
	const hasMigrationSplashFeature = useFeature('migration-splash');

	const shouldShowMigrationGrid = !isMigrated && hasMigrationSplashFeature;

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:select') {
				setDate(data?.date);
			}

			if (action === 'time:selector:range') {
				if (data?.startDate && data?.endDate) {
					setDate(data);
				}
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const grid = React.useMemo(() => {
		if (!hasConnectedOnboarding) {
			if (shouldShowMigrationGrid) {
				return GRID.MIGRATION_ONBOARDING;
			}

			return GRID.ONBOARDING;
		}

		if (shouldShowMigrationGrid) {
			return GRID.MIGRATION;
		}

		return GRID.DEFAULT;
	}, [hasConnectedOnboarding, shouldShowMigrationGrid]);

	return (
		<div className={cn('asteria-view__streamline', className)}>
			<StreamlineTitle
				onAction={handleAction}
				onSubmit={onSubmit}
				startDate={range.startDate}
				endDate={range.endDate}
				dataloader={dataloader}
			/>
			<StreamlinedViewFeedback onAction={onAction} />
			<TimeSelector
				onAction={handleAction}
				onSubmit={onSubmit}
				className="sticky -top-8 bg-white py-4"
				dataloader={dataloader}
			/>

			<FlexBox direction="vertical">
				<FlexGrid
					content={grid}
					onAction={handleAction}
					onSubmit={onSubmit}
					range={range}
					dataloader={dataloader}
				/>
			</FlexBox>

			<FeatureFlag feature="streamlined.floating.integrations">
				<div className="sticky bottom-0 flex items-end flex-col -mt-5">
					<SupportFloatButton
						dataloader={dataloader}
						className="static"
						onAction={onAction}
						onSubmit={onSubmit}
					/>
					<AddIntegrationActionbar
						dataloader={dataloader}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
				</div>
			</FeatureFlag>
		</div>
	);
});

StreamlineView.displayName = 'StreamlineView';

StreamlineView.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default StreamlineView;
