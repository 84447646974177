import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Footer,
	FooterSection,
	Header,
	Content as WrapperContent,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';

import { Content } from '../../basic';
import { useEmployee } from '../hooks';

import EmployeeList from './list';

/**
 * @typedef Props
 * @property { boolean } importing
 * @property { boolean } loading
 * @property { boolean } open
 * @property { unknown } query
 * @property { 'past' | 'today' | 'future' } variant
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 * @property { (event: MouseEvent) => void } onClose
 */

/** @type { React.FC<Props> } */
const ModalContent = React.memo(function ModalContent(props) {
	const {
		translationOptions,
		startDate,
		query,
		variant,
		importing,
		loading,
		onClose,
		onAction,
	} = props;

	const { filter, employees, setFilter } = useEmployee({
		employee: query?.data?.employee,
	});

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'list:filter') {
				return setFilter(data);
			}

			return onAction(action, data);
		},
		[onAction, setFilter],
	);

	const handleClick = React.useCallback(
		() => onAction?.('card:action', { type: 'salaries', date: startDate }),
		[onAction, startDate],
	);

	return (
		<Wrapper scroll className="asteria-component__salaries-modal">
			<Header onClose={onClose}>
				{TranslationService.getV2(
					['modal.card.title'],
					translationOptions,
				)}
			</Header>

			<WrapperContent scroll className="gap-4">
				<Content.Title {...props} />
				<Content.Text
					{...props}
					showLoading
					loading={importing || loading}
				/>
				<EmployeeList
					data={employees}
					filter={filter}
					translationOptions={translationOptions}
					onAction={handleAction}
				/>
			</WrapperContent>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="secondary"
						label={TranslationService.getV2(['modal.card.action'], {
							postfix: {
								action: 'close',
								...translationOptions?.postfix,
							},
							data: translationOptions?.data,
						})}
						onClick={onClose}
					/>
				</FooterSection>
				<FooterSection position="last">
					{variant === 'today' || variant === 'future' ? (
						<Button
							variant="primary"
							label={TranslationService.getV2(
								'card.button',
								translationOptions,
							)}
							onClick={handleClick}
						/>
					) : null}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

ModalContent.propTypes = {
	open: PropTypes.bool,
	importing: PropTypes.bool,
	loading: PropTypes.bool,
	variant: PropTypes.string,
	startDate: PropTypes.string,
	onClose: PropTypes.func,
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const UnderConstructionModal = React.memo(function UnderConstructionModal(
	props,
) {
	const { open, onClose } = props;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalContent {...props} />
		</Modal>
	);
});

UnderConstructionModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	translationData: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default UnderConstructionModal;
