import React from 'react';

import PropTypes from 'prop-types';

import { ForecastPrefix } from '@asteria/component-prefix/';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const CustomLegend = (props) => {
	const { payload, translationOptions, loading, graphData } = props;

	return (
		<div className="flex flex-wrap justify-center gap-4 mt-4">
			{payload.map((object, index) => {
				const hasValue = graphData?.some(
					(item) =>
						item?.[object?.dataKey] !== 0 &&
						object?.dataKey !== 'loading',
				);

				if (!hasValue) {
					return <div className="h-auto" />;
				}

				return (
					<div
						key={`item-${index}`}
						className="flex items-center gap-2"
					>
						{object?.dataKey === 'forecast' ? (
							<ForecastPrefix className="asteria-component__graph-legend-prefix" />
						) : (
							<svg
								width="12"
								height="12"
								className="asteria-component__graph-legend-prefix"
							>
								<rect
									width="12"
									height="12"
									style={{
										fill: `var(--color, ${object?.color})`,
									}}
								/>
							</svg>
						)}
						<span className="text-content-text-muted">
							{TranslationService.getV2('card.graph.legend', {
								...translationOptions,
								postfix: {
									...translationOptions?.postfix,
									key: object?.dataKey,
									loading: loading,
								},
								data: {
									...translationOptions?.data,
									value: object?.value,
								},
							})}
						</span>
					</div>
				);
			})}
		</div>
	);
};

CustomLegend.propTypes = {
	payload: PropTypes.array,
	loading: PropTypes.array,
	graphData: PropTypes.array,
	translationOptions: PropTypes.object,
};

export default CustomLegend;
