import React from 'react';

import PropTypes from 'prop-types';

import AuthView from '@asteria/view-auth';

import { useFeature } from '@asteria/component-tools/featureflag';

import TranslationContext from '@asteria/language/context';

import { LayoutContext } from '../context';

const AuthPage = React.memo(function AuthPage({ partnerId, logo, homepage }) {
	const { onSubmit, onAction } = React.useContext(LayoutContext);
	const { language } = React.useContext(TranslationContext);

	const hideSignupCode = useFeature('auth-hide-signup-code');
	const signup = useFeature('asteria-auth-signup');
	// eslint-disable-next-line spellcheck/spell-checker
	const passwordReset = useFeature('login.forgotpassword');
	const languages = useFeature('multi-language');

	return (
		<AuthView
			key={language}
			partnerId={partnerId}
			logo={logo}
			homepage={homepage}
			languages={languages}
			passwordReset={passwordReset}
			signup={signup}
			signupCode={!hideSignupCode}
			onAction={onAction}
			onSubmit={onSubmit}
		/>
	);
});

AuthPage.displayName = 'AuthPage';
AuthPage.propTypes = {
	partnerId: PropTypes.string,
	logo: PropTypes.node,
	homepage: PropTypes.bool,
};

export default AuthPage;
