import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Title as BaseTitle } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import TrendIcon from '../../../../components/trends';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const Trends = React.memo(function Trends(props) {
	const { translationOptions, query, direction } = props;

	return (
		<TrendIcon
			direction={direction}
			query={query}
			postfix={translationOptions?.postfix}
			data={translationOptions?.data}
		/>
	);
});

Trends.propTypes = {
	translationOptions: PropTypes.object,
	query: PropTypes.object,
	direction: PropTypes.string,
};

const TrendsConfig = withConfig(Trends, (props) => {
	const { version } = props;

	if (props?.loading || props?.state?.promotion?.visible) {
		return false;
	}

	return getVersion(
		props?.config?.card?.content?.title?.trends,
		version,
		false,
	);
});

/** @type { React.FC<ChildrenProps & { showLoading?: boolean, translationKey?: string | string[] }> } */
const Title = React.memo(function Title(props) {
	const {
		translationOptions,
		state,
		loading,
		showLoading,
		skeleton,
		translationKey: $translationKey,
	} = props;

	const translationKey = React.useMemo(() => {
		if ($translationKey) {
			return $translationKey;
		}

		let key = 'card.content.title';

		if (state?.promotion?.visible) {
			key = ['card.content.title', 'card.content.title.promotion'];
		}

		if (state?.feedback?.visible) {
			key = ['card.content.title', 'card.content.title.feedback'];
		}

		return key;
	}, [$translationKey, state?.feedback?.visible, state?.promotion?.visible]);

	return (
		<Group
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
		>
			<Translation
				translationKey={translationKey}
				translationOptions={translationOptions}
				showWhenEmpty={false}
				Component={BaseTitle}
				size="sm"
				loading={showLoading && loading}
				skeleton={skeleton}
			/>
			<TrendsConfig {...props} />
		</Group>
	);
});

Title.propTypes = {
	translationOptions: PropTypes.object,
	state: PropTypes.object,
	loading: PropTypes.bool,
	showLoading: PropTypes.bool,
	skeleton: PropTypes.object,
	translationKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

export default withConfig(Title, (props) => {
	const { version, state } = props;

	if (
		state?.promotion?.variant === 'card' &&
		props?.config?.card?.content?.promotion?.title !== undefined
	) {
		return getVersion(
			props?.config?.card?.content?.promotion?.title,
			version,
		);
	}

	return getVersion(props?.config?.card?.content?.title, version);
});
