import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

import Placeholder from './components/placeholder';

const PLACEHOLDER_PRIORITY = {
	background: 1,
	image: 2,
};

export function useFlow(type, key, callback) {
	return useConfig('integrations', {
		callback: React.useCallback(
			(response) => {
				const object = (response ?? []).find(
					(object) => object?.type === type && object?.key === key,
				);

				if (callback) {
					return callback(object);
				}

				return object;
			},
			[callback, key, type],
		),
	});
}

export function useIntegrationName(type, key) {
	const name = useFlow(
		type,
		key,
		React.useCallback((object) => object?.name, []),
	);

	return TranslationService.get(
		[
			'integration.title',
			`integration.title.${key}`,
			`integration.title.${type}.${key}`,
			`integration.${key}.title`,
			`integration.${type}.${key}.title`,
		],
		name,
	);
}

export function useGuided(open) {
	const [showGuide, setShow] = React.useState(open);

	const toggleGuide = React.useCallback(() => setShow((prev) => !prev), []);
	const openGuide = React.useCallback(() => setShow(true), []);
	const closeGuide = React.useCallback(() => setShow(false), []);

	return { showGuide, openGuide, toggleGuide, closeGuide };
}

function getPlaceholders(step) {
	return function (flow) {
		return []
			.concat(flow?.steps?.[step]?.placeholder)
			.filter(Boolean)
			.sort(
				({ type: a }, { type: b }) =>
					(PLACEHOLDER_PRIORITY[a] ?? 0) -
					(PLACEHOLDER_PRIORITY[b] ?? 0),
			);
	};
}

export function usePlaceholder(type, key, step, disabled = false) {
	const placeholders = useFlow(type, key, getPlaceholders(step));

	if (!placeholders.length || disabled) {
		return null;
	}

	return placeholders.map((placeholder) => (
		<Placeholder key={placeholder?.type} {...placeholder} />
	));
}

/**
 * @param { string } id
 * @returns { "error" | "disabled" | "importing" | "initiating" | "outdated" | "connected" | null }
 */
export function useIntegrationStatus(id) {
	return useSelector((store) => {
		const object = IntegrationStore.selectors.integration(store, id);

		if (object?.status?.$state) {
			return object?.status?.$state;
		}

		return IntegrationStore.utils.getIntegrationStatus(object);
	}, isEqual);
}
