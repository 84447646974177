import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { getAbbreviation } from '../utils';

import '../index.scss';

/**
 * @typedef { <TResponse = Promise<unknown>>(action: string, data: unknown) => TResponse } onAction
 */

/** @type { React.FC<{ content: string | object, onAction: onAction, onSubmit: onAction, onClose: import('react').MouseEventHandler }> } */
const MessageText = function MessageText({
	content,
	onAction,
	onSubmit,
	onClose,
}) {
	const actions = React.useMemo(
		() => ({ onAction, onSubmit, onClose }),
		[onAction, onClose, onSubmit],
	);

	if (typeof content === 'number') {
		return <Text>{content}</Text>;
	}

	if (typeof content === 'string') {
		return <Text dangerouslySetInnerHTML={{ __html: content }} />;
	}

	if (typeof content === 'object') {
		if (content?.type === 'contenter') {
			return <Contenter content={content?.content} actions={actions} />;
		}
	}

	return null;
};

MessageText.propTypes = {
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

const Messages = (props) => {
	const { messages, type, onAction, onSubmit, onClose } = props;

	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));

	const username =
		user?.fullName ||
		[user?.firstName, user?.lastName].filter(Boolean).join(' ') ||
		user?.username ||
		user?.id;

	return messages.map((object) => {
		const userLabel =
			object?.createdBy?.type === 'UserToken'
				? TranslationService.get(
						[
							'conversation.modal.message.user.label',
							`conversation.modal.${type}.message.user.label`,
							'conversation.modal.message.user.USER.label',
							`conversation.modal.${type}.message.user.USER.label`,
						],
						username,
						{ username: username },
				  )
				: TranslationService.get([
						'conversation.modal.message.user.label',
						`conversation.modal.${type}.message.user.label`,
						'conversation.modal.message.user.ADMIN.label',
						`conversation.modal.${type}.message.user.ADMIN.label`,
				  ]);

		return (
			<Group
				key={object.id}
				direction="vertical"
				className={cn('asteria-component__message-line-wrapper', {
					'asteria--type-user':
						object?.createdBy?.type === 'UserToken',
					'asteria--type-admin':
						object?.createdBy?.type === 'AdminToken',
				})}
			>
				<Text size="sm">{userLabel}</Text>
				<Group
					direction="horizontal"
					horizontalAlign="left"
					className={cn('asteria-component__message-line', {
						'asteria--type-user':
							object?.createdBy?.type === 'UserToken',
						'asteria--type-admin':
							object?.createdBy?.type === 'AdminToken',
					})}
				>
					<div className={cn('asteria-component__message-bubble')}>
						<Text
							className={cn('asteria-component__message-bubble')}
							weight="semibold"
						>
							{getAbbreviation(userLabel)}
						</Text>
					</div>

					<div className={cn('asteria-component__message-message')}>
						<MessageText
							content={object?.content}
							onAction={onAction}
							onSubmit={onSubmit}
							onClose={onClose}
						/>

						<Text
							size="xs"
							className="asteria-component__message-date"
						>
							{TranslationService.get(
								[
									'conversation.modal.message.created.label',
									`conversation.modal.${type}.message.created.label`,
								],
								undefined,
								{
									date: object?.createdAt,
								},
							)}
						</Text>
					</div>
				</Group>
			</Group>
		);
	});
};

Messages.displayName = 'Messages';

Messages.propTypes = {
	id: PropTypes.string,
};

export default Messages;
