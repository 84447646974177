import React from 'react';

import { useFormContext } from 'react-hook-form';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { stateClasses } from '@asteria/component-core/utils';

import Form from '@asteria/component-form';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useClickOutside from '@asteria/utils-hooks/useClickOutside';

import SearchFloating from './floating';
import SearchInput from './input';
import {
	CategorySelector,
	ClientSelector,
	CurrencySelector,
	StatusSelector,
} from './selectors';

import './styles.scss';

const Search = React.memo((props) => {
	const { className, onAction } = props;

	const queryClient = useQueryClient();

	const { resetField } = useFormContext();

	const [focus, setFocus] = React.useState(false);

	const ref = React.useRef(null);

	const onFocus = React.useCallback(() => {
		Analytics.event('search.focus');

		setFocus(true);
	}, []);

	const onBlur = React.useCallback(() => {
		Analytics.event('search.blur');

		setFocus(false);
	}, []);

	const handleAction = React.useCallback(
		(action, data, options) => {
			queryClient?.invalidateQueries?.({
				predicate: (query) => {
					return query.queryKey.includes('cashflow');
				},
			});

			Analytics.event('search.action', { action: action, data: data });

			if (!options?.skipBlur) {
				onBlur?.();
			}

			resetField('search-value');

			return onAction?.(action, data);
		},
		[onAction, onBlur, queryClient, resetField],
	);

	useClickOutside(ref, onBlur);

	return (
		<div
			className={cn(
				'asteria-component__search',
				stateClasses({ active: focus }),
				className,
			)}
			ref={ref}
		>
			<div className="asteria-component__search-content">
				<SearchInput onAction={handleAction} onFocus={onFocus} />
			</div>
			<div className="asteria-component__search-selectors">
				<CategorySelector onAction={handleAction} />
				<CurrencySelector onAction={handleAction} />
				<ClientSelector onAction={handleAction} />
				<StatusSelector onAction={handleAction} />
			</div>

			<SearchFloating onAction={handleAction} />
		</div>
	);
});

Search.displayName = 'Search';

Search.propTypes = { className: PropTypes.string, onAction: PropTypes.func };

const SearchProvider = React.memo((props) => {
	return (
		<Form className="asteria-component__search-form">
			<Search {...props} />
		</Form>
	);
});

SearchProvider.displayName = 'SearchProvider';

SearchProvider.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default SearchProvider;
