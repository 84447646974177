import React from 'react';

import { useQueries } from '@tanstack/react-query';
import {
	addMonths,
	endOfMonth,
	formatISO,
	startOfMonth,
	subMonths,
} from 'date-fns';
import PropTypes from 'prop-types';

import { parseDate } from '@asteria/utils-funcs/normalize';
import { useRequestLoader } from '@asteria/utils-hooks/useDataLoader';

import { formatData, getRequest } from '../../utils';

import ChartView from './chart.view';
import Loader from './loader';

/**
 * @typedef { import('../../../basic/types').Props } Props
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ChartProvider = React.memo(function ChartProvider(props) {
	const {
		startDate,
		endDate,
		onSubmit,
		loading,
		importing,
		state,
		dataloader: initialDataloader,
	} = props;

	const disabled = loading || importing || state?.promotion?.visible;

	const dataloader = useRequestLoader({ onSubmit });

	const dates = [
		{
			startDate: formatISO(
				startOfMonth(subMonths(parseDate(startDate), 1)),
				{ representation: 'date' },
			),
			endDate: formatISO(endOfMonth(subMonths(parseDate(endDate), 1)), {
				representation: 'date',
			}),
		},
		{ startDate, endDate },
		{
			startDate: formatISO(
				startOfMonth(addMonths(parseDate(startDate), 1)),
				{ representation: 'date' },
			),
			endDate: formatISO(endOfMonth(addMonths(parseDate(endDate), 1)), {
				representation: 'date',
			}),
		},
	];

	const queries = useQueries({
		queries: dates.map(({ startDate, endDate }) =>
			getRequest({
				startDate,
				endDate,
				onSubmit,
				dataloader: initialDataloader ?? dataloader,
				enabled: !disabled,
			}),
		),
	});

	const points = queries.map((query, index) =>
		formatData(query?.data, dates[index], dates?.[index + 1]),
	);

	if (disabled) {
		return <Loader animated={loading || importing} />;
	}

	return <ChartView points={points} />;
});

ChartProvider.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
	dataloader: PropTypes.object,
};

export default ChartProvider;
