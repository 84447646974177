import { useCallback, useState } from 'react';

function useStateToggle(defaultValue) {
	const [value, setValue] = useState(defaultValue);

	const toggle = useCallback((next) => {
		setValue((current) => {
			if (current === next) {
				return null;
			}

			return next;
		});
	}, []);

	return [value, toggle];
}

export default useStateToggle;
