import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

const useMediaQuery = (query, invert) => {
	const [match, setMatch] = useState(false);

	const handleResize = useCallback((e) => {
		setMatch(e.matches);
	}, []);

	useEffect(() => {
		const watcher = window.matchMedia(query);
		watcher.addEventListener('change', handleResize);

		handleResize(watcher);

		return () => {
			watcher.removeEventListener('change', handleResize);
		};
	}, [query, handleResize]);

	return useMemo(() => {
		if (invert) {
			return !match;
		}
		return match;
	}, [invert, match]);
};

/**
 * @type { React.FC<{ query: string, invert?: boolean }> }
 */
const MediaQuery = React.memo((props) => {
	const { query, invert, children } = props;
	const match = useMediaQuery(query, invert);

	if (!match) {
		return null;
	}

	return children;
});

MediaQuery.displayName = 'MediaQuery';

MediaQuery.propTypes = {
	query: PropTypes.string,
	invert: PropTypes.bool,
	children: PropTypes.node,
};

MediaQuery.defaultProps = {
	query: '',
	invert: false,
};

export default MediaQuery;
export { useMediaQuery };
